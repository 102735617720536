import React from "react";
import styled from "styled-components";
import ARR_MRR from "../../components/stat-saas/arr_mrr";
import UserGraphEvolution from "../../components/home/userGraphEvolution";
import RevenuStatEvolution from "../../components/home/revenuStatEvolution";
import RevenuStatdiagram from "../../components/home/revenuStatdiagram";

import SubscriptionChange from "./SubscriptionChange";
import GrowthRate from "./GrowthRate";
import ChurnRate from "./ChurnRate";
import EpisodesStat from "../../components/home/EpisodesStat";
import WorkshopsStat from "../../components/home/WorkshopsStat";
import TrailsStat from "../../components/home/TrailsStat";
import ChatEntriesStat from "../../components/home/chatEntriesStat";
import ChatDuration from "../../components/home/chatDuration";
import ChatTopUsers from "../../components/home/chatTopUsers";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import UpsellingRate from "./UpsellingRate";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";




const Wrapper = styled.div`
  width: 95%;
  min-height: ${Height};
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Row = styled.div`
  width: 100%;
  justify-content: space-between;

  display: flex;
  margin-top: 20px;
`;

const ContentWrapper = styled.div`
  background-color: white;
  width: 100%;
  padding: 25px;
  border-radius: 20px;
`;

const SmallColumn = styled.div`
  width: 31%;
  padding: 10px;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  border-radius: 15px;
`;

const RowSection = styled.div`
  width: 100%;
  justify-content: space-between;

  display: flex;
  margin-top: 20px;
`;





const Stats = () => {

    const [data, setData] = useState(null)
    const [data2, setData2] = useState([])
    const [year, setYear] = useState("2023")
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_COMPANY_STAT}/live-chat`).then(res => {
            setData(res.data)
            axios.get(`${process.env.REACT_APP_COMPANY_STAT}/chat-per-agent?year=${year}`).then(chatByAgent => {
                setData2(chatByAgent.data)

            })
        })
    }, [year])



    return (
        <Wrapper>
            <ContentWrapper>
                <ARR_MRR />
                <Row>
                    <SmallColumn>
                        <UserGraphEvolution />
                    </SmallColumn>
                    <SmallColumn>
                        <RevenuStatEvolution />
                    </SmallColumn>
                    <SmallColumn>
                        <RevenuStatdiagram />
                    </SmallColumn>
                </Row>
                <RowSection style={{ alignItems: "stretch" }}>
                    <SmallColumn>
                        <EpisodesStat />
                    </SmallColumn>
                    <SmallColumn>
                        <TrailsStat />
                    </SmallColumn>
                    <SmallColumn>
                        <WorkshopsStat />
                    </SmallColumn>

                </RowSection>
                <Row>

                    <SmallColumn>
                        <ChatEntriesStat />
                    </SmallColumn>
                    {data && <SmallColumn>
                        <ChatDuration year={year} setYear={setYear} data={data} data2={data2} />
                    </SmallColumn>}
                    {data && <SmallColumn>
                        <ChatTopUsers data={data} />
                    </SmallColumn>}
                </Row>
                <div>
                    <SubscriptionChange />
                </div>
                {/** growth rate */}
                <div>
                    <GrowthRate />
                </div>
                {/** churn rate */}
                <div>
                    <ChurnRate />
                </div>
                {/** resume and upselling rate */}
                <div style={{ width: '50%' }}>
                    <UpsellingRate />
                </div>
            </ContentWrapper>
        </Wrapper>
    );
};

export default Stats;

