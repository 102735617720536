import { Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { LoadingBigIcon } from "../../../components/textStyles/style";
import { appointmentStatusWithoutAll } from "../../../data/SelectOptions";
import { ChipText } from "../../../components/tables/styles";
import { ButtonPrimary, ButtonPrimaryLink } from "../../../components/buttonStyles/style";

const Appointments = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);



  // states for number stats
  const [keys, setKeys] = useState([]);
  const [values, setValues] = useState([]);

  useEffect(() => {
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    axios
      .get(process.env.REACT_APP_EXPERT_GET_APPOINTMENTS, config)
      .then((res) => {
        setLoading(false);
        setData(res.data);
        const s = res.data.map((el) => el.status);
        const occurrences = s.reduce(function (acc, curr) {
          return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
        }, {});
        const keys = Object.keys(occurrences);
        const values = Object.values(occurrences);

        setKeys(keys);
        setValues(values);
      });
  }, []);
  return (
    <div style={{ padding: 15 }}>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h5" style={{ fontSize: 18 }} color="#222222">
          Flash Coaching Appointments
        </Typography>
        <Typography
          fontSize={16}

          color="#484848"
          textAlign="center"

        >
          Total : {data.length} appointment(s)
        </Typography>
      </div>


      {loading ? (
        <div style={{ marginTop: 15 }}>
          <LoadingBigIcon />
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>

            {keys.map((val, i) => {
              const label = appointmentStatusWithoutAll.find(
                (s) => s.value === Number(val)
              ).label;
              return (
                <div key={val} style={{ marginTop: 10 }}>
                  <ChipText
                    dark={val === "0"}
                    warning={val === "1"}
                    success={val === "3"}
                    danger={val === "4"}
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                  >
                    {label} ({values[i]})
                  </ChipText>
                </div>
              );
            })}

          </div>
          <ButtonPrimaryLink to="/coaching-appointments" style={{ marginTop: 20 }} >
            Consult Appointments
          </ButtonPrimaryLink>
        </div>
      )}
    </div>
  );
};

export default Appointments;
