import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../../components/buttonStyles/style";
import { SelectInput } from "../../../components/Inputs";
import { LoadingIcon } from "../../../components/textStyles/style";
import ExpertInfo from "./expertInfo";

const LoadWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  margin-top: 25px;
  align-items: center;
`;

const UpdateButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 15px;
  align-items: center;
  justify-content: flex-end;
`;

const CoachingExpert = ({ appointment, setIsLoading }) => {
  const [loader, setLoader] = useState(true);
  const [experts, setExperts] = useState([]);
  const [expert, setExpert] = useState("");
  const [updateExpert, setUpdateExpert] = useState(false);

  const history = useHistory()
  useEffect(() => {
    const getExpertsData = async () => {
      setLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      // get experts list
      const ex = await axios.get(
        `${process.env.REACT_APP_GET_EXPERTS_DROPDOWN}?category=1&calendly=true`,
        config
      );

      setExperts(ex.data);
      if (appointment.expert) {
        const currentExpert = ex.data.find(
          (el) => el.value === appointment.expert
        );

        setExpert(currentExpert.value);

      }
      setLoader(false);
    };
    getExpertsData();
  }, [appointment]);

  const setExpertHandler = async (exp) => {

    setIsLoading(true)

    const TOKEN = localStorage.getItem("TOKEN");
    axios
      .post(
        `${process.env.REACT_APP_COACHING_APPOINTMENT}/set-expert?appointment_id=${appointment._id}`,
        {
          access_token: TOKEN,
          expert: exp,
        }
      )
      .then((res) => {
        setTimeout(() => {
          setIsLoading(false);
          history.go(0)
        }, 1000);

      })
      .catch((err) => {
        console.log(err);

      });

  };

  if (loader) {
    return (
      <LoadWrapper>
        <LoadingIcon />
      </LoadWrapper>
    );
  }

  if (appointment.expert) {
    // update expert
    return (
      <div>
        {updateExpert ? (
          <Row>
            <SelectInput
              values={experts}
              placeholder="Expert"
              value={expert}
              onChange={(e) => setExpertHandler(e.target.value)}
            />
            <ButtonSecondary
              onClick={() => setUpdateExpert(false)}
              style={{ marginLeft: 20 }}
            >
              Cancel
            </ButtonSecondary>
          </Row>
        ) : (
          <div>
            <UpdateButtonContainer>
              <ButtonSecondary onClick={() => setUpdateExpert(true)}>
                Change Expert
              </ButtonSecondary>
            </UpdateButtonContainer>
            <ExpertInfo
              appointment_id={appointment._id}
              expert={appointment.expert_data[0]}
            />
          </div>
        )}
      </div>
    );
  } else {
    // choose an expert to appointment
    return (
      <div >
        <SelectInput
          values={experts}
          placeholder="Choose un expert"
          value={expert}
          onChange={(e) => setExpertHandler(e.target.value)}
        />
      </div>



    );
  }
};

export default CoachingExpert;
