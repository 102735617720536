import styled from "styled-components";

export const Image = styled.img`
  width: 150px;
  height: 90px;
  border-radius: 10px;
`;

export const ChipText = styled.p`
  font-family: "Oxygen";
  font-weight: 600;
  font-size: 13px;
  padding: 6px 3px;
  text-align: center;
  border-radius: 6px;
  background-color: ${({ success, danger, warning, dark }) =>
    success
      ? "#A0C460"
      : danger
        ? "#c22222"
        : warning
          ? "#e8b041"
          : dark
            ? "#242424"
            : "#1b4965"};
  color: white;
  margin: 0px;
`;
