import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ButtonPrimary, ButtonSecondary } from "../buttonStyles/style";
import { H6, LoadingIcon, SmallBoldText } from "../textStyles/style";
import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import PhoneInput from "react-phone-number-input";
import { genderOptions } from "../../data/SelectOptions";
import { Input, SelectInput } from "../Inputs";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;
const PhoneInputWrapper = styled.div`
  border: none;
  overflow: hidden;
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export default function UpdateCompanyContantModal({
  isOpen,
  setIsOpen,
  company,
  contact,
  setSelectedContact,
}) {
  const history = useHistory();
  const [gender, setGender] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [mobile, setMobile] = useState("");
  const [phone, setPhone] = useState("");
  const [poste, setPoste] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [submitLoader, setSubmitLoader] = React.useState(false);
  const handleClose = () => {
    if (!submitLoader) {
      setIsOpen(false);
      setErrorMessage("");
      setSelectedContact(null);
    }
  };

  useEffect(() => {
    setGender(contact.gender);
    setFirstname(contact.firstname);
    setLastname(contact.lastname);
    setPoste(contact.poste);
    setMobile(contact.mobile);
    setPhone(contact.phone);
    setEmail(contact.email);
  }, [contact._id]);

  const submitHandler = () => {
    if (!submitLoader) {
      setErrorMessage("");
      setSubmitLoader(true);
      const re = /\S+@\S+\.\S+/;
      const isValid = re.test(email);

      if (gender == "") {
        setErrorMessage("Please fill civility");
        setSubmitLoader(false);
      } else if (firstname.length < 2) {
        setErrorMessage("Firstname invalid");
        setSubmitLoader(false);
      } else if (lastname.length < 2) {
        setErrorMessage("Lastname invalid");
        setSubmitLoader(false);
      } else if (!isValid) {
        setErrorMessage("address mail invalid");
        setSubmitLoader(false);
      } else {
        const TOKEN = localStorage.getItem("TOKEN");
        axios
          .put(process.env.REACT_APP_COMPANY_CONTACT, {
            access_token: TOKEN,
            gender,
            firstname,
            lastname,
            email,
            id: contact._id,
            phone,
            mobile,
            poste,
            email,
          })
          .then(() => {
            setSubmitLoader(false);
            history.go(0);
          })
          .catch((err) => {
            setSubmitLoader(false);
            console.log(err);
          });
      }
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
      <TitleContainer>
        <H6>Update Contact</H6>
      </TitleContainer>

      <DialogContent>
        {/** form */}
        {/** civilité + poste */}
        <Row>
          <SelectInput
            values={genderOptions}
            placeholder="Civility"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          />
          <Input
            placeholder="Position"
            value={poste}
            onChange={(e) => setPoste(e.target.value)}
          />
        </Row>
        {/** firstname - lastname */}
        <Row>
          <Input
            placeholder="Firstname"
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
          />
          <Input
            placeholder="Lastname"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />
        </Row>
        <Row>
          <Input
            placeholder="Address mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Row>
        {/** mobile + fix phones */}
        <Row style={{ marginBottom: 20 }}>
          <PhoneInputWrapper>
            <PhoneInput
              placeholder="Mobile phone"
              value={mobile}
              onChange={setMobile}
              defaultCountry="FR"
              international={false}
              rules={{ required: true }}
            />
          </PhoneInputWrapper>
          <PhoneInputWrapper>
            <PhoneInput
              placeholder="Landline phone"
              value={phone}
              onChange={setPhone}
              defaultCountry="FR"
              international={false}
              rules={{ required: true }}
            />
          </PhoneInputWrapper>
        </Row>
        {/** error message */}
        <SmallBoldText
          style={{ textAlign: "center", fontSize: 12, color: "#c22222" }}
        >
          {errorMessage ? errorMessage : " - "}
        </SmallBoldText>
      </DialogContent>

      <DialogActions style={{ marginTop: -10, marginBottom: 15 }}>
        <ButtonPrimary onClick={submitHandler}>
          {submitLoader ? <LoadingIcon color="#ffffff" /> : "Update"}
        </ButtonPrimary>

        <ButtonSecondary onClick={handleClose}>Close</ButtonSecondary>
      </DialogActions>
    </Dialog>
  );
}
