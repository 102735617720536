import { useEffect, useState } from "react";
import {
  Content,
  ContentWrapper,
  SwiperItemContainer,
  SwiperWrapper,
  RollOverWrapper,
  AddItemContainer,
  AddItemImage,
} from "./style";
import { H6, LoadingBigIcon } from "../textStyles/style";
import axios from "axios";
import LazyLoading from "../lazyLoading";
import SwiperItem from "./SwiperItem";
import RollOver from "./Rollover";
import ArrowComponent from "../buttonStyles/ArrowComponent";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./styles.css";
// import required modules
import { Keyboard, Navigation } from "swiper";
import addImage from "../../images/main/add_ressource.png";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import TrailsTable from "../tables/trails";
import WorkshopsTable from "../tables/workshops";

const LoadWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
`;

const SwiperComponent = ({ Endpoint, Type = "Trail", listMode }) => {
  const history = useHistory();
  const [Data, setData] = useState();
  const [loader, setLoader] = useState(true);

  const [active, setActive] = useState(null);

  useEffect(() => {
    const getData = async () => {
      setLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      const Response = await axios.get(Endpoint, config);

      setData([...Response.data]);
      setLoader(false);
    };
    setTimeout(() => {
      getData();
    }, 500);
  }, [Endpoint]);

  if (listMode) {
    return (
      <ContentWrapper>
        {loader ? (
          <LoadWrapper>
            <LoadingBigIcon />
          </LoadWrapper>
        ) : Type === "Trail" ? (
          <TrailsTable data={Data} />
        ) : (
          <WorkshopsTable data={Data} />
        )}
      </ContentWrapper>
    );
  }

  return (
    <SwiperWrapper isontop={Type === "Trail" ? 1 : 0}>
      <ContentWrapper>
        {loader ? (
          <>
            <SwiperItemContainer to="/">
              <LazyLoading />
            </SwiperItemContainer>
          </>
        ) : (
          <Content>
            <Swiper
              spaceBetween={30}
              slidesPerView={"auto"}
              keyboard={{
                enabled: true,
              }}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              modules={[Keyboard, Navigation]}
            >
              <SwiperSlide>
                <AddItemContainer
                  onClick={() =>
                    history.push(
                      Type === "Trail" ? "/new-trail" : "/new-workshop"
                    )
                  }
                >
                  <H6 color="#ffffff" style={{ position: "absolute" }}>
                    {Type === "Trail" ? "+ Add Trail" : "+ Add Workshop"}
                  </H6>
                  <AddItemImage src={addImage} />
                </AddItemContainer>
              </SwiperSlide>
              {Data.map((item, index) => {
                const isActive = index === active ? 1 : 0;
                return (
                  <SwiperSlide
                    key={index}
                    onMouseEnter={() => setActive(index)}
                    onMouseLeave={() => setActive(null)}
                  >
                    <SwiperItem Type={Type} item={item} active={isActive} />

                    <RollOverWrapper active={isActive}>
                      <RollOver Type={Type} item={item} />
                    </RollOverWrapper>
                  </SwiperSlide>
                );
              })}
              <div className="swiper-button-next">
                <ArrowComponent direction="right" Width="16px" Height="7px" />
              </div>
              <div className="swiper-button-prev">
                <ArrowComponent direction="left" Width="16px" Height="7px" />
              </div>
            </Swiper>
          </Content>
        )}
      </ContentWrapper>
    </SwiperWrapper>
  );
};

export default SwiperComponent;
