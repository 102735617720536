import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ButtonPrimary, ButtonSecondary } from "../buttonStyles/style";
import { H6, LoadingIcon, SmallBoldText } from "../textStyles/style";
import axios from "axios";
import { Alert } from "@mui/material";
import { Input, SelectInput } from "../Inputs";
import styled from "styled-components";
import { coachingMailsOptions } from "../../data/SelectOptions";
import { UserContext } from "../../App";
import { useHistory } from "react-router-dom";

const InputContainer = styled.div`
  margin-top: 20px;
`;

export default function SendCoachingEmailsModal({
  email,
  isOpen,
  setIsOpen,
  time,
  customer,
  appointment_id,
}) {
  const history = useHistory();
  const [text, setText] = React.useState("");
  const [object, setObject] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [submitLoader, setSubmitLoader] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [type, setType] = React.useState("");
  const { user } = React.useContext(UserContext);
  const expert = user.expert_data[0];

  const handleClose = () => {
    if (!submitLoader) {
      setIsOpen(false);
      setErrorMessage("");
      setShowSuccess(false);
    }
  };

  const submitHandler = () => {
    if (!submitLoader) {
      setErrorMessage("");
      setSubmitLoader(true);
      if (text.length < 4) {
        setErrorMessage("message too short");
        setSubmitLoader(false);
      } else if (object.length < 4) {
        setErrorMessage("objcet too short");
        setSubmitLoader(false);
      } else {
        const t = text.split("\n");
        const TOKEN = localStorage.getItem("TOKEN");
        axios
          .post(process.env.REACT_APP_SEND_EMAIL, {
            email,
            object,
            text: t,
            access_token: TOKEN,
            coaching: true,
            appointment_id: appointment_id,
          })
          .then((res) => {
            setShowSuccess(true);
            setObject("");
            setText("");
            setType("");
            setSubmitLoader(false);
            setTimeout(() => {
              history.go(0);
            }, 600);
          })
          .catch((err) => {
            setSubmitLoader(false);
            console.log(err);
          });
      }
    }
  };

  const objectPlanification = "Planification RDV Flash Coaching";
  const messagePlanification = `
Bonjour ${customer.firstname} ${customer.lastname},

Je suis ${expert.firstname} ${expert.lastname}, votre coach LISL UP.
  
Je vous propose de planifier votre RDV en cliquant sur le lien : ${expert.calendly}
  
Vous recevrez automatiquement un mail avec le lien Zoom pour notre visio de 30mn. 

A bientôt sur les chemins de LISL.
  `;

  const handleTypeChange = (e) => {
    const t = e.target.value;
    setType(t);
    if (t === "other") {
      setObject("");
      setText("");
    } else {
      setObject(t === "planification" ? objectPlanification : "");
      setText(t === "planification" ? messagePlanification : "");
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen}>
      <DialogContent>
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 20,
            marginTop: 10,
          }}
        >
          <H6> send mail to {email}</H6>
        </div>

        {showSuccess && <Alert severity="success">Email has been send !</Alert>}

        <SelectInput
          values={coachingMailsOptions}
          placeholder="Choose email modal"
          value={type}
          onChange={(e) => handleTypeChange(e)}
        />

        <InputContainer>
          <Input
            placeholder="LISL UP - Object"
            value={object}
            onChange={(e) => setObject(e.target.value)}
          />
        </InputContainer>
        <InputContainer style={{ marginBottom: 5 }}>
          <Input
            multiline
            rows={8}
            placeholder="LISL UP - Mail "
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </InputContainer>
        <SmallBoldText
          style={{ textAlign: "center", fontSize: 12, color: "#c22222" }}
        >
          {errorMessage ? errorMessage : " - "}
        </SmallBoldText>
      </DialogContent>
      <DialogActions style={{ marginTop: -20, marginBottom: 15 }}>
        {!showSuccess && (
          <ButtonPrimary onClick={submitHandler}>
            {submitLoader ? <LoadingIcon color="#fff" /> : "Send mail"}
          </ButtonPrimary>
        )}
        <ButtonSecondary onClick={handleClose}>Close</ButtonSecondary>
      </DialogActions>
    </Dialog>
  );
}
