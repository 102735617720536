import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import axios from "axios";
import ChartDataLabels from "chartjs-plugin-datalabels";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const CompanyCoachingStat = ({ company }) => {
  const [select, setSelect] = useState("months");
  const [responseData, setResponseData] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    const getData = async () => {
      axios
        .get(
          `${process.env.REACT_APP_COMPANY_COACHING_STAT}?company_id=${company}&select=${select}`
        )
        .then((res) => {
          setResponseData(res.data.map((el) => el.count));

          setLabels(
            res.data.map((el) =>
              select === "days"
                ? `${el._id.day}/${el._id.month}/${el._id.year}`
                : select === "months"
                  ? `${el._id.month}/${el._id.year}`
                  : el._id.year
            )
          );

        });
    };
    getData();
  }, [select, company]);

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Sold Appointment(s)",
        data: responseData,
        borderColor: "rgb(128, 179, 87)",
        borderWidth: 3,
        backgroundColor: "rgba(128, 179, 87, 0.3)",
      },
    ],
  };


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      datalabels: {
        display: (context) => context.dataset.data[context.dataIndex] !== 0,
        align: "left", // You can adjust label alignment (top, center, bottom)
        color: "rgb(128,179,87)", // Label color
        font: {
          weight: "bold", // Label font weight
          size: 12
        },
      },
    },
  };

  return (
    <div>
      {/* <Button
          style={{ marginRight: 8 }}
          onClick={() => setSelect("days")}
          variant="contained"
          color={select !== "days" ? "inherit" : "primary"}
        >
          Last 7 days
        </Button>
        <Button
          style={{ marginRight: 8 }}
          onClick={() => setSelect("months")}
          variant="contained"
          color={select !== "months" ? "inherit" : "primary"}
        >
          Last 12 months
        </Button>
        <Button
          style={{ marginRight: 8 }}
          onClick={() => setSelect("years")}
          variant="contained"
          color={select !== "years" ? "inherit" : "primary"}
        >
          Last 5 years
  </Button>*/}
      <div>
        <Line data={data} options={options} plugins={[ChartDataLabels]} />
      </div>
    </div>
  );
};

export default CompanyCoachingStat;
