import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ButtonPrimary, ButtonSecondary } from "../buttonStyles/style";
import { H6, LoadingIcon, SmallBoldText } from "../textStyles/style";
import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import { Input } from "../Inputs";
import { H7 } from "../../components/textStyles/style";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export default function AddCompagne({
    isOpen,
    setIsOpen,
    company,
    getCompanyData,
    compagnes
}) {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [name, setName] = useState('')
    const [errorMessage, setErrorMessage] = React.useState("");
    const [submitLoader, setSubmitLoader] = React.useState(false);
    const [heritQuestionsFromLastCompaign, setHeritQuestionsFromLastCompaign] = useState(false)

    const handleClose = () => {
        if (!submitLoader) {
            setIsOpen(false);
            setErrorMessage("");
            setStartDate("");
            setEndDate("");
            setName('')
            setSubmitLoader(false);
        }
    };

    const submitHandler = () => {
        if (!submitLoader) {
            setSubmitLoader(true);
            if (name == "") {
                setErrorMessage("Please choose a compain name");
                setSubmitLoader(false);
            }
            else if (startDate == "" || endDate == "") {
                setErrorMessage("Please select a valid start and end date for the compaign");
                setSubmitLoader(false);
            } else {
                axios
                    .post(
                        `${process.env.REACT_APP_QESTIONNAIRES}/add-compagne?company=${company}`,
                        {
                            startDate,
                            endDate,
                            name,
                            heritQuestionsFromLastCompaign
                        }
                    )
                    .then((res) => {
                        handleClose();
                        getCompanyData();
                    }).catch((error) => {
                        if (error.response) {
                            const status = error.response.status;
                            if (status === 400) {
                                // Handle status 400 (Bad Request)
                                setErrorMessage("Please choose a valid start date");
                                setSubmitLoader(false);
                            } else if (status === 403) {
                                // Handle status 403 (Forbidden)
                                setErrorMessage("Please choose a valid start & end date !");
                                setSubmitLoader(false);
                            } else if (status === 409) {
                                setErrorMessage("There is another active compaign in this range of dates !");
                                setSubmitLoader(false);
                            } else {
                                // Handle other status codes
                                setErrorMessage("There is a problem with this request !");
                                setSubmitLoader(false);
                            }
                        } else {
                            // Handle other types of errors
                            console.log("Error:", error);
                        }
                    });
            }
        }
    };

    return (
        <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
            <TitleContainer>
                <H6>Add Campaign</H6>
            </TitleContainer>

            <DialogContent>
                <SmallBoldText
                    style={{ textAlign: "center", fontSize: 12, color: "#c22222" }}
                >
                    {errorMessage ? errorMessage : " - "}
                </SmallBoldText>
                <Row>
                    <Input
                        placeholder="Name *"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Row>
                <Row>
                    <div style={{ width: "100%" }}>
                        <H7 style={{ marginBottom: 8 }}>Start Date *</H7>
                        <Input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </div>
                    <div style={{ width: "100%" }}>
                        <H7 style={{ marginBottom: 8 }}>End Date *</H7>
                        <Input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </div>
                </Row>
                <Row>
                    {compagnes.length > 0 &&
                        <FormControlLabel
                            style={{ marginRight: 30, }}
                            control={
                                <Checkbox
                                    checked={heritQuestionsFromLastCompaign}
                                    onChange={() => setHeritQuestionsFromLastCompaign(!heritQuestionsFromLastCompaign)}
                                    color="success"
                                />
                            }
                            label="if checked. The new compaign will automatically herit the questions of the previous compaign."
                        />
                    }
                </Row>
            </DialogContent>

            <DialogActions style={{ marginTop: -10, marginBottom: 15 }}>
                <ButtonPrimary onClick={submitHandler}>
                    {submitLoader ? <LoadingIcon color="#ffffff" /> : "Save Compagne"}
                </ButtonPrimary>

                <ButtonSecondary onClick={handleClose}>Close</ButtonSecondary>
            </DialogActions>
        </Dialog>
    );
}
