import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ButtonPrimary, ButtonSecondary } from "../buttonStyles/style";
import { H6, LoadingIcon, SmallBoldText } from "../textStyles/style";
import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import { Input } from "../Inputs";
import { useHistory } from "react-router-dom";
import { FormHelperText } from "@mui/material";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export default function AddReportCompaign({
    isOpen,
    setIsOpen,
    selectedCompagne,
    setSelectedCompagne,
    getCompanyData
}) {
    const history = useHistory();
    const [file, setFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [submitLoader, setSubmitLoader] = useState(false);

    const handleClose = () => {
        if (!submitLoader) {
            setIsOpen(false);
            setFile(null);
            setErrorMessage("");
            setSelectedCompagne(null);
            setSubmitLoader(false)
        }
    };

    const handleFileChange = (event) => {
        const uploadedFile = event.target.files[0];
        setFile(uploadedFile);
    };

    const submitHandler = async (event) => {
        if (!submitLoader) {
            setErrorMessage("");
            if (file == null) {
                setSubmitLoader(false);
                setErrorMessage("Please select a pdf file to upload.");
            } else {
                const fileExtension = file.name.split(".").pop();
                if (fileExtension === "pdf") {
                    setSubmitLoader(true);
                    event.preventDefault();

                    const formData = new FormData();
                    formData.append("file", file);
                    formData.append("compagne", selectedCompagne._id);
                    //formData.append("name", name);

                    try {
                        await axios.post(
                            `${process.env.REACT_APP_QESTIONNAIRES}/add-compaign-report`,
                            formData
                        )

                        handleClose()
                        getCompanyData()
                    } catch (error) {
                        setSubmitLoader(false);
                        console.log(error);
                        alert(error);
                    }
                } else {
                    setSubmitLoader(false);
                    setErrorMessage("Wrong Document. Only PDF files are accepted!");
                }
            }
        }
    };
    if (setSelectedCompagne == null) {
        return <></>;
    }
    return (
        <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
            <TitleContainer>
                <H6>Add New Report</H6>
            </TitleContainer>

            <DialogContent>
                {/** form */}

                <Row style={{ width: "100%" }}>
                    <Input id="file" type="file" onChange={handleFileChange} />
                    <FormHelperText>Only PDF files are allowed.</FormHelperText>
                </Row>
                <SmallBoldText
                    style={{ textAlign: "center", fontSize: 12, color: "#c22222" }}
                >
                    {errorMessage ? errorMessage : " - "}
                </SmallBoldText>
            </DialogContent>

            <DialogActions style={{ marginTop: -10, marginBottom: 15 }}>
                <ButtonPrimary onClick={submitHandler}>
                    {submitLoader ? <LoadingIcon color="#ffffff" /> : "Save"}
                </ButtonPrimary>

                <ButtonSecondary onClick={handleClose}>Close</ButtonSecondary>
            </DialogActions>
        </Dialog>
    );
}
