import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ButtonPrimary, ButtonSecondary } from "../buttonStyles/style";
import { H6, LoadingIcon, SmallBoldText } from "../textStyles/style";
import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import { Input } from "../Inputs";
import { useHistory } from "react-router-dom";
import { FormHelperText } from "@mui/material";
import moment from "moment";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export default function AddInvoiceModal({
  isOpen,
  setIsOpen,
  company_id,
  setSelectedContract,
  selectedContract,
}) {
  const history = useHistory();
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [name, setName] = useState("");

  const handleClose = () => {
    if (!submitLoader) {
      setIsOpen(false);
      setFile(null);
      setDate("");
      setName("");
      setErrorMessage("");
      setAmount("");
      setSelectedContract(null);
    }
  };

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
  };

  const submitHandler = async (event) => {
    if (!submitLoader) {
      setErrorMessage("");
      if (file == null) {
        setSubmitLoader(false);
        setErrorMessage("Please select a pdf file to upload.");
      } else if (amount === "") {
        setSubmitLoader(false);
        setErrorMessage("Please select a valid amount.");
      } else {
        const fileExtension = file.name.split(".").pop();
        if (fileExtension === "pdf") {
          setSubmitLoader(true);
          event.preventDefault();

          const formData = new FormData();
          formData.append("file", file);
          formData.append("date", date);
          formData.append("amount", amount);
          formData.append("contract", selectedContract);
          formData.append("name", name);

          try {
            await axios.post(
              `${process.env.REACT_APP_CONTRACTS}/add-invoice?company_id=${company_id}`,
              formData
            );
            history.go(0);
            setSubmitLoader(false);
          } catch (error) {
            setSubmitLoader(false);
            console.log(error);
            alert(error);
          }
        } else {
          setSubmitLoader(false);
          setErrorMessage("Wrong Document. Only PDF files are accepted!");
        }
      }
    }
  };
  if (selectedContract == null) {
    return <></>;
  }
  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
      <TitleContainer>
        <H6>Add New Invoice</H6>
      </TitleContainer>

      <DialogContent>
        {/** form */}
        <Row>
          <Input
            placeholder="Invoice Reference ..."
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Row>
        <Row>
          <Input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </Row>
        <Row>
          <Input
            type="number"
            placeholder="amount in € (HT)*"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </Row>

        <Row style={{ width: "100%" }}>
          <Input id="file" type="file" onChange={handleFileChange} />
          <FormHelperText>Only PDF files are allowed.</FormHelperText>
        </Row>
        <SmallBoldText
          style={{ textAlign: "center", fontSize: 12, color: "#c22222" }}
        >
          {errorMessage ? errorMessage : " - "}
        </SmallBoldText>
      </DialogContent>

      <DialogActions style={{ marginTop: -10, marginBottom: 15 }}>
        <ButtonPrimary onClick={submitHandler}>
          {submitLoader ? <LoadingIcon color="#ffffff" /> : "Save"}
        </ButtonPrimary>

        <ButtonSecondary onClick={handleClose}>Close</ButtonSecondary>
      </DialogActions>
    </Dialog>
  );
}
