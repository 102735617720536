import axios from "axios";
import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const Transformation = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      if (data.length === 0) {
        setLoader(true);
      }
      try {
        const TOKEN = localStorage.getItem("TOKEN");
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${TOKEN}`,
          },
        };
        const response = await axios.get(
          `${process.env.REACT_APP_GET_TRANS}?year=${year}`,
          config
        );
        setData(response.data);
        setLoader(false);
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, [year]);

  // CHART MONTH

  const ChartdataYear = {
    labels: [year, year - 1, year - 2, year - 3, year - 4],
    datasets: [
      {
        label: "Taux de transformation (%)",
        data: [
          data.trans && data.trans[0]
            ? data.trans[0].transformation_rate * 100
            : 0,
          data.trans && data.trans[1]
            ? data.trans[1].transformation_rate * 100
            : 0,
          data.trans && data.trans[2]
            ? data.trans[2].transformation_rate * 100
            : 0,
          data.trans && data.trans[3]
            ? data.trans[3].transformation_rate * 100
            : 0,
          data.trans && data.trans[4]
            ? data.trans[4].transformation_rate * 100
            : 0,
        ],
        backgroundColor: "#FF8C00",
        borderColor: "#FF8C00",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Taux de transformation (%)",
          font: {
            size: 14,
            weight: "bold",
          },
        },
        ticks: {
          stepSize: 10,
          callback: function (value) {
            return value + "%";
          },
        },
      },
      x: {
        title: {
          display: true,
          text: "Année",
          font: {
            size: 14,
            weight: "bold",
          },
        },
      },
    },
  };

  return (
    <div>
      <Line data={ChartdataYear} options={options} />
    </div>
  );
};

export default Transformation;
