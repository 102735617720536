import { useContext } from "react";
import styled from "styled-components";
import { UserContext } from "../../App";
import UserAccount from "./userAccount";
import { Typography } from "@mui/material";
import Appointments from "./expert-home/Appointments";
import {
  ButtonPrimary,
  ButtonPrimaryLink,
} from "../../components/buttonStyles/style";
import { useHistory } from "react-router-dom";
import { AccessAlarm, Receipt } from "@material-ui/icons";
let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100%;
  min-height: ${Height};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;

const ExpertHome = () => {
  const { user } = useContext(UserContext);
  const expert = user.expert_data[0];

  const isAllowedToChat = expert.category.find(
    (cat) => Number(cat.value) === 10
  );
  const history = useHistory();



  return (
    <Wrapper>
      <ContentWrapper>
        <UserAccount />
        {/** options : Flash coaching appointments - history payments - chat bot */}
        {/** appointments */}
        <div style={{ backgroundColor: '#f5f5f5', borderRadius: 15 }}>
          <Appointments />
        </div>
        {/** history payments */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ backgroundColor: '#f5f5f5', borderRadius: 15, padding: 15, width: 400, marginTop: 15 }}>
            <Typography variant="h5" style={{ fontSize: 18, marginBottom: 15, }} color="#222222">
              Payments
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <ButtonPrimaryLink to="/expert-payment" width="300px"><Receipt style={{ marginRight: 5 }} />Consult Payments</ButtonPrimaryLink>

            </div>
          </div>
          {/** chat bot */}
          <div >
            {isAllowedToChat && (
              <div style={{ backgroundColor: '#f5f5f5', borderRadius: 15, padding: 15, width: 400, marginTop: 15, marginLeft: 15 }}>
                <Typography variant="h5" style={{ fontSize: 18, marginBottom: 15, }} color="#222222">
                  Live Chat
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                  <ButtonPrimaryLink to="/chat" width="300px">
                    <AccessAlarm style={{ marginRight: 5 }} />
                    Consult Chat schedule
                  </ButtonPrimaryLink>
                </div>
              </div>
            )}
          </div>
        </div>

      </ContentWrapper>
    </Wrapper>
  );
};

export default ExpertHome;
