import styled from "styled-components";
import { H5, H6 } from "../textStyles/style";
import axios from "axios";

import TextField from "@material-ui/core/TextField";
import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const Wrapper = styled.div`
  width: 100%;

  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  padding-bottom: 20px;
  padding-top: 10px;
  border-radius: 15px;
`;

const RowCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ARR_MRR = () => {
  const [year2, setYear2] = useState(new Date().getFullYear());
  const [year3, setYear3] = useState(new Date().getFullYear());
  const [year, setYear] = useState(new Date().getFullYear());
  const [loader, setLoader] = useState(false);

  const [ARR, setARR] = useState([]);
  const [MRR, setMRR] = useState([]);
  const [CLTV, setCLTV] = useState([]);
  const [CLT, setCLT] = useState([]);
  const [ARPS, setARPS] = useState([]);

  const getData = async () => {
    if (ARR.length === 0) {
      setLoader(true);
    }
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    axios
      .get(`${process.env.REACT_APP_GET_ARR}?year=${year}`, config)
      .then(async (res) => {
        setARR(res.data);

        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData2 = async () => {
    if (MRR.length === 0) {
      setLoader(true);
    }
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    axios
      .get(`${process.env.REACT_APP_GET_MRR}?year=${year2}`, config)
      .then(async (res) => {
        setMRR(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData3 = async () => {
    if (CLTV.length === 0) {
      setLoader(true);
    }
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    axios
      .get(`${process.env.REACT_APP_GET_CLTV}`, config)
      .then(async (res) => {
        setCLTV(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData4 = async () => {
    if (ARPS.length === 0) {
      setLoader(true);
    }
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    axios
      .get(`${process.env.REACT_APP_GET_ARPS}?year=${year3}`, config)
      .then(async (res) => {
        setARPS(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData5 = async () => {
    if (CLTV.length === 0) {
      setLoader(true);
    }
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    axios
      .get(`${process.env.REACT_APP_GET_CLT}`, config)
      .then(async (res) => {
        setCLT(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
    getData3();
  }, [year]);

  useEffect(() => {
    getData4();
  }, [year3]);

  useEffect(() => {
    getData4();
  }, []);

  useEffect(() => {
    getData2();
  }, [year2]);

  useEffect(() => {
    getData5();
  }, []);

  return (
    <Wrapper>
      {/*<Typography color="#333" fontSize={13} style={{ marginTop: 10 }}>
          MRR = ∑ (monthly price of user's subscription plan / duration of
          user's subscription in months)
          <br></br>
          <br></br>
          ARR B2C = ∑ total of the user's Annual subscription invoice / duration
          of the user's subscription(by month)
          <br></br>
          ARR B2B= ∑ total company contract+avenant / duration of the
          contrats(by month) reducing the price by the number of months of the
          contract in the current year
          <br></br>
          <br></br>
          CLTV = avg_customer_purchase_value / customer_lifetime
          (customer_lifetime = last_purchase_date - first_purchase_date) time
          (month) only with subscription
          <br></br>
          <br></br>
          AB SUB (B2C) and (B2B) = total_revenue / total_subscriptions
  </Typography>*/}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ marginLeft: 15 }}>
          <H5 style={{ fontSize: 20 }}>General Statistics</H5>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: 15,
          }}
        >
          <div
            style={{
              width: 50,
              borderRadius: 10,
              display: "flex",
              height: 40,
              backgroundColor: "#94cbe0",
              alignItems: "center",
              justifyContent: "center",
              marginRight: 8,
            }}
          >
            <Typography fontSize={13} fontWeight="600" color="#333333">
              B2B
            </Typography>
          </div>
          <div
            style={{
              width: 50,
              borderRadius: 10,
              height: 40,
              display: "flex",
              backgroundColor: "#d0e6a8",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography fontSize={13} fontWeight="600" color="#333333">
              B2C
            </Typography>
          </div>
        </div>
      </div>

      <Table >
        <TableHead>
          <TableCell
            align="center"
            style={{ fontWeight: "bold", color: "#1B4965" }}
          >
            ARR
          </TableCell>
          <TableCell
            align="center"
            style={{ fontWeight: "bold", color: "#1B4965" }}
          >
            MRR
          </TableCell>
          <TableCell
            align="center"
            style={{ fontWeight: "bold", color: "#1B4965" }}
          >
            CLTV (monthly)
          </TableCell>
          <TableCell
            align="center"
            style={{ fontWeight: "bold", color: "#1B4965" }}
          >
            CLT
          </TableCell>
          <TableCell
            align="center"
            style={{ fontWeight: "bold", color: "#1B4965" }}
          >
            Average Basket (SUB) (B2C)
          </TableCell>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center" style={{ color: "#1B4965" }}>
              <TextField
                id="year"
                label="Year"
                style={{ width: "25%" }}
                type="number"
                value={year}
                onChange={(e) => setYear(e.target.value)}
              />
            </TableCell>
            <TableCell align="center" style={{ color: "#1B4965" }}>
              <RowCenter>
                <TextField
                  id="year2"
                  label="Year2"
                  style={{ width: "25%" }}
                  type="number"
                  value={year2}
                  onChange={(e) => setYear2(e.target.value)}
                />
              </RowCenter>
            </TableCell>
            <TableCell align="center" style={{ color: "#1B4965" }}></TableCell>
            <TableCell align="center" style={{ color: "#1B4965" }}></TableCell>
            <TableCell align="center" style={{ color: "#1B4965" }}>
              <RowCenter>
                <TextField
                  id="year3"
                  label="Year3"
                  style={{ width: "25%" }}
                  type="number"
                  value={year3}
                  onChange={(e) => setYear3(e.target.value)}
                />
              </RowCenter>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="center" style={{ color: "#1B4965" }}>
              <RowCenter>
                <div
                  style={{
                    width: "5VW",
                    color: "#1B4965",
                    backgroundColor: "#d0e6a8",
                  }}
                >
                  {ARR.arrTotal ? ARR.arrTotal.toFixed(2) + " €" : "-"}
                </div>

                <div
                  style={{
                    width: "5VW",
                    color: "#1B4965",
                    backgroundColor: "#94cbe0",
                  }}
                >
                  {ARR.arrTotalB2B ? ARR.arrTotalB2B.toFixed(2) + " €" : "-"}
                </div>
              </RowCenter>
            </TableCell>
            <TableCell align="center">
              <RowCenter>
                <Typography
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                  bgcolor={"#d0e6a8"}
                  color="#1b4965"
                >
                  {MRR.MRR ? MRR.MRR.toFixed(2) + " €" : "-"}
                </Typography>
              </RowCenter>
            </TableCell>
            <TableCell align="center" style={{ color: "#1B4965" }}>
              <RowCenter>
                <Typography
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                  bgcolor={"#d0e6a8"}
                  color="#1b4965"
                >
                  {CLTV.total_cltv ? CLTV.total_cltv.toFixed(2) + " €" : "-"}
                </Typography>
              </RowCenter>
            </TableCell>
            <TableCell align="center" style={{ color: "#1B4965" }}>
              <RowCenter>
                <div
                  style={{
                    width: "5VW",
                    color: "#1B4965",
                    backgroundColor: "#d0e6a8",
                  }}
                >
                  {CLT.clt_B2C ? CLT.clt_B2C.toFixed(2) : "Non"} month
                </div>{" "}
                /{" "}
                <div
                  style={{
                    width: "5VW",
                    color: "#1B4965",
                    backgroundColor: "#94cbe0",
                  }}
                >
                  {" "}
                  {CLT.clt_B2B ? CLT.clt_B2B.toFixed(2) : "-"} year{" "}
                </div>
              </RowCenter>
            </TableCell>
            <TableCell align="center" style={{ color: "#1B4965" }}>
              <RowCenter>
                <div
                  style={{
                    width: "5VW",
                    color: "#1B4965",
                    backgroundColor: "#d0e6a8",
                  }}
                >
                  {ARPS.arps ? ARPS.arps.toFixed(2) + " €" : "-"}
                </div>{" "}
                /{" "}
                <div
                  style={{
                    width: "5VW",
                    color: "#1B4965",
                    backgroundColor: "#94cbe0",
                  }}
                >
                  {ARPS.arpsB2B ? ARPS.arpsB2B.toFixed(2) + " €" : "-"}
                </div>
              </RowCenter>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Wrapper>
  );
};

export default ARR_MRR;
