import React, { useEffect, useState } from "react";
import axios from "axios";
import { H5 } from "../../components/textStyles/style";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { reportingOptions } from "../../data/SelectOptions";
import styled from "styled-components";


export const RowBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  border-bottom: 1px #484848 dashed;
  padding-bottom: 2px;
`;


const GlobalEvolution = ({ company, reportType }) => {
  // if reporting list then select . if not all reporting options
  const dataToMap = reportingOptions
  const startArray = Array.from({ length: dataToMap.length }, () => ({ i1: "-", i2: "-", i3: "-", total: "-" }));
  const [data, setData] = useState(startArray)
  const [i1Mean, setI1Mean] = useState(0)
  const [i2Mean, setI2Mean] = useState(0)
  const [i3Mean, setI3Mean] = useState(0)
  const [profiles, setProfiles] = useState(0)
  function calculateArrayMean(arr) {
    const sum = arr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const mean = sum / arr.length;
    return mean * 100;
  }

  const calculatesMeans = (arr) => {

    const i1s = arr.map(el => el.i1).filter(el => el !== "-")
    const i2s = arr.map(el => el.i2).filter(el => el !== "-")
    const i3s = arr.map(el => el.i3).filter(el => el !== "-")

    const i1Mean = calculateArrayMean(i1s)
    const i2Mean = calculateArrayMean(i2s)
    const i3Mean = calculateArrayMean(i3s)

    setI1Mean(i1Mean)
    setI2Mean(i2Mean)
    setI3Mean(i3Mean)

  }


  const TrailScoresRender = ({ el, i }) => {
    const [dataToShow, setDataToShow] = useState({ i1: "-", i2: "-", i3: "-", total: "-" });

    const option = reportingOptions.find(op => op.value === el.value)


    useEffect(() => {
      if (option) {
        axios
          .get(
            `${process.env.REACT_APP_COMPANY_INDICATORS}?trail=${el.value}&company=${company._id}`
          )
          .then((res) => {
            setDataToShow(res.data);
            const newArr = data
            newArr[i] = res.data
            calculatesMeans(newArr)

            setData(newArr)
          });
      }
    }, []);

    useEffect(() => {
      axios.get(`${process.env.REACT_APP_COMPANY_STAT}/profile-stat?company=${company._id}`).then((res) => {
        setProfiles(res.data)
      })
    }, [])

    const isInReportingList = () => {
      if (company.reporting) {
        const element = company.reporting.find(x => x.value == el.value)
        if (element) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }

    const isInReport = isInReportingList()


    if (dataToShow.i1 === "-" && dataToShow.i2 === "-" && dataToShow.i3 === "-") {
      return (
        null
      )
    }

    if (!isInReport) {
      return (
        false
      )
    }

    return (
      <TableRow>
        <TableCell
          align="center"
          style={{
            fontWeight: "bold",
            color: "#111",
            fontSize: 13,
          }}
        >
          {option ? option.label : el.label}
        </TableCell>
        {/** i1 */}

        <TableCell align="center" style={{ color: "#484848", fontSize: 13 }}>
          {dataToShow.i1 !== "-" ? (dataToShow.i1 * 100).toFixed(2) + " %" : "-"}
        </TableCell>
        {/** i2 */}

        <TableCell align="center" style={{ color: "#484848", fontSize: 13 }}>
          {dataToShow.i2 !== "-" ? (dataToShow.i2 * 100).toFixed(2) + " %" : "-"}
        </TableCell>
        {/** i3 */}
        <TableCell align="center" style={{ color: "#484848", fontSize: 13 }}>
          {dataToShow.i3 !== "-" ? (dataToShow.i3 * 100).toFixed(2) + " %" : "-"}
        </TableCell>
      </TableRow>
    );
  };


  return (
    <div>
      <div
        style={{
          width: "48%",
          borderRight: "0.25px #484848 solid",
          paddingRight: "2%",
          marginBottom: 30
        }}
      >
        <H5 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
          {reportType === "full" ? '13.' : "8."} Percentage Profile
        </H5>
        <RowBetween>
          <Typography style={{ color: "#363636" }} fontSize={13}>
            {profiles} Profile(s) / {company.current_users} user(s)
          </Typography>
          <Typography fontSize={13} color="#484848">
            {Number((profiles / company.current_users) * 100).toFixed(2)}
          </Typography>
        </RowBetween>
      </div>
      <H5 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
        {reportType === "full" ? '14.' : "9."} Global Evolution
      </H5>
      <div style={{ margin: 10, marginTop: 0 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableCell
                align="center"
                style={{
                  fontWeight: "bold",
                  color: "#444",
                  fontSize: 13,
                }}
              ></TableCell>
              <TableCell
                align="center"
                style={{
                  fontWeight: "bold",
                  color: "#222",
                  fontSize: 13,
                }}
              >
                I1
              </TableCell>
              <TableCell
                align="center"
                style={{
                  fontWeight: "bold",
                  color: "#222",
                  fontSize: 13,
                }}
              >
                I2
              </TableCell>
              <TableCell
                align="center"
                style={{
                  fontWeight: "bold",
                  color: "#222",
                  fontSize: 13,
                }}
              >
                I3
              </TableCell>
            </TableHead>
            <TableBody>
              {dataToMap.map((el, i) => {


                return <TrailScoresRender el={el} key={el.label} i={i} />;
              })
              }
              {/** mean  */}
              <TableRow>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: "bold",
                    color: "#66854e",
                    fontSize: 13,
                  }}
                >
                  All Trails
                </TableCell>
                {/** i1 */}

                <TableCell align="center" style={{
                  fontWeight: "bold",
                  color: "#66854e",
                  fontSize: 13,
                }}>
                  {i1Mean.toFixed(2) + ' %'}
                </TableCell>
                {/** i2 */}

                <TableCell align="center" style={{
                  fontWeight: "bold",
                  color: "#66854e",
                  fontSize: 13,
                }}>
                  {i2Mean.toFixed(2) + ' %'}
                </TableCell>
                {/** i3 */}
                <TableCell align="center" style={{
                  fontWeight: "bold",
                  color: "#66854e",
                  fontSize: 13,
                }}>
                  {i3Mean.toFixed(2) + ' %'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

        </TableContainer>
      </div>
    </div>
  );
};

export default GlobalEvolution;
