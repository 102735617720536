import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import { Input, SelectInput } from "../../components/Inputs";
import LazyLoading from "../../components/lazyLoading";
import {
  H5,
  H6,
  LoadingIcon,
  TextBold,
} from "../../components/textStyles/style";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SuccessImage from "../../images/main/green_circle_tick.svg";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const UpdateBook = () => {
  const MySwal = withReactContent(Swal);
  const history = useHistory();
  const { id } = useParams();
  const [showTitle, setShowTitle] = useState("");
  const [titleFr, setTitleFr] = useState("");
  const [titleEn, setTitleEn] = useState("");
  const [descriptionFr, setDescriptionFr] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [image, setImage] = useState("");
  const [price, setPrice] = useState("");
  const [stripe, setStripe] = useState("");
  const [points, setPoints] = useState("");
  const [pricePoints, setPricePoints] = useState("");
  const [stripePoints, setStripePoints] = useState("");
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const TOKEN = localStorage.getItem("TOKEN");
  const [experts, setExperts] = useState([]);
  const [expert, setExpert] = useState({});
  // UPDATE SUBMIT
  const submitHandler = async () => {
    setSubmitLoading(true);
    setErrorMessage("");
    try {
      const data = {
        access_token: TOKEN,
        id,
        title_fr: titleFr,
        title_en: titleEn,
        description_fr: descriptionFr,
        description_en: descriptionEn,
        image,
        price,
        stripe,
        price_points: pricePoints,
        stripe_points: stripePoints,
        link,
        points,
        expert,
      };

      await axios.put(process.env.REACT_APP_GET_BOOK, data);
      setSubmitLoading(false);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      MySwal.fire({
        title: <H5>Modifications Enregisté</H5>,
        html: <></>,
        imageUrl: SuccessImage,
        imageWidth: 200,
        imageHeight: 150,
        imageAlt: "Custom image",
        timer: 1500,
        showConfirmButton: false,

        timerProgressBar: true,
        padding: "2vw",
        showCloseButton: true,
        showClass: {
          popup: "animate__animated animate__fadeInDown animate__fast",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp animate__faster",
        },
      }).then(() => {
        history.goBack();
      });
    } catch (error) {
      setSubmitLoading(false);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      if (error.response.status === 400) {
        setErrorMessage("Données Invalides, Veuillez remplir tous les champs");
      } else {
        setErrorMessage("Probléme de Connexion");
      }
    }
  };

  useEffect(() => {
    const getBookData = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };

      // get experts list
      const ex = await axios.get(
        process.env.REACT_APP_GET_EXPERTS_DROPDOWN,
        config
      );

      setExperts(ex.data);

      const response = await axios.get(
        `${process.env.REACT_APP_GET_BOOK}/?id=${id}`,
        config
      );
      const book = response.data;
      // SET INPUTS TO EPISODE VALUES
      setTitleFr(book.title_fr);
      setShowTitle(book.title_fr);
      setTitleEn(book.title_en);
      setDescriptionFr(book.description_fr);
      setDescriptionEn(book.description_en);
      setImage(book.image);
      setPrice(book.price);
      setStripe(book.stripe);
      setPricePoints(book.price_points);
      setStripePoints(book.stripe_points);
      setPoints(book.points);
      setExpert(book.expert);
      setLoading(false);
    };
    getBookData();
  }, [id, TOKEN]);
  return (
    <Wrapper>
      {loading ? (
        <LazyLoading height={Height} />
      ) : (
        <ContentWrapper>
          <H5>Update Book - {showTitle}</H5>
          <Row>
            <TextBold color="#c22222">
              {errorMessage ? errorMessage : " "}
            </TextBold>
          </Row>
          <div style={{ marginTop: 20 }}>
            <H6>Informations génerales </H6>
          </div>
          {/**  title fr + title en */}
          <Row>
            <Input
              placeholder="Titre - FR"
              value={titleFr}
              onChange={(e) => setTitleFr(e.target.value)}
            />
            <Input
              placeholder="Titre - EN"
              value={titleEn}
              onChange={(e) => setTitleEn(e.target.value)}
            />
          </Row>
          {/**  description fr + description en */}
          <Row>
            <Input
              multiline
              placeholder="Description - FR"
              value={descriptionFr}
              onChange={(e) => setDescriptionFr(e.target.value)}
            />
            <Input
              multiline
              placeholder="Description - EN"
              value={descriptionEn}
              onChange={(e) => setDescriptionEn(e.target.value)}
            />
          </Row>
          {/** expert */}
          <Row>
            <SelectInput
              values={experts}
              placeholder="Ecrivain"
              value={expert}
              onChange={(e) => setExpert(e.target.value)}
            />
          </Row>
          {/**  image + link */}
          <Row style={{ marginTop: 20 }}>
            <Input
              placeholder="Image"
              value={image}
              onChange={(e) => setImage(e.target.value)}
            />
            <Input
              placeholder="Link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
          </Row>
          <div style={{ marginTop: 20 }}>
            <H6>Prix </H6>
          </div>
          {/**  price + stripe */}
          <Row>
            <Input
              type="number"
              placeholder="Prix TTC (XX.XX)"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
            <Input
              placeholder="Stripe Price"
              value={stripe}
              onChange={(e) => setStripe(e.target.value)}
            />
          </Row>
          <div style={{ marginTop: 20 }}>
            <H6>Prix avec jetons</H6>
          </div>
          {/**  price points + stripe points + points */}
          <Row>
            <Input
              type="number"
              placeholder="Prix avec jetons  TTC (XX.XX)"
              value={pricePoints}
              onChange={(e) => setPricePoints(e.target.value)}
            />
            <Input
              type="number"
              placeholder="Jetons"
              value={points}
              onChange={(e) => setPoints(e.target.value)}
            />
            <Input
              type=""
              placeholder="Stripe Price"
              value={stripePoints}
              onChange={(e) => setStripePoints(e.target.value)}
            />
          </Row>

          <Row style={{ marginTop: 40 }}>
            <ButtonPrimary onClick={submitHandler}>
              {submitLoading ? <LoadingIcon color="inherit" /> : "Enregister"}
            </ButtonPrimary>
          </Row>
        </ContentWrapper>
      )}
    </Wrapper>
  );
};

export default UpdateBook;
