import { Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { types } from '../../data/SelectOptions'


const trails = [
    {
        value: "T001",
        label: "La confiance en soi : Recette de l'abondance."
    },
    {
        value: "T002",
        label: "Le chaudron des croyances"
    },
    {
        value: "T003",
        label: "Trouver votre poids idéal"
    },
    {
        value: "T004",
        label: "Objectif: no stress"
    },
    {
        value: "T005",
        label: "Trouvez l'équilibre émotionnel"
    }
]

const Annexe = () => {
    const [trails, setTrails] = useState([])
    const [workshops, setWorkshops] = useState([])
    const [totalWorkshops, setTotalWorkshops] = useState(0)
    useEffect(() => {
        const getData = async () => {
            const TOKEN = localStorage.getItem("TOKEN");
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${TOKEN}`,
                },
            };

            const trailsResponse = await axios.get(
                `${process.env.REACT_APP_GET_TRAILS_DROPDOWN}`,
                config
            );
            setTrails(trailsResponse.data)
            const workshopsResponse = await axios.get(
                `${process.env.REACT_APP_GET_WORKSHOPS}-stats`,
                config
            );
            setWorkshops(workshopsResponse.data.workshops)
            setTotalWorkshops(workshopsResponse.data.count)
        };
        getData()
    }, []);

    return (
        <div style={{ marginTop: 30 }}>
            <Typography color="#000" fontWeight="800" style={{ marginBottom: 30 }} fontSize={18} textAlign="center" >Appendix</Typography>
            <Typography color="#1b4965" fontWeight="600" style={{ marginBottom: 5 }} fontSize={12}>Indicators</Typography>
            <Typography color="#484848" fontSize={12}>
                I1 indicates the improvement when at least one season has been
                completed.
            </Typography>
            <Typography color="#484848" fontSize={12}>
                I2 indicates the rate of users who consider that their problem has
                been solved.
            </Typography>
            <Typography color="#484848" fontSize={12}>
                I3 indicates the development of behavioral capacity.
            </Typography>
            <div style={{ display: 'flex', alignItems: "flex-end", marginTop: 20, marginBottom: 5 }}>
                <Typography color="#1b4965" fontWeight="600" fontSize={12}>Available Trails</Typography>
                <Typography style={{ marginLeft: 5, marginBottom: 2 }} color="#484848" fontWeight="200" fontSize={10}>({trails.length} Trails)</Typography>
            </div>
            {trails.map(el => <Typography key={el.value} color="#484848" fontSize={12}>
                {el.value} - {el.label}
            </Typography>)}
            <div style={{ display: 'flex', alignItems: "flex-end", marginTop: 20, marginBottom: 5 }}>
                <Typography color="#1b4965" fontWeight="600" fontSize={12}>Available Workshops</Typography>
                <Typography style={{ marginLeft: 5, marginBottom: 2 }} color="#484848" fontWeight="200" fontSize={10}>({totalWorkshops} Workshops)</Typography>
            </div>
            <div>
                {
                    workshops.map(item => {
                        const categoryFromOptions = types.find(el => el.value === item._id)
                        return (
                            <div key={item._id}>
                                <Typography color="#484848" fontSize={12}>{item.count} {categoryFromOptions.label} Workshops.</Typography>

                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Annexe