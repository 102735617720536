import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import styled from "styled-components";
import { H5, Text } from "../../components/textStyles/style";
import {
  ButtonPrimary,
  ButtonPrimaryLink,
} from "../../components/buttonStyles/style";
import axios from "axios";
import { SelectInput } from "../../components/Inputs";
import { languages } from "../../data/SelectOptions";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useHistory } from "react-router-dom";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
`;
export const ChipText = styled.p`
  font-family: "Oxygen";
  font-size: 13px;
  padding: 6px 10px;
  text-align: center;
  border-radius: 6px;
  background-color: ${({ success, danger }) =>
    success ? "#A8C492" : danger ? "#c22222" : "#1b4965"};
  color: white;
`;
const ContentWrapper = styled.div`
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px;
`;

const SelectContainer = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

const Questionnaires = () => {
  const [data, setData] = useState([]);
  const [language, setLanguage] = useState("fr");
  const history = useHistory();
  const { i18n } = useTranslation();

  useEffect(() => {
    const getData = async () => {
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      const Response = await axios.get(
        `${process.env.REACT_APP_QESTIONNAIRES}?language=${language}`,
        config
      );

      setData(Response.data);
    };
    getData();
  }, [language]);

  const changeLanguageHandler = (e) => {
    i18n.changeLanguage(e.target.value);
    setLanguage(e.target.value);
  };

  // const ContactsResponse = data;
  return (
    <Wrapper>
      <ContentWrapper>
        <Row>
          <ButtonPrimary
            onClick={() => history.push("/new-questionnaire")}
            style={{ width: 300 }}
          >
            Add new Questionnaire
          </ButtonPrimary>

          <H5>Questionnaires</H5>
          <SelectContainer>
            <div style={{ width: 180, marginRight: 15 }}>
              <SelectInput
                values={languages}
                placeholder="Language"
                value={language}
                onChange={changeLanguageHandler}
              />
            </div>
            <Text style={{ marginLeft: 5 }}>{data.length} result(s) found</Text>
          </SelectContainer>
        </Row>
        <TableContainer>
          <Table>
            <TableHead>
              <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                Name
              </TableCell>

              <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                Description
              </TableCell>
              <TableCell
                align="center"
                style={{ fontWeight: "bold", color: "#1B4965" }}
              >
                Questions Number
              </TableCell>
              <TableCell
                align="center"
                style={{ fontWeight: "bold", color: "#1B4965" }}
              >
                Published
              </TableCell>
              <TableCell
                align="center"
                style={{ fontWeight: "bold", color: "#1B4965" }}
              >
                CreatedAt
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", color: "#1B4965" }}
              ></TableCell>
            </TableHead>
            <TableBody>
              {data.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="left">{item.name}</TableCell>
                    <TableCell align="left">{item.description}</TableCell>
                    <TableCell align="center" style={{ color: "#1b4965" }}>
                      {item.questions.length} Question(s)
                    </TableCell>
                    <TableCell align="center" style={{ color: "#1b4965" }}>
                      <ChipText
                        success={item.published}
                        danger={!item.published}
                      >
                        {item.published ? "Published" : "Not Published"}
                      </ChipText>
                    </TableCell>
                    <TableCell
                      style={{ fontSize: 13, color: "#484848" }}
                      align="center"
                    >
                      {moment(item.createdAt).format("DD-MM-YYYY HH:mm")}
                    </TableCell>

                    <TableCell align="left">
                      {item.welcome ? (
                        <ChipText success>Welcome Questionnaire</ChipText>
                      ) : (
                        ""
                      )}
                    </TableCell>

                    <TableCell align="left">
                      <ButtonPrimaryLink
                        to={`/update-questionnaire/${item._id}`}
                        width="40px"
                        height="40px"
                      >
                        &#8594;
                      </ButtonPrimaryLink>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Questionnaires;
