// This file contains the JSX of the dropdown menu for the mobile screen
// This file was last modified on 2021-11-20 16:55:50
// Author : Mohamed Amine Dhiab © Massa Trails

import React from "react";
import {
  DropdownContainer,
  DropDownBack,
  DropDownWrapper,
  Links,
  HomeIcon,
} from "./style";

const DropdownMenu = ({ isOpen, toggle_dropdown }) => {
  //boolean variable that controls the toggle of the language selection menu

  //Data used for the menu as well as text loading using i18n
  const menuData = [
    { title: "Trails", link: "/trails" },
    { title: "Workshops", link: "/workshops" },
    { title: "Client", link: "/new-company" },
    { title: "Helpdesk", link: "/contact" },
    { title: "FAQS", link: "/faqs" },
    { title: "Experts", link: "/experts" },
  ];

  return (
    <DropdownContainer isopen={isOpen}>
      <DropDownBack />
      <DropDownWrapper>
        {menuData.map((item, index) => {
          return (
            <Links key={index} onClick={toggle_dropdown} to={item.link}>
              {item.link === "/" ? <HomeIcon /> : <></>}
              {item.title}
            </Links>
          );
        })}
      </DropDownWrapper>
    </DropdownContainer>
  );
};

export default DropdownMenu;
