import React, { useEffect, useState } from "react";
import { H6, H7, LoadingIcon } from "../../components/textStyles/style";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Link,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Edit, Receipt } from "@material-ui/icons";
import moment from "moment";
import AddAvenants from "../../components/modals/AddAvenants";
import { useHistory } from "react-router-dom";
import UpdateAvenant from "../../components/modals/UpdateAvenant";

const Avenants = ({ contract, company_id }) => {
  const [amendments, setAmendments] = useState([]);
  const history = useHistory();
  const [selectedContract, setSelectedContact] = useState(contract);
  const [showAmendmantModal, setShowAmendmantModal] = useState(false);
  const [showUpdateAmendmant, setShowUpdateAmendmant] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedAmendment, setSelectedAmendment] = useState(null);
  useEffect(() => {
    const getContracts = async () => {
      axios
        .get(
          `${process.env.REACT_APP_CONTRACTS}/avenants?contract_id=${contract._id}`
        )
        .then((res) => {
          setAmendments(res.data);
        });
    };
    getContracts();
  }, [contract]);

  const deleteHandler = async () => {
    if (!deleteLoader) {
      setDeleteLoader(true);

      axios
        .put(`${process.env.REACT_APP_CONTRACTS}/delete-avenant`, {
          avenant_id: selectedAmendment._id,
          company_id,
          contract_id: contract._id,
        })
        .then((res) => {
          setDeleteLoader(false);
          history.go(0);
        })
        .catch((err) => {
          console.log(err);
          setDeleteLoader(false);
        });
    }
  };

  if (contract === null) {
    return null;
  }

  return (
    <div style={{ marginLeft: 40 }}>
      <Divider style={{ marginTop: 10, marginBottom: 20 }} />
      <H6 style={{ fontSize: 16 }}>
        Amendments -{" "}
        <H6 style={{ fontSize: 16 }} color="#807e7c">
          {amendments.length} amendment(s)
        </H6>
      </H6>
      {amendments.length === 0 ? (
        <div>
          <H7
            style={{
              marginTop: 10,
              fontSize: 16,

              marginBottom: 15,
            }}
            color="#333333"
          >
            No amendments created for this contract.
          </H7>
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <ButtonPrimary
              onClick={() => {
                setShowAmendmantModal(true);
              }}
            >
              Add new Amendment
            </ButtonPrimary>
          </div>
        </div>
      ) : (
        <div style={{ marginTop: 20 }}>
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <ButtonPrimary
              onClick={() => {
                setShowAmendmantModal(true);
              }}
            >
              Add new Amendment
            </ButtonPrimary>
          </div>
          {amendments.map((amendment, i) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 10,
                  paddingBottom: 5,
                }}
                key={amendment._id}
              >
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                  <div>
                    <Receipt
                      style={{
                        fontSize: 25,
                        color: "#1b4965",
                        marginRight: 5,
                      }}
                    />
                  </div>
                  <div>
                    <Typography
                      color="#1b4965"
                      fontWeight="600"
                      fontSize={14}
                      textTransform="capitalize"
                    >
                      Amendment - {amendment.name}
                    </Typography>
                    <Typography color="#444" fontSize={14}>
                      Contract Ref : {amendment.contract_data[0].name}
                    </Typography>
                    <Typography color="#444" fontSize={14}>
                      Contract Period :{" "}
                      {moment(amendment.contract_data[0].startDate).format(
                        "DD-MM-YYYY"
                      )}{" "}
                      |{" "}
                      {moment(amendment.contract_data[0].endDate).format(
                        "DD-MM-YYYY"
                      )}
                    </Typography>
                    <Typography color="#444" fontSize={14}>
                      Users : {amendment.users} user(s) - Amount :{" "}
                      {amendment.amount_sub.toFixed(2)} €
                    </Typography>

                    <Typography color="#444" fontSize={14}>
                      Trails access : {amendment.number_trails} Trail(s) access
                      - Amount : {amendment.amount_trails.toFixed(2)} €
                    </Typography>

                    <Typography color="#444" fontSize={14}>
                      Flash Coaching : {amendment.coaching} Appointment(s) -
                      Amount : {amendment.amount_coaching.toFixed(2)} €
                    </Typography>
                    <Typography color="#444" fontSize={14}>
                      Ebooks : {amendment.coaching} ebooks(s) - Amount :{" "}
                      {amendment.amount_ebooks.toFixed(2)} €
                    </Typography>

                    <Typography
                      style={{ marginTop: 5 }}
                      color="#1b4965"
                      fontSize={16}
                    >
                      Total :{" "}
                      {Number(
                        amendment.amount_sub +
                          amendment.amount_trails +
                          amendment.amount_ebooks +
                          amendment.amount_coaching
                      ).toFixed(2)}{" "}
                      €
                    </Typography>
                    <Typography color="#666" fontSize={12}>
                      Amendment created :
                      {moment(amendment.createdAt).format("DD-MM-YYYY HH:mm")}
                    </Typography>
                  </div>
                </div>
                <div>
                  <Link href={amendment.url} target="_blank">
                    See amendment
                  </Link>
                  {i === 0 && (
                    <Button
                      style={{ marginLeft: 15 }}
                      variant="outlined"
                      onClick={() => {
                        setSelectedAmendment(amendment);
                        setShowUpdateAmendmant(true);
                      }}
                    >
                      <Edit />
                    </Button>
                  )}
                  {i === 0 && (
                    <Button
                      style={{ marginLeft: 10 }}
                      variant="outlined"
                      onClick={() => {
                        setSelectedAmendment(amendment);
                        setShowConfirmDelete(true);
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  )}
                </div>
              </div>
            );
          })}

          <Divider style={{ marginTop: 30 }} />
          {/** dialog to confirm delete */}
          {selectedAmendment && (
            <Dialog
              open={showConfirmDelete}
              onClose={setShowConfirmDelete}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Delete Amendment - {selectedAmendment.name} ?
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Do you want to permanently delete this amendment?
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ padding: "10px" }}>
                <Button onClick={deleteHandler}>
                  {deleteLoader ? <LoadingIcon /> : "Delete"}
                </Button>
                <Button autoFocus onClick={() => setShowConfirmDelete(false)}>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
      )}

      <Divider style={{ marginTop: 20, marginBottom: 30 }} />

      <AddAvenants
        isOpen={showAmendmantModal}
        setIsOpen={setShowAmendmantModal}
        company_id={company_id}
        selectedContract={selectedContract}
        setSelectedContract={setSelectedContact}
      />
      {/** dialog to update amendment */}
      {selectedAmendment && (
        <UpdateAvenant
          isOpen={showUpdateAmendmant}
          setIsOpen={setShowUpdateAmendmant}
          company_id={company_id}
          selectedAmendment={selectedAmendment}
          selectedContract={selectedContract}
        />
      )}
    </div>
  );
};

export default Avenants;
