import React from "react";
import { useState } from "react";
import { SelectInput } from "../../components/Inputs";
import {
    languagesWithAll,
    monthsOptionsWithoutAll,
    yearsOptionsWithoutAll,
} from "../../data/SelectOptions";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useEffect } from "react";
import axios from "axios";
import { lislColors } from "../../styles/colors";

const UpsellingRate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const [selectedYear, setSelectedYear] = useState(year);
    const [data, setData] = useState(null);

    useEffect(() => {
        const getData = (y, l) => {
            axios
                .get(
                    `${process.env.REACT_APP_COMPANY_STAT}/upselling?year=${selectedYear}`
                )
                .then((res) => {
                    setData(res.data);
                    console.log(res.data);
                });
        };
        getData();
    }, [selectedYear]);

    if (data === null) {
        return null;
    }

    return (
        <div
            style={{
                marginTop: 20,
                backgroundColor: "#f5f5f5",
                padding: 20,
                borderRadius: 15,
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography style={{ fontSize: 20, color: "#484848" }}>
                    Revenue Summary & Upselling Rate
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: 100 }}>
                        <SelectInput
                            values={yearsOptionsWithoutAll}
                            placeholder="Year *"
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            {/** table 1  */}
            <div>
                <Table style={{ marginTop: 12, marginBottom: 12 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                <Typography
                                    style={{ fontSize: 11, color: "#1b4965", fontWeight: "600" }}
                                >
                                    APP products (Gifts, Trails,Ebooks,Flash..)
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography
                                    style={{ fontSize: 11, color: "#1b4965", fontWeight: "600" }}
                                >
                                    Subs. (B2C & B2B excl. Prepaid)
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography
                                    style={{ fontSize: 11, color: "#1b4965", fontWeight: "600" }}
                                >
                                    TOTAL 1
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="center">
                                <Typography
                                    style={{ fontSize: 11, color: "#484848", fontWeight: "500" }}
                                >
                                    {data.totalInvoicesWithoutSub}€
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography
                                    style={{ fontSize: 11, color: "#484848", fontWeight: "500" }}
                                >
                                    {data.subs}€
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography
                                    style={{ fontSize: 11, color: "#484848", fontWeight: "500" }}
                                >
                                    {data.totalInvoices}€
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
            <div>
                {/** table 1  */}
                <Table style={{ marginTop: 12, marginBottom: 12 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                <Typography
                                    style={{ fontSize: 11, color: "#1b4965", fontWeight: "600" }}
                                >
                                    Prepaid B2B (Trails, ebooks, flash)
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography
                                    style={{ fontSize: 11, color: "#1b4965", fontWeight: "600" }}
                                >
                                    Prepaid B2B (subs.)
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography
                                    style={{ fontSize: 11, color: "#1b4965", fontWeight: "600" }}
                                >
                                    Total 2
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="center">
                                <Typography
                                    style={{ fontSize: 11, color: "#484848", fontWeight: "500" }}
                                >
                                    {data.totalContracts.totalAmountCoaching +
                                        data.totalContracts.totalAmountTrails +
                                        data.totalContracts.totalAmountEbook +
                                        data.totalAvenants.totalAmountEbook +
                                        data.totalAvenants.totalAmountCoaching +
                                        data.totalAvenants.totalAmountTrails
                                    }
                                    €
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography
                                    style={{ fontSize: 11, color: "#484848", fontWeight: "500" }}
                                >
                                    {data.totalContracts.totalAmountSub + data.totalAvenants.totalAmountSub}€
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography
                                    style={{ fontSize: 11, color: "#484848", fontWeight: "500" }}
                                >
                                    {data.totalB2b}€
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <Typography
                    bgcolor="#484848"
                    style={{ padding: 10, fontSize: 14, width: 330, textAlign: "right" }}
                    fontWeight="500"
                    color={lislColors.white}
                >
                    Upselling (prepaid B2B + APP products) : {data.totalInvoicesWithoutSub + data.totalContracts.totalAmountCoaching +
                        data.totalContracts.totalAmountTrails +
                        data.totalContracts.totalAmountEbook +
                        data.totalAvenants.totalAmountEbook +
                        data.totalAvenants.totalAmountCoaching +
                        data.totalAvenants.totalAmountTrails} €
                </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <Typography
                    bgcolor="#1b4965"
                    style={{ padding: 10, fontSize: 14, width: 330, textAlign: "right" }}
                    fontWeight="500"
                    color={lislColors.white}
                >
                    TOTAL (TOTAL 1 + TOTAL 2) {data.total} €
                </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <Typography
                    bgcolor="rgb(123, 150, 101)"
                    style={{ padding: 10, fontSize: 14, width: 330, textAlign: "right" }}
                    fontWeight="500"
                    color={lislColors.white}
                >
                    Upselling Rate : {(((data.totalInvoicesWithoutSub + data.totalContracts.totalAmountCoaching +
                        data.totalContracts.totalAmountTrails +
                        data.totalContracts.totalAmountEbook +
                        data.totalAvenants.totalAmountEbook +
                        data.totalAvenants.totalAmountCoaching +
                        data.totalAvenants.totalAmountTrails) / data.total) * 100).toFixed(2)} %
                </Typography>
            </div>
        </div>
    );
};

export default UpsellingRate;
