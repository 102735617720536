import React, { useEffect, useState } from "react";
import axios from "axios";
import { H5 } from "../../components/textStyles/style";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { RowBetween } from "./CompanyStat";
import { Typography } from "@mui/material";
import { languages } from "../../data/SelectOptions";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip);

const CompanyLanguage = ({ company }) => {
  const [lngs, setLngs] = useState([0, 0]);
  const [total, setTotal] = useState(0);

  const colors = [
    "#A8C492",
    "#1B4965",
    "#FD7064",
    "#00796b",
    "#FFCE63",
    "#94CDDB",
    "#d50000",
    "#d500f9",
    "#fb8c00",
    "#4527a0",
    "#ff5722",
    "#263238",
  ];

  useEffect(() => {
    const getData = async () => {
      axios
        .get(
          `${process.env.REACT_APP_COMPANY_STAT}/company-users-language?company_id=${company}`
        )
        .then((res) => {
          setLngs(res.data.languages);
          setTotal(res.data.total);
        });
    };
    getData();
  }, [company]);

  const totalHandler = (val) => {
    if (val == 0 || total == 0) {
      return " (0%)";
    } else {
      return ` (${((val / total) * 100).toFixed(2)}%)`;
    }
  };

  const labels = languages.map((el) => el.label);

  const pieDataHandler = () => {
    const d = {
      labels,
      datasets: [
        {
          data: lngs,
          backgroundColor: colors,
        },
      ],
    };
    return d;
  };

  return (
    <div>
      <H5 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
        12. Users Per Language
      </H5>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: "100%", marginRight: 20 }}>
          {languages.map((lng, i) => {
            return (
              <RowBetween key={lng.value}>
                <Typography style={{ color: "#484848" }} fontSize={13}>
                  {lng.label}
                </Typography>
                <Typography fontSize={13} color="#484848">
                  {lngs[i]} {totalHandler(lngs[i])}
                </Typography>
              </RowBetween>
            );
          })}
          <RowBetween style={{ marginTop: 15 }}>
            <Typography style={{ color: "#484848" }} fontSize={13}>
              Total
            </Typography>
            <Typography fontSize={13} color="#484848">
              {total.toFixed(2)} (100%)
            </Typography>
          </RowBetween>
        </div>
        <div style={{ maxWidth: 150 }}>
          <Pie
            plugins={[ChartDataLabels]}
            options={{
              plugins: {
                legend: false,
                datalabels: {
                  display: (context) =>
                    context.dataset.data[context.dataIndex] !== 0,
                  formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map((data) => {
                      sum += data;
                    });
                    let percentage = ((value * 100) / total).toFixed(2) + "%";
                    return percentage;
                  },
                  color: "white", // Label color
                  font: {
                    weight: "bold", // Label font weight
                  },
                },
              },
            }}
            data={pieDataHandler()}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyLanguage;
