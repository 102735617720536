import styled from "styled-components";
import LazyLoading from "../components/lazyLoading";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
`;
const Account = () => {
  return (
    <Wrapper>
      <LazyLoading height={Height} />
    </Wrapper>
  );
};

export default Account;
