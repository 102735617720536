import React, { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import axios from "axios";
import ChartDataLabels from "chartjs-plugin-datalabels";


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    Filler,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const ExampleUsersStat = () => {


    const labels = ['10/2022', '11/2022', '12/2022', '1/2023', '2/2023', '3/2023', '4/2023', '5/2023', '6/2023', '7/2023', '8/2023', '9/2023', '10/2023']
    const responseData = [0, 0, 0, 0, 0, 0, 34, 41, 18, 44, 38, 84, 21]

    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label: "Users Inscription(s)",
                data: responseData,
                borderColor: "rgb(27, 73, 101)",
                borderWidth: 3,
                backgroundColor: "rgba(27, 73, 101, 0.3)",
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            datalabels: {
                display: (context) => context.dataset.data[context.dataIndex] !== 0,
                align: "top", // You can adjust label alignment (top, center, bottom)
                color: "rgb(27, 73, 101)", // Label color
                font: {
                    weight: "bold", // Label font weight
                    size: 12,

                },

            },
        },
    };

    return (
        <div>

            <div>
                <Line data={data} options={options} plugins={[ChartDataLabels]} />
            </div>

        </div>
    );
};

export default ExampleUsersStat;
