import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import { Input } from "../../components/Inputs";
import LazyLoading from "../../components/lazyLoading";
import { H5, LoadingIcon, TextBold } from "../../components/textStyles/style";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SuccessImage from "../../images/main/green_circle_tick.svg";
import MultipleSelect from "../../components/MulitpleSelect";
import { srtOptions } from "../../data/SelectOptions";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const UpdateEpisode = () => {
  const MySwal = withReactContent(Swal);
  const { i18n } = useTranslation();
  const history = useHistory();
  const { episode_code } = useParams();
  const [code, setCode] = useState("");
  const [title, setTitle] = useState("");
  const [showTitle, setShowTitle] = useState("");
  const [link, setLink] = useState("");
  const [duration, setDuration] = useState("");
  const [srt, setSrt] = useState([]);
  const [workshopCode, setWorkshopCode] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const TOKEN = localStorage.getItem("TOKEN");
  const [language, setLanguage] = useState("");

  // UPDATE SUBMIT
  const submitHandler = async () => {
    setSubmitLoading(true);
    setErrorMessage("");
    try {
      const data = {
        access_token: TOKEN,
        language,
        episode_code: code,
        title,
        link,
        duration: Number(duration),
        srt,
        workshop_code: workshopCode,
      };

      await axios.put(process.env.REACT_APP_UPDATE_EPISODE, data);
      setSubmitLoading(false);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      MySwal.fire({
        title: <H5>Modifications Enregisté</H5>,
        html: <></>,
        imageUrl: SuccessImage,
        imageWidth: 200,
        imageHeight: 150,
        imageAlt: "Custom image",
        timer: 1500,
        showConfirmButton: false,

        timerProgressBar: true,
        padding: "2vw",
        showCloseButton: true,
        showClass: {
          popup: "animate__animated animate__fadeInDown animate__fast",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp animate__faster",
        },
      });
    } catch (error) {
      console.log(error);
      setSubmitLoading(false);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setErrorMessage("Probléme de Connexion");
    }
  };

  useEffect(() => {
    const getEpisodeData = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_GET_EPISODE}/${episode_code}`,
        config
      );
      const episode = response.data;
      // SET INPUTS TO EPISODE VALUES
      setCode(episode.episode_code);
      setTitle(episode.title);
      setShowTitle(episode.title);
      setLanguage(episode.language)
      setWorkshopCode(episode.workshop_code);
      setLink(episode.link);
      setDuration(episode.duration);
      setSrt(episode.srt);
      setLoading(false);
    };
    getEpisodeData();
  }, [episode_code, i18n, TOKEN]);
  return (
    <Wrapper>
      {loading ? (
        <LazyLoading height={Height} />
      ) : (
        <ContentWrapper>
          {/** iframe if streaming */}
          {link.endsWith(".m3u8") && (
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 30,
              }}
            >
              <iframe
                title="workshop video"
                src={`https://iframe.mediadelivery.net/embed/161025/${link.split("/")[3]
                  }?autoplay=false&preload=false`}
                loading="lazy"
                width="500"
                style={{ border: "none", aspectRatio: "16 / 9" }}
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowFullScreen={true}
              ></iframe>
            </div>
          )}
          <H5>Update Episode : {showTitle}</H5>
          <Row>
            <TextBold color="#c22222">
              {errorMessage ? errorMessage : " "}
            </TextBold>
          </Row>
          {/** Episode code + title */}
          <Row>
            <Input
              disabled={true}
              placeholder="Episode Code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <Input
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Row>

          {/** link */}
          <Row>
            <Input
              placeholder="Audio/Vidéo Link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
          </Row>

          {/** duration + srt  */}
          <Row>
            <Input
              placeholder="Duration in minutes"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            />
            <MultipleSelect
              placeholder="SRT Languages"
              options={srtOptions}
              selectedValues={srt}
              setSelectedValues={setSrt}
            />
          </Row>
          {/** workshop code */}
          <Row>
            <Input
              placeholder="Movement (Workshop code)"
              value={workshopCode}
              onChange={(e) => setWorkshopCode(e.target.value)}
            />
          </Row>

          <Row style={{ marginTop: 40 }}>
            <ButtonPrimary onClick={submitHandler}>
              {submitLoading ? <LoadingIcon color="inherit" /> : "Enregister"}
            </ButtonPrimary>
          </Row>
        </ContentWrapper>
      )}
    </Wrapper>
  );
};

export default UpdateEpisode;
