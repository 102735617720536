import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  H5,
  LoadingBigIcon,
  SmallBoldText,
  SmallText,
} from "../../components/textStyles/style";
import axios from "axios";
import { ButtonPrimaryLink } from "../../components/buttonStyles/style";
import { SearchInput, SelectInput } from "../../components/Inputs";
import moment from "moment";
import { NotifSendType, languages } from "../../data/SelectOptions";
import { ChipText } from "../contacts";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;

const LoadWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
`;

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
`;

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;

  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
`;

const Notifications = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesNumber, setPagesNumber] = useState(null);
  const [searchTotal, setSearchTotal] = useState(null);
  const [language, setLanguage] = useState("en");
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    const getData = async () => {
      setLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      const Response = await axios.get(
        `${process.env.REACT_APP_NOTIFICATIONS}?keyword=${keyword}&pageNumber=${currentPage}`,
        config
      );
      const { messages, page, pages, count } = Response.data;

      setData(messages);
      setCurrentPage(page);
      setPagesNumber(pages);
      setSearchTotal(count);
      setLoader(false);
    };
    getData();
  }, [currentPage, keyword]);

  return (
    <Wrapper>
      <ContentWrapper>
        <H5>Notifications list - LISL UP</H5>
        <hr />
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ width: 400 }}>
            <ButtonPrimaryLink to="/new-notification">
              Add new notification
            </ButtonPrimaryLink>
          </div>
          <SearchContainer>
            <SearchInput
              onFocus={() => setCurrentPage(1)}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />

            <SmallText>{searchTotal} notification(s) found</SmallText>
          </SearchContainer>
        </div>

        {data.length > 0 && pagesNumber && (
          <PaginationContainer>
            <Pagination
              color="primary"
              count={pagesNumber}
              page={currentPage}
              onChange={(e, val) => setCurrentPage(val)}
            />
          </PaginationContainer>
        )}

        {loader ? (
          <LoadWrapper>
            <LoadingBigIcon />
          </LoadWrapper>
        ) : data.length > 0 ? (
          <div style={{ margin: 30, marginTop: 10 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Created at
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      color: "#1B4965",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ width: 200, marginLeft: -10 }}>
                        <SelectInput
                          values={languages}
                          placeholder="Language"
                          value={language}
                          onChange={(e) => setLanguage(e.target.value)}
                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      color: "#1B4965",
                    }}
                  >
                    Notification Delivery Options
                  </TableCell>
                  <TableCell></TableCell>
                </TableHead>
                <TableBody>
                  {data.map((notif) => {
                    const createdAt = moment(notif.createdAt).format(
                      "DD/MM/YYYY"
                    ); // January 3, 2023 9:09 PM
                    const type = NotifSendType.find(
                      (el) => el.value === notif.type
                    );
                    return (
                      <TableRow key={notif._id}>
                        <TableCell align="center" scope="row">
                          {createdAt}
                        </TableCell>
                        <TableCell align="center">
                          <SmallBoldText color="#000000">
                            {language === "fr"
                              ? notif.title_fr
                              : notif.title_en}
                          </SmallBoldText>
                          <br></br>
                          {language === "fr" ? notif.text_fr : notif.text_en}
                        </TableCell>
                        <TableCell align="center" scope="row">
                          <ChipText success={notif.type === "random"}>
                            {type ? type.label : " - "}
                          </ChipText>
                        </TableCell>
                        <TableCell align="center">
                          <ButtonPrimaryLink
                            to={`/update-notification/${notif._id}`}
                            width="40px"
                            height="40px"
                          >
                            &#8594;
                          </ButtonPrimaryLink>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {pagesNumber && (
              <PaginationContainer style={{ marginTop: 20 }}>
                <Pagination
                  color="primary"
                  count={pagesNumber}
                  page={currentPage}
                  onChange={(e, val) => setCurrentPage(val)}
                />
              </PaginationContainer>
            )}
          </div>
        ) : (
          <></>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default Notifications;
