import React, { useEffect } from "react";
import { Input } from "../../components/Inputs";
import { Button, IconButton } from "@mui/material";
import { H7 } from "../../components/textStyles/style";
import { Add, Close } from "@material-ui/icons";
import { lislColors } from "../../styles/colors";

const AnswersInput = ({ inputs, setInputs, values, setValues }) => {
    const handleAddInput = () => {
        setInputs((prevInputs) => [...prevInputs, ""]);
        setValues((prevInputs) => [...prevInputs, ""]);
    };

    const handleInputChange = (index, e) => {
        const updatedInputs = [...inputs];
        updatedInputs[index] = e.target.value;
        setInputs(updatedInputs);
    };
    const handleValueChange = (index, e) => {
        const updatedInputs = [...values];
        updatedInputs[index] = e.target.value;
        setValues(updatedInputs);
    };

    const handleDeleteInput = (index) => {
        const updatedInputs = [...inputs];
        const updatedValues = [...values];
        updatedValues.splice(index, 1);
        updatedInputs.splice(index, 1);
        setInputs(updatedInputs);
        setValues(updatedValues)
    };


    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <H7 style={{ fontSize: 20 }}>Answers</H7>
                <IconButton onClick={handleAddInput} sx={{ backgroundColor: lislColors.green0 }} color="success"><Add /></IconButton>
            </div>
            {inputs.map((input, index) => (
                <div
                    style={{ display: "flex", alignItems: "center", marginTop: 15, gap: 15 }}
                    key={index}
                >
                    <div>
                        <Input
                            type="number"
                            placeholder={`answer's value`}
                            value={values[index]}
                            onChange={(e) => handleValueChange(index, e)}
                        />
                    </div>
                    <div style={{ width: "100%" }}>
                        <Input
                            placeholder={`answer nº ${index + 1}`}
                            value={input}
                            onChange={(e) => handleInputChange(index, e)}
                        />
                    </div>
                    <div >
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleDeleteInput(index)}
                        >
                            <Close />
                        </Button>
                    </div>
                </div>
            ))}

        </div>
    );
};

export default AnswersInput