import { ListAltSharp, ViewCarousel } from "@material-ui/icons";
import { ButtonBase } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SearchInput, SelectInput } from "../../components/Inputs";
import SwiperComponent from "../../components/swiper";
import { H5 } from "../../components/textStyles/style";
import { languages } from "../../data/SelectOptions";

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - clamp(54px, 8vw, 84px) - 62px);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px 0;
  z-index: 1;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px 20px 30px;
`;

const SelectContainer = styled.div`
  width: 150px;
  margin-right: 10px;
`;

const Trails = () => {
  const { i18n } = useTranslation();
  const [keyword, setKeyword] = useState("");
  const [language, setLanguage] = useState(i18n.language);
  const [listMode, setListMode] = useState(false);
  const changeLanguageHandler = (e) => {
    i18n.changeLanguage(e.target.value);
    setLanguage(e.target.value);
  };
  return (
    <Wrapper>
      <RowContainer>
        <H5>Trails</H5>
        <RowContainer>
          <SelectContainer>
            <SelectInput
              values={languages}
              placeholder="Language"
              value={language}
              onChange={changeLanguageHandler}
            />
          </SelectContainer>
          <SearchInput
            id="search-trails"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
          <ButtonBase
            onClick={() => setListMode(!listMode)}
            style={{
              width: 50,
              height: 50,
              borderRadius: 25,
              marginLeft: 20,
            }}
          >
            {listMode ? (
              <ListAltSharp
                style={{ width: 30, height: 30, color: "#424242" }}
              />
            ) : (
              <ViewCarousel
                style={{ width: 30, height: 30, color: "#424242" }}
              />
            )}
          </ButtonBase>
        </RowContainer>
      </RowContainer>

      <SwiperComponent
        listMode={listMode}
        Endpoint={`${process.env.REACT_APP_GET_TRAILS}?keyword=${keyword}&language=${language}`}
        Type="Trail"
      />
    </Wrapper>
  );
};

export default Trails;
