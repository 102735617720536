import React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import styled from "styled-components";
import logo from "../images/main/green-logo.svg";
import { useState } from "react";
import { H4, LoadingIcon, Text } from "../components/textStyles/style";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useHistory } from "react-router-dom";
const MySwal = withReactContent(Swal);

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © LISL UP - v1.0.0 "}
      <Link color="primary" href="https://lislup.com/" target="_blank" pl="10">
        (https://lislup.com/)
      </Link>
    </Typography>
  );
}

const Logo = styled.img`
  width: 90%;
  max-width: 200px;
  margin: auto 0px;
`;

const LogoWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const theme = createTheme();

export default function Login() {
  const [loader, setLoader] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!loader) {
      setLoader(true);
      axios
        .post(process.env.REACT_APP_LOGIN, { username, password })
        .then(async (res) => {
          const { access_token } = res.data;
          await window.localStorage.setItem("TOKEN", access_token);
          history.push("/");
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          MySwal.fire({
            title: <H4>Oops...</H4>,
            html: <Text>Wrong Credentials.</Text>,
            iconColor: "#FD7064",
            icon: "error",
            confirmButtonText: <Text color="white">Try again</Text>,
            confirmButtonColor: "#FD7064",
            confirmButtonAriaLabel: "Try again",
          });
        });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          sx={{
            backgroundImage:
              "url(https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/signin.jpg?alt=media&token=f81778eb-364f-427c-a87f-36f309b2f30b)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <LogoWrapper>
              <Logo src={logo} alt="image" />
            </LogoWrapper>
            <Typography color="#444" variant="h6">
              LISL UP - DASHBOARD
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <Button
                type="submit"
                fullWidth
                style={{
                  backgroundColor: "#88c1a3",
                  height: 45,
                  color: "#111111",
                }}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {loader ? <LoadingIcon color="white" /> : "Sign In"}
              </Button>
              <RouterLink to="/forgot-password">Forgot password?</RouterLink>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
