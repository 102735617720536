import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import { Input, SelectInput } from "../../components/Inputs";
import {
  H5,
  LoadingIcon,
  TextBold,
  LoadingBigIcon,
  Text,
  H6,
} from "../../components/textStyles/style";
import "../../styles/custom-phone-input.css";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import {
  notifcationPlatformOptions,
  notifcationTargetOptions,
  typeNotificationOptions,
} from "../../data/SelectOptions";
let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;
const LoadWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
`;

const UpdateNotification = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [textFr, setTextFr] = useState("");
  const [textEn, setTextEn] = useState("");
  const [titleFr, setTitleFr] = useState("");
  const [titleEn, setTitleEn] = useState("");
  const [loader, setLoader] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [notification, setNotification] = useState(null);
  const { notification_id } = useParams();
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [sendNotifLoader, setSendNotifLoader] = useState(false);
  const [type, setType] = useState("one_use");
  const [selectedPlatform, setSelectedPlatform] = useState("all");

  const [selectedTarget, setSelectedTarget] = useState("all");

  const TOKEN = localStorage.getItem("TOKEN");
  const submitHandler = async () => {
    if (!loading) {
      setLoading(true);
      setErrorMessage("");
      const TOKEN = localStorage.getItem("TOKEN");
      try {
        const data = {
          access_token: TOKEN,
          text_fr: textFr,
          text_en: textEn,
          title_fr: titleFr,
          title_en: titleEn,
          type,
        };

        await axios.put(
          `${process.env.REACT_APP_NOTIFICATION}?notification_id=${notification_id}`,
          data
        );
        setLoading(false);
        history.goBack();
      } catch (error) {
        setLoading(false);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(error);
        setErrorMessage("Probléme de Connexion");
      }
    }
  };

  const deleteHandler = () => {
    if (!deleteLoader) {
      setDeleteLoader(true);
      try {
        axios.delete(
          `${process.env.REACT_APP_NOTIFICATION}?notification_id=${notification_id}`,
          { data: { access_token: TOKEN } }
        );
        setDeleteLoader(false);
        setDeleteModal(false);
        history.goBack();
      } catch (error) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(error);
        setErrorMessage("Probléme de Connexion");
        setDeleteLoader(false);
        setDeleteModal(false);
      }
    }
  };

  const sendNotificationHandler = () => {
    if (!sendNotifLoader) {
      setSendNotifLoader(true);
      axios
        .post(process.env.REACT_APP_SEND_NOTIFICATION, {
          target: selectedTarget,
          platform: selectedPlatform,
          notification,
        })
        .then((res) => {
          setSendNotifLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setSendNotifLoader(false);
        });
    }
  };

  useEffect(() => {
    const getNotificationData = async () => {
      setLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_NOTIFICATION}?notification_id=${notification_id}`,
        config
      );
      const n = response.data;
      // SET INPUTS TO EXPERT VALUES
      setNotification(n);
      setTextFr(n.text_fr);
      setTextEn(n.text_en);
      setTitleEn(n.title_en);
      setTitleFr(n.title_fr);
      setType(n.type ? n.type : "one_use");
      setLoader(false);
    };

    getNotificationData();
  }, [notification_id, TOKEN]);

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <H5>Notification</H5>
        {loader ? (
          <LoadWrapper>
            <LoadingBigIcon />
          </LoadWrapper>
        ) : (
          <>
            <Row>
              <TextBold color="#c22222">
                {errorMessage ? errorMessage : " "}
              </TextBold>
            </Row>
            <Row>
              <Input
                placeholder="Notification Title - FR"
                value={titleFr}
                onChange={(e) => setTitleFr(e.target.value)}
              />
              <Input
                placeholder="Notification Title - EN"
                value={titleEn}
                onChange={(e) => setTitleEn(e.target.value)}
              />
            </Row>
            <Row>
              <Input
                multiline
                placeholder="Notification - FR"
                value={textFr}
                onChange={(e) => setTextFr(e.target.value)}
              />
              <Input
                multiline
                placeholder="Notification - EN"
                value={textEn}
                onChange={(e) => setTextEn(e.target.value)}
              />
            </Row>
            <div style={{ marginTop: 20, marginLeft: 10 }}>
              <FormControl>
                <H6 id="type-buttons-group-label">
                  Notification Delivery Options
                </H6>
                <RadioGroup
                  aria-labelledby="type-buttons-group-label"
                  value={type}
                  name="type-buttons-group"
                  onChange={handleTypeChange}
                >
                  {typeNotificationOptions.map((element) => (
                    <FormControlLabel
                      color="#1b4965"
                      key={element.value}
                      value={element.value}
                      control={<Radio />}
                      label={element.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
            <Row style={{ marginTop: 40 }}>
              <ButtonPrimary onClick={submitHandler}>
                {loading ? (
                  <LoadingIcon color="inherit" />
                ) : (
                  "Update notification"
                )}
              </ButtonPrimary>
            </Row>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setDeleteModal(true)}
              >
                <DeleteIcon />
              </Button>
            </div>
            <hr />
            <H5>Send Notification</H5>
            <br></br>
            <Text>
              To send a notification, please select your target users from the
              following options. You can also choose to send the notification to
              all devices or select specific platform (android - ios - web)
            </Text>
            <div style={{ marginTop: 20, marginBottom: 80 }}>
              <div style={{ width: 400, marginTop: 15 }}>
                <SelectInput
                  values={notifcationPlatformOptions}
                  placeholder="Platform"
                  value={selectedPlatform}
                  onChange={(e) => setSelectedPlatform(e.target.value)}
                />
              </div>
              <div style={{ width: 400, marginTop: 15 }}>
                <SelectInput
                  values={notifcationTargetOptions}
                  placeholder="Target"
                  value={selectedTarget}
                  onChange={(e) => setSelectedTarget(e.target.value)}
                />
              </div>

              <ButtonPrimary
                onClick={sendNotificationHandler}
                width={400}
                style={{ width: 400, marginTop: 30 }}
              >
                {sendNotifLoader ? (
                  <LoadingIcon color="white" />
                ) : (
                  "Send Notification"
                )}
              </ButtonPrimary>
            </div>
          </>
        )}
      </ContentWrapper>
      {/** dialog to confirm delete */}
      <Dialog
        open={deleteModal}
        onClose={setDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete this notification?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to permanently delete this notification?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "10px" }}>
          <Button onClick={deleteHandler}>
            {deleteLoader ? <LoadingIcon /> : "Delete"}
          </Button>
          <Button autoFocus onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Wrapper>
  );
};

export default UpdateNotification;
