import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Pie } from "react-chartjs-2";
import axios from "axios";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { RowBetween, RowCenter } from "../styles";
import {
  H6,
  LoadingIcon,
  SmallText,
} from "../../../components/textStyles/style";
import FinishedWorkshopsListModal from "../../../components/modals/FinishedWorkshopsListModal";
ChartJS.register(ArcElement, Tooltip);

const FinishedWorkshops = ({ user }) => {
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);
  const [showWorkshopsModal, setShowWorkshopsModal] = useState(false);
  const [modalData, setmodalData] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [threeMonthsData, setThreeMonthsData] = useState(false);
  const colors = [
    "#FD7064",
    "#1B4965",
    "#A8C492",
    "#00796b",
    "#FFCE63",
    "#94CDDB",
    "#d50000",
    "#d500f9",
    "#fb8c00",
    "#4527a0",
    "#ff5722",
    "#263238",
  ];
  useEffect(() => {
    const getData = async () => {
      if (data.length == 0) {
        setLoader(true);
      }
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      axios
        .get(
          `${
            process.env.REACT_APP_FINISHED_WORKSHOPS
          }?user_id=${user}&three_months=${threeMonthsData ? "true" : ""}`,
          config
        )
        .then(async (res) => {
          setData(res.data);
          const types = res.data.map(
            (fElement) => fElement.workshop_data[0].type
          );
          const occurrences = types.reduce(function (acc, curr) {
            return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
          }, {});
          const keys = Object.keys(occurrences);
          const values = Object.values(occurrences);

          setLabels(keys);
          setValues(values);

          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getData();
  }, [user, threeMonthsData]);

  const pieDataHandler = () => {
    const d = {
      labels,
      datasets: [
        {
          label: "Atlier (s)",
          data: values,
          backgroundColor: colors,
        },
      ],
    };
    return d;
  };

  if (loader) {
    return (
      <RowCenter>
        <LoadingIcon />
      </RowCenter>
    );
  }
  if (labels.length === 0) {
    return (
      <RowBetween style={{ marginBottom: 20 }}>
        <H6>Workshops</H6>
        <SmallText>No watched Workshops yet.</SmallText>
      </RowBetween>
    );
  }
  return (
    <div>
      <RowBetween style={{ marginBottom: 20 }}>
        <H6>Workshops</H6>
        <RowCenter style={{ gap: 10 }}>
          <Button
            onClick={() => setThreeMonthsData(false)}
            variant="contained"
            color={threeMonthsData ? "inherit" : "primary"}
          >
            All time
          </Button>
          <Button
            onClick={() => setThreeMonthsData(true)}
            variant="contained"
            color={!threeMonthsData ? "inherit" : "primary"}
          >
            Last 3 months
          </Button>
        </RowCenter>
        <SmallText>{data.length} watched workshops(s) </SmallText>
      </RowBetween>
      {/** pie */}
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ maxWidth: 180, marginRight: 20 }}>
          <Pie
            options={{ plugins: { legend: false } }}
            data={pieDataHandler(labels, values, colors)}
          />
        </div>

        {/** table */}
        <Table>
          <TableHead>
            <TableCell
              align="center"
              style={{ fontSize: 14, fontWeight: "bold", color: "#1B4965" }}
            >
              Categories
            </TableCell>
            {labels.map((label, i) => {
              return (
                <TableCell
                  align="center"
                  style={{
                    fontSize: 13,
                    fontFamily: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  <Button
                    onClick={() => {
                      const filteredWorkshops = data.filter(
                        (d) => d.workshop_data[0].type === label
                      );
                      setSelectedType(label);
                      setmodalData(filteredWorkshops);
                      setShowWorkshopsModal(true);
                    }}
                    variant="contained"
                    color="inherit"
                  >
                    <span
                      style={{
                        color: colors[i],
                        fontSize: 13,
                        fontWeight: "bold",
                        marginRight: 3,
                      }}
                    >
                      ●
                    </span>
                    <span
                      style={{
                        color: "black",
                        fontSize: 13,
                        fontWeight: "bold",
                      }}
                    >
                      {label}
                    </span>
                  </Button>
                </TableCell>
              );
            })}
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                align="center"
                style={{ fontSize: 14, fontWeight: "bold", color: "#1B4965" }}
              >
                Workshops number
              </TableCell>
              {values.map((val) => {
                return (
                  <TableCell
                    align="center"
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "#1B4965",
                    }}
                  >
                    {`${val} Workshop(s)`}
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                style={{ fontSize: 14, fontWeight: "bold", color: "#1B4965" }}
              >
                Pourcentage
              </TableCell>
              {values.map((val) => {
                return (
                  <TableCell
                    align="center"
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "#1B4965",
                    }}
                  >
                    {((Number(val) / Number(data.length)) * 100).toFixed(2)}%
                  </TableCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </div>
      {/** show workshop modal */}
      <FinishedWorkshopsListModal
        isOpen={showWorkshopsModal}
        setIsOpen={setShowWorkshopsModal}
        workshops={modalData}
        setWorkshops={setmodalData}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
      />
    </div>
  );
};

export default FinishedWorkshops;
