import React, { useEffect, useState } from "react";
import { H5 } from "../textStyles/style";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Button, Typography } from "@mui/material";
import axios from "axios";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const WorkshopsStat = () => {
  const [select, setSelect] = useState("months");
  const [responseData, setResponseData] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    const getData = async () => {
      axios
        .get(
          `${process.env.REACT_APP_FINISHED_WORKSHOPS_STATS}?select=${select}`
        )
        .then((res) => {
          setResponseData(res.data.map((el) => el.count));

          setLabels(
            res.data.map((el) =>
              select === "days"
                ? `${el._id.day}/${el._id.month}/${el._id.year}`
                : select === "months"
                  ? `${el._id.month}/${el._id.year}`
                  : el._id.year
            )
          );
        });
    };
    getData();
  }, [select]);

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Completed Workshops",
        data: responseData,
        borderColor: "rgb(27, 73, 101)",
        borderWidth: 3,
        backgroundColor: "rgba(27, 73, 101, 0.3)",
      },
    ],
  };

  return (
    <div style={{ padding: 10 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <H5 style={{ fontSize: 20, textDecoration: "underline" }}>Workshops</H5>

        <div style={{}}>
          <Button
            style={{ marginRight: 5 }}
            onClick={() => setSelect("days")}
            variant="contained"
            color={select !== "days" ? "inherit" : "primary"}
          >
            <Typography fontSize={12}>7 days</Typography>
          </Button>
          <Button
            style={{ marginRight: 5 }}
            onClick={() => setSelect("months")}
            variant="contained"
            color={select !== "months" ? "inherit" : "primary"}
          >
            <Typography fontSize={12}>12 months</Typography>
          </Button>
          <Button
            onClick={() => setSelect("years")}
            variant="contained"
            color={select !== "years" ? "inherit" : "primary"}
          >
            <Typography fontSize={12}>5 years</Typography>
          </Button>
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        <Line
          style={{ height: 200 }}
          data={data}
          plugins={[ChartDataLabels]}
          options={{
            plugins: {
              legend: {
                display: true,
                position: "bottom",
              },
              datalabels: {
                display: (context) =>
                  context.dataset.data[context.dataIndex] !== 0,
                formatter: (value, ctx) => {
                  return value;
                },
                align: "left",
                offset: 3,
                color: '#333',
                font: {
                  weight: "bold", // Label font weight
                  size: 11,
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default WorkshopsStat;
