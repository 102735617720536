import styled from "styled-components";
import { H5 } from "../textStyles/style";
import axios from "axios";
import { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import TextField from "@material-ui/core/TextField";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import dayjs from "dayjs";

ChartJS.register(
  BarElement,
  LineElement,
  CategoryScale,
  PointElement,
  LinearScale,
  Tooltip,
  Legend
);
const Wrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const RowCenter = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RevenuStat = () => {
  const [loader, setLoader] = useState(false);
  const [threeMonthsData, setThreeMonthsData] = useState(false);
  const [data, setData] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleChange = (e) => {
    setYear((prevYear) => {
      getData(e.target.value);
      return e.target.value;
    });
  };

  const getData = async () => {
    if (data.length === 0) {
      setLoader(true);
    }
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    axios
      .get(
        `${process.env.REACT_APP_GET_TAXSTAT}?year=${year}&month=${threeMonthsData ? "true" : ""
        }`,
        config
      )
      .then(async (res) => {

        setData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, [year, threeMonthsData]);
  // BUILD DATE

  var now = parseInt(dayjs().format("MM"), 10);
  var month3ago = now - 3;
  if (month3ago <= 0) {
    month3ago = 12 + month3ago;
  }
  var month2ago = now - 2;
  if (month2ago <= 0) {
    month2ago = 12 + month2ago;
  }
  var month1ago = now - 1;
  if (month1ago <= 0) {
    month1ago = 12 + month1ago;
  }

  //  CHART YEAR

  const ChartdataYear = {
    labels: ["Other Subs", "Life Subs", "Trails", "Ebook", "Coaching"],
    datasets: [
      //data for revenu
      {
        label: ["user"],
        fill: false,
        tension: 0.15,
        data: [
          data.Year1TaxDataSub,
          data.Year1TaxDataLife,
          data.Year1TaxDataTrails,
          data.Year1TaxDataEBook,
          data.Year1TaxDataCoach,
        ],
        backgroundColor: [
          "#A8C492",
          "#1B4965",
          "#FD7064",
          "#00796b",
          "#FFCE63",
          "#94CDDB",
          "#d50000",
        ],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "left",
      },
      datalabels: {
        display: (context) => context.dataset.data[context.dataIndex] > 0.01,
        formatter: (value, ctx) => {
          let percentage = (value * 100).toFixed(2) + "%";
          return percentage;
        },
        color: "#000", // Label color
        font: {
          weight: "bold", // Label font weight
          size: 10,
        },
      },
    },
  };
  return (
    <Wrapper>
      <H5
        style={{
          fontSize: 20,
          marginTop: 10,
          marginBottom: 20,
          textAlign: "center",
        }}
      >
        Turnover Details
      </H5>
      <RowCenter>
        <TextField
          id="year"
          label="Year"
          style={{ width: "100%" }}
          type="number"
          value={year}
          onChange={(e) => setYear(e.target.value)}
        />
      </RowCenter>
      <div style={{ height: 300, marginTop: -30 }}>
        <Pie
          data={ChartdataYear}
          plugins={[ChartDataLabels]}
          options={options}
        />
      </div>
      {/* <div style={{ display: isCollapsed ? "none" : "block" }}>
        <p>same pratice as Turnover trend</p>
      </div>*/}
      {/* <Button onClick={() => setIsCollapsed(!isCollapsed)}>Info on data</Button>*/}
    </Wrapper>
  );
};

export default RevenuStat;
