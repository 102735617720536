import styled from "styled-components";
import UserGraph from "../../components/home/userGraph";
import ContentStat from "../../components/home/contentStat";
import RevenuStat from "../../components/home/revenuStat";
import SaleStat from "../../components/home/saleStat";
import CommunityStat from "../../components/home/communityStat";
import WarningStat from "../../components/home/warningStat";
import UserAccount from "./userAccount";
import SubscriptionsInfo from "../../components/home/subscriptionsInfo";

import UserGraphdiagram from "../../components/home/userGraphdiagram";
import EarningStat from "../../components/home/earningStat";
import UserRep from "../../components/home/UserRepartition";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 91%;
  min-height: ${Height};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Row = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

const ContentWrapper = styled.div`
  background-color: white;
  width: 100%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const UserWrapper = styled.div`
  width: 100%;

  border-radius: 20px;
  margin: 20px 0;
`;

const SmallColumn = styled.div`
  width: 33%;
  border-radius: 15px;
  margin-top: 10px;
  background-color: #f5f5f5;
`;

const RowSection = styled.div`
  width: 100%;
  background-color: #f5f5f5;
  margin-top: 15px;
  border-radius: 20px;
`;

const Home = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <UserAccount />



        <UserWrapper>
          {/* <UserTable/>*/}
          <SubscriptionsInfo />
        </UserWrapper>

        <RowSection>
          <EarningStat />
        </RowSection>
        <RowSection>
          <ContentStat />
        </RowSection>
        <RowSection>
          <SaleStat />
        </RowSection>
        <RowSection id="alerts">
          <CommunityStat />
        </RowSection>
        <RowSection >
          <WarningStat />
        </RowSection>
        <RowSection>
          <UserRep />
        </RowSection>
      </ContentWrapper>
    </Wrapper>
  );
};

/*
          data graph for later

          <UserGraph />
          <ContentReview />
          <ContentStat />
          <DurationStat />

        <H6>
          Nombre de réponse à la dernière enquête de satisfaction, nombre de
          litiges, nombre de messages reçus
        </H6>
        <br></br> <br></br>
        <H6>
          Nombre de réponse à la dernière enquête de satisfaction, nombre de
          litiges, nombre de messages reçus
        </H6>
        <br></br> <br></br>
        <DetailStat />
*/

export default Home;
