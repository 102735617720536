import styled from "styled-components";
import { H5 } from "../textStyles/style";
import axios from "axios";
import icon_client from "../../images/main/icon_home/image23.png";
import icon_ActiveUser from "../../images/main/icon_home/image2.png";
import icon_message from "../../images/main/icon_home/image9.png";
import icon_Leads from "../../images/main/icon_home/image31.png";
import { useState, useEffect } from "react";
import { Button, ButtonGroup, Typography } from "@mui/material";
import Transformation from "./transformation";

const Wrapper = styled.div`
  align-items: left;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
`;
const RowBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ElementsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  margin-top: 20px;
`;

const ElementContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  padding-top: 10px;
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const SaleStat = () => {
  const [loader, setLoader] = useState(false);
  const [language, setLanguage] = useState("all");
  const [data, setData] = useState([]);
  const getData = async () => {
    setLoader(true);
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };

    // will need a proprer API call !

    const Response = await axios.get(
      `${process.env.REACT_APP_GET_SALE_INFO}?language=${language}`,
      config
    );
    const Result = Response.data;

    setData(Result);
    setLoader(false);
  };
  useEffect(() => {
    getData();
  }, [language]);

  return (
    <Wrapper>
      <RowBetween style={{ paddingLeft: 10, paddingRight: 10 }}>
        <H5 style={{ fontSize: 22, textDecoration: "underline" }}>Sales</H5>

        <ButtonGroup>
          <Button
            size="small"
            onClick={() => setLanguage("all")}
            variant="contained"
            color={language != "all" ? "inherit" : "primary"}
          >
            all
          </Button>
          <Button
            size="small"
            onClick={() => setLanguage("fr")}
            variant="contained"
            color={language != "fr" ? "inherit" : "primary"}
          >
            fr
          </Button>
          <Button
            size="small"
            onClick={() => setLanguage("en")}
            variant="contained"
            color={language != "en" ? "inherit" : "primary"}
          >
            en
          </Button>
        </ButtonGroup>
      </RowBetween>
      <ElementsContainer>
        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Paying Companies
          </Typography>

          <img height={50} src={icon_client} alt="Client Icon" />

          <Typography>{data.totCompUsers} clients ({data.totCompUsersActive} active)</Typography>
        </ElementContainer>

        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Leads (active)
          </Typography>
          <img height={50} src={icon_Leads} alt="Leads Icon" />

          <Typography>{data.leads}</Typography>
        </ElementContainer>

        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Active Users (24h)
          </Typography>
          <img height={50} src={icon_ActiveUser} alt="Active Icon" />

          <Typography>{data.active}</Typography>
        </ElementContainer>

        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Message contact
          </Typography>

          <img height={50} src={icon_message} alt="Message Icon" />

          <Typography>{data.message}</Typography>
        </ElementContainer>
        <div style={{ width: "30%" }}>
          <Transformation />
        </div>
      </ElementsContainer>
    </Wrapper>
  );
};

export default SaleStat;
