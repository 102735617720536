import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import { Input, SelectInput } from "../../components/Inputs";
import { H5, LoadingIcon, TextBold } from "../../components/textStyles/style";
import "../../styles/custom-phone-input.css";

import { languages } from "../../data/SelectOptions";
import { useTranslation } from "react-i18next";
let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const NewQuestionnaire = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [language, setLanguage] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const { i18n } = useTranslation();

  const submitHandler = async () => {
    if (!loading) {
      setLoading(true);
      setErrorMessage("");
      const TOKEN = localStorage.getItem("TOKEN");
      try {
        const data = {
          access_token: TOKEN,
          name,
          description,
          language,
        };

        await axios.post(process.env.REACT_APP_QESTIONNAIRES, data);
        setLoading(false);
        history.goBack();
      } catch (error) {
        setLoading(false);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(error);
        setErrorMessage("Problem connexion with server");
      }
    }
  };
  const changeLanguageHandler = (e) => {
    i18n.changeLanguage(e.target.value);
    setLanguage(e.target.value);
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <H5>New Questionnaire</H5>
        <Row>
          <TextBold color="#c22222">
            {errorMessage ? errorMessage : " "}
          </TextBold>
        </Row>
        <Row>
          <SelectInput
            values={languages}
            placeholder="Language"
            value={language}
            onChange={changeLanguageHandler}
          />
          <Input
            placeholder="Questionnaire Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Row>
        <Row>
          <Input
            multiline
            placeholder="Questionnaire Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Row>

        <Row style={{ marginTop: 40 }}>
          <ButtonPrimary onClick={submitHandler}>
            {loading ? (
              <LoadingIcon color="inherit" />
            ) : (
              "Add new questionnaire"
            )}
          </ButtonPrimary>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default NewQuestionnaire;
