import React from "react";

import { ButtonsContainer, RollContainer, RollContent } from "./style";
import {
  ButtonPrimaryLink,
  ButtonSecondary,
  ButtonSecondaryLink,
} from "../buttonStyles/style";
import { H6, SmallBoldText } from "../textStyles/style";
const RollOver = ({ Type, item }) => {
  return (
    <RollContainer>
      <RollContent>
        <H6 style={{ marginTop: 10 }}>{item.title}</H6>
        <br />
        {Type === "Trail" && (
          <div style={{ marginTop: -5 }}>
            <SmallBoldText color="#302B2A">{`${item.seasons.length} saisons | ${item.episodes_number} episodes`}</SmallBoldText>
          </div>
        )}
      </RollContent>
      <ButtonsContainer>
        <ButtonPrimaryLink
          to={
            Type === "Trail"
              ? `/trail/${item.trail_code}`
              : `/workshop/${item.workshop_code}`
          }
        >
          Edit
        </ButtonPrimaryLink>
        <ButtonSecondaryLink to={`#`}>Stats</ButtonSecondaryLink>
        <ButtonSecondary>Delete</ButtonSecondary>
      </ButtonsContainer>
    </RollContainer>
  );
};

export default RollOver;
