import styled from "styled-components";
import { H5 } from "../textStyles/style";
import axios from "axios";
import { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import TextField from "@material-ui/core/TextField";
import {
  Chart as ChartJS,
  BarElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import dayjs from "dayjs";
import { Button } from "@mui/material";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const Wrapper = styled.div`
  width: 95%;
  align-items: left;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;
const Spacer = styled.div`
  height: 10vh;
`;

const TableWrapper = styled.div`
  width: 95%;
  align-items: left;
  margin-left: 5%;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;
const RowCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Marge = styled.div`
  margin-left: 20px;
`;
const UserGraph = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [english, setEnglish] = useState("all");
  const [freemium, setFreemium] = useState("");
  useEffect(() => {
    const getData = async () => {
      if (data.length == 0) {
        setLoader(true);
      }
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      axios
        .get(`${process.env.REACT_APP_GET_USERS_TYPE_NBR}?year=${year}&language=${english}`, config)
        .then(async (res) => {
          setData(res.data);
          console.log(data);
          setLoader(false);
          setFreemium(
            res.data.totNmbrUsers -
              res.data.totCompUsers -
              res.data.totLifeSubUsers -
              res.data.totSubsUsers
          );
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getData();
  }, [year,english]);

  // BUILD DATE

  var now = parseInt(dayjs().format("MM"), 10);
  var month3ago = now - 3;
  if (month3ago <= 0) {
    month3ago = 12 + month3ago;
  }
  var month2ago = now - 2;
  if (month2ago <= 0) {
    month2ago = 12 + month2ago;
  }
  var month1ago = now - 1;
  if (month1ago <= 0) {
    month1ago = 12 + month1ago;
  }

  //NUMBER INTO MONTH

  // CHART MONTH

  const ChartdataMonth = {
    labels: [
      "Total",
      "B2B",
      "B2C",
      "LifeTime Subs",
      "Annual Subs",
      "Quarter Subs",
      "Freemium",
    ],
    datasets: [
      //data for subscribers
      {
        label: ["users"],
        data: [
          data.totNmbrUsers,
          data.totCompUsers,
          data.totLifeSubUsers + data.totSubsUsers,
          data.totLifeSubUsers,
          data.countYearSub,
          data.count3MonthSub,
          freemium,
        ],
        backgroundColor: [
          "#FFCE63",
          "#9d8898",
          "#FF8C00",
          "#FD7064",
          "#9e9898",
          "#A145D0",
          "#77AF57",
          "#F4F0EF",
        ],
      },
      //data for subscription renewal
    ],
  };
  const option = {
    scales: {
      y: {
        title: {
          display: true,
          text: "Subscription Number",
        },
      },
    },
  };

  return (
    <Wrapper>
    <RowCenter>
      <h3>Information Subscription</h3>
      <Marge></Marge>
        <Button
          onClick={() => setEnglish("all")}
          variant="contained"
          color={english != "all" ? "inherit" : "primary"}
        >
          all
        </Button>
        <Button
          onClick={() => setEnglish("fr")}
          variant="contained"
          color={english != "fr" ? "inherit" : "primary"}
        >
          fr
        </Button>
        <Button
          onClick={() => setEnglish("en")}
          variant="contained"
          color={english != "en" ? "inherit" : "primary"}
        >
          en
        </Button>
        <Marge></Marge>
        <TextField
        id="year"
        label="Year"
        style={{ width: "200px" }}
        type="number"
        value={year}
        onChange={(e) => setYear(e.target.value)}
      />
        </RowCenter>
     
      <Bar data={ChartdataMonth} options={option} />
    </Wrapper>
  );
};

export default UserGraph;
