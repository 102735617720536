import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import axios from "axios";
import { H5 } from "../../components/textStyles/style";
import { RowBetween } from "./CompanyStat";

const CompanyPurchasesUsers = ({ company, reportType }) => {
  const [responseData, setResponseData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      axios
        .get(
          `${process.env.REACT_APP_COMPANY_USERS_PURCHASES}?company_id=${company}`
        )
        .then((res) => {
          setResponseData(res.data);

        });
    };
    getData();
  }, [company]);

  return (
    <div>
      <H5 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
        {reportType === "full" ? '8.' : '7.'} Users Purchases
      </H5>

      <RowBetween>
        <Typography fontSize={13} color="#484848">
          Trails
        </Typography>
        <Typography fontSize={13} color="#484848">
          {responseData.trails} Purchase(s)
        </Typography>
      </RowBetween>
      <RowBetween>
        <Typography fontSize={13} color="#484848">
          Flash Coaching
        </Typography>
        <Typography fontSize={13} color="#484848">
          {responseData.coaching} Purchase(s)
        </Typography>
      </RowBetween>
      <RowBetween>
        <Typography fontSize={13} color="#484848">
          E-books
        </Typography>
        <Typography fontSize={13} color="#484848">
          {responseData.ebooks} Purchase(s)
        </Typography>
      </RowBetween>
    </div>
  );
};

export default CompanyPurchasesUsers;
