import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import axios from "axios";
import { H6 } from "../../components/textStyles/style";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend
);

const CompanyChatEntriesStat = ({ company, reportType }) => {
  const [select, setSelect] = useState("months");
  const [responseData, setResponseData] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    const getData = async () => {
      axios
        .get(
          `${process.env.REACT_APP_COMPANY_CHAT_ENTRIES_STAT}?select=${select}&company_id=${company}`
        )
        .then((res) => {
          setResponseData(res.data.map((el) => el.count));

          setLabels(
            res.data.map((el) =>
              select === "days"
                ? `${el._id.day}/${el._id.month}/${el._id.year}`
                : select === "months"
                  ? `${el._id.month}/${el._id.year}`
                  : el._id.year
            )
          );
        });
    };
    getData();
  }, [select, company]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      datalabels: {
        display: (context) => context.dataset.data[context.dataIndex] !== 0,
        align: "right", // You can adjust label alignment (top, center, bottom)
        color: "rgb(28, 73, 102)", // Label color
        font: {
          weight: "bold", // Label font weight
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        fill: true,

        label: "Live Chat entries",
        data: responseData,
        borderColor: "rgb(28, 73, 102)",
        borderWidth: 3,
        backgroundColor: "rgba(28, 73, 102, 0.3)",
      },
    ],
  };
  console.log('reportTypereportTypereportType', reportType)
  return (
    <div style={{ width: "100%" }}>
      <H6 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
        {reportType === "full" ? '9.' : '6.'} Chat entries
      </H6>

      {/*<Button
          style={{ marginRight: 8 }}
          onClick={() => setSelect("days")}
          variant="contained"
          color={select !== "days" ? "inherit" : "primary"}
        >
          Last 7 days
        </Button>
        <Button
          style={{ marginRight: 8 }}
          onClick={() => setSelect("months")}
          variant="contained"
          color={select !== "months" ? "inherit" : "primary"}
        >
          Last 12 months
        </Button>
        <Button
          style={{ marginRight: 8 }}
          onClick={() => setSelect("years")}
          variant="contained"
          color={select !== "years" ? "inherit" : "primary"}
        >
          Last 5 years
  </Button>*/}
      <div style={{ width: "100%", marginTop: 10 }}>
        <Line
          style={{ width: "100%" }}
          plugins={[ChartDataLabels]}
          options={options}
          data={data}
        />
      </div>
    </div>
  );
};

export default CompanyChatEntriesStat;
