import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { H6 } from "../textStyles/style";

const FinishedWorkshopsListModal = ({
  workshops,
  isOpen,
  setIsOpen,
  setWorkshops,
  selectedType,
  setSelectedType,
}) => {
  const closeHandler = () => {
    setIsOpen(false);
    setWorkshops([]);

    setSelectedType("");
  };

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={closeHandler}>
      <DialogContent>
        <H6>
          Ateliers terminés -{" "}
          <H6 style={{ textTransform: "capitalize" }}>{selectedType}</H6>
        </H6>
        <div style={{ marginTop: 15 }}>
          <Table>
            <TableHead>
              <TableCell>
                <Typography fontWeight="600" color="#1B4965" fontSize={12}>
                  Code atelier
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="600" color="#1B4965" fontSize={12}>
                  Titre
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="600" color="#1B4965" fontSize={12}>
                  Date
                </Typography>
              </TableCell>
            </TableHead>
            <TableBody>
              {workshops.map((result) => {
                const workshop = result.workshop_data[0];
                return (
                  <TableRow>
                    <TableCell>
                      <Typography fontSize={12}>
                        {workshop.workshop_code}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Link
                        style={{ color: "#1b4965" }}
                        to={`/workshop/${workshop.workshop_code}`}
                        target="_blank"
                      >
                        <Typography fontSize={12}>{workshop.title}</Typography>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize={12}>
                        {moment(result.createdAt).format("DD/MM/YYYY HH:mm")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FinishedWorkshopsListModal;
