import styled from "styled-components";

export const AddContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #d4cbca;
  padding-bottom: 30px;
`;

export const ItemContainer = styled.div`
  height: 100px;
  border: 1px solid #d4cbca;
  border-radius: 20px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 10px;
`;

export const NoContentContainer = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TrashLogo = styled.img`
  width: 22;
  height: 22;
`;

export const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;
  height: clamp(50px, 5.5vw, 57px);
  width: clamp(50px, 5.5vw, 57px);
  border-radius: 1000px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 1px solid #a89d9c;
  z-index: 1;
  margin-left: 5px;
  color: red;
  &:hover {
    color: white;
    background-color: ${(props) => props.hovercolor || "#E1E8DC"};
    border-color: #e1e8dc;
  }
  transition: all 0.3s ease-in-out;
`;
