import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { H6 } from "../textStyles/style";
import { countries } from "../../data/SelectOptions";
import axios from "axios";
import { ButtonPrimary, ButtonPrimaryLink } from "../buttonStyles/style";
import { Edit, } from "@material-ui/icons";
import { AutoGraph, Person } from "@mui/icons-material";



const GroupsModal = ({ groups, isOpen, setIsOpen, setSelectedGroup, setShowUpdateGroup }) => {
  const closeHandler = () => {
    setIsOpen(false);
    setSelectedGroup(null)
  };


  const RenderRow = ({ group, country }) => {
    const [count, setCount] = useState("");
    useEffect(() => {
      const getCount = async () => {
        const TOKEN = localStorage.getItem("TOKEN");
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${TOKEN}`,
          },
        };
        const Response = await axios.get(
          `${process.env.REACT_APP_GROUPS}/group-number?group_id=${group._id}`,
          config
        );
        setCount(Response.data);
      };
      getCount();
    }, [group]);
    return (
      <TableRow>
        <TableCell align="center">
          <Typography fontSize={12}>{country ? country.label : " - "}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography fontSize={12}>{group.name}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography fontSize={12}>
            {count === "" ? " - " : count} Entitie(s)
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography fontSize={12}>
            {moment(group.createdAt).format("DD/MM/YYYY HH:mm")}
          </Typography>
        </TableCell>

        <TableCell align="right">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center", gap: 10 }}>
            <ButtonPrimaryLink
              to={`/group-users/${group._id}`}
              width="40px"
              height="40px"
            >
              <Person />
            </ButtonPrimaryLink>
            <ButtonPrimaryLink
              to={`/group-stats/${group._id}`}
              width="40px"
              height="40px"
            >
              <AutoGraph />
            </ButtonPrimaryLink>
            <ButtonPrimary onClick={() => {
              setSelectedGroup(group)
              setShowUpdateGroup(true)
            }} width="40px"
              height="40px"><Edit /></ButtonPrimary>
          </div>
        </TableCell>

      </TableRow>
    );
  };

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={closeHandler}>
      <DialogContent>
        <H6>Groups List</H6>
        <div style={{ marginTop: 15 }}>
          <Table>
            <TableHead>
              <TableCell align="center">
                <Typography fontWeight="600" color="#1B4965" fontSize={12}>
                  Country
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontWeight="600" color="#1B4965" fontSize={12}>
                  Name
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontWeight="600" color="#1B4965" fontSize={12}>
                  Entities
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontWeight="600" color="#1B4965" fontSize={12}>
                  Created Date
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontWeight="600" color="#1B4965" fontSize={12}>
                  Actions
                </Typography>
              </TableCell>
            </TableHead>
            <TableBody>
              {groups.map((group) => {
                const country = countries.find(
                  (el) => el.value === group.country
                );
                return <RenderRow group={group} country={country} />;
              })}
            </TableBody>
          </Table>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default GroupsModal;
