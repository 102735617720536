import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import {
  H6,
  LoadingIcon,
  SmallBoldText,
} from "../../../components/textStyles/style";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import moment from "moment";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../../components/buttonStyles/style";
import { createdAtTable } from "../Appointment";
import { Time, Title } from "./styles";
import axios from "axios";
import { useHistory } from "react-router";
import styled from "styled-components";

const UpdateButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 15px;
  align-items: center;
  justify-content: flex-end;
`;
const RowBetween = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Row = styled.div`
  margin-top: 5;
  display: flex;
  align-items: center;
`;

const CoachingDate = ({ appointment }) => {
  const history = useHistory();
  const [value, setValue] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [updateDate, setUpdateDate] = useState(false);
  const [isDateEntered, setIsDateEntered] = useState(false);

  const setDateHandler = () => {
    if (!submitLoader && value !== "") {
      setSubmitLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      axios
        .post(
          `${process.env.REACT_APP_COACHING_APPOINTMENT}?appointment_id=${appointment._id}`,
          {
            access_token: TOKEN,
            time: Date.parse(value),
          }
        )
        .then(() => {
          setSubmitLoader(false);
          history.go(0);
        })
        .catch((err) => {
          console.log(err);
          setSubmitLoader(false);
        });
    }
  };

  useEffect(() => {
    if (appointment.time) {
      const d = new Date(appointment.time).getTime();

      setValue(d);
    }
  }, [appointment]);

  // case update time
  if (appointment.time) {
    return (
      <div>
        {updateDate ? (
          <RowBetween>
            <ReactDatePicker
              selected={value}
              onChange={(date) => {
                setValue(date);
              }}
              placeholderText="DD/MM/yyyy h:mm aa"
              showTimeSelect
              minTime={setHours(setMinutes(new Date(), 0), 8)}
              maxTime={setHours(setMinutes(new Date(), 0), 18)}
              dateFormat="dd/MM/yyyy h:mm aa"
            />

            <div style={{ marginLeft: 10 }}>
              <Title>Europe/Paris</Title>
              <Time>
                {moment.tz(value, "Europe/Paris").format("DD-MM-YYYY HH:mm")}{" "}
                (Europe/Paris)
              </Time>
            </div>

            <div>
              <Title>{appointment.timezone}</Title>
              <Time>
                {moment
                  .tz(value, appointment.timezone)
                  .format("DD-MM-YYYY HH:mm")}{" "}
                ({appointment.timezone})
              </Time>
            </div>

            <Row>
              <ButtonPrimary
                onClick={setDateHandler}
                style={{ marginLeft: 20 }}
              >
                {submitLoader ? <LoadingIcon color="white" /> : "Save"}
              </ButtonPrimary>
              <ButtonSecondary
                onClick={() => setUpdateDate(false)}
                style={{ marginLeft: 10 }}
              >
                Cancel
              </ButtonSecondary>
            </Row>
          </RowBetween>
        ) : (
          <>
            <UpdateButtonContainer>
              <ButtonSecondary onClick={() => setUpdateDate(true)}>
                Change time
              </ButtonSecondary>
            </UpdateButtonContainer>
            {createdAtTable("Appointment time", appointment.timezone, value)}
          </>
        )}
      </div>
    );
  }

  // case add date
  return (
    <div>
      <SmallBoldText>Choose Appointment time</SmallBoldText>
      <RowBetween>
        <ReactDatePicker
          selected={value}

          onChange={(date) => {
            setIsDateEntered(true);
            setValue(date);
          }}
          placeholderText="DD/MM/yyyy h:mm aa"
          showTimeSelect
          minTime={setHours(setMinutes(new Date(), 0), 8)}
          maxTime={setHours(setMinutes(new Date(), 0), 18)}
          dateFormat="dd/MM/yyyy h:mm aa"
        />
        {isDateEntered && (
          <div style={{ marginLeft: 10 }}>
            <Title>Europe/Paris</Title>
            <Time>
              {moment.tz(value, "Europe/Paris").format("DD-MM-YYYY HH:mm")}{" "}
              (Europe/Paris)
            </Time>
          </div>
        )}
        {isDateEntered && (
          <div>
            <Title>{appointment.timezone}</Title>
            <Time>
              {moment
                .tz(value, appointment.timezone)
                .format("DD-MM-YYYY HH:mm")}{" "}
              ({appointment.timezone})
            </Time>
          </div>
        )}
        <ButtonPrimary onClick={setDateHandler} style={{ marginLeft: 20 }}>
          {submitLoader ? <LoadingIcon color="white" /> : "Save"}
        </ButtonPrimary>
      </RowBetween>
    </div>
  );
};

export default CoachingDate;
