import styled from "styled-components";
import { H5 } from "../textStyles/style";
import axios from "axios";
import { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import icon_client from "../../images/main/icon_home/image24.png";
import icon_Leads from "../../images/main/icon_home/image28.png";
import icon_www from "../../images/main/icon_home/image32.png";
import { Typography } from "@mui/material";
import { genderOptions, languages, ageOptions } from "../../data/SelectOptions";
import { SelectInput } from "../Inputs";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";

ChartJS.register(
  BarElement,
  LineElement,
  CategoryScale,
  PointElement,
  LinearScale,
  Tooltip,
  Legend
);
const Wrapper = styled.div`
  align-items: left;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
`;
const RowBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ElementsContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin: 10px;
  margin-top: 20px;
`;

const ElementContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  padding-top: 10px;
  width: 19%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const UserRep = () => {
  const lngsOptions = [{ label: "All", value: "all" }, ...languages];
  const gendrOptions = [{ label: "All", value: "all" }, ...genderOptions];
  const agOptions = [{ label: "All", value: "all" }, ...ageOptions];

  const [data, setData] = useState(null);
  const [currToken, setCurrToken] = useState(0);
  const [Total, setTotal] = useState(0);
  const [gender, setGender] = useState("all");
  const [language, setLanguage] = useState("all");
  const [age, setAge] = useState("all");

  const [qcm, setQcm] = useState(null);
  useEffect(() => {
    const getData = async () => {
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      axios
        .get(
          `${process.env.REACT_APP_USER_REPARTITION}?language=${language}&gender=${gender}&age=${age}`,
          config
        )
        .then(async (res) => {
          setData(res.data.UserRepartition);
          console.log();
          setCurrToken(res.data.currToken[0].count);
          setTotal(res.data.Token[0].count);
          console.log(res.data);
          setQcm(res.data.qcm);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getData();
  }, [language, gender, age]);

  const colors = ["#FD7064", "#1B4965", "#A8C492"];

  const data1 = {
    labels: [
      data && data[0] ? data[0]._id : " ",
      data && data[1] ? data[1]._id : " ",
      data && data[2] ? data[2]._id : " ",
    ],
    datasets: [
      {
        label: "Rank",
        data: [
          data && data[0] ? data[0].count : 0,
          data && data[1] ? data[1].count : 0,
          data && data[2] ? data[2].count : 0,
        ],
        backgroundColor: colors,
        borderColor: ["white", "white", "white"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Wrapper>
      <RowBetween style={{ paddingLeft: 10, paddingRight: 10 }}>
        <H5 style={{ fontSize: 22, textDecoration: "underline" }}>
          User Activites
        </H5>

        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ width: 80, marginRight: 10 }}>
            <SelectInput
              values={lngsOptions}
              placeholder="Language"
              value={language}
              onChange={(e) => {
                setLanguage(e.target.value);
              }}
            />
          </div>

          <div style={{ width: 80, marginRight: 10 }}>
            <SelectInput
              values={gendrOptions}
              placeholder="Gender"
              value={gender}
              onChange={(e) => {
                setGender(e.target.value);
              }}
            />
          </div>

          <div style={{ width: 80 }}>
            <SelectInput
              values={agOptions}
              placeholder="Age"
              value={age}
              onChange={(e) => {
                setAge(e.target.value);
              }}
            />
          </div>
        </div>
      </RowBetween>
      <ElementsContainer>
        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Nb Users One QCM answered at least
          </Typography>
          <img height={50} src={icon_client} alt="Client Icon" />
          <Typography>{qcm}</Typography>
        </ElementContainer>
        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Used Token/total tokens
          </Typography>
          <img height={50} src={icon_Leads} alt="Leads Icon" />
          <Typography>{(Total - currToken)} / {Total}</Typography>
          <Typography>{(((Total - currToken) / Total) * 100).toFixed(2)}%</Typography>
        </ElementContainer>

        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Total Tokens
          </Typography>
          <img height={50} src={icon_www} alt="Leads Icon" />
          <Typography>{Total}</Typography>
        </ElementContainer>

        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Token value (token = 0.025 €)
          </Typography>
          <img height={50} src={icon_www} alt="Leads Icon" />
          <Typography>{(Total * 0.025).toFixed(2)} €</Typography>
        </ElementContainer>

        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Users Per Rank
          </Typography>
          <div style={{ maxWidth: 260 }}>
            <Pie
              plugins={[ChartDataLabels]}
              options={{
                plugins: {
                  legend: {
                    display: true,
                    position: "bottom",
                  },

                  datalabels: {
                    display: (context) =>
                      context.dataset.data[context.dataIndex] !== 0,
                    formatter: (value, ctx) => {
                      let sum = 0;
                      let dataArr = ctx.chart.data.datasets[0].data;
                      dataArr.map((data) => {
                        sum += data;
                      });
                      let percentage = ((value * 100) / sum).toFixed(0) + "%";
                      return percentage;
                    },
                    color: "#333", // Label color
                    font: {
                      weight: "bold", // Label font weight
                      size: 10,
                    },
                  },
                },
              }}
              width={200}
              height={200}
              data={data1}
            />
          </div>
        </ElementContainer>
      </ElementsContainer>
    </Wrapper>
  );
};

export default UserRep;
