import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import { Input, SelectInput } from "../../components/Inputs";
import {
  H5,
  H6,
  LoadingIcon,
  TextBold,
} from "../../components/textStyles/style";
import {
  companiesPaymentOptions,
  companyTypologieOptions,
  countries,
  sectorsOptions,
} from "../../data/SelectOptions";
import "../../styles/custom-phone-input.css";
let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const PhoneInputWrapper = styled.div`
  border: none;
  overflow: hidden;
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const NewCompany = () => {
  const history = useHistory();
  const today = moment(new Date()).format("yyyy-MM-DD");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [name, setName] = useState("");
  const [siret, setSiret] = useState("");
  const [typologie, setTypologie] = useState("");
  const [phone, setPhone] = useState("");
  const [tva, setTva] = useState("");
  const [sector, setSector] = useState("");
  const [payment, setPayment] = useState("");
  const [totalEmployees, setTotalEmployees] = useState("");
  const [domain, setDomain] = useState("");
  const [code, setCode] = useState("");
  const [duration, setDuration] = useState("");
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("FR");
  const [groups, setGroups] = useState([]);
  const [numOrder, setNumOrder] = useState("");
  const [group, setGroup] = useState("");
  const submitHandler = async () => {
    if (!loading) {
      setLoading(true);
      setErrorMessage("");
      const TOKEN = localStorage.getItem("TOKEN");
      try {
        const data = {
          access_token: TOKEN,
          name,
          typologie,
          sector,
          group,
          siret,
          phone,
          total_employees: Number(totalEmployees),
          domain,
          code,
          num_order: numOrder,
          address,
          country,
          tva,
          payment,
        };
        console.log(data);
        await axios.post(process.env.REACT_APP_NEW_COMPANY, data);
        setLoading(false);
        history.goBack();
      } catch (error) {
        setLoading(false);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(error);
        setErrorMessage("Invalid data");
      }
    }
  };

  const getGroupsData = async () => {
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    // get groups list
    const res = await axios.get(
      `${process.env.REACT_APP_GROUPS}?dropdown=true`,
      config
    );

    setGroups(res.data);
  };

  useEffect(() => {
    getGroupsData();
  }, []);

  return (
    <Wrapper>
      <ContentWrapper>
        <H5>New Company</H5>
        <Row>
          <TextBold color="#c22222">
            {errorMessage ? errorMessage : " "}
          </TextBold>
        </Row>
        <H6 style={{ fontSize: 20 }}>Identification</H6>
        {/** number of order */}
        <Row>
          <Input
            style={{ marginTop: 20 }}
            placeholder="Order number *"
            value={numOrder}
            onChange={(e) => setNumOrder(e.target.value)}
          />
        </Row>

        {/** group name  - typologie */}
        <Row>
          <SelectInput
            values={companyTypologieOptions}
            placeholder="Typology *"
            value={typologie}
            onChange={(e) => setTypologie(e.target.value)}
          />

          <SelectInput
            values={groups}
            placeholder="Group"
            value={group}
            onChange={(e) => setGroup(e.target.value)}
          />
        </Row>
        {/** company name */}
        <Row>
          <Input
            placeholder="Company name *"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <SelectInput
            values={companiesPaymentOptions}
            placeholder="Payment Method *"
            value={payment}
            onChange={(e) => setPayment(e.target.value)}
          />
        </Row>
        {/** siret + tva*/}
        <Row>
          <Input
            placeholder="TVA Number (optional)"
            value={tva}
            onChange={(e) => setTva(e.target.value)}
          />
          <Input
            placeholder="SIRET (optional)"
            value={siret}
            onChange={(e) => setSiret(e.target.value)}
          />
        </Row>
        {/** phone -sector */}
        <Row>
          <SelectInput
            values={sectorsOptions}
            placeholder="Activity Sector *"
            value={sector}
            onChange={(e) => setSector(e.target.value)}
          />
          <PhoneInputWrapper>
            <PhoneInput
              placeholder="Phone Number (optional)"
              value={phone}
              onChange={setPhone}
              defaultCountry="FR"
              international={false}
              rules={{ required: true }}
            />
          </PhoneInputWrapper>
        </Row>
        <Row style={{ marginBottom: 20 }}>
          <Input
            placeholder="Company domain (optional)"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
          />
          <Input
            type="number"
            placeholder="Total Employees *"
            value={totalEmployees}
            onChange={(e) => setTotalEmployees(e.target.value)}
          />
        </Row>

        <Row>
          <Input
            placeholder="Billing Address *"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <SelectInput
            values={countries}
            placeholder="country *"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
          <Input
            placeholder="Signup code *"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </Row>

        <Row style={{ marginTop: 40 }}>
          <ButtonPrimary onClick={submitHandler}>
            {loading ? <LoadingIcon color="inherit" /> : "Add new Company"}
          </ButtonPrimary>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default NewCompany;
