import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  H5,
  LoadingBigIcon,
  SmallText,
} from "../../components/textStyles/style";
import axios from "axios";
import { genderOptions } from "../../data/SelectOptions";
import { ButtonPrimaryLink } from "../../components/buttonStyles/style";
import { SearchInput } from "../../components/Inputs";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;

const LoadWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
`;

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
`;

const Experts = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [searchTotal, setSearchTotal] = useState(0);
  const [keyword, setKeyword] = useState("");

  const getData = async (keyword) => {
    setLoader(true);
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    console.log(process.env.REACT_APP_GET_EXPERTS);
    const Response = await axios.get(
      `${process.env.REACT_APP_GET_EXPERTS}?keyword=${keyword}`,
      config
    );

    const experts = Response.data.experts;
    setData(experts);
    setSearchTotal(Response.data.count);

    setLoader(false);
  };

  useEffect(() => {
    getData(keyword);
  }, [keyword]);

  return (
    <Wrapper>
      <ContentWrapper>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ width: 300 }}>
            <ButtonPrimaryLink width={300} to="/new-expert">
              Add new expert
            </ButtonPrimaryLink>
          </div>
          <SearchContainer>
            <SearchInput
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />

            <SmallText>{searchTotal} expert(s) found</SmallText>
          </SearchContainer>
        </div>
        <H5>Expert's List</H5>
        {loader ? (
          <LoadWrapper>
            <LoadingBigIcon />
          </LoadWrapper>
        ) : (
          <div style={{ margin: 30 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Civility
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    firstname
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Lastname
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Category
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Trails
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Workshops
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Coaching
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  ></TableCell>
                </TableHead>
                <TableBody>
                  {data.map((expert) => {
                    const catLabels = expert.category.map(
                      (expert) => expert.label
                    );
                    const cat = catLabels.join(",");
                    console.log(cat);
                    const gender = genderOptions.find(
                      (g) => g.value === expert.gender
                    );

                    return (
                      <TableRow key={expert._id}>
                        <TableCell scope="row">
                          {gender ? gender.label : "-"}
                        </TableCell>
                        <TableCell align="left">
                          {expert.firstname ? expert.firstname : "-"}
                        </TableCell>
                        <TableCell align="left">
                          {expert.lastname ? expert.lastname : "-"}
                        </TableCell>
                        <TableCell align="left">{cat ? cat : "-"}</TableCell>
                        <TableCell align="left">
                          {expert.num_trails > 0 ? expert.num_trails : "-"}
                        </TableCell>
                        <TableCell align="left">
                          {expert.num_workshops > 0
                            ? expert.num_workshops
                            : "-"}
                        </TableCell>
                        <TableCell align="left">
                          {expert.num_appointments > 0
                            ? expert.num_appointments
                            : "-"}
                        </TableCell>
                        <TableCell align="left">
                          <ButtonPrimaryLink
                            to={`/expert/${expert._id}`}
                            width="40px"
                            height="40px"
                          >
                            &#8594;
                          </ButtonPrimaryLink>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default Experts;
