import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { H5 } from "../textStyles/style";
import { Pie } from "react-chartjs-2";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const InvoiceSourceStat = () => {
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const colors = [
    "#FD7064",
    "#1B4965",
    "#A8C492",
    "#00796b",
    "#FFCE63",
    "#94CDDB",
    "#d50000",
    "#d500f9",
    "#fb8c00",
    "#4527a0",
    "#ff5722",
    "#263238",
  ];

  useEffect(() => {
    const getData = async () => {
      setLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      axios
        .get(`${process.env.REACT_APP_CHAT_INVOICES_SOURCE_STATS}`, config)
        .then(async (res) => {
          const labels = res.data.map((el) => el._id);
          const values = res.data.map((el) => el.count);
          setData(values);
          setLabels(labels);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getData();
  }, []);

  const pieDataHandler = () => {
    const d = {
      labels,
      datasets: [
        {
          label: "Purchase (s)",
          data,
          backgroundColor: colors,
        },
      ],
    };
    return d;
  };

  const sum = data.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);

  return (
    <div>
      <H5 style={{ fontSize: 22, marginBottom: 40 }}>
        Distribution of invoices by source
      </H5>
      {loader ? (
        <></>
      ) : (
        <div
          style={{
            marginTop: 15,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/** pie */}
          <div style={{ maxWidth: 180, marginRight: 40, marginLeft: 20 }}>
            <Pie
              options={{ plugins: { legend: false } }}
              data={pieDataHandler(labels, data, colors)}
            />
          </div>
          {/** table */}
          <div>
            <Table>
              <TableHead>
                <TableCell
                  align="center"
                  style={{ fontSize: 14, fontWeight: "bold", color: "#1B4965" }}
                >
                  Source
                </TableCell>
                {labels.map((label, i) => {
                  return (
                    <TableCell
                      key={label}
                      align="center"
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#1B4965",
                      }}
                    >
                      {label}
                    </TableCell>
                  );
                })}
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "#1B4965",
                    }}
                  >
                    Invoices
                  </TableCell>
                  {data.map((val) => {
                    return (
                      <TableCell
                        key={val}
                        align="center"
                        style={{
                          fontSize: 14,
                          color: "#222222",
                        }}
                      >
                        {`${val} Invoices(s)`}
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "#1B4965",
                    }}
                  >
                    Pourcentage
                  </TableCell>
                  {data.map((val) => {
                    return (
                      <TableCell
                        key={val}
                        align="center"
                        style={{
                          fontSize: 14,
                          color: "#222222",
                        }}
                      >
                        {((Number(val) / Number(sum)) * 100).toFixed(2)}%
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvoiceSourceStat;
