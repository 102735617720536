import styled from "styled-components";
import { H5 } from "../textStyles/style";
import axios from "axios";
import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import TextField from "@material-ui/core/TextField";
import { Button, Typography } from "@mui/material";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import dayjs from "dayjs";

ChartJS.register(
  BarElement,
  LineElement,
  CategoryScale,
  PointElement,
  LinearScale,
  Tooltip,
  Legend
);
const RowCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RevenuStatEvolution = () => {
  const [loader, setLoader] = useState(false);
  const [threeMonthsData, setThreeMonthsData] = useState(false);
  const [data, setData] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleChange = (e) => {
    setYear((prevYear) => {
      getData(e.target.value);
      return e.target.value;
    });
  };

  const getData = async () => {
    if (data.length === 0) {
      setLoader(true);
    }
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    axios
      .get(
        `${process.env.REACT_APP_GET_TAXSTAT}?year=${year}&month=${
          threeMonthsData ? "true" : ""
        }`,
        config
      )
      .then(async (res) => {
        setData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, [year, threeMonthsData]);
  // BUILD DATE

  var now = parseInt(dayjs().format("MM"), 10);
  var month3ago = now - 3;
  if (month3ago <= 0) {
    month3ago = 12 + month3ago;
  }
  var month2ago = now - 2;
  if (month2ago <= 0) {
    month2ago = 12 + month2ago;
  }
  var month1ago = now - 1;
  if (month1ago <= 0) {
    month1ago = 12 + month1ago;
  }

  //  CHART YEAR

  const ChartdataYear = {
    labels: [year - 2, year - 1, year],
    datasets: [
      //data for revenu
      {
        label: "total",
        fill: false,
        tension: 0.15,
        data: [data.Year3TaxData, data.Year2TaxData, data.Year1TaxData],
        borderColor: ["#1b4965"],
        backgroundColor: ["#1b4965"],
      },
      {
        label: "Other Subs",
        fill: false,
        tension: 0.15,
        data: [
          data.Year3TaxDataSub,
          data.Year2TaxDataSub,
          data.Year1TaxDataSub,
        ],
        borderColor: ["#FFCE63"],
        backgroundColor: ["#FFCE63"],
      },
      {
        label: "Life Subs",
        fill: false,
        tension: 0.15,
        data: [
          data.Year3TaxDataLife,
          data.Year2TaxDataLife,
          data.Year1TaxDataLife,
        ],
        borderColor: ["#FD7064"],
        backgroundColor: ["#FD7064"],
      },
      {
        label: "Trails",
        fill: false,
        tension: 0.15,
        data: [
          data.Year3TaxDataTrails,
          data.Year2TaxDataTrails,
          data.Year1TaxDataTrails,
        ],
        borderColor: ["#00796b"],
        backgroundColor: ["#00796b"],
      },
      {
        label: "Ebook",
        fill: false,
        tension: 0.15,
        data: [
          data.Year3TaxDataEBook,
          data.Year2TaxDataEBook,
          data.Year1TaxDataEBook,
        ],
        borderColor: ["#fb8c00"],
        backgroundColor: ["#fb8c00"],
      },
      {
        label: "Coaching",
        fill: false,
        data: [
          data.Year3TaxDataCoach,
          data.Year2TaxDataCoach,
          data.Year1TaxDataCoach,
        ],
        borderColor: ["#263238"],
        backgroundColor: ["#263238"],
      },
    ],
  };

  const ChartdataMonth = {
    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    datasets: [
      //data for revenu
      {
        label: "total",
        fill: false,
        tension: 0.15,
        data: [
          data.tableByYearTax && data.tableByYearTax[1]
            ? data.tableByYearTax[1] / 10000
            : 0,
          data.tableByYearTax && data.tableByYearTax[2]
            ? data.tableByYearTax[2] / 10000
            : 0,
          data.tableByYearTax && data.tableByYearTax[3]
            ? data.tableByYearTax[3] / 10000
            : 0,
          data.tableByYearTax && data.tableByYearTax[4]
            ? data.tableByYearTax[4] / 10000
            : 0,
          data.tableByYearTax && data.tableByYearTax[5]
            ? data.tableByYearTax[5] / 10000
            : 0,
          data.tableByYearTax && data.tableByYearTax[6]
            ? data.tableByYearTax[6] / 10000
            : 0,
          data.tableByYearTax && data.tableByYearTax[7]
            ? data.tableByYearTax[7] / 10000
            : 0,
          data.tableByYearTax && data.tableByYearTax[8]
            ? data.tableByYearTax[8] / 10000
            : 0,
          data.tableByYearTax && data.tableByYearTax[9]
            ? data.tableByYearTax[9] / 10000
            : 0,
          data.tableByYearTax && data.tableByYearTax[10]
            ? data.tableByYearTax[10] / 10000
            : 0,
          data.tableByYearTax && data.tableByYearTax[11]
            ? data.tableByYearTax[11] / 10000
            : 0,
          data.tableByYearTax && data.tableByYearTax[12]
            ? data.tableByYearTax[12] / 10000
            : 0,
        ],
        borderColor: ["#1b4965"],
        backgroundColor: ["#1b4965"],
      },
      {
        label: "Other Subs",
        fill: false,
        tension: 0.15,
        data: [
          data.tableByYearTaxSub && data.tableByYearTaxSub[1]
            ? data.tableByYearTaxSub[1] / 10000
            : 0,
          data.tableByYearTaxSub && data.tableByYearTaxSub[2]
            ? data.tableByYearTaxSub[2] / 10000
            : 0,
          data.tableByYearTaxSub && data.tableByYearTaxSub[3]
            ? data.tableByYearTaxSub[3] / 10000
            : 0,
          data.tableByYearTaxSub && data.tableByYearTaxSub[4]
            ? data.tableByYearTaxSub[4] / 10000
            : 0,
          data.tableByYearTaxSub && data.tableByYearTaxSub[5]
            ? data.tableByYearTaxSub[5] / 10000
            : 0,
          data.tableByYearTaxSub && data.tableByYearTaxSub[6]
            ? data.tableByYearTaxSub[6] / 10000
            : 0,
          data.tableByYearTaxSub && data.tableByYearTaxSub[7]
            ? data.tableByYearTaxSub[7] / 10000
            : 0,
          data.tableByYearTaxSub && data.tableByYearTaxSub[8]
            ? data.tableByYearTaxSub[8] / 10000
            : 0,
          data.tableByYearTaxSub && data.tableByYearTaxSub[9]
            ? data.tableByYearTaxSub[9] / 10000
            : 0,
          data.tableByYearTaxSub && data.tableByYearTaxSub[10]
            ? data.tableByYearTaxSub[10] / 10000
            : 0,
          data.tableByYearTaxSub && data.tableByYearTaxSub[11]
            ? data.tableByYearTaxSub[11] / 10000
            : 0,
          data.tableByYearTaxSub && data.tableByYearTaxSub[12]
            ? data.tableByYearTaxSub[12] / 10000
            : 0,
        ],
        borderColor: ["#FFCE63"],
        backgroundColor: ["#FFCE63"],
      },
      {
        label: "Life Subs",
        fill: false,
        tension: 0.15,
        data: [
          data.tableByYearTaxLife && data.tableByYearTaxLife[1]
            ? data.tableByYearTaxLife[1] / 10000
            : 0,
          data.tableByYearTaxLife && data.tableByYearTaxLife[2]
            ? data.tableByYearTaxLife[2] / 10000
            : 0,
          data.tableByYearTaxLife && data.tableByYearTaxLife[3]
            ? data.tableByYearTaxLife[3] / 10000
            : 0,
          data.tableByYearTaxLife && data.tableByYearTaxLife[4]
            ? data.tableByYearTaxLife[4] / 10000
            : 0,
          data.tableByYearTaxLife && data.tableByYearTaxLife[5]
            ? data.tableByYearTaxLife[5] / 10000
            : 0,
          data.tableByYearTaxLife && data.tableByYearTaxLife[6]
            ? data.tableByYearTaxLife[6] / 10000
            : 0,
          data.tableByYearTaxLife && data.tableByYearTaxLife[7]
            ? data.tableByYearTaxLife[7] / 10000
            : 0,
          data.tableByYearTaxLife && data.tableByYearTaxLife[8]
            ? data.tableByYearTaxLife[8] / 10000
            : 0,
          data.tableByYearTaxLife && data.tableByYearTaxLife[9]
            ? data.tableByYearTaxLife[9] / 10000
            : 0,
          data.tableByYearTaxLife && data.tableByYearTaxLife[10]
            ? data.tableByYearTaxLife[10] / 10000
            : 0,
          data.tableByYearTaxLife && data.tableByYearTaxLife[11]
            ? data.tableByYearTaxLife[11] / 10000
            : 0,
          data.tableByYearTaxLife && data.tableByYearTaxLife[12]
            ? data.tableByYearTaxLife[12] / 10000
            : 0,
        ],
        borderColor: ["#FD7064"],
        backgroundColor: ["#FD7064"],
      },
      {
        label: "Trails",
        fill: false,
        tension: 0.15,
        data: [
          data.tableByYearTaxTrails && data.tableByYearTaxTrails[1]
            ? data.tableByYearTaxTrails[1] / 10000
            : 0,
          data.tableByYearTaxTrails && data.tableByYearTaxTrails[2]
            ? data.tableByYearTaxTrails[2] / 10000
            : 0,
          data.tableByYearTaxTrails && data.tableByYearTaxTrails[3]
            ? data.tableByYearTaxTrails[3] / 10000
            : 0,
          data.tableByYearTaxTrails && data.tableByYearTaxTrails[4]
            ? data.tableByYearTaxTrails[4] / 10000
            : 0,
          data.tableByYearTaxTrails && data.tableByYearTaxTrails[5]
            ? data.tableByYearTaxTrails[5] / 10000
            : 0,
          data.tableByYearTaxTrails && data.tableByYearTaxTrails[6]
            ? data.tableByYearTaxTrails[6] / 10000
            : 0,
          data.tableByYearTaxTrails && data.tableByYearTaxTrails[7]
            ? data.tableByYearTaxTrails[7] / 10000
            : 0,
          data.tableByYearTaxTrails && data.tableByYearTaxTrails[8]
            ? data.tableByYearTaxTrails[8] / 10000
            : 0,
          data.tableByYearTaxTrails && data.tableByYearTaxTrails[9]
            ? data.tableByYearTaxTrails[9] / 10000
            : 0,
          data.tableByYearTaxTrails && data.tableByYearTaxTrails[10]
            ? data.tableByYearTaxTrails[10] / 10000
            : 0,
          data.tableByYearTaxTrails && data.tableByYearTaxTrails[11]
            ? data.tableByYearTaxTrails[11] / 10000
            : 0,
          data.tableByYearTaxTrails && data.tableByYearTaxTrails[12]
            ? data.tableByYearTaxTrails[12] / 10000
            : 0,
        ],
        borderColor: ["#00796b"],
        backgroundColor: ["#00796b"],
      },
      {
        label: "Ebook",
        fill: false,
        tension: 0.15,
        data: [
          data.tableByYearTaxEBook && data.tableByYearTaxEBook[1]
            ? data.tableByYearTaxEBook[1] / 10000
            : 0,
          data.tableByYearTaxEBook && data.tableByYearTaxEBook[2]
            ? data.tableByYearTaxEBook[2] / 10000
            : 0,
          data.tableByYearTaxEBook && data.tableByYearTaxEBook[3]
            ? data.tableByYearTaxEBook[3] / 10000
            : 0,
          data.tableByYearTaxEBook && data.tableByYearTaxEBook[4]
            ? data.tableByYearTaxEBook[4] / 10000
            : 0,
          data.tableByYearTaxEBook && data.tableByYearTaxEBook[5]
            ? data.tableByYearTaxEBook[5] / 10000
            : 0,
          data.tableByYearTaxEBook && data.tableByYearTaxEBook[6]
            ? data.tableByYearTaxEBook[6] / 10000
            : 0,
          data.tableByYearTaxEBook && data.tableByYearTaxEBook[7]
            ? data.tableByYearTaxEBook[7] / 10000
            : 0,
          data.tableByYearTaxEBook && data.tableByYearTaxEBook[8]
            ? data.tableByYearTaxEBook[8] / 10000
            : 0,
          data.tableByYearTaxEBook && data.tableByYearTaxEBook[9]
            ? data.tableByYearTaxEBook[9] / 10000
            : 0,
          data.tableByYearTaxEBook && data.tableByYearTaxEBook[10]
            ? data.tableByYearTaxEBook[10] / 10000
            : 0,
          data.tableByYearTaxEBook && data.tableByYearTaxEBook[11]
            ? data.tableByYearTaxEBook[11] / 10000
            : 0,
          data.tableByYearTaxEBook && data.tableByYearTaxEBook[12]
            ? data.tableByYearTaxEBook[12] / 10000
            : 0,
        ],
        borderColor: ["#fb8c00"],
        backgroundColor: ["#fb8c00"],
      },
      {
        label: "Coaching",
        fill: false,
        data: [
          data.tableByYearTaxCoach && data.tableByYearTaxCoach[1]
            ? data.tableByYearTaxCoach[1] / 10000
            : 0,
          data.tableByYearTaxCoach && data.tableByYearTaxCoach[2]
            ? data.tableByYearTaxCoach[2] / 10000
            : 0,
          data.tableByYearTaxCoach && data.tableByYearTaxCoach[3]
            ? data.tableByYearTaxCoach[3] / 10000
            : 0,
          data.tableByYearTaxCoach && data.tableByYearTaxCoach[4]
            ? data.tableByYearTaxCoach[4] / 10000
            : 0,
          data.tableByYearTaxCoach && data.tableByYearTaxCoach[5]
            ? data.tableByYearTaxCoach[5] / 10000
            : 0,
          data.tableByYearTaxCoach && data.tableByYearTaxCoach[6]
            ? data.tableByYearTaxCoach[6] / 10000
            : 0,
          data.tableByYearTaxCoach && data.tableByYearTaxCoach[7]
            ? data.tableByYearTaxCoach[7] / 10000
            : 0,
          data.tableByYearTaxCoach && data.tableByYearTaxCoach[8]
            ? data.tableByYearTaxCoach[8] / 10000
            : 0,
          data.tableByYearTaxCoach && data.tableByYearTaxCoach[9]
            ? data.tableByYearTaxCoach[9] / 10000
            : 0,
          data.tableByYearTaxCoach && data.tableByYearTaxCoach[10]
            ? data.tableByYearTaxCoach[10] / 10000
            : 0,
          data.tableByYearTaxCoach && data.tableByYearTaxCoach[11]
            ? data.tableByYearTaxCoach[11] / 10000
            : 0,
          data.tableByYearTaxCoach && data.tableByYearTaxCoach[12]
            ? data.tableByYearTaxCoach[12] / 10000
            : 0,
        ],
        borderColor: ["#263238"],
        backgroundColor: ["#263238"],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: "Income on thousands",
        },
      },
    },
  };
  return (
    <Wrapper>
      <H5
        style={{
          fontSize: 20,
          marginTop: 10,
          marginBottom: 20,
          textAlign: "center",
        }}
      >
        Turnover Trend
      </H5>
      <RowCenter style={{ alignItems: "flex-end" }}>
        <TextField
          id="year"
          label="Year"
          style={{ width: "200px" }}
          type="number"
          value={year}
          onChange={(e) => setYear(e.target.value)}
        />

        <Button
          onClick={() => setThreeMonthsData(false)}
          variant="contained"
          style={{ marginLeft: 10, marginRight: 5 }}
          color={threeMonthsData ? "inherit" : "primary"}
        >
          <Typography fontSize={13}>Year</Typography>
        </Button>
        <Button
          onClick={() => setThreeMonthsData(true)}
          variant="contained"
          color={!threeMonthsData ? "inherit" : "primary"}
        >
          <Typography fontSize={13}>Months</Typography>
        </Button>
      </RowCenter>
      <div style={{ marginTop: 20, width: "100%", alignItems: "center" }}>
        <Line
          style={{ width: "80%" }}
          data={threeMonthsData ? ChartdataMonth : ChartdataYear}
          options={options}
        />
      </div>
      {/* <div style={{ display: isCollapsed ? "none" : "block" }}>
        <p>
          calculates the total amount of taxes for each type of revenue source
          and for each month of the year. It iterates through each tax invoice
          in the dataset, extracts the corresponding month and revenue source
          type, and adds the tax amount from the invoice to the corresponding
          variable for the appropriate month and revenue source type. Then, it
          stores the aggregated totals for each month of the year and each
          revenue source type in arrays. For year is does the same but with, the
          past, current, and next year and regroup data by year instead of month{" "}
        </p>
      </div>
      <Button onClick={() => setIsCollapsed(!isCollapsed)}>Info on data</Button>*/}
    </Wrapper>
  );
};

export default RevenuStatEvolution;
