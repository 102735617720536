import {
  Backdrop,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import moment from "moment-timezone";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ChipText } from "../../../components/tables/styles";
import {
  H5,
  H6,
  LoadingBigIcon,
  LoadingIcon,
  Text,
} from "../../../components/textStyles/style";
import {
  appointmentStatusToExpert,
  appointmentStatusWithoutAll,
  types,
} from "../../../data/SelectOptions";
import {
  ChipsContainer,
  ContentWrapper,
  LoadWrapper,
  Wrapper,
} from "../styles";
import CoachingUsers from "../appointment/coachingUsers";
import CoachingDate from "../appointment/coachingDate";
import { Input, SelectInput } from "../../../components/Inputs";
import { ButtonPrimary } from "../../../components/buttonStyles/style";
import SendCoachingEmailsModal from "../../../components/modals/SendCoachingEmailsModal";
import MessagesHistory from "../appointment/messagesHistory";

export const createdAtTable = (title, timezone, time) => {
  return (
    <Table>
      <TableHead>
        <TableCell
          align="center"
          style={{ fontWeight: "bold", color: "#1B4965" }}
        ></TableCell>
        <TableCell
          align="center"
          style={{ fontWeight: "bold", color: "#1B4965" }}
        >
          Europe/Paris
        </TableCell>
        <TableCell
          align="center"
          style={{ fontWeight: "bold", color: "#1B4965" }}
        >
          {timezone}
        </TableCell>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell
            align="center"
            style={{ fontWeight: "bold", color: "#1B4965" }}
          >
            {title}
          </TableCell>
          <TableCell align="center">
            {moment.tz(time, "Europe/Paris").format("DD-MM-YYYY HH:mm z")}
          </TableCell>
          <TableCell align="center">
            {moment.tz(time, timezone).format("DD-MM-YYYY HH:mm z")}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const ExpertAppointment = () => {
  const { appointment_id } = useParams();
  const [loader, setLoader] = useState(true);
  const [appointment, setAppointment] = useState(null);
  const [currentStatus, setCurrentStatus] = useState("");

  const [cancelReason, setCancelReason] = useState("");
  const [saveReasonLoader, setSaveReasonLoader] = useState(false);
  const [changeStatusLoader, setChangeStatusLoader] = useState(false);
  const [showAppUserEmailModal, setShowAppUserEmailModal] = useState(false);
  const [showRecipientEmailModal, setShowRecipientEmailModal] = useState(false);
  const [messages, setMessages] = useState([]);

  const history = useHistory();
  const TOKEN = localStorage.getItem("TOKEN");
  useEffect(() => {
    const getAppointmentData = async () => {
      setLoader(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_COACHING_APPOINTMENT}?appointment_id=${appointment_id}`,
        config
      );
      setAppointment(response.data);
      setCancelReason(
        response.data.cancel_reason ? response.data.cancel_reason : ""
      );
      setCurrentStatus(response.data.status);
      // GET APPOINTMENT MESSAGES
      const messagesResponse = await axios.get(
        `${process.env.REACT_APP_APPOINTMENT_MESSAGES}?appointment_id=${appointment_id}`,
        config
      );
      setMessages(messagesResponse.data);
      setLoader(false);
    };

    getAppointmentData();
  }, [appointment_id, TOKEN]);

  const categoryRender = (value) => {
    if (value === "all") {
      return "All Categories";
    } else if (value) {
      return types.find((cat) => cat.value === value).label;
    } else {
      return " - ";
    }
  };

  const status = (value) =>
    appointmentStatusWithoutAll.find((s) => s.value === value).label;

  const YesNoRender = (value, title) => {
    const v = value === "true" || value === true ? true : false;
    return (
      <ChipText style={{ paddingLeft: 20, paddingRight: 20 }}>
        {title} : {v ? "Yes" : "No"}
      </ChipText>
    );
  };

  const updateAppointmentStatus = async (val) => {
    if (!changeStatusLoader) {
      setChangeStatusLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      axios
        .put(
          `${process.env.REACT_APP_COACHING_APPOINTMENT}?appointment_id=${appointment._id}`,
          {
            access_token: TOKEN,
            status: Number(val),
          }
        )
        .then(() => {
          setCurrentStatus(val);
          setTimeout(() => {
            setChangeStatusLoader(false);
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          setChangeStatusLoader(false);
        });
    }
  };

  const saveReasonHandler = () => {
    if (!saveReasonLoader) {
      setSaveReasonLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      axios
        .put(
          `${process.env.REACT_APP_COACHING_APPOINTMENT}?appointment_id=${appointment._id}`,
          {
            access_token: TOKEN,
            cancel_reason: cancelReason,
          }
        )
        .then(() => {
          setTimeout(() => {
            setSaveReasonLoader(false);
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          setSaveReasonLoader(false);
        });
    }
  };

  return (
    <Wrapper>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={changeStatusLoader}
        onClick={() => { }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ContentWrapper>
        <H5 style={{ fontSize: 24 }}>Flash Coaching </H5>

        {loader || appointment === null ? (
          <LoadWrapper>
            <LoadingBigIcon />
          </LoadWrapper>
        ) : (
          <>
            <div style={{ marginTop: 10 }}>
              <div style={{ backgroundColor: "#f5f5f5", padding: 15 }}>
                <H6 style={{ fontSize: 20 }}>General Informations</H6>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <ChipsContainer>
                      {/** status */}
                      <ChipText
                        dark={currentStatus === 0}
                        warning={currentStatus === 1}
                        success={currentStatus === 3}
                        danger={
                          currentStatus === 4 ||
                          currentStatus === 5 ||
                          currentStatus === 6
                        }
                        style={{ paddingLeft: 20, paddingRight: 20 }}
                      >
                        Status : {currentStatus + 1} - {status(currentStatus)}
                      </ChipText>
                      {/** is Gift */}
                      {YesNoRender(appointment.isGift, "Gift")}
                      {/** Payed with points */}
                      {YesNoRender(appointment.withPoints, "Paid with token ")}
                    </ChipsContainer>
                    <div style={{ marginTop: 25, marginBottom: 25 }}>
                      {/** topic */}
                      <Typography color="#1B4965">
                        <Typography variant="span" fontWeight="bold">
                          Subject :{" "}
                        </Typography>
                        {appointment.topic}
                      </Typography>
                      {/** message */}
                      <Typography color="#1B4965">
                        <Typography variant="span" fontWeight="bold">
                          Message :{" "}
                        </Typography>
                        {appointment.message || " - "}
                      </Typography>
                      {appointment.topic == "Trail" ? (
                        // Ressource :  case topic trail

                        <Typography color="#1B4965">
                          <Typography variant="span" fontWeight="bold">
                            Trail :{" "}
                          </Typography>
                          {`${appointment.trail_code}`} -{" "}
                          {appointment.trail_title}
                        </Typography>
                      ) : appointment.topic == "Atelier" ? (
                        // Ressource :   case topic workshop
                        <Typography color="#1B4965">
                          <Typography variant="span" fontWeight="bold">
                            Category :{" "}
                          </Typography>
                          {categoryRender(appointment.category)}
                        </Typography>
                      ) : (
                        // Ressource :   case topic "autre"
                        <></>
                      )}
                    </div>
                  </div>

                  <div>
                    {/** created at */}
                    {createdAtTable(
                      "Created at",
                      appointment.timezone,
                      appointment.createdAt
                    )}
                  </div>
                </div>
              </div>

              <div style={{ marginTop: 15, display: 'flex', alignItems: 'stretch', justifyContent: 'space-between' }}>
                <div style={{ width: '49.5%', backgroundColor: '#f5f5f5', borderRadius: 10, overflow: 'hidden' }}>
                  {/** status */}
                  <div style={{ padding: 15 }}>

                    <H6 style={{ fontSize: 20 }}>Appointment Status</H6>
                    <div style={{ marginTop: 15 }}>
                      {
                        currentStatus === 5 ? <div>
                          <Typography color="#c22222" fontSize={14} style={{ marginLeft: 5 }}>Appointment canceled by User at {moment(appointment.cancelAt).format('LLL')}.</Typography>
                          <Typography color="#484848" fontSize={14} style={{ marginLeft: 5 }}>Cancel Reason : {cancelReason}</Typography>
                        </div> :
                          <SelectInput
                            values={appointmentStatusToExpert}
                            placeholder="Appointment Status"
                            value={currentStatus}
                            onChange={(e) => updateAppointmentStatus(e.target.value)}
                          />
                      }
                      {(currentStatus === 4 || currentStatus === 6) && <div style={{ marginTop: 30 }}>
                        <Input

                          multiline={true}
                          placeholder="Reason for cancellation of appointment *"
                          value={cancelReason}
                          onChange={(e) => setCancelReason(e.target.value)}
                        />
                        <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 12, }}>
                          <ButtonPrimary width="270px" onClick={saveReasonHandler}>{saveReasonLoader ? <LoadingIcon color="white" /> : "Update Cancel reason"}</ButtonPrimary>
                        </div>
                      </div>}
                    </div>

                  </div>
                </div>
                <div style={{ width: '49.5%', backgroundColor: '#f5f5f5', borderRadius: 10, overflow: 'hidden' }}>
                  {/** date */}
                  <div style={{ padding: 15 }}>
                    <H6 style={{ fontSize: 20 }}>Appointment Time</H6>
                    <div style={{ marginTop: 15 }}>
                      <CoachingDate appointment={appointment} />
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  padding: 15,
                  marginTop: 15,
                }}
              >
                <CoachingUsers
                  setShowAppUserEmailModal={setShowAppUserEmailModal}
                  setShowRecipientEmailModal={setShowRecipientEmailModal}
                  appointment={appointment}
                />
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#f5f5f5",
                borderRadius: 10,
                padding: 15,
                marginTop: 20,
              }}
            >
              <H6 style={{ fontSize: 20 }}>Messages history</H6>
              <br />
              {messages.length == 0 ? (
                <Text color="#444" style={{ marginTop: 10, marginBottom: 20 }}>
                  No messages ...
                </Text>
              ) : (
                <MessagesHistory messages={messages} />
              )}
            </div>
          </>
        )}
      </ContentWrapper>

      {/** modal to send email to recipient of flash coaching */}
      {appointment && (
        <SendCoachingEmailsModal
          email={appointment.email}
          isOpen={showRecipientEmailModal}
          setIsOpen={setShowRecipientEmailModal}
          customer={{
            firstname: appointment.firstname,
            lastname: appointment.lastname,
          }}
          appointment_id={appointment_id}
          time={
            appointment.time
              ? moment
                .tz(appointment.time, appointment.timezone)
                .format("DD-MM-YYYY HH:mm") +
              " - " +
              appointment.timezone
              : ""
          }
        />
      )}
    </Wrapper>
  );
};

export default ExpertAppointment;
