import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ButtonPrimary, ButtonSecondary } from "../buttonStyles/style";
import { H6, LoadingIcon, SmallBoldText } from "../textStyles/style";
import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import { Input } from "../Inputs";
import { useHistory } from "react-router-dom";
import moment from "moment";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export default function UpdateAvenant({
  isOpen,
  setIsOpen,
  company_id,
  selectedContract,
  selectedAmendment,
}) {
  const [amountSub, setAmountSub] = useState(selectedAmendment.amount_sub);
  const [amountCoaching, setAmountCoaching] = useState(
    selectedAmendment.amount_coaching
  );
  const [amountTrails, setAmountTrails] = useState(
    selectedAmendment.amount_trails
  );
  const [amountEbooks, setAmountEbooks] = useState(
    selectedAmendment.amount_ebooks
  );
  const [name, setName] = useState(selectedAmendment.name);
  const [numberTrails, setNumberTrails] = useState(
    selectedAmendment.number_trails
  );
  const [ebooks, setEbooks] = useState(selectedAmendment.ebooks);
  const [coaching, setCoaching] = useState(selectedAmendment.coaching);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [users, setUsers] = useState(selectedAmendment.users);
  const end = moment(selectedContract.endDate).format("yyyy-MM-DD");
  const [endDate, setEndDate] = useState(end);

  const history = useHistory();

  const handleClose = () => {
    if (!submitLoader) {
      setIsOpen(false);
      selectedAmendment(null);
      setName("");
      setNumberTrails("");
      setErrorMessage("");
      setAmountSub("");
      setAmountTrails("");
      setAmountCoaching("");
      setAmountEbooks("");
      setEbooks("");
    }
  };

  React.useEffect(() => {
    if (isOpen && selectedAmendment) {
      const end = moment(selectedContract.endDate).format("yyyy-MM-DD");
      setEndDate(end);
    }
  }, [isOpen, selectedAmendment]);

  const submitHandler = async (event) => {
    if (!submitLoader) {
      setSubmitLoader(true);
      const data = {
        avenant_id: selectedAmendment._id,
        company_id,
        name,
        end_date: endDate,
        ebooks,
        users,
        number_trails: numberTrails,
        coaching,
        amount_sub: amountSub,
        amount_coaching: amountCoaching,
        amount_trails: amountTrails,
        amount_ebooks: amountEbooks,
      };
      try {
        await axios.put(`${process.env.REACT_APP_ASSOCIATION}`, data);
        history.go(0);
        setSubmitLoader(false);
      } catch (error) {
        setSubmitLoader(false);
        console.log(error);
      }
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
      <TitleContainer>
        <H6>Update Amendment</H6>
      </TitleContainer>

      <DialogContent>
        {/** form */}
        <Row style={{ marginTop: 20 }}>
          <Input
            placeholder="Amendment Reference ..."
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Input
            type="number"
            placeholder="Users access to add *"
            value={users}
            onChange={(e) => setUsers(e.target.value)}
          />
          <Input
            type="number"
            placeholder="Trails access To add *"
            value={numberTrails}
            onChange={(e) => setNumberTrails(e.target.value)}
          />
        </Row>

        <Row style={{ marginTop: 20 }}>
          <Input
            type="number"
            placeholder="Flash Coaching Appointments to add *"
            value={coaching}
            onChange={(e) => setCoaching(e.target.value)}
          />
          <Input
            type="number"
            placeholder="Ebooks To add *"
            value={ebooks}
            onChange={(e) => setEbooks(e.target.value)}
          />
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Input
            type="number"
            placeholder="amount Subscriptions in € (HT)*"
            value={amountSub}
            onChange={(e) => setAmountSub(e.target.value)}
          />
          <Input
            type="number"
            placeholder="amount Trails access in € (HT)*"
            value={amountTrails}
            onChange={(e) => setAmountTrails(e.target.value)}
          />
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Input
            type="number"
            placeholder="amount Flash Coaching in € (HT)*"
            value={amountCoaching}
            onChange={(e) => setAmountCoaching(e.target.value)}
          />
          <Input
            type="number"
            placeholder="amount Ebook in € (HT)*"
            value={amountEbooks}
            onChange={(e) => setAmountEbooks(e.target.value)}
          />
        </Row>

        <SmallBoldText
          style={{ textAlign: "center", fontSize: 12, color: "#c22222" }}
        >
          {errorMessage ? errorMessage : " - "}
        </SmallBoldText>
      </DialogContent>

      <DialogActions style={{ marginTop: -10, marginBottom: 15 }}>
        <ButtonPrimary onClick={submitHandler}>
          {submitLoader ? <LoadingIcon color="#ffffff" /> : "Save"}
        </ButtonPrimary>

        <ButtonSecondary onClick={handleClose}>Close</ButtonSecondary>
      </DialogActions>
    </Dialog>
  );
}
