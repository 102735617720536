import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import { Input, SelectInput } from "../../components/Inputs";
import { H5, LoadingIcon, TextBold } from "../../components/textStyles/style";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SuccessImage from "../../images/main/green_circle_tick.svg";
import {
  FAQCategories,
  FAQFacturationSubCategories,
  FAQTechniqueSubCategories,
  FAQTrailsSubCategories,
  FAQWorkshopsSubCategories,
  languages,
} from "../../data/SelectOptions";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const AddFAQ = () => {
  const MySwal = withReactContent(Swal);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [language, setLanguage] = useState("fr");
  const [trails, setTrails] = useState([]);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const history = useHistory();
  const [id, setId] = useState("");
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");
    setId(id);
    const reponse = queryParams.get("reponse");
    setAnswer(reponse);
    const question = queryParams.get("question");
   

    setQuestion(question);
  }, []);
  const submitHandler = () => {
    if (!loading) {
      setLoading(true);
      if (
        question.length > 2 &&
        category.length > 0 &&
        answer.length > 2 &&
        (category == "autres" || subCategory.length > 0)
      ) {
        const TOKEN = localStorage.getItem("TOKEN");
        axios
          .post(process.env.REACT_APP_FAQ, {
            access_token: TOKEN,
            question,
            answer,
            category,
            subCategory,
            language,
            id
          })
          .then(() => {
            setTimeout(() => {
              setLoading(false);
              MySwal.fire({
                title: <H5>Added to FAQ</H5>,
                html: <></>,
                imageUrl: SuccessImage,
                imageWidth: 180,
                imageHeight: 130,
                imageAlt: "Custom image",
                timer: 1500,
                showConfirmButton: false,

                timerProgressBar: true,
                padding: "2vw",
                showCloseButton: true,
                showClass: {
                  popup: "animate__animated animate__fadeInDown animate__fast",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp animate__faster",
                },
              });
              history.goBack();
              history.goBack();
            }, 800);
          })
          .catch((err) => {
            setErrorMessage("Erreur connexion avec le serveur");
            setLoading(true);
          });
      } else {
        setErrorMessage("Invalid data");
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const getData = async () => {
      setSubCategory("");

      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      const Response = await axios.get(
        `${process.env.REACT_APP_GET_TRAILS}?language=${language}`,
        config
      );
      const t = [];
      await Response.data.map((trail) => {
        t.push({ label: trail.title, value: trail.title });
      });
      setTrails([{ value: "all_trails", label: "Tous les Trails" }, , ...t]);
    };
    getData();
  }, [language]);

  return (
    <Wrapper>
      <ContentWrapper>
        <H5>New FAQ</H5>
        <Row>
          <TextBold color="#c22222">
            {errorMessage ? errorMessage : " "}
          </TextBold>
        </Row>

        {/** language & catégorie */}
        <Row>
          <SelectInput
            values={FAQCategories}
            placeholder="Category *"
            value={category}
            onChange={(e) => {
              setCategory(e.target.value);
              setSubCategory("");
            }}
          />
          <SelectInput
            values={languages}
            placeholder="Language *"
            value={language}
            onChange={(e) => {
              setLanguage(e.target.value);
            }}
          />
        </Row>
        {/** sub category */}
        <Row>
          {category == "trail" ? (
            <SelectInput
              values={trails}
              placeholder="Subject *"
              value={subCategory}
              onChange={(e) => {
                setSubCategory(e.target.value);
              }}
            />
          ) : category == "facturation" ? (
            <SelectInput
              values={FAQFacturationSubCategories}
              placeholder="Subject *"
              value={subCategory}
              onChange={(e) => {
                setSubCategory(e.target.value);
              }}
            />
          ) : category == "technique" ? (
            <SelectInput
              values={FAQTechniqueSubCategories}
              placeholder="Subject *"
              value={subCategory}
              onChange={(e) => {
                setSubCategory(e.target.value);
              }}
            />
          ) : category == "workshop" ? (
            <SelectInput
              values={FAQWorkshopsSubCategories}
              placeholder="Subject *"
              value={subCategory}
              onChange={(e) => {
                setSubCategory(e.target.value);
              }}
            />
          ) : (
            <></>
          )}
        </Row>
        {/** Question + Response */}
        <Row>
          <Input
            placeholder="Request *"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
        </Row>
        <Row>
          <Input
            multiline
            placeholder="Answer *"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          />
        </Row>
        <Row style={{ marginTop: 40 }}>
          <ButtonPrimary onClick={submitHandler}>
            {loading ? <LoadingIcon color="inherit" /> : "Add to FAQ"}
          </ButtonPrimary>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default AddFAQ;
