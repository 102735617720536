import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { H4, H5, H6, LoadingBigIcon } from "../../components/textStyles/style";
import axios from "axios";
import { ButtonPrimaryLink } from "../../components/buttonStyles/style";
import { ChipText } from "../../components/tables/styles";
import moment from "moment-timezone";
import { appointmentStatus, types } from "../../data/SelectOptions";
import { UserContext } from "../../App";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
moment.locale("en");
let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;

const LoadWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
`;

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
`;

export const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  margin-bottom: 40px;
  align-items: center;
  justify-content: space-between;

  margin-top: 20px;
`;

const ExpertPayement = () => {
  const { user } = useContext(UserContext);
  const expert = user.expert_data[0];
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      setLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      const Response = await axios.get(
        `${process.env.REACT_APP_EXPERT_FINISHED_BY_MONTH}?expert=${expert._id}`,
        config
      );

      console.log(Response.data);

      setData(Response.data);

      setLoader(false);
    };

    getData();
  }, [expert]);

  return (
    <Wrapper>
      <ContentWrapper>
        <H5 style={{ fontSize: 24 }}>Payments - Flash Coaching LISL UP</H5>



        {loader ? (
          <LoadWrapper>
            <LoadingBigIcon />
          </LoadWrapper>
        ) : data.length > 0 ? (
          <div style={{ marginTop: 30 }}>
            {data.map((element) => {
              const date = moment({
                year: element._id.year,
                month: element._id.month - 1,
                day: 1,
              });
              const formatted = date.format("MMMM - YYYY");
              return (
                <div
                  style={{
                    borderBottom: "1px #999 solid",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 10,
                    backgroundColor: '#f5f5f5',
                    padding: 15,
                    borderRadius: 15
                  }}
                  key={element._id.month + element._id.year}
                >
                  <div style={{ display: "flex", alignItems: "flex-start" }}>
                    <CalendarMonthIcon
                      style={{
                        fontSize: 24,
                        color: "#484848",
                        marginRight: 10,
                      }}
                    />
                    <H4 color="#484848" style={{ fontSize: 18 }}>
                      {formatted}
                    </H4>
                  </div>
                  <H6 style={{ fontSize: 18 }}>
                    {element.count} Completed appointments
                  </H6>
                </div>
              );
            })}
          </div>
        ) : (
          <div
            style={{
              marginTop: 40,
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <H6 color="#444">No finished appointments to be invoiced</H6>
          </div>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default ExpertPayement;
