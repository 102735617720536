// This file contains the css styling of the components of the dropdown menu for the mobile screen
// This file was last modified on 2021-11-21 18:40:08
// Author : Mohamed Amine Dhiab © Massa Trails

import styled from "styled-components";
import back from "../../images/main/tiles.webp";
import { Link } from "react-router-dom";
import { BiHomeSmile } from "react-icons/bi";
export const DropdownContainer = styled.div`
  position: absolute;
  z-index: 100;
  width: 25vw;
  height: calc(50vh - clamp(54px, 8vw, 84px));
  background-color: #fbf4f1;
  display: grid;
  align-items: center;
  left: 70vw;
  border: 1px solid grey;
  transition: all 0.8s cubic-bezier(0.17, 0.67, 0.36, 0.94);
  opacity: ${({ isopen }) => (isopen ? "1" : "1")};
  top: ${({ isopen }) => (isopen ? "clamp(85px,8vw,84px)" : "-100vh")};
  border-bottom: 1px solid black;
  overflow: auto;
`;

export const DropDownBack = styled.div`
  position: absolute;
  width: 25vw;
  height: 100%;
  background-image: url(${back});
  mix-blend-mode: darken;
  opacity: 0.15;
  z-index: -1;
`;

export const DropDownWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 40px;
  box-sizing: border-box;
  > * {
    margin-bottom: 35px;
  }
`;
export const SearchBarWrapper = styled.div`
  width: clamp(250px, 80%, 400px);
  height: 45px;
`;
export const SearchBar = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fbf6f3;
  border: 1px solid #d4cbca;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
  position: relative;
`;

export const NotFoundWrapper = styled.div`
  position: absolute;
  border-radius: 5px;
  border: 1px solid #d4cbca;
  top: 110%;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  box-sizing: border-box;
  background-color: #fbf6f3;
  display: ${({ active }) => (active ? "block" : "none")};
`;

export const SearchIconWrapper = styled.div`
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;

export const SearchIcon = styled.img`
  width: 60%;
`;

export const SearchField = styled.input`
  width: 85%;
  height: 100%;
  border: none;
  background-color: #fbf6f3;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Oxygen";
  font-size: 18px;

  &:focus {
    outline: none;
  }
  ::placeholder {
    color: #a89d9c;
  }
`;

export const Links = styled(Link)`
  text-decoration: none;
  width: 60%;
  height: 34px;
  text-align: center;
  font-family: "Gotu";
  font-size: 24px;
  color: #1b4965;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    font-weight: 800;
  }
`;

export const Button = styled(Link)`
  text-decoration: none;
  width: 231px;
  height: 57px;
  background-color: #88c1a3;
  font-family: "Oxygen";
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1b4965;
  border-radius: 35px;
  cursor: pointer;
`;

export const LanguageSelection = styled.div`
  width: clamp(76px, 30%, 86px);
  height: clamp(30px, 5%, 40px);
  position: relative;
  display: flex;
  color: #1b4965;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 40px;
`;

export const LanguageSelectorContainer = styled.div`
  width: 65px;
  height: 102px;
  box-sizing: border-box;
  position: absolute;
  top: ${({ active }) =>
    active ? "calc(-102px - 10px)" : "calc(-102px + 10px)"};
  left: calc((100% - 65px) / 2);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  opacity: ${({ active }) => (active ? "1" : "0")};
  transition: all 0.2s ease;
  pointer-events: ${({ active }) => (active ? "" : "none")};
`;

export const HomeIcon = styled(BiHomeSmile)`
  width: 20px;
  height: auto;
  margin-right: 10px;
  margin-bottom: 5px;
`;
