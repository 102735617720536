import React from "react";
import { H5 } from "../textStyles/style";
import {

    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import moment from "moment";
import { ButtonPrimaryLink } from "../buttonStyles/style";
import { Link } from 'react-router-dom'
const ChatTopUsers = ({ data }) => {



    function formatDuration(milliseconds) {
        // Create a Moment.js duration object from milliseconds
        const duration = moment.duration(milliseconds, 'milliseconds');

        // Format the duration as HH:mm:ss
        const formattedDuration = moment.utc(duration.as('milliseconds')).format('HH:mm:ss');

        return formattedDuration;
    }


    return (
        <div style={{ marginTop: 15, width: '100%' }}>
            <div style={{ display: "flex", flexDirection: 'column', justifyContent: "space-between" }}>
                <div >
                    <H5 style={{ fontSize: 18, textDecoration: "underline" }}>
                        Live Chat - Top 3 Companies
                    </H5>
                    {data.topB2b.map(el => {
                        return (
                            <Link key={el._id}
                                to={`/company/${el.company_info?._id}`}

                            >
                                <Typography>
                                    {el.company_info?.name} ({el.count} entrie(s))</Typography>
                            </Link>
                        )
                    })}
                </div>
                <div style={{ marginTop: 20 }}>
                    <H5 style={{ fontSize: 18, textDecoration: "underline" }}>
                        Live Chat - Top 5 B2C Users
                    </H5>
                    {data.topB2c.map(el => {
                        return (
                            <Link key={el._id}
                                to={`/app-user/${el.user_info?._id}`}

                            >
                                <Typography>
                                    {el.user_info?.email} ({el.count} entrie(s))</Typography>
                            </Link>
                        )
                    })}
                </div>

            </div>

        </div>
    );
};

export default ChatTopUsers;
