import React from "react";
import { useState } from "react";
import { SelectInput } from "../../components/Inputs";
import {
    languagesWithAll,
    monthsOptionsWithoutAll,
    yearsOptionsWithoutAll,
} from "../../data/SelectOptions";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useEffect } from "react";
import axios from "axios";
import { lislColors } from "../../styles/colors";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const GrowthRate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const [selectedYear, setSelectedYear] = useState(year);
    const [lng, setLng] = useState("all");
    const [totalOfYear, setTotalOfYear] = useState(0);
    const [numbers, setNumbers] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])


    const getTotal = (y, l) => {
        axios
            .get(
                `${process.env.REACT_APP_COMPANY_STAT}/users-number?year=${y}&language=${l}`
            )
            .then((res) => {
                const response = res.data;
                setTotalOfYear(response.total)
            });
    }

    useEffect(() => {
        getTotal(selectedYear, lng)
    }, [selectedYear, lng])

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,

            },
            datalabels: {
                display: (context) =>
                    context.dataset.data[context.dataIndex] !== 0,
                formatter: (value, ctx) => {
                    return value;
                },
                anchor: "end",
                align: "left",
                offset: 4,
                color: "#333",
                font: {
                    weight: "bold", // Label font weight
                    size: 11,
                },
            },
        },
    };



    const d = {
        labels: monthsOptionsWithoutAll.map(el => el.label + ' ' + year),
        datasets: [
            {
                label: "New Users Inscription",
                data: numbers,
                borderColor: "rgb(123, 150, 101)",
                borderWidth: 3,
                backgroundColor: "rgba(123, 150, 101, 0.5)",
            },

        ],
    };




    const RenderCell = ({ el, i, totals }) => {
        const [data, setData] = useState({});
        useEffect(() => {
            axios
                .get(
                    `${process.env.REACT_APP_COMPANY_STAT}/growth-rate?year=${selectedYear}&month=${el.value}&language=${lng}`
                )
                .then((res) => {
                    const response = res.data;

                    if (totals > 0) {
                        setData({ count: response.totalUsers });
                    } else {
                        setData(response);
                        let arr = numbers
                        arr[i] = response.count ? response.count : 0
                        setNumbers(arr)
                    }
                });
        }, [selectedYear, lng]);

        return (
            <TableCell
                align="center"
                style={{ fontWeight: "bold", color: "#484848", fontSize: 12 }}
            >
                {data.count ? data.count : "0"}
            </TableCell>
        );
    };



    return (
        <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 20,
            backgroundColor: "#f5f5f5",
            padding: 20,
            borderRadius: 15,
        }}>
            {/** table */}
            <div
                style={{ width: '100%', marginRight: 20 }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",

                    }}
                >
                    <Typography style={{ fontSize: 20, color: "#484848" }}>
                        Growth Rate
                    </Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: 100, marginRight: 10 }}>
                            <SelectInput
                                values={languagesWithAll}
                                placeholder="Language *"
                                value={lng}
                                onChange={(e) => {
                                    setLng(e.target.value)
                                    getTotal(year, e.target.value)

                                }}
                            />
                        </div>
                        <div style={{ width: 100 }}>
                            <SelectInput
                                values={yearsOptionsWithoutAll}
                                placeholder="Year *"
                                value={selectedYear}
                                onChange={(e) => {
                                    setSelectedYear(e.target.value)
                                    getTotal(e.target.value, lng)

                                }}

                            />
                        </div>
                    </div>
                </div>
                {/** table */}
                <Table style={{ marginTop: 12, marginBottom: 12 }}>
                    <TableHead>
                        <TableCell
                            align="center"
                            style={{ fontWeight: "bold", color: "#1B4965", fontSize: 12 }}
                        >
                            Month
                        </TableCell>
                        {monthsOptionsWithoutAll.map((el) => (
                            <TableCell
                                align="center"
                                key={el.value}
                                style={{ fontWeight: "bold", color: "#1B4965", fontSize: 12 }}
                            >
                                {el.label}
                            </TableCell>
                        ))}
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell
                                align="center"
                                style={{ fontWeight: "bold", color: "#1B4965", fontSize: 12 }}
                            >
                                New Users
                            </TableCell>
                            {monthsOptionsWithoutAll.map((el, i) => (
                                <RenderCell el={el} i={i} />
                            ))}
                        </TableRow>

                        <TableCell
                            align="center"
                            style={{ fontWeight: "bold", color: "#1B4965", fontSize: 12 }}
                        >
                            Cumul Users
                        </TableCell>
                        {monthsOptionsWithoutAll.map((el, i) => (
                            <RenderCell el={el} i={i} totals={true} />
                        ))}
                    </TableBody>
                </Table>
                <div style={{ display: 'flex', flexDirection: 'row-reverse', }}>
                    <Typography bgcolor='rgb(123, 150, 101)' style={{ padding: 10, fontSize: 14 }} fontWeight="500" color={lislColors.white} >{totalOfYear} new users in {selectedYear}</Typography>
                </div>
            </div >
            {/** chart */}
            <div style={{ width: 400, }}>
                <Line style={{ height: 200 }} plugins={[ChartDataLabels]} options={options} data={d} />
            </div>
        </div>


    );
};

export default GrowthRate;
