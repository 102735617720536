import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { H6, LoadingBigIcon, SmallText } from "../textStyles/style";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import { RowBetween } from "../../pages/app-users/styles";

const Wrapper = styled.div`
  width: 100%;
`;

const LoadWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;
`;

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`;

const WatchedEpisodes = ({ user }) => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesNumber, setPagesNumber] = useState(null);
  const [result, setResult] = useState(null);
  const getData = async () => {
    setLoader(true);
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const url = `${process.env.REACT_APP_GET_WATCHED_EPISODES}?user_id=${user}&pageNumber=${currentPage}`;
    console.log(
      "le lien est",
      `${process.env.REACT_APP_GET_WATCHED_EPISODES}?user_id=${user}&pageNumber=${currentPage}`
    );
    const Response = await axios.get(url, config);
    const { watchedEpisodes, page, pages, count } = Response.data;

    setData(watchedEpisodes);
    setCurrentPage(page);
    setPagesNumber(pages);
    setResult(count);
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [currentPage, user]);

  return (
    <Wrapper>
      {loader ? (
        <LoadWrapper>
          <LoadingBigIcon />
        </LoadWrapper>
      ) : data.length > 0 ? (
        <div style={{ marginTop: 10, width: "100%" }}>
          <RowBetween style={{ marginBottom: 10 }}>
            <H6>Watched Episodes</H6>
            <SmallText>{result} watched episodes</SmallText>
          </RowBetween>
          <TableContainer>
            <Table>
              <TableHead>
                <TableCell
                  style={{ fontWeight: "bold", color: "#1B4965", fontSize: 14 }}
                >
                  Episode Code
                </TableCell>

                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "#1B4965",
                    fontSize: 14,
                  }}
                >
                  Title
                </TableCell>
                <TableCell
                  style={{ fontWeight: "bold", color: "#1B4965", fontSize: 14 }}
                >
                  Date
                </TableCell>
              </TableHead>
              <TableBody>
                {data.map((episode) => {
                  const createdAt = moment(episode.createdAt).format(
                    "DD/MM/YYYY HH:mm"
                  ); // January 3, 2023 9:09 PM

                  return (
                    <TableRow key={episode._id}>
                      <TableCell style={{ fontSize: 14 }}>
                        {episode.ressource}
                      </TableCell>
                      <TableCell style={{ fontSize: 14 }}>
                        <Link
                          to={`/episode/${episode.ressource}`}
                          target="_blank"
                        >
                          {episode.episode_data.length > 0
                            ? episode.episode_data[0].title
                            : " - "}
                        </Link>
                      </TableCell>
                      <TableCell style={{ fontSize: 14 }}>
                        {createdAt}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {pagesNumber && (
            <PaginationContainer style={{ marginTop: 20 }}>
              <Pagination
                color="primary"
                count={pagesNumber}
                page={currentPage}
                onChange={(e, val) => setCurrentPage(val)}
              />
            </PaginationContainer>
          )}
        </div>
      ) : (
        <RowBetween style={{ marginBottom: 10 }}>
          <H6>Watched Episodes</H6>
          <SmallText>No watched Episodes yet</SmallText>
        </RowBetween>
      )}
    </Wrapper>
  );
};

export default WatchedEpisodes;
