import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import { Input } from "../../components/Inputs";
import { H5, LoadingIcon, TextBold } from "../../components/textStyles/style";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;
const NewSeason = () => {
  const history = useHistory();
  const { i18n } = useTranslation();
  const { trail_code } = useParams();
  const [code, setCode] = useState("");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [poster, setPoster] = useState("");
  const [keywords, setKeywords] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [relatedWorkshops, setRelatedWorkshops] = useState("");
  const submitHandler = async () => {
    setLoading(true);
    setErrorMessage("");
    const TOKEN = localStorage.getItem("TOKEN");
    var codeChecker = code.split("-");
    if (codeChecker[0].length !== 4 || codeChecker[1].length !== 2) {
      setLoading(false);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setErrorMessage("code season invalid");
    } else {
      try {
        const data = {
          access_token: TOKEN,
          season_code: code,
          trail_code,
          language: i18n.language,
          title,
          related_workshops: relatedWorkshops
            ? relatedWorkshops.split(",")
            : [],
          description,
          poster_link: poster,
          keywords,
        };
        console.log(data);
        await axios.post(process.env.REACT_APP_NEW_SEASON, data);
        history.goBack();
      } catch (error) {
        setLoading(false);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

        if (error.response.status === 400) {
          setErrorMessage(
            "Données Invalides, Veuillez remplir tous les champs"
          );
        } else if (error.response.status === 409) {
          setErrorMessage("Une Saison existe avec le code fourni");
        } else {
          setErrorMessage("Probléme de Connexion");
        }
      }
    }
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <H5>New Season</H5>
        <Row>
          <TextBold color="#c22222">
            {errorMessage ? errorMessage : " "}
          </TextBold>
        </Row>
        {/** trail code + title */}
        <Row>
          <Input
            placeholder="Season Code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <Input
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Row>

        {/** description */}
        <Row>
          <Input
            placeholder="Description"
            multiline={true}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Row>

        {/** poster link */}
        <Row>
          <Input
            placeholder="Poster Link"
            value={poster}
            onChange={(e) => setPoster(e.target.value)}
          />
        </Row>

        {/** keywords */}
        <Row>
          <Input
            placeholder="Keywords (séparés avec ,) ex: confiance,émotion,stress"
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
          />
        </Row>
        {/** related workshops */}
        <Row>
          <Input
            placeholder="Ateliers recommandés (séparés par , et sans espace)"
            value={relatedWorkshops}
            onChange={(e) => setRelatedWorkshops(e.target.value)}
          />
        </Row>
        <Row style={{ marginTop: 40 }}>
          <ButtonPrimary onClick={submitHandler}>
            {loading ? (
              <LoadingIcon color="inherit" />
            ) : (
              "Ajouter nouveau saison"
            )}
          </ButtonPrimary>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default NewSeason;
