import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ButtonPrimary, ButtonSecondary } from "../buttonStyles/style";
import { H6, LoadingIcon, SmallBoldText } from "../textStyles/style";
import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import { countries } from "../../data/SelectOptions";
import { Input, SelectInput } from "../Inputs";
import MultipleSelect from "../MulitpleSelect";
import { useEffect } from "react";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export default function UpdateGroupModal({ isOpen, setIsOpen, selectedGroup, setSelectedGroup, getGroups, trails }) {

    const [name, setName] = useState(selectedGroup.name);
    const [id, setId] = useState(selectedGroup._id)
    const [country, setCountry] = useState(selectedGroup.country);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [reporting, setReporting] = useState(selectedGroup.reporting ? selectedGroup.reporting : [])
    const [submitLoader, setSubmitLoader] = React.useState(false);

    useEffect(() => {
        setName(selectedGroup.name)
        setId(selectedGroup._id)
        setCountry(selectedGroup.country)
        setReporting(selectedGroup.reporting ? selectedGroup.reporting : [])
    }, [selectedGroup])

    const handleClose = () => {
        if (!submitLoader) {
            setIsOpen(false);
            setErrorMessage("");
            setName("");
            setCountry("FR");
        }
    };

    const submitHandler = () => {
        if (name === "") {
            setErrorMessage("Group name is required");
            setSubmitLoader(false);
        } else if (country === "") {
            setErrorMessage("Country is required");
            setSubmitLoader(false);
        } else {
            if (!submitLoader) {
                setErrorMessage("");
                setSubmitLoader(true);
                const TOKEN = localStorage.getItem("TOKEN");
                axios
                    .put(process.env.REACT_APP_GROUPS, {
                        access_token: TOKEN,
                        name,
                        country,
                        id,
                        reporting,
                    })
                    .then(() => {
                        getGroups()
                        setIsOpen(false);
                        setSelectedGroup(null)
                        setErrorMessage("");
                        setSubmitLoader(false);
                    })
                    .catch((err) => {
                        console.log(err);
                        setSubmitLoader(false);
                    });
            }
        }
    };

    return (
        <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
            <TitleContainer>
                <H6>Update Group - {selectedGroup.name}</H6>
            </TitleContainer>

            <DialogContent>
                {/** form */}
                {/** civilité + poste */}
                <Row>
                    <SelectInput
                        values={countries}
                        placeholder="country *"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                    />
                    <Input
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Row>
                <Row style={{ marginTop: 30 }}>

                    <MultipleSelect
                        transparent
                        placeholder="Choose From Trails list. (to show in company report)"
                        options={trails}
                        selectedValues={reporting}
                        setSelectedValues={setReporting}
                    />
                </Row>
                {/** error message */}
                <SmallBoldText
                    style={{ textAlign: "center", fontSize: 12, color: "#c22222" }}
                >
                    {errorMessage ? errorMessage : " - "}
                </SmallBoldText>
            </DialogContent>

            <DialogActions style={{ marginTop: -10, marginBottom: 15 }}>
                <ButtonPrimary onClick={submitHandler}>
                    {submitLoader ? <LoadingIcon color="#ffffff" /> : "Update"}
                </ButtonPrimary>

                <ButtonSecondary onClick={handleClose}>Close</ButtonSecondary>
            </DialogActions>
        </Dialog>
    );
}
