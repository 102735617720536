import React from "react";
import { useHistory } from "react-router-dom";
import { ButtonPrimary } from "../buttonStyles/style";
import { Text, TextBold } from "../textStyles/style";
import {
  AddContentWrapper,
  ItemContainer,
  NoContentContainer,
  TrashLogo,
  ButtonsContainer,
  DeleteButton,
} from "./style";
import DeleteLogo from "../../images/main/trash.svg";

const EpisodesList = ({ episodes, season_code }) => {
  const history = useHistory();

  return (
    <>
      <AddContentWrapper>
        <TextBold>Episodes ( {episodes.length} )</TextBold>
        <ButtonPrimary
          onClick={() => history.push(`/new-episode/${season_code}`)}
        >
          + Ajouter Episode
        </ButtonPrimary>
      </AddContentWrapper>
      {episodes.length === 0 ? (
        <NoContentContainer>
          <Text color="#b8b8b8">Aucune épisode</Text>
        </NoContentContainer>
      ) : (
        episodes.map((episode) => {
          return (
            <ItemContainer key={episode}>
              <Text>{episode}</Text>
              <ButtonsContainer>
                <ButtonPrimary
                  onClick={() => history.push(`/episode/${episode}`)}
                >
                  edit
                </ButtonPrimary>
                <DeleteButton>
                  <TrashLogo src={DeleteLogo} />
                </DeleteButton>
              </ButtonsContainer>
            </ItemContainer>
          );
        })
      )}
    </>
  );
};

export default EpisodesList;
