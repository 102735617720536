import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  H5,
  LoadingBigIcon,
  SmallText,
  Text,
} from "../../components/textStyles/style";
import axios from "axios";
import { ButtonPrimaryLink } from "../../components/buttonStyles/style";
import moment from "moment";
import { UserImage, AvatarContainer } from "./styles";
import UserAvatar from "@material-ui/icons/Person";
import { SearchInput } from "../../components/Inputs";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
`;

const LoadWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
`;

const Companies = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [searchTotal, setSearchTotal] = useState(0);
  const [keyword, setKeyword] = useState("");

  const getData = async (keyword) => {
    setLoader(true);
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const Response = await axios.get(
      `${process.env.REACT_APP_ASSOS}?keyword=${keyword}`,
      config
    );

    setData(Response.data);
    setSearchTotal(Response.data.length);
    setLoader(false);
  };

  useEffect(() => {
    getData(keyword);
  }, [keyword]);

  return (
    <Wrapper>
      <ContentWrapper>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ width: 260 }}>
            <ButtonPrimaryLink to="/new-association">
              Add Association
            </ButtonPrimaryLink>
          </div>
          <SearchContainer>
            <SearchInput
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />

            <SmallText>{searchTotal} association(s) found</SmallText>
          </SearchContainer>
        </div>

        <H5>Association's List</H5>
        {loader ? (
          <LoadWrapper>
            <LoadingBigIcon />
          </LoadWrapper>
        ) : (
          <div style={{ margin: 30 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Logo
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Name
                  </TableCell>

                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Address
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Phone
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Website
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Creation date
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  ></TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  ></TableCell>
                </TableHead>
                <TableBody>
                  {data.map((association) => {
                    return (
                      <TableRow key={association._id}>
                        <TableCell scope="row">
                          {association.image ? (
                            <div
                              style={{
                                alignSelf: "flex-start",
                                marginRight: 10,
                              }}
                            >
                              <UserImage src={`${association.image}`} />
                            </div>
                          ) : (
                            <AvatarContainer>
                              <UserAvatar
                                style={{ fontSize: 70, color: "#A89D9C" }}
                              />
                            </AvatarContainer>
                          )}
                        </TableCell>
                        <TableCell scope="row" style={{ width: 250 }}>
                          <Text style={{ fontWeight: "bold" }}>
                            {association.name_en ? association.name_en : "-"}
                          </Text>
                          <br></br>
                          <Text>
                            {association.name_fr ? association.name_fr : "-"}
                          </Text>
                        </TableCell>

                        <TableCell align="left">
                          {association.address ? association.address : "-"}
                        </TableCell>
                        <TableCell align="left">
                          {association.phone ? association.phone : "-"}
                        </TableCell>
                        <TableCell>
                          {association.website ? association.website : "-"}
                        </TableCell>
                        <TableCell>
                          {association.createdAt
                            ? moment(association.createdAt).format("DD/MM/YYYY")
                            : "-"}
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell align="left">
                          <ButtonPrimaryLink
                            to={`/update-association/${association._id}`}
                            width="40px"
                            height="40px"
                          >
                            &#8594;
                          </ButtonPrimaryLink>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default Companies;
