import {
  Button,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  H5,
  LoadingBigIcon,
  SmallText,
} from "../../components/textStyles/style";
import axios from "axios";
import { genderOptions, ageOptions } from "../../data/SelectOptions";
import { ButtonPrimaryLink } from "../../components/buttonStyles/style";
import { ChipText } from "../../components/tables/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { SearchInput } from "../../components/Inputs";
import TextField from "@material-ui/core/TextField";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;

const LoadWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
`;

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
`;
const Marge = styled.div`
  margin-left: 20px;
`;

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
`;
const RowCenter = styled.div`
  display: flex;
  align-items: top;
  justify-content: left;
`;

const ExpertRessources = () => {
  const useStyles = makeStyles(() => ({
    ul: {
      "& .MuiPaginationItem-root": {
        "&.Mui-selected": {
          background: "#1B4965",
          color: "white",
          fontWeight: "700",
          // borderRadius: '50%',
        },
      },
    },
  }));
  const classes = useStyles();
  const [year, setYear] = useState(new Date().getFullYear());
  const [year1, setYear1] = useState(new Date().getFullYear());
  const [year2, setYear2] = useState(new Date().getFullYear());
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [trails, setTrails] = useState([]);
  const [workshops, setWorkshop] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesNumber, setPagesNumber] = useState(null);
  const [searchTotal, setSearchTotal] = useState(null);

  const [currentPage1, setCurrentPage1] = useState(1);
  const [pagesNumber1, setPagesNumber1] = useState(null);
  const [searchTotal1, setSearchTotal1] = useState(null);

  const [currentPage2, setCurrentPage2] = useState(1);
  const [pagesNumber2, setPagesNumber2] = useState(null);
  const [searchTotal2, setSearchTotal2] = useState(null);

  
  const [keyword, setKeyword] = useState("");


  const [selectedMonth, setSelectedMonth] = useState(12); // initial value is January (0)

  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value));
  }

  const months = [
    "January", "February", "March", "April", 
    "May", "June", "July", "August", 
    "September", "October", "November", "December","All"
  ];

  const getData = async () => {
    setLoader(true);
    const path = window.location.pathname.split("/");
    const id = path[2];
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const Response = await axios.get(
      `${process.env.REACT_APP_RESSOURCE_EXPERT_WORKSHOP}?id=${id}&pageNumber=${currentPage}&year=${year1}`,
      config
    );
    const { expert_ressources_workshop, page, pages, count } = Response.data;

    setData(expert_ressources_workshop);
    setCurrentPage(page);
    setPagesNumber(pages);
    setSearchTotal(count);
    setLoader(false);
  };

  const getData2 = async () => {
    setLoader(true);
    const path = window.location.pathname.split("/");
    const id = path[2];
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const Response = await axios.get(
      `${process.env.REACT_APP_RESSOURCE_EXPERT_TRAIL}?id=${id}&pageNumber=${currentPage1}&year=${year2}`,
      config
    );
    const { expert_ressources_trail, page, pages, count } = Response.data;

    setData1(expert_ressources_trail);
    setCurrentPage1(page);
    setPagesNumber1(pages);
    setSearchTotal1(count);
    setLoader(false);
  };

  const getData3 = async () => {
    setLoader(true);
    const path = window.location.pathname.split("/");
    const id = path[2];
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const Response = await axios.get(
      `${process.env.REACT_APP_RESSOURCE_APPOINTMENT_EXPERT}?id=${id}&pageNumber=${currentPage2}&year=${year}&month=${selectedMonth}`,
      config
    );
    console.log(Response.data)
    const { appointments, page, pages, count } = Response.data;

    setData2(appointments);
    setCurrentPage2(page);
    setPagesNumber2(pages);
    setSearchTotal2(count);
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [currentPage, keyword,year1]);
  useEffect(() => {
    getData2();
  }, [currentPage1, keyword,year2]);
  useEffect(() => {
    getData3();
  }, [currentPage2, keyword, year,selectedMonth]);

  return (
    <Wrapper>
      <RowCenter>
      <ContentWrapper>
        <H5>Workshop List</H5>
        <Marge></Marge>
            <TextField
        id="year1"
        label="Year"
        style={{ width: "200px" }}
        type="number"
        value={year1}
        onChange={(e) => setYear1(e.target.value)}
      />

        <hr />

        {data.length > 0 && pagesNumber && (
          <PaginationContainer>
            <Pagination
              classes={{
                root: classes.ul,
              }}
              count={pagesNumber}
              page={currentPage}
              onChange={(e, val) => setCurrentPage(val)}
            /> <SmallText>{searchTotal} ressources found</SmallText>
          </PaginationContainer>
          
        )}

        {loader ? (
          <LoadWrapper>
            <LoadingBigIcon />
          </LoadWrapper>
        ) : data.length > 0 ? (
          <div style={{ margin: 30, marginTop: 10 }}>
            <TableContainer>
           
              <Table>
                <TableHead>
                 
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Title
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Creation date
                  </TableCell>
                </TableHead>
                <TableBody>
                  {data.map((ressources, index, array) => {
                    return (
                      <TableRow key={ressources._id}>
                        <TableCell scope="row">
    {ressources.workshop_data && ressources.workshop_data[0] && ressources.workshop_data[0].title ? ressources.workshop_data[0].title : "No title"}
</TableCell>

                        <TableCell scope="row">
                          {moment(ressources.createdAt).format(
                            'DD/MM/YYYY HH:mm'
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {pagesNumber && (
              <PaginationContainer style={{ marginTop: 20 }}>
                <Pagination
                  classes={{
                    root: classes.ul,
                  }}
                  count={pagesNumber}
                  page={currentPage}
                  onChange={(e, val) => setCurrentPage(val)}
                />
              </PaginationContainer>
            )}
          </div>
        ) : (
          <></>
        )}
      </ContentWrapper>
      <Marge></Marge>
      <ContentWrapper>
        <H5>Trails List</H5>
        <Marge></Marge>
            <TextField
        id="year1"
        label="Year"
        style={{ width: "200px" }}
        type="number"
        value={year2}
        onChange={(e) => setYear2(e.target.value)}
      />
        <hr />

        {data1.length > 0 && pagesNumber1 && (
          <PaginationContainer>
            <Pagination
              classes={{
                root: classes.ul,
              }}
              count={pagesNumber1}
              page={currentPage1}
              onChange={(e, val) => setCurrentPage(val)}
            /> <SmallText>{searchTotal1} ressources found</SmallText>
          </PaginationContainer>
        )}

        {loader ? (
          <LoadWrapper>
            <LoadingBigIcon />
          </LoadWrapper>
        ) : data1.length > 0 ? (
          <div style={{ margin: 30, marginTop: 10 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Title
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Creation date
                  </TableCell>
                </TableHead>
                <TableBody>
                  {data1.map((ressources, index, array) => {
                    return (
                      <TableRow key={ressources._id}>
                        <TableCell scope="row">
                          {ressources.trail_data[0].title}
                        </TableCell>
                        <TableCell scope="row">
                          {moment(ressources.createdAt).format(
                            'DD/MM/YYYY HH:mm'
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {pagesNumber1 && (
              <PaginationContainer style={{ marginTop: 20 }}>
                <Pagination
                  classes={{
                    root: classes.ul,
                  }}
                  count={pagesNumber1}
                  page={currentPage1}
                  onChange={(e, val) => setCurrentPage(val)}
                />
              </PaginationContainer>
            )}
          </div>
        ) : (
          <></>
        )}
      </ContentWrapper>
      <Marge></Marge>
     
      <ContentWrapper>
        <H5>coaching</H5>
        <Marge></Marge>
        <RowCenter><TextField
        id="year"
        label="Year"
        style={{ width: "200px" }}
        type="number"
        value={year}
        onChange={(e) => setYear(e.target.value)}
      />
       <Marge></Marge>
      <div>
      <label htmlFor="monthSelector">Select a month: </label>
      <select name="monthSelector" onChange={handleMonthChange} value={selectedMonth}>
        {months.map((month, index) => 
          <option key={index} value={index}>{month}</option>
        )}
      </select>
    </div></RowCenter>
           
        <hr />

        {data2.length > 0 && pagesNumber2 && (
          <PaginationContainer>
            <Pagination
              classes={{
                root: classes.ul,
              }}
              count={pagesNumber2}
              page={currentPage2}
              onChange={(e, val) => setCurrentPage(val)}
            /> <SmallText>{searchTotal2} ressources found</SmallText>
            
          </PaginationContainer>
        )}

        {loader ? (
          <LoadWrapper>
            <LoadingBigIcon />
          </LoadWrapper>
        ) : data2.length > 0 ? (
          <div style={{ margin: 30, marginTop: 10 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Type
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Category
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                  Topic
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Date
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    User
                  </TableCell>
                </TableHead>
                <TableBody>
                  {data2.map((ressources, index, array) => {
                    return (
                      <TableRow key={ressources._id}>
                        <TableCell scope="row">{ressources.topic}</TableCell>
                        <TableCell scope="row">{ressources.category}</TableCell>
                        <TableCell scope="row">
                        <Link
                            to={`/appointment/${ressources._id}`}
                            width="40px"
                            height="40px"
                          >
                          {ressources.trail_title ? ressources.trail_title : "No topic"}
                          </Link>
                        </TableCell>
                        <TableCell scope="row">
                          {moment(ressources.time).format(
                            'DD/MM/YYYY HH:mm'
                          )}
                        </TableCell>
                        <TableCell>
                        <Link
                            to={`/app-user/${ressources.user}`}
                            width="40px"
                            height="40px"
                          >
                            {ressources.email}
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {pagesNumber2 && (
              <PaginationContainer style={{ marginTop: 20 }}>
                <Pagination
                  classes={{
                    root: classes.ul,
                  }}
                  count={pagesNumber2}
                  page={currentPage2}
                  onChange={(e, val) => setCurrentPage(val)}
                />
              </PaginationContainer>
            )}
          </div>
        ) : (
          <></>
        )}
      </ContentWrapper>
      </RowCenter>
      
    </Wrapper>
  );
};

export default ExpertRessources;
