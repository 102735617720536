import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, Button, Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {

  H5,
  H6,
  LoadingBigIcon,
  SmallText,
} from "../../../components/textStyles/style";
import axios from "axios";
import {
  ButtonPrimary,
  ButtonPrimaryLink,
} from "../../../components/buttonStyles/style";
import { ChipText } from "../../../components/tables/styles";
import { Input, SearchInput, SelectInput } from "../../../components/Inputs";
import moment from "moment-timezone";
import { appointmentStatus, types } from "../../../data/SelectOptions";
import { UserContext } from "../../../App";
import { useHistory } from "react-router-dom";
import { CgSpinner } from "react-icons/cg";
import ReactDatePicker from "react-datepicker";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;

const LoadWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
`;

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
`;

export const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;

  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
`;

const ExpertAppointments = () => {
  const { user } = useContext(UserContext);
  const expert = user.expert_data[0];
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesNumber, setPagesNumber] = useState(null);
  const [searchTotal, setSearchTotal] = useState(null);
  const [status, setStatus] = useState(-1);
  const [keyword, setKeyword] = useState("");
  const [calendlyLink, setCalendlyLink] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [startDate, setStartDate] = useState(new Date("01/01/2023"));
  const [endDate, setEndDate] = useState(new Date());
  const [filterDate, setFilterDate] = useState('createdAt')

  const history = useHistory();
  const getData = async () => {
    if (expert.calendly) {
      setLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      const url = `${process.env.REACT_APP_COACHING_APPOINTMENTS
        }?pageNumber=${currentPage}&status=${status > -1 ? status : ""}&monthStart=${startDate.getMonth() + 1
        }&yearStart=${startDate.getFullYear()}&monthEnd=${endDate.getMonth() + 1
        }&yearEnd=${endDate.getFullYear()}&keyword=${keyword}&filterDate=${filterDate}&expert_id=${expert._id
        }`;
      const Response = await axios.get(
        url,
        config
      );
      const { appointments, page, pages, count } = Response.data;

      setData(appointments);
      setCurrentPage(page);
      setPagesNumber(pages);
      setSearchTotal(count);
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [currentPage, status, keyword, startDate, endDate, filterDate]);

  const addCalendlyLinkHandler = () => {
    if (!submitLoader && calendlyLink.startsWith("https://calendly.com/")) {
      setSubmitLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      axios
        .put(`${process.env.REACT_APP_UPDATE_EXPERT}?expert_id=${expert._id}`, {
          calendly: calendlyLink,
          access_token: TOKEN,
        })
        .then(() => {
          history.go(0);
        })
        .catch((err) => {
          console.log(err);
          setSubmitLoader(false);
        });
    }
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <H5>Your coaching Appointments - LISL UP</H5>

        <hr />
        {/** case calendly ok show data else need to add calendly */}
        {!expert.calendly ? (
          <div style={{ margin: 20 }}>
            <H6>
              To manage our flash coaching appointments, we will use Calendly.
            </H6>
            <br></br>
            <br></br>
            <H6 color="green">
              Calendly is your scheduling automation platform to eliminate
              countless email back-and-forths to find the perfect time, and
              more...
            </H6>

            <div style={{ marginTop: 15 }}>
              <H6>
                1. click here to open{" "}
                <a
                  style={{ textDecoration: "underline", color: "#1b4965" }}
                  href="https://calendly.com/"
                  target="_blank"
                >
                  Calendly.
                </a>{" "}
                Then, put your mail address and press "Sign up"
              </H6>
              <br></br>
              <br></br>
              <img
                src="https://lislup-pull.b-cdn.net/calendly/calendly1.png"
                alt="calendly1"
              />
            </div>
            <div style={{ marginTop: -20 }}>
              <H6>
                2. Fill the form with appropriate informations,{" "}
                <H6 color="green">
                  you’ll receive an email to verify your account.
                </H6>
              </H6>

              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src="https://lislup-pull.b-cdn.net/calendly/calendly2.png"
                  alt="calendly2"
                />
                →
                <img
                  src="https://lislup-pull.b-cdn.net/calendly/calendly3.png"
                  alt="calendly3"
                />
              </div>
            </div>
            <div style={{ marginTop: -40 }}>
              <H6>
                3. Create your calendly url. Then link your calendar to calendly
                account and set your availability time.
              </H6>
              <br></br>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  marginTop: 15,
                }}
              >
                <img
                  src="https://lislup-pull.b-cdn.net/calendly/calendly4.png"
                  alt="calendly4"
                />
                →
                <img
                  src="https://lislup-pull.b-cdn.net/calendly/calendly5.png"
                  alt="calendly5"
                />
                <img
                  src="https://lislup-pull.b-cdn.net/calendly/calendly6.png"
                  alt="calendly6"
                />
              </div>
            </div>

            <H6>
              4.After sign up , Open this link{" "}
              <a
                style={{ textDecoration: "underline", color: "green" }}
                href="https://calendly.com/integrations?category=video_conferencing"
                target="_blank"
              >
                https://calendly.com/integrations?category=video_conferencing
              </a>{" "}
              and add one of the visio tools (zoom, google meet, Microsoft Teams
              ...) to your calenly account.
            </H6>
            <br></br>
            <img
              style={{ marginTop: 20 }}
              src="https://lislup-pull.b-cdn.net/calendly/tuto22.png"
              alt="calendly9"
            />
            <div style={{ marginTop: 20 }}>
              <H6>
                5. On the home page press on "+ Create" then press "Event Type"
              </H6>
              <br></br>
              <br></br>
              <img
                src="https://lislup-pull.b-cdn.net/calendly/calendly7.png"
                alt="calendly7"
              />
            </div>
            <H6>
              6. Choose "One-on-One" then insert Flash-Coaching as "Event name",
              choose your choosen visio tool as interview location, and insert
              Lisl up Flash Coaching appointment as a "description" then press
              next
            </H6>
            <br></br>
            <img
              src="https://lislup-pull.b-cdn.net/calendly/calendly8.png"
              alt="calendly8"
            />
            <br></br>
            <br></br>
            <H6>
              7. Keep this informations as they are, just check the box to add
              15 min time before the event (some buffer time to prepare) then
              press next :
            </H6>
            <br></br>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                marginTop: 25,
              }}
            >
              <img
                src="https://lislup-pull.b-cdn.net/calendly/calendly10.png"
                alt="calendly10"
              />
              →
              <img
                src="https://lislup-pull.b-cdn.net/calendly/calendly11.png"
                alt="calendly11"
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <H6>
                8. Next, press “Notifications and cancellation Policy” and
                “enable Email Reminders” then press “save and close”
              </H6>
              <br></br>
              <br></br>

              <img
                src="https://lislup-pull.b-cdn.net/calendly/calendly12.png"
                alt="calendly12"
              />
            </div>
            <div style={{ marginTop: 25 }}>
              <H6>9. Press on “Share”</H6>
              <br></br>
              <br></br>
              <img
                src="https://lislup-pull.b-cdn.net/calendly/calendly13.png"
                alt="calendly13"
              />
            </div>
            <H6>10 . Paste your event calendly link here and press save.</H6>
            <br></br>

            <div style={{ marginTop: 10 }}>
              <Input
                placeholder="Calendly Link * (starts with https://calendly.com/...."
                value={calendlyLink}
                onChange={(e) => setCalendlyLink(e.target.value)}
              />

              <ButtonPrimary
                onClick={addCalendlyLinkHandler}
                style={{ float: "right", marginTop: 10 }}
              >
                {submitLoader ? <CgSpinner color="white" /> : "Save"}
              </ButtonPrimary>
            </div>
          </div>
        ) : (
          <>
            <SelectContainer>

              {/** search */}
              <SearchInput
                style={{ width: 250 }}
                onFocus={() => setCurrentPage(1)}
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
              {/** status */}
              <div style={{ width: 250 }}>
                <SelectInput
                  values={appointmentStatus}
                  placeholder="Appointments status"
                  value={status}
                  onChange={(e) => {
                    setCurrentPage(1);
                    setStatus(e.target.value);
                  }}
                />
              </div>

              <SmallText>{searchTotal} Appointment(s) founded</SmallText>
            </SelectContainer>


            {/** filter by date */}
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>

                <Typography style={{ color: '#1b4965', fontFamily: 'Oxygen', fontWeight: '700', fontSize: 14, marginRight: 10 }}>Filter By</Typography>
                <Button
                  style={{ marginRight: 5, height: 40 }}
                  onClick={() => setFilterDate("createdAt")}
                  variant="contained"
                  color={filterDate !== "createdAt" ? "inherit" : "primary"}
                >
                  <Typography fontSize={12}>Creation Date</Typography>
                </Button>
                <Button
                  style={{ marginRight: 5, height: 40 }}
                  onClick={() => setFilterDate("time")}
                  variant="contained"
                  color={filterDate !== "time" ? "inherit" : "primary"}
                >
                  <Typography fontSize={12}>Appointment Date</Typography>
                </Button>
              </div>
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography style={{ color: '#1b4965', fontFamily: 'Oxygen', fontWeight: '700', marginBottom: 5, marginRight: 5, fontSize: 14 }}>Start -</Typography>
                  <div style={{ marginRight: 10 }}>
                    <ReactDatePicker
                      selected={startDate}
                      wrapperClassName="datePicker"
                      onChange={(date) => setStartDate(date)}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      showFullMonthYearPicker
                      showTwoColumnMonthYearPicker
                    />

                  </div>
                  <Typography style={{ color: '#1b4965', fontFamily: 'Oxygen', fontWeight: '700', marginBottom: 5, marginRight: 5, fontSize: 14 }}>End -</Typography>
                  <div style={{ marginRight: 10 }}>
                    <ReactDatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      showFullMonthYearPicker
                      showTwoColumnMonthYearPicker
                    />
                  </div>
                </div>
              </div>
            </div>

            {data.length > 0 && pagesNumber && (
              <PaginationContainer>
                <Pagination
                  color="primary"
                  count={pagesNumber}
                  page={currentPage}
                  onChange={(e, val) => setCurrentPage(val)}
                />
              </PaginationContainer>
            )}

            {loader ? (
              <LoadWrapper>
                <LoadingBigIcon />
              </LoadWrapper>
            ) : data.length > 0 ? (
              <div style={{ marginTop: 10 }}>
                <Table>
                  <TableHead>
                    <TableCell
                      align="center"
                      style={{ fontWeight: "bold", color: "#1B4965" }}
                    >
                      Created At
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontWeight: "bold", color: "#1B4965" }}
                    >
                      Topic
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontWeight: "bold", color: "#1B4965" }}
                    >
                      Firstname
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{ fontWeight: "bold", color: "#1B4965" }}
                    >
                      Lastname
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontWeight: "bold", color: "#1B4965" }}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontWeight: "bold", color: "#1B4965" }}
                    >
                      Ressource
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{ fontWeight: "bold", color: "#1B4965" }}
                    >
                      Appointment time
                    </TableCell>

                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableHead>
                  <TableBody>
                    {data.map((rdv) => {
                      const createdAt = moment
                        .tz(rdv.createdAt, "Europe/Paris")
                        .format("DD/MM/YYYY HH:mm"); // January 3, 2023 9:09 PM
                      const status = appointmentStatus.find(
                        (s) => s.value === rdv.status
                      );
                      const category =
                        rdv.category === "all"
                          ? { label: "Tous", value: "-" }
                          : rdv.category
                            ? types.find((cat) => cat.value === rdv.category)
                            : { label: " - ", value: "-" };
                      return (
                        <TableRow key={rdv._id}>
                          <TableCell
                            align="center"
                            style={{
                              fontSize: 13,
                              width: 100,
                            }}
                            scope="row"
                          >
                            {createdAt}
                          </TableCell>
                          <TableCell align="center">{rdv.topic}</TableCell>
                          <TableCell align="center">{rdv.firstname}</TableCell>
                          <TableCell align="center">{rdv.lastname}</TableCell>
                          <TableCell align="center">{rdv.email}</TableCell>
                          <TableCell align="center" style={{ width: 100 }}>
                            {rdv.trail_code
                              ? `Trail : ${rdv.trail_title}`
                              : rdv.category
                                ? `Ateliers : ${category.label}`
                                : " - "}
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ color: "#1b4965", fontWeight: "bold" }}
                          >
                            {rdv.time
                              ? moment
                                .tz(rdv.time, "Europe/Paris")
                                .format("DD-MM-YYYY HH:mm")
                              : " - "}
                          </TableCell>
                          <TableCell align="center">
                            <ChipText
                              dark={rdv.status === 0}
                              warning={rdv.status === 1}
                              success={rdv.status === 3}
                              danger={rdv.status === 4 || rdv.status === 5 || rdv.status === 6}
                              style={{ width: 155 }}
                            >
                              {status.label}
                            </ChipText>
                          </TableCell>

                          <TableCell align="center">
                            <ButtonPrimaryLink
                              to={`/appointment/${rdv._id}`}
                              width="40px"
                              height="40px"
                            >
                              &#8594;
                            </ButtonPrimaryLink>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>

                {pagesNumber && (
                  <PaginationContainer style={{ marginTop: 20 }}>
                    <Pagination
                      color="primary"
                      count={pagesNumber}
                      page={currentPage}
                      onChange={(e, val) => setCurrentPage(val)}
                    />
                  </PaginationContainer>
                )}
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default ExpertAppointments;
