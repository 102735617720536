import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ButtonPrimary, ButtonSecondary } from "../buttonStyles/style";
import { H6, LoadingIcon, SmallBoldText } from "../textStyles/style";
import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import { questionType, sectionQuestionType } from "../../data/SelectOptions";
import { Input, SelectInput } from "../Inputs";
import { Divider, IconButton } from "@mui/material";
import { Add, Delete } from "@material-ui/icons";
import { lislColors } from "../../styles/colors";
import { Button } from "@mui/material";
import { H7 } from "../../components/textStyles/style";
import { Close } from "@material-ui/icons";
import AnswersInput from "./AnswersInput";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const TitleContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;

`;

export default function UpdateSectionQuestion({ isOpen, setIsOpen, getCompangeQuestions, questionToUpdate, setQuestionToUpdate }) {

    const a = questionToUpdate.answers.map(el => el.label)
    const v = questionToUpdate.answers.map(el => el.value)

    const [label, setLabel] = useState(questionToUpdate.label);
    const [type, setType] = useState(questionToUpdate.type)
    const [errorMessage, setErrorMessage] = React.useState("");
    const [answers, setAnswers] = useState(a)
    const [values, setValues] = useState(v)
    const [submitLoader, setSubmitLoader] = React.useState(false);

    const handleClose = () => {
        if (!submitLoader) {
            setIsOpen(false);
            setErrorMessage("");
            setLabel("");

            setAnswers([])
            setValues([])
            setQuestionToUpdate(null)
        }
    };




    const submitHandler = () => {
        const finalAnswers = answers.map((label, index) => {
            return {
                label: label,
                value: Number(values[index])
            };
        });
        if (!submitLoader) {
            setSubmitLoader(true)
            if (label === "") {
                setErrorMessage("Question name is required");
                setSubmitLoader(false);
            } else if (answers.length === 0) {
                setErrorMessage("Question answers is required");
                setSubmitLoader(false);
            }
            else {
                if (!submitLoader) {
                    setErrorMessage("");
                    setSubmitLoader(true);
                    const TOKEN = localStorage.getItem("TOKEN");
                    axios
                        .put(`${process.env.REACT_APP_QESTIONNAIRES}/update-company-question?question=${questionToUpdate._id}`, {
                            access_token: TOKEN,
                            label,
                            type,
                            answers: finalAnswers
                        })
                        .then(() => {
                            getCompangeQuestions()
                            handleClose()
                        })
                        .catch((err) => {
                            setErrorMessage('Connexion Problem, Try later')
                            console.log(err);
                            setSubmitLoader(false);
                        });
                }
            }
        }

    };



    return (
        <Dialog fullWidth maxWidth="lg" open={isOpen} onClose={handleClose}>
            <TitleContainer>
                <H6>Update Question</H6>

            </TitleContainer>

            <DialogContent>

                <SmallBoldText
                    style={{ textAlign: "center", fontSize: 12, color: "#c22222", }}
                >
                    {errorMessage ? errorMessage : " - "}
                </SmallBoldText>
                <Row>
                    <div style={{ marginTop: 10, marginBottom: 15, width: '100%' }}>
                        <SelectInput
                            values={questionType}
                            placeholder="Type *"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                        />
                    </div>

                </Row>
                <Row>
                    <Input
                        placeholder="Question"
                        value={label}
                        onChange={(e) => setLabel(e.target.value)}
                    />
                </Row>

                <Divider style={{ marginTop: 15, marginBottom: 10 }} />

                {type !== "free" && <div style={{ marginTop: 10 }}>
                    <AnswersInput inputs={answers} setInputs={setAnswers} values={values} setValues={setValues} />
                </div>}
            </DialogContent>

            <DialogActions style={{ marginTop: -10, marginBottom: 15 }}>
                <ButtonPrimary onClick={submitHandler}>
                    {submitLoader ? <LoadingIcon color="#ffffff" /> : "Save"}
                </ButtonPrimary>

                <ButtonSecondary onClick={handleClose}>Close</ButtonSecondary>
            </DialogActions>
        </Dialog>
    );
}
