import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";


ChartJS.register(
    CategoryScale,
    LinearScale,
    Filler,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const ExamplePurchasesStat = () => {


    const labels = ['10/2022', '11/2022', '12/2022', '1/2023', '2/2023', '3/2023', '4/2023', '5/2023', '6/2023', '7/2023', '8/2023', '9/2023', '10/2023']
    const ebooksData = [0, 0, 0, 0, 0, 0, 1, 1, 3, 2, 1, 2, 1]
    const coachingData = [0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 1, 1, 4]
    const trailsData = [0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 2, 0, 1]



    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                label: "Trails",
                data: trailsData,
                borderColor: "rgb(28, 73, 102)",
                borderWidth: 3,
                backgroundColor: "rgba(28, 73, 102, 0.5)",
            },
            {
                label: "Ebooks",
                data: ebooksData,
                borderColor: "rgb(253, 112, 100)",
                borderWidth: 3,
                backgroundColor: "rgba(253, 112, 100, 0.5)",
            },
            {
                label: "Flash Coaching",

                data: coachingData,
                borderColor: "rgb(128, 179, 87)",
                borderWidth: 3,
                backgroundColor: "rgba(128, 179, 87, 0.5)",
            },
        ],
    };

    return (
        <div>
            <div style={{ marginTop: 15 }}>

                <div>
                    <Line options={options} data={data} />
                </div>
            </div>
        </div>
    );
};

export default ExamplePurchasesStat;
