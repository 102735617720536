import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ButtonPrimary, ButtonSecondary } from "../buttonStyles/style";
import { H6, LoadingIcon, SmallBoldText } from "../textStyles/style";
import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import { Input } from "../Inputs";
import { useHistory } from "react-router-dom";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export default function AddQuestionnaireMessage({
  isOpen,
  setIsOpen,
  questionnaire_id,
}) {
  const [message, setMessage] = useState("");
  const history = useHistory();
  const [condition, setCondition] = useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const [submitLoader, setSubmitLoader] = React.useState(false);
  const handleClose = () => {
    if (!submitLoader) {
      setIsOpen(false);
      setErrorMessage("");
      setMessage("");
      setCondition("");
    }
  };

  const submitHandler = () => {
    if (message === "") {
      setErrorMessage("Message is required");
      setSubmitLoader(false);
    } else if (condition === "") {
      setErrorMessage("Condition is required");
      setSubmitLoader(false);
    } else {
      if (!submitLoader) {
        setErrorMessage("");
        setSubmitLoader(true);

        const TOKEN = localStorage.getItem("TOKEN");
        axios
          .post(
            `${process.env.REACT_APP_QESTIONNAIRES}/add-message?id=${questionnaire_id}`,
            {
              access_token: TOKEN,
              message,
              condition,
            }
          )
          .then(() => {
            setIsOpen(false);
            setCondition("");
            setMessage("");
            setErrorMessage("");
            setSubmitLoader(false);
            history.go(0);
          })
          .catch((err) => {
            console.log(err);
            setSubmitLoader(false);
          });
      }
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
      <TitleContainer>
        <H6>Add New Message</H6>
      </TitleContainer>

      <DialogContent>
        <Row>
          <Input
            placeholder="Condition*"
            value={condition}
            onChange={(e) => setCondition(e.target.value)}
          />
        </Row>
        <Row>
          <Input
            multiline={true}
            placeholder="Message*"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Row>
        {/** error message */}
        <SmallBoldText
          style={{ textAlign: "center", fontSize: 12, color: "#c22222" }}
        >
          {errorMessage ? errorMessage : " - "}
        </SmallBoldText>
      </DialogContent>

      <DialogActions style={{ marginTop: -10, marginBottom: 15 }}>
        <ButtonPrimary onClick={submitHandler}>
          {submitLoader ? <LoadingIcon color="#ffffff" /> : "Save"}
        </ButtonPrimary>

        <ButtonSecondary onClick={handleClose}>Close</ButtonSecondary>
      </DialogActions>
    </Dialog>
  );
}
