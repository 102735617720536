import { Person, Email } from "@material-ui/icons";
import {
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext } from "react";
import { UserContext } from "../../../App";

const CoachingUsers = ({
  appointment,
  setShowAppUserEmailModal,
  setShowRecipientEmailModal,
}) => {
  const { user } = useContext(UserContext);
  return (
    <Table style={{ width: '100%' }}>
      <TableHead>
        <TableCell align="center" style={{ fontWeight: "bold", color: "#1B4965" }}>
          -
        </TableCell>
        <TableCell align="center" style={{ fontWeight: "bold", color: "#1B4965" }}>
          Lastname
        </TableCell>
        <TableCell align="center" style={{ fontWeight: "bold", color: "#1B4965" }}>
          Firstname
        </TableCell>
        <TableCell align="center" style={{ fontWeight: "bold", color: "#1B4965" }}>
          Address mail
        </TableCell>
        <TableCell align="center"></TableCell>
      </TableHead>
      <TableBody>
        {/** destinataire */}
        <TableRow>
          <TableCell align="center" style={{ fontWeight: "bold", color: "#1B4965" }}>
            User
          </TableCell>
          <TableCell align="center">{appointment.lastname}</TableCell>
          <TableCell align="center">{appointment.firstname}</TableCell>
          <TableCell align="center">{appointment.email}</TableCell>
          <TableCell >
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                onClick={() => {
                  setShowRecipientEmailModal(true);
                }}
              >
                <Email />
              </Button>
            </ButtonGroup>
          </TableCell>
        </TableRow>
        {/** Utilisateur */}

        {user.role !== "expert" && (
          <TableRow>
            <TableCell align="center" style={{ fontWeight: "bold", color: "#1B4965" }}>
              Customer
            </TableCell>
            <TableCell align="center">{appointment.user_data[0].lastname}</TableCell>
            <TableCell align="center">{appointment.user_data[0].firstname}</TableCell>
            <TableCell align="center">{appointment.user_data[0].email}</TableCell>
            <TableCell >
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
              >
                <Button
                  onClick={() => {
                    window.open(
                      `/app-user/${appointment.user_data[0]._id}`,
                      "_blank"
                    );
                  }}
                >
                  <Person />
                </Button>
                <Button
                  onClick={() => {
                    setShowAppUserEmailModal(true);
                  }}
                >
                  <Email />
                </Button>
                <Button
                  onClick={() => {
                    window.open(
                      `https://dashboard.stripe.com/customers/${appointment.user_data[0].customer}`,
                      "_blank"
                    );
                  }}
                >
                  Stripe
                </Button>
              </ButtonGroup>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default CoachingUsers;
