import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import MenuIcon from "@material-ui/icons/Menu";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import ExitToApp from "@material-ui/icons/ExitToApp";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ListSubheader } from "@mui/material";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { H5 } from "../textStyles/style";
import SeeYouImg from "../../images/main/see_you_popup.svg";
import Apartment from "@material-ui/icons/Apartment";
import Assos from "@material-ui/icons/Star";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import {
  Help,
  LocalOffer,
  MenuBook,
  Description,
  PostAdd,
  Receipt,
  QuestionAnswer,
  Gamepad,
} from "@material-ui/icons";
import { useContext } from "react";
import { UserContext } from "../../App";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";

import { CardGiftcard, QueryStats } from "@mui/icons-material";
export default function TemporaryDrawer() {
  const { user, setUser } = useContext(UserContext);
  const [state, setState] = React.useState(false);

  const toggleDrawer = () => (event) => {
    setState(!state);
  };

  const path = useLocation().pathname;
  let history = useHistory();
  const MySwal = withReactContent(Swal);

  React.useEffect(() => {
    setState(false);
  }, [path]);

  const logoutHandler = () => {
    setState(false)
    MySwal.fire({
      title: <H5>Thanks for your visit</H5>,
      html: <></>,
      imageUrl: SeeYouImg,
      imageWidth: 200,
      imageHeight: 150,
      imageAlt: "Custom image",
      timer: 1500,
      showConfirmButton: false,

      timerProgressBar: true,
      padding: "2vw",
      showCloseButton: true,
      showClass: {
        popup: "animate__animated animate__fadeInDown animate__fast",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp animate__faster",
      },
    }).then(() => {
      localStorage.removeItem("TOKEN");
      history.replace("/signin");
      setUser({});
    });
  };

  const list = () => (
    <Box role="presentation">
      <List>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/">

            <AnalyticsIcon fontSize="small" />

            <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Data Dashboard" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/stats">

            <QueryStats fontSize="small" />

            <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Statistics" />
          </ListItemButton>
        </ListItem>
        <ListSubheader style={{ textAlign: "center", fontWeight: '700', color: '#484848', fontSize: '13px' }}>
          Ressources Management
        </ListSubheader>

        {/** monthly messages */}
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/monthly-messages">

            <MailOutlineIcon fontSize="small" />

            <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Monthly messages" />
          </ListItemButton>
        </ListItem>
        {/** notifications */}
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/notifications">

            <NotificationsActiveIcon fontSize="small" />

            <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Notifications" />
          </ListItemButton>
        </ListItem>
        {/** trails */}
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/trails">

            <OndemandVideoIcon fontSize="small" />

            <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Trails" />
          </ListItemButton>
        </ListItem>
        {/** workshops */}
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/workshops">

            <VideoLibraryIcon fontSize="small" />

            <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Workshops" />
          </ListItemButton>
        </ListItem>
        {/** books */}
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/books">

            <MenuBook fontSize="small" />

            <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Books" />
          </ListItemButton>
        </ListItem>
        {/** experts */}
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/experts">

            <PeopleAltIcon fontSize="small" />

            <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Experts" />
          </ListItemButton>
        </ListItem>
        {/** coaching-appointments */}
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/coaching-appointments">

            <Description fontSize="small" />

            <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Flash Coachings" />
          </ListItemButton>
        </ListItem>

        {/** questionnaires */}
        <ListSubheader style={{ textAlign: "center", fontWeight: '700', color: '#484848', fontSize: '13px' }}>
          Questionnaires
        </ListSubheader>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/questionnaires">

            <QuestionAnswer fontSize="small" />
            <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Trails Questionnaires" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/bilan-questions">
            <QuestionAnswer fontSize="small" />
            <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Bilan Questions" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/bilan-analysis">
            <QuestionAnswer fontSize="small" />
            <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Bilan Analysis" />
          </ListItemButton>
        </ListItem>

        {/** companies and  app users */}
        <Divider style={{ marginTop: 10 }} />
        <ListSubheader style={{ textAlign: "center", fontWeight: '700', color: '#484848', fontSize: '13px' }}>
          Application users
        </ListSubheader>
        <ListItemButton component={Link} to="/app-users">

          <PeopleAltIcon fontSize="small" />

          <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Users" />
        </ListItemButton>
        <ListItemButton component={Link} to="/companies">

          <Apartment fontSize="small" />

          <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Companies" />
        </ListItemButton>
        <ListItemButton component={Link} to="/association">

          <Assos fontSize="small" />

          <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Associations" />
        </ListItemButton>
        <Divider style={{ marginTop: 10 }} />
        <ListSubheader style={{ textAlign: "center", fontWeight: '700', color: '#484848', fontSize: '13px' }}>
          Facturation & Paiement
        </ListSubheader>
        {/** notifications */}
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/invoices">

            <Receipt fontSize="small" />

            <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Invoices" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={(e) =>
              window.open("https://dashboard.stripe.com/customers", "_blank")
            }
          >

            <PeopleAltIcon fontSize="small" />

            <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Customers" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={(e) =>
              window.open("https://dashboard.stripe.com/coupons", "_blank")
            }
          >

            <LocalOffer fontSize="small" />

            <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Promotions" />
          </ListItemButton>
        </ListItem>
        <ListItemButton component={Link} to="/gifts">

          <CardGiftcard fontSize="small" />

          <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Plans Gifts" />
        </ListItemButton>
        <Divider style={{ marginTop: 10 }} />
        <ListSubheader style={{ textAlign: "center", fontWeight: '700', color: '#484848', fontSize: '13px' }}>Community</ListSubheader>
        {/** community */}
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/posts">

            <PostAdd fontSize="small" />

            <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Publications" />
          </ListItemButton>
        </ListItem>
        <Divider style={{ marginTop: 10 }} />
        <ListSubheader style={{ textAlign: "center", fontWeight: '700', color: '#484848', fontSize: '13px' }}>
          CONTACT - FAQ
        </ListSubheader>
        {/** contact */}
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/contact">

            <MailIcon fontSize="small" />

            <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Contact messages" />
          </ListItemButton>
        </ListItem>
        {/** FAQ */}
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/faqs">

            <Help fontSize="small" />

            <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Help - FAQ" />
          </ListItemButton>
        </ListItem>

        <Divider style={{ marginTop: 10 }} />
        <ListSubheader style={{ textAlign: "center", fontWeight: '700', color: '#484848', fontSize: '13px' }}>Accounts</ListSubheader>

        {/** manage accounts*/}
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/users">

            <AccessibilityIcon fontSize="small" />

            <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Dashboard Users" />
          </ListItemButton>
        </ListItem>
        <ListSubheader style={{ textAlign: "center", fontWeight: '700', color: '#484848', fontSize: '13px' }}>CHAT</ListSubheader>
        {/** time chatbot */}
        <ListItem disablePadding>
          <ListItemButton component={Link} to={"/chat"}>

            <AccessAlarmIcon fontSize="small" />

            <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Chat schedule" />
          </ListItemButton>
        </ListItem>
        <ListSubheader style={{ textAlign: "center", fontWeight: '700', color: '#484848', fontSize: '13px' }}>GAMES</ListSubheader>
        {/** time chatbot */}
        <ListItem disablePadding>
          <ListItemButton component={Link} to={"/dominos"}>

            <Gamepad fontSize="small" />

            <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Dominos" />
          </ListItemButton>
        </ListItem>
        <ListSubheader style={{ textAlign: "center", fontWeight: '700', color: '#484848', fontSize: '13px' }}>Account</ListSubheader>

        {/** logout */}
        <ListItem disablePadding>
          <ListItemButton onClick={logoutHandler}>

            <ExitToApp fontSize="small" />

            <ListItemText style={{ fontSize: 13, paddingLeft: 12 }} disableTypography primary="Logout" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
  const anchor = "right";

  if (!user) {
    return <></>;
  }

  if (user.role !== "expert") {
    return (
      <div>
        <React.Fragment>
          <Button
            style={{ marginLeft: 20, marginRight: 20 }}
            onClick={toggleDrawer()}
          >
            <MenuIcon />
          </Button>
          <Drawer anchor="right" open={state} onClose={toggleDrawer()}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      </div>
    );
  } else {
    return <></>;
  }
}
