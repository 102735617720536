import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { H5, H6, H7, LoadingBigIcon } from '../../components/textStyles/style';
import { Button, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { ButtonPrimary } from '../../components/buttonStyles/style';
import { Download } from '@mui/icons-material';
import { SelectInput } from '../../components/Inputs';
import { languages } from '../../data/SelectOptions';
import { lislColors } from '../../styles/colors';
import { Edit } from '@material-ui/icons';
import UpdateSectionQuestion from '../../components/modals/UpdateSectionQuestion';
import AddSectionQuestion from '../../components/modals/AddSectionQuestion';
import moment from 'moment';

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;

const LoadWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Compagne = () => {

  const { compagne_id } = useParams();
  const [loader, setLoader] = useState(true);
  const [compagne, setCompagne] = useState(null);

  const [profilesNumber, setProfilesNumber] = useState(0)
  const [finishedProfilesNumber, setFinishedProfilesNumber] = useState(0)


  const [questions, setQuestions] = useState([])
  const [questionsLng, setQuestionsLng] = useState('fr')
  const [showAddQuestion, setShowAddQuestion] = useState(false)
  const [showUpdateQuestion, setShowUpdateQuestion] = useState(false);
  const [questionToUpdate, setQuestionToUpdate] = useState(null);

  const getData = async () => {
    const compagneRequest = await axios.get(`${process.env.REACT_APP_QESTIONNAIRES}/get-compagne?id=${compagne_id}`)
    setCompagne(compagneRequest.data)

    const profilesData = await axios.get(`${process.env.REACT_APP_QESTIONNAIRES}/compagne-stats?compagne=${compagne_id}`)

    setProfilesNumber(profilesData.data.profiles)
    setFinishedProfilesNumber(profilesData.data.finished)




    setLoader(false)

  }

  useEffect(() => {
    getData()
  }, []);

  const history = useHistory()

  const getCompangeQuestions = () => {
    axios.get(`${process.env.REACT_APP_QESTIONNAIRES}/company-questions?language=${questionsLng}&compagne=${compagne_id}&select=all`).then((res) => {
      setQuestions(res.data)
    })
  }
  useEffect(() => {
    getCompangeQuestions()
  }, [compagne_id, questionsLng])

  const showQuestionsHandler = () => {
    axios
      .put(
        `${process.env.REACT_APP_QESTIONNAIRES}/toggle-questions?compagne=${compagne_id}`,
        {
          hidden: false
        }
      )
      .then((res) => {
        history.go(0)
      });
  }

  const hideQuestionsHandler = () => {
    axios
      .put(
        `${process.env.REACT_APP_QESTIONNAIRES}/toggle-questions?compagne=${compagne_id}`,
        {
          hidden: true
        }
      )
      .then((res) => {
        history.go(0)
      });
  }

  const now = new Date()
  const isFinished = now > new Date(compagne?.endDate)

  return (
    <Wrapper>
      <ContentWrapper>


        {loader || compagne == null ? (
          <LoadWrapper>
            <LoadingBigIcon />
          </LoadWrapper>
        ) : (<div>
          {/** stats */}

          <div style={{ marginTop: 20 }}>
            <H5 style={{ textAlign: 'center', color: '#222', fontSize: 30, letterSpacing: -1 }}>Campaign : {compagne.name} | {moment(compagne.startDate).format('LL')} - {moment(compagne.endDate).format('LL')}</H5>
            <div style={{ paddingTop: 20, borderTop: '1px solid #ccc', marginTop: 10 }}>


              <H6 >Campaign Profiles Stats</H6>

              <Table style={{ marginTop: 15, maxWidth: 500 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <Typography style={{ fontSize: 14, color: '#1b4965' }} >Total Profiles</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography style={{ fontSize: 14, color: '#1b4965' }} >Finnished Profiles</Typography>
                    </TableCell  >
                    <TableCell align="center">
                      <Typography style={{ fontSize: 14, color: '#1b4965' }} >Unfinished Profiles</Typography>
                    </TableCell>
                    <TableCell align='center'></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      <Typography style={{ fontSize: 14, color: '#484848' }} >
                        {profilesNumber}
                      </Typography>

                    </TableCell>
                    <TableCell align="center">
                      <Typography style={{ fontSize: 14, color: '#484848' }} >
                        {finishedProfilesNumber}/{profilesNumber} ({profilesNumber === 0 ? 0 : (((finishedProfilesNumber / profilesNumber)) * 100).toFixed(0)}%)
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography style={{ fontSize: 14, color: '#484848' }} >
                        {profilesNumber - finishedProfilesNumber}/{profilesNumber} ({profilesNumber === 0 ? 0 : ((((profilesNumber - finishedProfilesNumber) / profilesNumber)) * 100).toFixed(0)}%)
                      </Typography>
                    </TableCell>

                    <TableCell align="center">
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <ButtonPrimary
                          onClick={() => window.open(`${process.env.REACT_APP_QESTIONNAIRES}/company-bilan?company=${compagne_id}&compagne=${compagne._id}`, '_blank')}
                        >
                          <Download />
                        </ButtonPrimary>

                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
          {/** questions */}
          <Divider style={{ marginTop: 25, marginBottom: 25 }} />
          <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <H6 >Campagin Questions ({questions.length})</H6>
              {isFinished ? null : compagne.hidden ? <ButtonPrimary onClick={() => showQuestionsHandler()} style={{ marginTop: 10 }}>
                Show Specific Questions
              </ButtonPrimary> : <ButtonPrimary color={lislColors.red1} onClick={() => hideQuestionsHandler()}>Hide Specific Questions</ButtonPrimary>}

            </div>

            <div style={{ flexDirection: 'row', display: 'flex', marginTop: 50, alignItems: 'center', justifyContent: 'space-between' }}>

              <div style={{ width: 220 }}>
                {!isFinished && <ButtonPrimary width={220}
                  onClick={() => setShowAddQuestion(true)}
                >
                  Add new Question
                </ButtonPrimary>}
              </div>
              <div style={{ width: 150 }}>
                <SelectInput
                  values={languages}
                  placeholder="Language *"
                  value={questionsLng}
                  onChange={(e) => setQuestionsLng(e.target.value)}
                />
              </div>
            </div>
            <div style={{ width: "100%" }}>
              {questions.length === 0 ? (
                <Typography
                  style={{
                    textAlign: "center",
                    fontSize: 16,
                    color: "#484848",
                    marginTop: 20,
                  }}
                >
                  No questions found in selected language.
                </Typography>
              ) : (
                <div>
                  {questions.map((q, i) => {
                    const answersString = q.answers.map(
                      (el) => `${el.label} =${el.value}`
                    );

                    return (
                      <div
                        key={q._id}
                        style={{
                          backgroundColor: "#fff",
                          marginTop: 15,
                          padding: 15,
                          border: '1px dashed #444',
                          display: 'flex', alignItems: 'center', justifyContent: 'space-between'
                        }}
                      >
                        <div>

                          <div style={{ display: "flex" }}>
                            <Typography style={{ color: "#1b4965", fontWeight: "600" }}>
                              Question {i + 1} :{" "}
                            </Typography>

                            <Typography marginLeft={1}>{q.label}</Typography>
                          </div>
                          {q.type == "free" ? <div style={{ display: "flex", marginTop: 10 }}>
                            <Typography
                              style={{
                                color: "#1b4965",
                                fontWeight: "700",
                                fontSize: 14,
                              }}
                            >
                              Free Text Answer
                            </Typography></div> : <div style={{ display: "flex", marginTop: 10 }}>
                            <Typography
                              style={{
                                color: "#1b4965",
                                fontWeight: "600",
                                fontSize: 14,
                              }}
                            >
                              Answers :{" "}
                            </Typography>

                            <Typography marginLeft={1} style={{ fontSize: 14 }}>
                              {answersString.join(", ")}
                            </Typography>
                          </div>}
                        </div>
                        <div >
                          {isFinished && q.hidden ? 'Hidden' : isFinished ? null : q.hidden ?
                            <div style={{ display: "flex", alignItems: 'center', }}>
                              <Button
                                style={{ backgroundColor: lislColors.green2 }}
                                onClick={() => {
                                  axios
                                    .put(`${process.env.REACT_APP_QESTIONNAIRES}/update-company-question?question=${q._id}`, {
                                      hidden: false,
                                    })
                                    .then(() => {
                                      getCompangeQuestions()
                                    })
                                }}
                              >
                                Publish Question
                              </Button>
                              <Button
                                onClick={() => {
                                  setQuestionToUpdate(q)
                                  setShowUpdateQuestion(true)
                                }}
                              >
                                <Edit />
                              </Button>
                            </div> : <div>
                              <Button
                                style={{ backgroundColor: lislColors.red1 }}
                                onClick={() => {
                                  axios
                                    .put(`${process.env.REACT_APP_QESTIONNAIRES}/update-company-question?question=${q._id}`, {
                                      hidden: true,
                                    })
                                    .then(() => {
                                      getCompangeQuestions()
                                    })
                                }}
                              >
                                Hide Question
                              </Button>
                            </div>
                          }

                        </div>
                      </div>
                    );
                  })}
                </div>)}
            </div>
          </div>


        </div>)}


      </ContentWrapper>
      {/** modal add question */}
      {compagne && <AddSectionQuestion
        isOpen={showAddQuestion}
        company={compagne.company}
        compagne={compagne._id}
        setIsOpen={setShowAddQuestion}
        getCompangeQuestions={getCompangeQuestions}
      />}
      {/** modal to update company question */}
      {compagne && questionToUpdate && <UpdateSectionQuestion
        isOpen={showUpdateQuestion}

        setIsOpen={setShowUpdateQuestion}
        getCompangeQuestions={getCompangeQuestions}
        questionToUpdate={questionToUpdate}
        setQuestionToUpdate={setQuestionToUpdate}
      />}
    </Wrapper>
  )
}

export default Compagne