import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ButtonPrimary, ButtonSecondary } from "../buttonStyles/style";
import { H5, H6, LoadingIcon, SmallBoldText } from "../textStyles/style";
import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import { Input } from "../Inputs";
import SuccessImage from "../../images/main/green_circle_tick.svg";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useContext } from "react";
import { UserContext } from "../../App";
const Row = styled.div`
  margin-top: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

const Container = styled.div`
  padding: 20px;
`;

export default function ChangeUserCalendly({ isOpen, setIsOpen }) {
  const { user } = useContext(UserContext);
  const [calendly, setCalendly] = useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [submitLoader, setSubmitLoader] = React.useState(false);
  const MySwal = withReactContent(Swal);
  const handleClose = () => {
    if (!submitLoader) {
      setIsOpen(false);
      setErrorMessage("");
    }
  };

  const submitHandler = () => {
    if (!submitLoader && calendly.startsWith("https://calendly.com/")) {
      setSubmitLoader(true);
      setErrorMessage("");
      const TOKEN = localStorage.getItem("TOKEN");
      axios
        .put(
          `${process.env.REACT_APP_UPDATE_EXPERT}?expert_id=${user.expert_data[0]._id}`,
          {
            calendly,
            access_token: TOKEN,
          }
        )
        .then(() => {
          setSubmitLoader(false);
          MySwal.fire({
            title: <H5>Modifications saved</H5>,
            html: <></>,
            imageUrl: SuccessImage,
            imageWidth: 200,
            imageHeight: 150,
            imageAlt: "Custom image",
            timer: 1500,
            showConfirmButton: false,

            timerProgressBar: true,
            padding: "2vw",
            showCloseButton: true,
            showClass: {
              popup: "animate__animated animate__fadeInDown animate__fast",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp animate__faster",
            },
          });
          handleClose();
        })
        .catch((err) => {
          setErrorMessage("Problem with server");
          console.log(err);
          setSubmitLoader(false);
        });
    }
  };

  React.useEffect(() => {
    if (user && user.expert_data[0] && user.expert_data[0].calendly) {
      setCalendly(user.expert_data[0].calendly);
    } else {
      setCalendly("");
    }
  }, []);

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
      <TitleContainer>
        <H6>Setup calendly link</H6>
      </TitleContainer>

      {/** form */}

      <Container>
        <Row>
          <Input
            placeholder="Calendly Link * (starts with https://calendly.com/...."
            value={calendly}
            onChange={(e) => setCalendly(e.target.value)}
          />
        </Row>

        <Row style={{ justifyContent: "flex-start" }}>
          <SmallBoldText
            style={{
              textAlign: "center",
              fontSize: 14,
              marginLeft: 5,
              color: "#c22222",
            }}
          >
            {errorMessage ? errorMessage : " - "}
          </SmallBoldText>
        </Row>
      </Container>

      <DialogActions style={{ marginTop: -10, marginBottom: 15 }}>
        <ButtonPrimary onClick={submitHandler}>
          {submitLoader ? (
            <LoadingIcon color="#ffffff" />
          ) : (
            "Change calendly link"
          )}
        </ButtonPrimary>

        <ButtonSecondary onClick={handleClose}>Close</ButtonSecondary>
      </DialogActions>
    </Dialog>
  );
}
