import React from "react";
import { useHistory } from "react-router-dom";
import { ButtonPrimary } from "../buttonStyles/style";
import { Text, TextBold } from "../textStyles/style";
import {
  AddContentWrapper,
  ItemContainer,
  NoContentContainer,
  TrashLogo,
  ButtonsContainer,
  DeleteButton,
} from "./style";
import DeleteLogo from "../../images/main/trash.svg";

const SeasonsList = ({ seasons, trail_code }) => {
  const history = useHistory();

  return (
    <>
      <AddContentWrapper>
        <TextBold>Saisons ( {seasons.length} )</TextBold>
        <ButtonPrimary
          onClick={() => history.push(`/new-season/${trail_code}`)}
        >
          + Ajouter Saison
        </ButtonPrimary>
      </AddContentWrapper>
      {seasons.length === 0 ? (
        <NoContentContainer>
          <Text color="#b8b8b8">Aucune saison</Text>
        </NoContentContainer>
      ) : (
        seasons.map((season) => {
          return (
            <ItemContainer key={season}>
              <Text>{season}</Text>
              <ButtonsContainer>
                <ButtonPrimary
                  onClick={() => history.push(`/season/${season}`)}
                >
                  edit
                </ButtonPrimary>
                <DeleteButton>
                  <TrashLogo src={DeleteLogo} />
                </DeleteButton>
              </ButtonsContainer>
            </ItemContainer>
          );
        })
      )}
    </>
  );
};

export default SeasonsList;
