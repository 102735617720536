import React, { useEffect } from "react";
import { Input } from "../../components/Inputs";
import { Button } from "@mui/material";
import { H7 } from "../../components/textStyles/style";
import { ButtonSecondary } from "../../components/buttonStyles/style";
import { Close } from "@material-ui/icons";

const AnswersInput = ({ inputs, setInputs }) => {
  const handleAddInput = () => {
    setInputs((prevInputs) => [...prevInputs, ""]);
  };

  const handleInputChange = (index, e) => {
    const updatedInputs = [...inputs];
    updatedInputs[index] = e.target.value;
    setInputs(updatedInputs);
  };

  const handleDeleteInput = (index) => {
    const updatedInputs = [...inputs];
    updatedInputs.splice(index, 1);
    setInputs(updatedInputs);
  };
  useEffect(() => {
    console.log("the new is ", inputs);
  }, [inputs]);

  return (
    <div>
      <H7 style={{ marginTop: 10 }}>Answers :</H7>

      {inputs.map((input, index) => (
        <div
          style={{ display: "flex", alignItems: "center", marginTop: 15 }}
          key={index}
        >
          <Input
            placeholder={`answer nº ${index + 1}`}
            value={input}
            onChange={(e) => handleInputChange(index, e)}
          />
          <div style={{ marginLeft: 10 }}>
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleDeleteInput(index)}
            >
              <Close />
            </Button>
          </div>
        </div>
      ))}
      <div
        style={{
          marginTop: inputs.length > 0 ? 20 : 10,
        }}
      >
        <ButtonSecondary onClick={handleAddInput}>
          Add new answer
        </ButtonSecondary>
      </div>
    </div>
  );
};

export default AnswersInput;
