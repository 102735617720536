import styled from "styled-components";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

export const LoadingWrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  min-height: ${Height};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-bottom: 30px;
`;

export const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
`;

export const ProfileSectionContainer = styled.div`
  width: 100%;
  display: flex;
  border-radius: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  box-shadow: 2px 6px 13px -3px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 6px 13px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 6px 13px -3px rgba(0, 0, 0, 0.75);
`;

export const UserImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 40px;
`;

export const AvatarContainer = styled.div`
  width: 74px;
  height: 74px;
  border-radius: 40px;
  border: 3px #a89d9c solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ReverseRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const WatchedEpisodesContainer = styled.div`
  border-radius: 20px;
  margin-top: 30px;
  padding: 30px;
  box-shadow: 2px 6px 13px -3px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 6px 13px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 6px 13px -3px rgba(0, 0, 0, 0.75);
`;

export const RowBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RowCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
