import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  H5,
  LoadingBigIcon,
  SmallText,
} from "../../components/textStyles/style";
import axios from "axios";
import { sourceInvoicesOptions } from "../../data/SelectOptions";
import { SelectInput } from "../../components/Inputs";
import moment from "moment";
import { Link } from "react-router-dom";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import { Preview } from "@mui/icons-material";
import InvoiceSourceStat from "../../components/home/invoiceSourceStat";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;

const LoadWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
`;

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
`;

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
`;

const Invoices = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesNumber, setPagesNumber] = useState(null);
  const [searchTotal, setSearchTotal] = useState(null);

  const [selectedSource, setselectedSource] = useState("all");

  useEffect(() => {
    const getData = async () => {
      setLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      const Response = await axios.get(
        `${process.env.REACT_APP_GET_INVOICES}?pageNumber=${currentPage}&source=${selectedSource}`,
        config
      );
      const { invoices, page, pages, count } = Response.data;
      console.log(Response.data.invoices);
      setData(invoices);
      setCurrentPage(page);
      setPagesNumber(pages);
      setSearchTotal(count);
      setLoader(false);
    };

    getData();
  }, [currentPage, selectedSource]);

  return (
    <Wrapper>
      <ContentWrapper>
        <H5>LISL UP - Invoices</H5>

        <hr />
        <SearchContainer>
          <div style={{ width: 250 }}>
            <SelectInput
              values={sourceInvoicesOptions}
              placeholder="Source"
              value={selectedSource}
              onChange={(e) => {
                setCurrentPage(1);
                setselectedSource(e.target.value);
              }}
            />
          </div>

          <SmallText>{searchTotal} invoices found</SmallText>
        </SearchContainer>

        {data.length > 0 && pagesNumber && (
          <PaginationContainer>
            <Pagination
              color="primary"
              count={pagesNumber}
              page={currentPage}
              onChange={(e, val) => setCurrentPage(val)}
            />
          </PaginationContainer>
        )}

        {loader ? (
          <LoadWrapper>
            <LoadingBigIcon />
          </LoadWrapper>
        ) : data.length > 0 ? (
          <div style={{ margin: 30, marginTop: 10 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Paid At
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    User
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Total (HT)
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Tax
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Total TTC
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Source
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  ></TableCell>
                </TableHead>
                <TableBody>
                  {data.map((invoice) => {
                    const source = sourceInvoicesOptions.find(
                      (el) => el.value === invoice.source
                    );
                    return (
                      <TableRow key={invoice._id}>
                        <TableCell align="center" scope="row">
                          {moment(invoice.createdAt).format("DD-MM-YYYY HH:mm")}
                        </TableCell>
                        <TableCell align="center">
                          {invoice.user_data[0] ? (
                            <Link
                              style={{ color: "#1b4965", fontSize: 13 }}
                              target="_blank"
                              to={`/app-user/${invoice.user_data[0]._id}`}
                            >
                              <Typography
                                fontSize={13}
                              >{`${invoice.user_data[0]?.firstname} ${invoice.user_data[0]?.lastname}`}</Typography>
                              <Typography
                                style={{ marginTop: 5 }}
                                fontSize={13}
                              >{`${invoice.user_data[0]?.email}`}</Typography>
                            </Link>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell align="center" scope="row">
                          {(invoice.subtotal / 100).toFixed(2)} €
                        </TableCell>
                        <TableCell align="center" scope="row">
                          {(invoice.tax / 100).toFixed(2)} €
                        </TableCell>
                        <TableCell align="center" scope="row">
                          {(invoice.total / 100).toFixed(2)} €
                        </TableCell>
                        <TableCell align="center">
                          {source ? source.label : " - "}
                        </TableCell>
                        <TableCell align="center" scope="row">
                          <ButtonPrimary
                            onClick={(e) =>
                              window.open(invoice.invoice_link, "_blank")
                            }
                          >
                            <Preview />
                          </ButtonPrimary>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {pagesNumber && (
              <PaginationContainer style={{ marginTop: 20 }}>
                <Pagination
                  color="primary"
                  count={pagesNumber}
                  page={currentPage}
                  onChange={(e, val) => setCurrentPage(val)}
                />
              </PaginationContainer>
            )}
          </div>
        ) : (
          <></>
        )}
        {/** stats */}
        <hr />
        <div style={{ marginTop: 30 }}>
          <InvoiceSourceStat />
        </div>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Invoices;
