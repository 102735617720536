import logo from "../../images/main/green-logo.svg";
import {
  LogoWrapper,
  HeaderSection,
  HeaderWrapper,
  Logo,
  GoBackText,
  GoBackIconContainer,
  GoBackWrapper,
  GoBackContainer,
  LeftSection,
  RightSection,
} from "./style";
import { useLocation, useHistory } from "react-router-dom";
import Icon from "../../images/main/back-arrow.svg";
import { Text } from "../textStyles/style";
import { useEffect, useState } from "react";
import styled from "styled-components";
import TemporaryDrawer from "./TemporaryDrawer";
import { useContext } from "react";
import { UserContext } from "../../App";
import { Avatar, Typography } from "@mui/material";

export const DropDownButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
`;

const Header = function () {
  const [isHome, setIsHome] = useState(true);
  const { user } = useContext(UserContext);

  const path = useLocation().pathname;

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "#1b4965",
        width: 40,
        height: 40,
        fontSize: 16,
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setIsHome(path === "/");
    }
    return (mounted = false);
  }, [path]);
  return (
    <HeaderSection>
      {isHome ? (
        <HeaderWrapper>
          <LeftSection>
            <LogoWrapper to="/">
              <Logo src={logo} alt="image" />
            </LogoWrapper>
          </LeftSection>
          <RightSection>
            {user && (
              <Avatar {...stringAvatar(`${user.firstname} ${user.lastname}`)} />
            )}
            {user && (
              <div style={{ marginLeft: 5, marginRight: 10 }}>
                <Typography fontSize={10} color="#1b4965">
                  {user.firstname} {user.lastname}
                </Typography>
                <Typography
                  style={{ marginTop: -4 }}
                  fontSize={10}
                  color="#1b4965"
                >
                  {user.username}
                </Typography>
              </div>
            )}
            <TemporaryDrawer />
          </RightSection>
        </HeaderWrapper>
      ) : (
        <GoBackWrapper>
          {location.key !== "default" && (
            <GoBackContainer>
              <GoBackIconContainer
                onClick={() => {
                  history.goBack();
                }}
              >
                <img src={Icon} alt="back-img" />
              </GoBackIconContainer>
              <GoBackText>
                <Text>Back</Text>
              </GoBackText>
            </GoBackContainer>
          )}
          {user && (
            <div
              style={{ display: "flex", alignItems: "center", marginRight: 10 }}
            >
              <Avatar {...stringAvatar(`${user.firstname} ${user.lastname}`)} />
              <div style={{ marginLeft: 5 }}>
                <Typography fontSize={10} color="#1b4965">
                  {user.firstname} {user.lastname}
                </Typography>
                <Typography
                  style={{ marginTop: -4 }}
                  fontSize={10}
                  color="#1b4965"
                >
                  {user.username}
                </Typography>
              </div>
              <TemporaryDrawer />
            </div>
          )}
        </GoBackWrapper>
      )}
    </HeaderSection>
  );
};

export default Header;
