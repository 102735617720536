import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import styled from "styled-components";
import { H5, Text } from "../../components/textStyles/style";
import { ButtonPrimaryLink } from "../../components/buttonStyles/style";
import makeStyles from "@material-ui/core/styles/makeStyles";
import axios from "axios";
import { SelectInput } from "../../components/Inputs";
import {
  contactObject,
  contactSubObject,
  languages,
} from "../../data/SelectOptions";
import { useTranslation } from "react-i18next";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
`;
export const ChipText = styled.p`
  font-family: "Oxygen";
  font-size: 13px;
  padding: 6px 10px;
  text-align: center;
  border-radius: 6px;
  background-color: ${({ success, danger }) =>
    success ? "#A8C492" : danger ? "#c22222" : "#1b4965"};
  color: white;
`;
const ContentWrapper = styled.div`
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px;
`;
export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
`;

const SelectContainer = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

const Contacts = () => {
  const useStyles = makeStyles(() => ({
    ul: {
      "& .MuiPaginationItem-root": {
        "&.Mui-selected": {
          background: "#1B4965",
          color: "white",
          fontWeight: "700",
          // borderRadius: '50%',
        },
      },
    },
  }));
  const classes = useStyles();

  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesNumber, setPagesNumber] = useState(null);
  const [data, setData] = useState([]);
  const [language, setLanguage] = useState("fr");
  const [resultCount, setResultCount] = useState(0);

  const { i18n } = useTranslation();

  useEffect(() => {
    const getData = async () => {
      setLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      const Response = await axios.get(
        `${process.env.REACT_APP_CONTACT}?pageNumber=${currentPage}&language=${language}`,
        config
      );
      const { contact, page, pages, count } = Response.data;

      setCurrentPage(page);
      setPagesNumber(pages);
      setData(contact);
      setResultCount(count);
      setLoader(false);
    };
    getData();
  }, [currentPage, language]);

  const changeLanguageHandler = (e) => {
    i18n.changeLanguage(e.target.value);
    setLanguage(e.target.value);
  };

  // const ContactsResponse = data;
  return (
    <Wrapper>
      <ContentWrapper>
        <Row>
          <H5>Contact Messages</H5>
          <SelectContainer>
            <div style={{ width: 180, marginRight: 15 }}>
              <SelectInput
                values={languages}
                placeholder="Language"
                value={language}
                onChange={changeLanguageHandler}
              />
            </div>
            <Text style={{ marginLeft: 5 }}>{resultCount} result(s) found</Text>
          </SelectContainer>
        </Row>
        <TableContainer>
          <Table>
            <TableHead>
              <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                Email
              </TableCell>
              <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                User
              </TableCell>

              <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                Phone
              </TableCell>
              <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                Object
              </TableCell>
              <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                Sub-Object
              </TableCell>
              <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                Message
              </TableCell>
              <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                Status
              </TableCell>
              <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                Details
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", color: "#1B4965" }}
              ></TableCell>
            </TableHead>
            <TableBody>
              {data.map((item, index) => {
                const obj = contactObject.find(
                  (el) => el.value === item.object
                );
                const subObject = contactSubObject.find(
                  (el) => el.value === item.subObject
                ) || { label: item.subObject };
                return (
                  <TableRow key={index}>
                    <TableCell align="left">{item.email}</TableCell>
                    <TableCell align="left">
                      {item.firstname + " " + item.lastname}
                    </TableCell>
                    <TableCell align="left">{item.phone}</TableCell>
                    <TableCell align="left">
                      {obj ? obj.label : " - "}
                    </TableCell>
                    <TableCell align="left">
                      {subObject ? subObject.label : " - "}
                    </TableCell>
                    <TableCell align="left">
                      {item.message.split("").slice(0, 20).join("") + "..."}
                    </TableCell>
                    <TableCell align="left">
                      {item.answered ? (
                        <ChipText success>Answered</ChipText>
                      ) : (
                        <ChipText>Not Answered</ChipText>
                      )}
                    </TableCell>

                    <TableCell align="left">
                      <ButtonPrimaryLink
                        to={`/contact/${item._id}`}
                        width="40px"
                        height="40px"
                      >
                        &#8594;
                      </ButtonPrimaryLink>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {pagesNumber && (
          <PaginationContainer style={{ marginTop: 20 }}>
            <Pagination
              classes={{
                root: classes.ul,
              }}
              count={pagesNumber}
              page={currentPage}
              onChange={(e, val) => setCurrentPage(val)}
            />
          </PaginationContainer>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default Contacts;
