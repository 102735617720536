import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { ButtonPrimaryLink } from "../buttonStyles/style";
import { ChipText, Image } from "./styles";

const TrailTable = ({ data }) => {
  return (
    <div style={{ marginLeft: 30, marginRight: 30, width: "100%" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
              Image
            </TableCell>
            <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
              Trail Code
            </TableCell>

            <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
              Titre
            </TableCell>
            <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
              Catégorie
            </TableCell>
            <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
              Nb. saisons
            </TableCell>
            <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
              Nb. épisodes
            </TableCell>
            <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
              Free ?
            </TableCell>
            <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
              limite / jour ?
            </TableCell>
            <TableCell
              style={{ fontWeight: "bold", color: "#1B4965" }}
            ></TableCell>
          </TableHead>
          <TableBody>
            {data.map((trail) => {
              return (
                <TableRow key={trail.trail_code}>
                  {/** image */}
                  <TableCell component="th" scope="row">
                    <Image src={trail.poster_link} />
                  </TableCell>
                  <TableCell align="left">{trail.trail_code}</TableCell>
                  <TableCell align="left">{trail.title}</TableCell>
                  <TableCell align="left">{trail.category}</TableCell>
                  <TableCell align="left">
                    {trail.seasons ? trail.seasons.length : 0}
                  </TableCell>
                  <TableCell align="left">{trail.episodes_number}</TableCell>
                  <TableCell align="left">
                    {trail.free ? (
                      <ChipText success>Gratuit</ChipText>
                    ) : (
                      <ChipText>Payant</ChipText>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {trail.free ? (
                      <ChipText success>Oui</ChipText>
                    ) : (
                      <ChipText danger>Non</ChipText>
                    )}
                  </TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left">
                    <ButtonPrimaryLink
                      to={`/trail/${trail.trail_code}`}
                      width="40px"
                      height="40px"
                    >
                      &#8594;
                    </ButtonPrimaryLink>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TrailTable;
