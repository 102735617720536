import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { H5, LoadingBigIcon } from "../../components/textStyles/style";
import axios from "axios";
import {
  ButtonPrimaryLink,
  PrimaryLink,
} from "../../components/buttonStyles/style";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;

const LoadWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
`;

const Books = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [experts, setExperts] = useState("");

  const getData = async () => {
    setLoader(true);
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    // get experts list
    const ex = await axios.get(
      process.env.REACT_APP_GET_EXPERTS_DROPDOWN,
      config
    );

    setExperts(ex.data);
    const Response = await axios.get(process.env.REACT_APP_GET_BOOKS, config);

    setData([...Response.data]);

    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Wrapper>
      <ContentWrapper>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ width: 260 }}>
            <ButtonPrimaryLink to="/new-book">
              Ajouter nouveau livre
            </ButtonPrimaryLink>
          </div>
        </div>
        <H5>Liste des Livres</H5>
        {loader ? (
          <LoadWrapper>
            <LoadingBigIcon />
          </LoadWrapper>
        ) : (
          <div style={{ margin: 30 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Nom du livre
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Prix
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Prix (avec points)
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Expert
                  </TableCell>

                  <TableCell
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  ></TableCell>
                </TableHead>
                <TableBody>
                  {data.map((book) => {
                    const expert = experts.find(
                      (expert) => expert.value === book.expert
                    );
                    return (
                      <TableRow key={book._id}>
                        <TableCell component="th" scope="row">
                          {book.title_fr}
                        </TableCell>
                        <TableCell align="left">{book.price}€</TableCell>
                        <TableCell align="left">
                          {`${book.price_points}€ + ${book.points} jetons `}
                        </TableCell>

                        <TableCell align="left">
                          {expert ? (
                            <PrimaryLink to={`/expert/${expert.value}`}>
                              {expert.label}
                            </PrimaryLink>
                          ) : (
                            " - "
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <ButtonPrimaryLink
                            to={`/book/${book._id}`}
                            width="40px"
                            height="40px"
                          >
                            &#8594;
                          </ButtonPrimaryLink>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default Books;
