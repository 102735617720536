import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend
);

const ExampleWorkshopStats = () => {

    const labels = ['10/2022', '11/2022', '12/2022', '1/2023', '2/2023', '3/2023', '4/2023', '5/2023', '6/2023', '7/2023', '8/2023', '9/2023', '10/2023']
    const responseData = [0, 0, 0, 0, 0, 0, 38, 114, 226, 331, 377, 326, 392]


    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            datalabels: {
                display: (context) => context.dataset.data[context.dataIndex] !== 0,
                align: "top", // You can adjust label alignment (top, center, bottom)
                color: "rgb(253, 112, 100)", // Label color
                font: {
                    weight: "bold", // Label font weight
                },
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label: "Workshops Views",
                data: responseData,
                borderColor: "rgb(253, 112, 100)",
                borderWidth: 3,
                backgroundColor: "rgba(253, 112, 100, 0.3)",
            },
        ],
    };

    return (
        <div>

            <div>
                <Line options={options} data={data} plugins={[ChartDataLabels]} />
            </div>
        </div>
    );
};

export default ExampleWorkshopStats;
