import {
    Button,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { H5, LoadingBigIcon } from "../../components/textStyles/style";
import axios from "axios";

import { SelectInput } from "../../components/Inputs";
import { languages, sectionsOptions } from "../../data/SelectOptions";
import { Edit } from "@material-ui/icons";
import UpdateQuestion from "../../components/modals/UpdateQuestion";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
    width: 100vw;
    min-height: ${Height};
    display: flex;
    justify-content: center;
  `;
const ContentWrapper = styled.div`
    background-color: white;
    width: 92%;
    padding: 30px;
    border-radius: 20px;
    margin: 30px 0;
  `;

const LoadWrapper = styled.div`
    width: 100%;
    display: flex;
    margin-top: 50px;
    align-items: center;
    justify-content: center;
  `;

const BilanQuestions = () => {
    const [loader, setLoader] = useState(false);
    const [selectedSection, setSelectedSection] = useState('all')
    const [data, setData] = useState([]);
    const [lng, setLng] = useState('fr')
    const [showUpdateQuestion, setShowUpdateQuestion] = useState(false);
    const [questionToUpdate, setQuestionToUpdate] = useState(null);

    const getData = async () => {
        setLoader(true);

        const Response = await axios.get(`${process.env.REACT_APP_QESTIONNAIRES}/section-questions?language=${lng}&section=${selectedSection}`);

        setData(Response.data);

        setLoader(false);
    };

    useEffect(() => {
        getData();
    }, [lng, selectedSection]);



    return (
        <Wrapper>
            <ContentWrapper>
                <H5>Personal Assessment - Bilan Questions</H5>
                <div style={{ display: "flex", flexDirection: 'row-reverse', alignItems: 'center' }}>
                    <div style={{ width: 180 }}>
                        <SelectInput
                            values={languages}
                            placeholder="Language"
                            value={lng}
                            onChange={(e) => setLng(e.target.value)}
                        />
                    </div>
                    <div style={{ width: 220, marginRight: 10 }}>
                        <SelectInput
                            values={sectionsOptions}
                            placeholder="Section"
                            value={selectedSection}
                            onChange={(e) => setSelectedSection(e.target.value)}
                        />
                    </div>
                </div>

                {loader ? (
                    <LoadWrapper>
                        <LoadingBigIcon />
                    </LoadWrapper>
                ) : (
                    <div style={{ margin: 20 }}>

                        {data.length === 0 ? (
                            <Typography
                                style={{
                                    textAlign: "center",
                                    fontSize: 16,
                                    color: "#484848",
                                    marginTop: 20,
                                }}
                            >
                                No questions found in selected section - language.
                            </Typography>
                        ) : (
                            <div>
                                {data.map((q, i) => {
                                    const answersString = q.value == 98 ? q.answers : q.answers.map(
                                        (el) => `${el.label} =${el.value}`
                                    );

                                    return (
                                        <div
                                            key={q._id}
                                            style={{
                                                backgroundColor: "#fff",
                                                marginTop: 15,
                                                padding: 15,
                                                border: '1px black dashed',
                                                display: 'flex', alignItems: 'center', justifyContent: 'space-between'
                                            }}
                                        >
                                            <div>

                                                <div style={{ display: "flex" }}>
                                                    <Typography style={{ color: "#1b4965", fontWeight: "600" }}>
                                                        Question {i + 1} :{" "}
                                                    </Typography>

                                                    <Typography marginLeft={1}>{q.label}</Typography>
                                                </div>
                                                <div style={{ display: "flex", marginTop: 10 }}>
                                                    <Typography
                                                        style={{
                                                            color: "#1b4965",
                                                            fontWeight: "600",
                                                            fontSize: 14,
                                                        }}
                                                    >
                                                        Answers :{" "}
                                                    </Typography>

                                                    <Typography marginLeft={1} style={{ fontSize: 14 }}>
                                                        {answersString.join(", ")}
                                                    </Typography>
                                                </div>
                                            </div>
                                            <div >


                                                <Button
                                                    onClick={() => {
                                                        setQuestionToUpdate(q)
                                                        setShowUpdateQuestion(true)
                                                    }}
                                                >
                                                    <Edit />
                                                </Button>



                                            </div>
                                        </div>
                                    );
                                })}
                            </div>)}

                    </div>
                )}
            </ContentWrapper>
            {/** modal to update question */}
            {questionToUpdate && <UpdateQuestion
                isOpen={showUpdateQuestion}

                setIsOpen={setShowUpdateQuestion}
                getData={getData}
                questionToUpdate={questionToUpdate}
                setQuestionToUpdate={setQuestionToUpdate}
            />}
        </Wrapper>
    );
};

export default BilanQuestions;
