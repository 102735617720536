import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ButtonPrimary, ButtonSecondary } from "../buttonStyles/style";
import { H5, H6, LoadingIcon, SmallBoldText } from "../textStyles/style";
import axios from "axios";
import styled from "styled-components";
import { Input } from "../Inputs";
import { Divider } from "@mui/material";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import SuccessImage from "../../images/main/green_circle_tick.svg";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export default function CompanyAnalysis({ isOpen, setIsOpen, company, section1, setSection1, section2, setSection2, section3, setSection3 }) {



    const [errorMessage, setErrorMessage] = React.useState("");
    const [submitLoader, setSubmitLoader] = React.useState(false);


    const MySwal = withReactContent(Swal);

    const handleClose = () => {
        if (!submitLoader) {
            setIsOpen(false);
            setErrorMessage("");

        }
    };




    const submitHandler = () => {


        if (!submitLoader) {
            setErrorMessage("");
            setSubmitLoader(true);
            const TOKEN = localStorage.getItem("TOKEN");

            axios
                .put(`${process.env.REACT_APP_UPDATE_COMPANY}`, {
                    access_token: TOKEN,
                    id: company,
                    section1, section2, section3
                })
                .then(() => {

                    setSubmitLoader(false)
                    setIsOpen(false)
                    MySwal.fire({
                        title: <H5>Modifications Enregisté</H5>,
                        html: <></>,
                        imageUrl: SuccessImage,
                        imageWidth: 200,
                        imageHeight: 150,
                        imageAlt: "Custom image",
                        timer: 1500,
                        showConfirmButton: false,

                        timerProgressBar: true,
                        padding: "2vw",
                        showCloseButton: true,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown animate__fast",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp animate__faster",
                        },
                    });
                })
                .catch((err) => {
                    setErrorMessage('Connexion Problem, Try later')
                    console.log(err);
                    setSubmitLoader(false);
                });
        }



    };



    return (
        <Dialog fullWidth maxWidth="lg" open={isOpen} onClose={handleClose}>
            <TitleContainer>
                <H6>Diverses Analysis</H6>
            </TitleContainer>

            <DialogContent>

                <SmallBoldText
                    style={{ textAlign: "center", fontSize: 12, color: "#c22222", }}
                >
                    {errorMessage ? errorMessage : " - "}
                </SmallBoldText>
                <Row>
                    <Input
                        multiline={true}
                        rows={6}
                        placeholder="Score 0 → 33"
                        value={section1}
                        onChange={(e) => setSection1(e.target.value)} />
                </Row>
                <Row>
                    <Input
                        multiline={true}
                        rows={6}
                        placeholder="Score 34 → 66"
                        value={section2}
                        onChange={(e) => setSection2(e.target.value)} />
                </Row>
                <Row>
                    <Input
                        multiline={true}
                        rows={6}
                        placeholder="Score 67 → 100"
                        value={section3}
                        onChange={(e) => setSection3(e.target.value)} />
                </Row>


                <Divider style={{ marginTop: 15, marginBottom: 10 }} />


            </DialogContent>

            <DialogActions style={{ marginTop: -10, marginBottom: 15 }}>
                <ButtonPrimary onClick={submitHandler}>
                    {submitLoader ? <LoadingIcon color="#ffffff" /> : "Save"}
                </ButtonPrimary>

                <ButtonSecondary onClick={handleClose}>Close</ButtonSecondary>
            </DialogActions>
        </Dialog>
    );
}
