import axios from "axios";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  ButtonPrimary,
  ButtonPrimaryLink,
} from "../../components/buttonStyles/style";
import { Input, SelectInput } from "../../components/Inputs";
import MultipleSelect from "../../components/MulitpleSelect";
import {
  H5,
  H6,
  LoadingBigIcon,
  LoadingIcon,
  TextBold,
} from "../../components/textStyles/style";
import { expertCategories, genderOptions } from "../../data/SelectOptions";
import "../../styles/custom-phone-input.css";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const LoadWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
`;

const PhoneInputWrapper = styled.div`
  border: none;
  overflow: hidden;
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const UpdateExpert = () => {
  const history = useHistory();
  const { expert_id } = useParams();
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [code, setCode] = useState("");
  const [codeEn, setCodeEn] = useState("");
  const [category, setCategory] = useState([]);
  const [gender, setGender] = useState("");
  const [titleFr, setTitleFr] = useState("");
  const [tva, setTva] = useState("");
  const [titleEn, setTitleEn] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [company, setCompany] = useState("");
  const [siret, setSiret] = useState("");
  const [addressPro, setAddressPro] = useState("");
  const [addressPerso, setAddressPerso] = useState("");
  const [emailPro, setEmailPro] = useState("");
  const [emailPerso, setEmailPerso] = useState("");
  const [phonePro, setPhonePro] = useState("");
  const [phonePerso, setPhonePerso] = useState("");
  const [logo, setLogo] = useState("");
  const [website, setWebsite] = useState("");
  const TOKEN = localStorage.getItem("TOKEN");
  const [calendly, setCalendly] = useState("");
  const submitHandler = async () => {
    if (!loading) {
      setLoading(true);
      setErrorMessage("");
      const TOKEN = localStorage.getItem("TOKEN");
      try {
        const data = {
          access_token: TOKEN,
          code,
          code_en: codeEn,
          category,
          tva,
          gender,
          lastname,
          firstname,
          title_fr: titleFr,
          title_en: titleEn,
          company,
          siret,
          address_pro: addressPro,
          address_perso: addressPerso,
          email_pro: emailPro,
          email_perso: emailPerso,
          phone_pro: phonePro,
          phone_perso: phonePerso,
          logo,
          website,
          calendly,
        };

        await axios.put(
          `${process.env.REACT_APP_UPDATE_EXPERT}?expert_id=${expert_id}`,
          data
        );
        setLoading(false);
        history.goBack();
      } catch (error) {
        setLoading(false);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(error);
        setErrorMessage("Probléme de Connexion");
      }
    }
  };
  /*const getExpertRessources = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const response = await axios.get(
      `http://localhost:4400/expertRessources?firstname=${firstname}&lastname=${lastname}`,
      config
    );
    const expertRessources = response.data;
  };*/
  useEffect(() => {
    const getExpertData = async () => {
      setLoader(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_GET_EXPERT}?expert_id=${expert_id}`,
        config
      );
      const expert = response.data;
      // SET INPUTS TO EXPERT VALUES
      setCode(expert.code);
      setGender(expert.gender);
      setCodeEn(expert.code_en);
      setCategory(expert.category);
      setFirstname(expert.firstname);
      setLastname(expert.lastname);
      setTva(expert.tva);
      setTitleEn(expert.title_en);
      setTitleFr(expert.title_fr);
      setAddressPerso(expert.address_perso);
      setAddressPro(expert.address_pro);
      setEmailPerso(expert.email_perso);
      setEmailPro(expert.email_pro);
      setPhonePerso(expert.phone_perso);
      setPhonePro(expert.phone_pro);
      setCompany(expert.company);
      setSiret(expert.siret);
      setLogo(expert.logo);
      setWebsite(expert.website);
      setLoader(false);
      setCalendly(expert.calendly ? expert.calendly : "");
    };

    getExpertData();
  }, [expert_id, TOKEN]);
  //getExpertRessources();
  return (
    <Wrapper>
      <ContentWrapper>
        <H5>Update Expert</H5>
        {loader ? (
          <LoadWrapper>
            <LoadingBigIcon />
          </LoadWrapper>
        ) : (
          <>
            <Row>
              <TextBold color="#c22222">
                {errorMessage ? errorMessage : " "}
              </TextBold>
            </Row>
            <H6>General Informations</H6>
            {/** code : variable a afficher en front - gender - category */}
            <Row>
              <Input
                placeholder="FR Display"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              <Input
                placeholder="EN Display"
                value={codeEn}
                onChange={(e) => setCodeEn(e.target.value)}
              />
              <ButtonPrimaryLink to={`/expert/${expert_id}/ressources`}>
                Check ressources
              </ButtonPrimaryLink>
            </Row>
            <Row>
              <MultipleSelect
                placeholder="Category"
                options={expertCategories}
                selectedValues={category}
                setSelectedValues={setCategory}
              />
            </Row>
            {/** firstname - lastname */}
            <Row>
              <Input
                placeholder="Firstname"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
              <Input
                placeholder="Lastname"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
            </Row>
            {/** firstname - lastname */}
            <Row style={{ marginBottom: 20 }}>
              <Input
                placeholder="Fr title"
                value={titleFr}
                onChange={(e) => setTitleFr(e.target.value)}
              />
              <Input
                placeholder="En title"
                value={titleEn}
                onChange={(e) => setTitleEn(e.target.value)}
              />
            </Row>
            <H6>Adresses</H6>
            {/** address pro - address perso */}
            <Row style={{ marginBottom: 20 }}>
              <Input
                placeholder="Professionnal Adresse"
                value={addressPro}
                onChange={(e) => setAddressPro(e.target.value)}
              />
              <Input
                placeholder="Personal Adresse"
                value={addressPerso}
                onChange={(e) => setAddressPerso(e.target.value)}
              />
            </Row>
            <H6>Emails</H6>
            {/** email pro - email perso */}
            <Row style={{ marginBottom: 20 }}>
              <Input
                placeholder="Professionnal Mail"
                value={emailPro}
                onChange={(e) => setEmailPro(e.target.value)}
              />
              <Input
                placeholder="Personal Mail"
                value={emailPerso}
                onChange={(e) => setEmailPerso(e.target.value)}
              />
            </Row>
            <H6>Téléphone</H6>
            {/** phone pro - phone  perso */}

            <Row style={{ marginBottom: 20 }}>
              <PhoneInputWrapper>
                <PhoneInput
                  placeholder="Professionnal Phone"
                  value={phonePro}
                  onChange={setPhonePro}
                  defaultCountry="FR"
                  international={false}
                  rules={{ required: true }}
                />
              </PhoneInputWrapper>
              <PhoneInputWrapper>
                <PhoneInput
                  placeholder="Personel Phone"
                  value={phonePerso}
                  onChange={setPhonePerso}
                  defaultCountry="FR"
                  international={false}
                  rules={{ required: true }}
                />
              </PhoneInputWrapper>
            </Row>
            <H6>Société</H6>
            {/** logo - company  */}
            <Row>
              <Input
                placeholder="Logo url"
                value={logo}
                onChange={(e) => setLogo(e.target.value)}
              />
              <Input
                placeholder="Company Name"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
            </Row>
            {/** siret + tva*/}
            <Row>
              <Input
                placeholder="Intra-community VAT number"
                value={tva}
                onChange={(e) => setTva(e.target.value)}
              />
              <Input
                placeholder="SIRET"
                value={siret}
                onChange={(e) => setSiret(e.target.value)}
              />
            </Row>
            {/** website */}
            <Row style={{ marginBottom: 20 }}>
              <Input
                placeholder="Website url"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </Row>
            <H6>Calendly link</H6>
            {/** logo - company  */}
            <Row>
              <Input
                placeholder="Calendly link"
                value={calendly}
                onChange={(e) => setCalendly(e.target.value)}
              />
            </Row>
            <Row style={{ marginTop: 40 }}>
              <ButtonPrimary onClick={submitHandler}>
                {loading ? <LoadingIcon color="inherit" /> : "Save"}
              </ButtonPrimary>
            </Row>
          </>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default UpdateExpert;
