import React from "react";
import styled from "styled-components";
import moment from "moment-timezone";
import { Typography } from "@mui/material";
import { usersRolesOptions } from "../../../data/SelectOptions";

const MessageContainer = styled.div`
  padding: 20px;
`;

const InfoContainer = styled.div`
  border-bottom: 1px dashed #999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
`;

const TextContainer = styled.div`
  border-bottom: 1px solid black;
  padding: 5px;
`;

const MessagesHistory = ({ messages }) => {
  return (
    <div>
      {messages.map((msg) => {
        const user = msg.user_data[0];
        const role = usersRolesOptions.find((r) => r.value === user.role);
        return (
          <MessageContainer key={msg._id}>
            <InfoContainer>
              {user && (
                <div>
                  <Typography fontWeight="600" fontSize={14} color="#555">
                    {user.firstname + " " + user.lastname}{" "}
                    <span>{`- ${role.label}`} </span>
                  </Typography>
                  <Typography fontWeight="600" fontSize={14} color="#222">
                    to → {msg.email} {msg.recipient === "expert" && " (expert)"}
                  </Typography>
                </div>
              )}
              <Typography fontSize={14} color="#555">
                {moment
                  .tz(msg.sendAt, "Europe/Paris")
                  .format("DD-MM-YYYY HH:mm")}{" "}
                (Europe/Paris)
              </Typography>
            </InfoContainer>
            <TextContainer>
              <Typography
                fontWeight={"600"}
                fontSize={16}
                color="#1b4965"
                style={{ marginTop: 10 }}
              >
                Object : {msg.object}
              </Typography>
              {msg.text.map((t, i) => {
                return (
                  <Typography
                    key={i}
                    color="#000"
                    fontWeight="400"
                    fontSize={14}
                    style={{ marginTop: 15 }}
                  >
                    {t}
                  </Typography>
                );
              })}
            </TextContainer>
          </MessageContainer>
        );
      })}
    </div>
  );
};

export default MessagesHistory;
