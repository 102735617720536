import React from "react";
import { H5 } from "../../../components/textStyles/style";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { RowBetween } from "../CompanyStat";
import { Typography } from "@mui/material";
import { ageOptions } from "../../../data/SelectOptions";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip);

const ExampleAge = () => {


    const labels = ageOptions


    const ages = [
        48,
        0,
        0,
        56,
        98,
        78

    ]
    const total = 280

    const colors = [
        "#A8C492",
        "#94CDDB",
        "#FFCE63",
        "#fb8c00",
        "#FD7064",
        "#1B4965",
        "#d50000",
        "#d500f9",
        "#00796b",
        "#4527a0",
        "#ff5722",
        "#263238",
    ];




    const totalHandler = (val) => {
        if (val == 0 || total == 0) {
            return " (0.00%)";
        } else {
            return ` (${((val / total) * 100).toFixed(2)}%)`;
        }
    };



    const pieDataHandler = () => {
        const d = {
            labels,
            datasets: [
                {
                    data: ages,
                    backgroundColor: colors,
                },
            ],
        };
        return d;
    };

    return (
        <div>
            <H5 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold", }} color="#000">
                11. Users Per Age
            </H5>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: "100%", marginRight: 20 }}>
                    {ageOptions.map((age, i) => {
                        if (ages[i] == 0) {
                            return null;
                        }
                        return (
                            <RowBetween key={age.value}>
                                <Typography style={{ color: "#484848" }} fontSize={13}>
                                    {age.label}
                                </Typography>
                                <Typography fontSize={13} color="#484848">
                                    {ages[i]} {totalHandler(ages[i])}
                                </Typography>
                            </RowBetween>
                        );
                    })}
                    <RowBetween style={{ marginTop: 15 }}>
                        <Typography style={{ color: "#484848" }} fontSize={13}>
                            Total
                        </Typography>
                        <Typography fontSize={13} color="#484848">
                            {total.toFixed(2)} (100%)
                        </Typography>
                    </RowBetween>
                </div>
                <div style={{ maxWidth: 150 }}>
                    <Pie
                        plugins={[ChartDataLabels]}
                        options={{
                            plugins: {
                                legend: false,
                                datalabels: {
                                    display: (context) =>
                                        context.dataset.data[context.dataIndex] !== 0,
                                    formatter: (value, ctx) => {
                                        let sum = 0;
                                        let dataArr = ctx.chart.data.datasets[0].data;
                                        dataArr.map((data) => {
                                            sum += data;
                                        });
                                        let percentage = ((value * 100) / total).toFixed(2) + "%";
                                        return percentage;
                                    },
                                    color: "#fefefe", // Label color
                                    font: {
                                        weight: "bold", // Label font weight
                                        size: 10
                                    },
                                },
                            },
                        }}
                        data={pieDataHandler()}
                    />
                </div>
            </div>
        </div>
    );
};

export default ExampleAge;
