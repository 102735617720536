import styled from "styled-components";
import { H6, LoadingBigIcon } from "../components/textStyles/style";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { daysOptions, hoursOptions } from "../data/SelectOptions";
import { SelectInput } from "../components/Inputs";
import { useContext } from "react";
import { UserContext } from "../App";
import { lislColors } from "../styles/colors";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 95%;
  min-height: ${Height};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentWrapper = styled.div`
  background-color: white;
  width: 100%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;

const RenderDayTime = ({ day, expert }) => {
  const { user } = useContext(UserContext);

  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(day.open);
    setStart(day.start);
    setEnd(day.end);
  }, [day]);

  return (
    <div
      style={{
        backgroundColor: lislColors.beige,
        marginTop: 10,
        padding: open ? 10 : 0,

        borderRadius: 10,
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          disabled={!(user.role === "admin" || user.expert === expert)}
          checked={open}
          onChange={() => {
            setOpen(!open);
            const TOKEN = localStorage.getItem("TOKEN");
            axios.put(`${process.env.REACT_APP_CHAT_TIME_NEW}?day=${day._id}`, {
              access_token: TOKEN,
              open: !open,
            });
          }}
          color="success"
        />
        <Typography style={{ fontSize: 13 }} color={open ? "green" : "#444"}>
          {open ? "Available" : "Not available"}
        </Typography>
      </div>

      <div>
        {open && (
          <div style={{ marginTop: 15 }}>
            <SelectInput
              disabled={!(user.role === "admin" || user.expert === expert)}
              values={hoursOptions}
              placeholder="start"
              value={start}
              onChange={(e) => {
                setStart(e.target.value);
                const TOKEN = localStorage.getItem("TOKEN");
                axios.put(
                  `${process.env.REACT_APP_CHAT_TIME_NEW}?day=${day._id}`,
                  {
                    access_token: TOKEN,
                    start: e.target.value,
                  }
                );
              }}
              small
            />
          </div>
        )}
      </div>

      <div style={{ marginTop: 15 }}>
        {open && (
          <SelectInput
            disabled={!(user.role === "admin" || user.expert === expert)}
            values={hoursOptions}
            placeholder="end"
            value={end}
            onChange={(e) => {
              setEnd(e.target.value);
              const TOKEN = localStorage.getItem("TOKEN");
              axios.put(
                `${process.env.REACT_APP_CHAT_TIME_NEW}?day=${day._id}`,
                {
                  access_token: TOKEN,
                  end: e.target.value,
                }
              );
            }}
            small
          />
        )}
      </div>
    </div>
  );
};

const Chat = () => {
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);

  const getData = async () => {
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    axios
      .get(process.env.REACT_APP_CHAT_TIMES_NEW, config)
      .then((res) => {
        setLoader(false);
        setData(res.data);

      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Wrapper>
      <ContentWrapper>
        <H6>Chat times</H6>
        {loader ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 60,
              marginBottom: 60,
            }}
          >
            <LoadingBigIcon />
          </div>
        ) : (
          <div style={{ marginTop: 30 }}>
            {/** table */}
            <Table border="1px #d3d3d3 dashed">
              <TableHead>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#1B4965" }}
                >
                  Expert
                </TableCell>

                {daysOptions.map((day) => (
                  <TableCell
                    key={day.value}
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    {day.label}
                  </TableCell>
                ))}
              </TableHead>
              <TableBody>
                {data.map((element, j) => {
                  const daysDocs = element.documents;

                  return (
                    <TableRow key={element.expertId}>
                      <TableCell
                        align="center"
                        style={{ fontWeight: "bold", color: "#1B4965" }}
                      >
                        {element.expertFirstName} {element.expertLastName}
                      </TableCell>
                      {daysDocs.map((d, i) => {
                        const arr = daysDocs.filter((el) => el.day == i);

                        if (arr.length == 0) {
                          return null;
                        }
                        return (
                          <TableCell
                            key={d._id}
                            align="center"
                            style={{
                              color: "#1B4965",
                              borderWidth: 1,
                            }}
                          >
                            {arr.map((el) => {
                              return (
                                <RenderDayTime
                                  day={el}
                                  expert={element.expertId}
                                />
                              );
                            })}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default Chat;
