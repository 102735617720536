import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import moment from "moment-timezone";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ChipText } from "../../components/tables/styles";
import {
  H5,
  H6,
  LoadingBigIcon,
  LoadingIcon,
  Text,
} from "../../components/textStyles/style";
import {
  appointmentStatusToExpert,
  appointmentStatusWithoutAll,
  types,
} from "../../data/SelectOptions";
import { ChipsContainer, ContentWrapper, LoadWrapper, Wrapper } from "./styles";
import { Link } from "react-router-dom";
import CoachingUsers from "./appointment/coachingUsers";
import SendEmailModal from "../../components/modals/SendEmailModal";
import CoachingExpert from "./appointment/coachingExpert";
import CoachingDate from "./appointment/coachingDate";
import { Input, SelectInput } from "../../components/Inputs";
import { Receipt } from "@material-ui/icons";
import MessagesHistory from "./appointment/messagesHistory";
import { ButtonPrimary, ButtonPrimaryLink } from "../../components/buttonStyles/style";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import SuccessImage from "../../images/main/green_circle_tick.svg";


export const createdAtTable = (title, timezone, time) => {
  return (
    <Table>
      <TableHead>
        <TableCell
          align="center"
          style={{ fontWeight: "bold", color: "#1B4965" }}
        ></TableCell>
        <TableCell
          align="center"
          style={{ fontWeight: "bold", color: "#1B4965" }}
        >
          Europe/Paris
        </TableCell>
        <TableCell
          align="center"
          style={{ fontWeight: "bold", color: "#1B4965" }}
        >
          {timezone}
        </TableCell>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell
            align="center"
            style={{ fontWeight: "bold", color: "#1B4965" }}
          >
            {title}
          </TableCell>
          <TableCell align="center">
            {moment.tz(time, "Europe/Paris").format("DD-MM-YYYY HH:mm z")}
          </TableCell>
          <TableCell align="center">
            {moment.tz(time, timezone).format("DD-MM-YYYY HH:mm z")}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};


const Appointment = () => {
  const { appointment_id } = useParams();
  const [loader, setLoader] = useState(true);
  const [appointment, setAppointment] = useState(null);
  const [currentStatus, setCurrentStatus] = useState("");
  const [showAppUserEmailModal, setShowAppUserEmailModal] = useState(false);
  const [showRecipientEmailModal, setShowRecipientEmailModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [cancelReason, setCancelReason] = useState("");
  const [remboursed, setRemboursed] = useState(false);
  const [saveReasonLoader, setSaveReasonLoader] = useState(false);
  const history = useHistory();
  const TOKEN = localStorage.getItem("TOKEN");
  const MySwal = withReactContent(Swal);
  useEffect(() => {
    const getAppointmentData = async () => {
      setLoader(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_COACHING_APPOINTMENT}?appointment_id=${appointment_id}`,
        config
      );
      setAppointment(response.data);
      setCancelReason(
        response.data.cancel_reason ? response.data.cancel_reason : ""
      );
      setRemboursed(
        response.data.remboursed ? response.data.remboursed : false
      );
      setCurrentStatus(response.data.status);

      // GET APPOINTMENT MESSAGES
      const messagesResponse = await axios.get(
        `${process.env.REACT_APP_APPOINTMENT_MESSAGES}?appointment_id=${appointment_id}`,
        config
      );
      setMessages(messagesResponse.data);

      setLoader(false);
    };

    getAppointmentData();
  }, [appointment_id, TOKEN]);

  const categoryRender = (value) => {
    if (value === "all") {
      return "All Categories";
    } else if (value) {
      return types.find((cat) => cat.value === value).label;
    } else {
      return " - ";
    }
  };

  const status = (value) =>
    appointmentStatusWithoutAll.find((s) => s.value === value).label;

  const YesNoRender = (value, title) => {
    const v = value === "true" || value === true ? true : false;
    return (
      <ChipText style={{ paddingLeft: 20, paddingRight: 20 }}>
        {title} : {v ? "Yes" : "No"}
      </ChipText>
    );
  };

  const updateAppointmentStatus = async (val) => {
    setIsLoading(true);
    const TOKEN = localStorage.getItem("TOKEN");
    axios
      .put(
        `${process.env.REACT_APP_COACHING_APPOINTMENT}?appointment_id=${appointment._id}`,
        {
          access_token: TOKEN,
          status: Number(val),
        }
      )
      .then(() => {
        setIsLoading(false);
        setCurrentStatus(val);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const saveReasonHandler = () => {
    if (!saveReasonLoader) {
      setSaveReasonLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      axios
        .put(
          `${process.env.REACT_APP_COACHING_APPOINTMENT}?appointment_id=${appointment._id}`,
          {
            access_token: TOKEN,
            cancel_reason: cancelReason,
          }
        )
        .then(() => {
          setTimeout(() => {
            setSaveReasonLoader(false);
            MySwal.fire({
              title: <H5>Modifications Enregisté</H5>,
              html: <></>,
              imageUrl: SuccessImage,
              imageWidth: 200,
              imageHeight: 150,
              imageAlt: "Custom image",
              timer: 1500,
              showConfirmButton: false,

              timerProgressBar: true,
              padding: "2vw",
              showCloseButton: true,
              showClass: {
                popup: "animate__animated animate__fadeInDown animate__fast",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp animate__faster",
              },
            });
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          setSaveReasonLoader(false);
        });
    }
  };

  return (
    <Wrapper>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={() => { }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ContentWrapper>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <H5 style={{ fontSize: 24 }}>Flash Coaching </H5>
          {/** invoice */}
          {appointment &&
            appointment?.payment &&
            appointment?.payment !== "ios-iap" && (
              <div>
                <Button
                  onClick={() => {
                    window.open(appointment?.payment, "_blank");
                  }}
                  size="small"
                  startIcon={<Receipt />}
                  variant="contained"
                  color="info"
                >
                  Facture
                </Button>
              </div>
            )}
        </div>
        {loader || appointment === null ? (
          <LoadWrapper>
            <LoadingBigIcon />
          </LoadWrapper>
        ) : (
          <>
            <div style={{ marginTop: 10 }}>
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  borderRadius: 10,
                  padding: 15,
                }}
              >
                <H6 style={{ fontSize: 20 }}>General Informations</H6>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <ChipsContainer>
                      {/** status */}
                      <ChipText
                        dark={currentStatus === 0}
                        warning={currentStatus === 1}
                        success={currentStatus === 3}
                        danger={
                          currentStatus === 4 ||
                          currentStatus === 5 ||
                          currentStatus === 6
                        }
                        style={{ paddingLeft: 20, paddingRight: 20 }}
                      >
                        Status : {currentStatus + 1} - {status(currentStatus)}
                      </ChipText>
                      {/** is Gift */}
                      {YesNoRender(appointment.isGift, "Gift")}
                      {/** Payed with points */}
                      {YesNoRender(appointment.withPoints, "Payed with tokens")}
                    </ChipsContainer>
                    <div style={{ marginTop: 25, marginBottom: 25 }}>
                      {/** topic */}
                      <Typography color="#1B4965">
                        <Typography variant="span" fontWeight="bold">
                          Subject :{" "}
                        </Typography>
                        {appointment.topic}
                      </Typography>
                      {/** message */}
                      <Typography color="#1B4965">
                        <Typography variant="span" fontWeight="bold">
                          Message :{" "}
                        </Typography>
                        {appointment.message || " - "}
                      </Typography>
                      {appointment.topic == "Trail" ? (
                        // Ressource :  case topic trail
                        <Link
                          style={{ color: "#1b4965" }}
                          to={`/trail/${appointment.trail_code}`}
                          target="_blank"
                        >
                          <Typography color="#1B4965">
                            <Typography variant="span" fontWeight="bold">
                              Trail :{" "}
                            </Typography>
                            {`${appointment.trail_code}`} -{" "}
                            {appointment.trail_title}
                          </Typography>
                        </Link>
                      ) : appointment.topic == "Atelier" ? (
                        // Ressource :   case topic workshop
                        <Typography color="#1B4965">
                          <Typography variant="span" fontWeight="bold">
                            Category :{" "}
                          </Typography>
                          {categoryRender(appointment.category)}
                        </Typography>
                      ) : (
                        // Ressource :   case topic "autre"
                        <></>
                      )}
                    </div>
                  </div>
                  <div>
                    {/** created at */}
                    {createdAtTable(
                      "Date prise RDV",
                      appointment.timezone,
                      appointment.createdAt
                    )}
                  </div>
                </div>
              </div>

              <div
                style={{
                  marginTop: 15,
                  display: "flex",
                  alignItems: "stretch",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "49.5%",
                    backgroundColor: "#f5f5f5",
                    borderRadius: 10,
                    overflow: "hidden",
                  }}
                >
                  {/** status */}
                  <div style={{ padding: 15 }}>
                    <H6 style={{ fontSize: 20 }}>Appointment Status</H6>
                    <div style={{ marginTop: 15, marginLeft: 5 }}>
                      {currentStatus === 5 ? (
                        <div>
                          <Typography color="#c22222" fontSize={14} style={{}}>
                            Appointment canceled by User at{" "}
                            {moment(appointment.cancelAt).format("LLL")}.
                          </Typography>
                          <Typography
                            color="#484848"
                            fontSize={14}
                            style={{ marginTop: 5 }}
                          >
                            Cancel Reason : {cancelReason}
                          </Typography>
                          {/** remboursement  */}
                          {<div style={{ borderTop: '1px #484848 solid', marginTop: 20, paddingTop: 10 }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                              <Typography
                                color={appointment.remboursable ? '#1b4965' : "#c22222"}
                                fontSize={appointment.remboursable ? 20 : 14}

                              >
                                {appointment.remboursable ? "Payment Refund :" : "This appointment will can't be refunded . the cancelation was less than 48h of the appointment time."}
                              </Typography>
                              {appointment.remboursable && appointment.paymentIntent &&


                                <ButtonPrimary onClick={(e) =>
                                  window.open(`https://dashboard.stripe.com/payments/${appointment.paymentIntent}`, "_blank")
                                }>
                                  Refund
                                </ButtonPrimary>}
                            </div>

                            {appointment.remboursable && <FormControlLabel

                              control={
                                <Checkbox
                                  checked={remboursed}
                                  onChange={(e) => {
                                    setIsLoading(true);
                                    axios
                                      .post(process.env.REACT_APP_REFUND_COACHING, {
                                        access_token: TOKEN,
                                        remboursed: e.target.checked,
                                        appointment_id: appointment._id,
                                      })
                                      .then(() => {
                                        setIsLoading(false);
                                        setRemboursed(e.target.value);
                                        history.go(0);
                                      });
                                  }}
                                  color="success"
                                />
                              }
                              label={remboursed ? "Refunded" : "Mark as Refunded"}
                            />}
                            {appointment.remboursedAt && (
                              <Typography color="#484848" fontSize={14}>
                                Refunded at {moment().format("LLL")}
                              </Typography>
                            )}
                          </div>}
                        </div>
                      ) : (
                        <SelectInput
                          values={appointmentStatusToExpert}
                          placeholder="Appointment Status"
                          value={currentStatus}
                          onChange={(e) =>
                            updateAppointmentStatus(e.target.value)
                          }
                        />
                      )}
                      {currentStatus === 4 && (
                        <div>
                          <Typography
                            color="#c22222"
                            fontSize={14}
                            style={{ marginLeft: 5, marginTop: 15 }}
                          >
                            Appointment canceled by Expert.
                          </Typography>
                        </div>
                      )}
                      {(currentStatus === 4 || currentStatus === 6) && (
                        <div style={{ marginTop: 30 }}>
                          <Input
                            multiline={true}
                            placeholder="Reason for cancellation of appointment *"
                            value={cancelReason}
                            onChange={(e) => setCancelReason(e.target.value)}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row-reverse",
                              marginTop: 12,
                            }}
                          >
                            <ButtonPrimary
                              width="270px"
                              onClick={saveReasonHandler}
                            >
                              {saveReasonLoader ? (
                                <LoadingIcon color="white" />
                              ) : (
                                "Update Cancel reason"
                              )}
                            </ButtonPrimary>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "49.5%",
                    backgroundColor: "#f5f5f5",
                    borderRadius: 10,
                    overflow: "hidden",
                  }}
                >
                  {/** date */}
                  <div style={{ padding: 15 }}>
                    <H6 style={{ fontSize: 20 }}>Appointment time</H6>
                    <div style={{ marginTop: 15 }}>
                      <CoachingDate
                        setIsLoading={setIsLoading}
                        appointment={appointment}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  marginTop: 15,
                  display: "flex",
                  alignItems: "stretch",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "49.5%",
                    backgroundColor: "#f5f5f5",
                    borderRadius: 10,
                    overflow: "hidden",
                  }}
                >
                  <div style={{ padding: 15 }}>
                    <H6 style={{ fontSize: 20 }}>
                      Customer / User Informations{" "}
                    </H6>
                    <CoachingUsers
                      setShowAppUserEmailModal={setShowAppUserEmailModal}
                      setShowRecipientEmailModal={setShowRecipientEmailModal}
                      appointment={appointment}
                    />
                  </div>
                </div>
                {/** expert */}
                <div
                  style={{
                    width: "49.5%",
                    backgroundColor: "#f5f5f5",
                    borderRadius: 10,
                  }}
                >
                  <div style={{ padding: 15 }}>
                    <H6 style={{ fontSize: 20 }}>Informations Expert</H6>
                    <div>
                      <CoachingExpert
                        appointment={appointment}
                        setIsLoading={setIsLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div
              style={{
                backgroundColor: "#f5f5f5",
                borderRadius: 10,
                padding: 15,
                marginTop: 20,
              }}
            >
              <H6 style={{ fontSize: 20 }}>Messages history</H6>
              <br />
              {messages.length == 0 ? (
                <Text
                  color="#444"
                  style={{ marginTop: 10, marginBottom: 20, marginLeft: 5 }}
                >
                  No messages ...
                </Text>
              ) : (
                <MessagesHistory messages={messages} />
              )}
            </div>
          </>
        )}
      </ContentWrapper>
      {/** modal to send email to app user */}
      {appointment && (
        <SendEmailModal
          coaching={true}
          email={appointment.user_data[0].email}
          isOpen={showAppUserEmailModal}
          appointment_id={appointment_id}
          setIsOpen={setShowAppUserEmailModal}
        />
      )}
      {/** modal to send email to recipient of flash coaching */}
      {appointment && (
        <SendEmailModal
          coaching={true}
          email={appointment.email}
          isOpen={showRecipientEmailModal}
          appointment_id={appointment_id}
          setIsOpen={setShowRecipientEmailModal}
        />
      )}
    </Wrapper>
  );
};

export default Appointment;
