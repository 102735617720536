import {
  Avatar,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  H5,
  LoadingBigIcon,
  SmallText,
  Text,
} from "../../components/textStyles/style";
import axios from "axios";
import { languages, CommunityPostStatus } from "../../data/SelectOptions";
import { ButtonPrimaryLink } from "../../components/buttonStyles/style";
import { ChipText } from "../../components/tables/styles";

import { PaginationContainer, SearchContainer } from "../app-users/appUsers";
import { SearchInput, SelectInput } from "../../components/Inputs";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";
import CommentStatus from "./CommentStatus";
moment.locale("fr");

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;

const LoadWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
`;
const Posts = () => {
  const [loader, setLoader] = useState(false);
  const [trails, setTrails] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesNumber, setPagesNumber] = useState(null);
  const [searchTotal, setSearchTotal] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [language, setLanguage] = useState("fr");
  const [trail, setTrail] = useState("");
  const [status, setStatus] = useState("");
  const statusOptions = [{ label: "Tous", value: "" }, ...CommunityPostStatus];

  useEffect(() => {
    const getTrails = async () => {
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      const Response = await axios.get(
        `${process.env.REACT_APP_GET_TRAILS}?language=${language}`,
        config
      );
      const t = [];
      await Response.data.map((trail) => {
        t.push({ label: trail.title, value: trail.trail_code });
      });
      setTrails([{ value: "", label: "Tous" }, ...t]);
    };
    getTrails();
  }, [language]);

  const getData = async () => {
    setLoader(true);
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const url = `${process.env.REACT_APP_COMMUNITY_POSTS}?language=${language}&pageNumber=${currentPage}&keyword=${keyword}&trail_code=${trail}&status=${status}`;

    const Response = await axios.get(url, config);
    const { posts, page, pages, count } = Response.data;

    setData(posts);

    setCurrentPage(page);
    setPagesNumber(pages);
    setSearchTotal(count);
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [currentPage, keyword, language, trail, status]);

  return (
    <Wrapper>
      <ContentWrapper>
        <H5>Liste des Publications</H5>
        <SearchContainer style={{ marginBottom: 15 }}>
          {/** language */}
          <div style={{ width: 250 }}>
            <SelectInput
              values={languages}
              placeholder="Langue"
              value={language}
              onChange={(e) => {
                setCurrentPage(1);
                setLanguage(e.target.value);
              }}
            />
          </div>
          {/** trail */}
          <SelectInput
            values={trails}
            placeholder="Trail"
            value={trail}
            onChange={(e) => {
              setCurrentPage(1);
              setTrail(e.target.value);
            }}
          />
          {/** trail */}
          <SelectInput
            values={statusOptions}
            placeholder="Status publication"
            value={status}
            onChange={(e) => {
              setCurrentPage(1);
              setStatus(e.target.value);
            }}
          />
          {/** keyword */}
          <div style={{ width: 400 }}>
            <SearchInput
              style={{ width: 400 }}
              onFocus={() => setCurrentPage(1)}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </div>

          <SmallText>{searchTotal} résultat(s) trouvée(s)</SmallText>
        </SearchContainer>
        {loader ? (
          <LoadWrapper>
            <LoadingBigIcon />
          </LoadWrapper>
        ) : (
          <div style={{ margin: 30 }}>
            {data.length > 0 && pagesNumber && (
              <PaginationContainer>
                <Pagination
                  color="primary"
                  count={pagesNumber}
                  page={currentPage}
                  onChange={(e, val) => setCurrentPage(val)}
                />
              </PaginationContainer>
            )}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Auteur
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Trail
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Saison
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Titre
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                    Contenu
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965", width: 110 }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Crée le
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Nb Commentaires signalés
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  ></TableCell>
                </TableHead>
                <TableBody>
                  {data.map((post) => {
                    const user = post.user_data[0];
                    const trail = post.trail_data[0];
                    const image = user.image
                      ? `${process.env.REACT_APP_BASE_URL}${user.image}`
                      : "https://www.pngmart.com/files/21/Account-Avatar-Profile-PNG-Photo.png";

                    return (
                      <TableRow key={post._id}>
                        <TableCell scope="row" style={{ width: 60 }}>
                          <Link
                            style={{ color: "#1b4965", fontSize: 13 }}
                            target="_blank"
                            to={`/app-user/${user._id}`}
                          >
                            <Avatar alt="user avatar" src={image} />

                            <Typography
                              style={{ marginTop: 5 }}
                              fontSize={13}
                            >{`${user.firstname} ${user.lastname}`}</Typography>
                            <Typography
                              color="#1b4965"
                              fontWeight="600"
                              fontSize={13}
                            >{`${user.pseudo}`}</Typography>
                          </Link>
                        </TableCell>
                        <TableCell style={{ width: 200 }} align="left">
                          <Link
                            style={{ color: "#1b4965", fontSize: 14 }}
                            target="_blank"
                            to={`/trail/${post.trail_code}`}
                          >{`${trail.title}`}</Link>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            color="#1b4965"
                            fontWeight="600"
                            fontSize={14}
                          >
                            {post.season.label}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            style={{ width: 150 }}
                            color="#1b4965"
                            fontWeight="500"
                            fontSize={14}
                          >
                            {post.title}
                          </Typography>
                        </TableCell>
                        <TableCell style={{ width: 200 }} align="left">
                          <Typography
                            color="#1b4965"
                            fontWeight="500"
                            fontSize={14}
                            style={{ width: 200 }}
                          >
                            {post.text}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {post.status === "pending" ? (
                            <ChipText style={{ width: 110 }}>
                              Non approuvé
                            </ChipText>
                          ) : post.status === "active" ? (
                            <ChipText style={{ width: 110 }} success>
                              Approuvée
                            </ChipText>
                          ) : (
                            <ChipText style={{ width: 110 }} danger>
                              Rejetée
                            </ChipText>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Typography fontSize={13}>
                            {moment(post.createdAt).format("LLL")}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <CommentStatus post_id={post._id} />
                        </TableCell>
                        <TableCell align="right">
                          <ButtonPrimaryLink
                            to={`/post/${post._id}`}
                            width="40px"
                            height="40px"
                          >
                            &#8594;
                          </ButtonPrimaryLink>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default Posts;
