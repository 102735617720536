import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { H6 } from "../../../components/textStyles/style";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Filler,
    Tooltip,
    Legend
);

const ExampleChartEntries = ({ company }) => {
    const labels = ['10/2022', '11/2022', '12/2022', '1/2023', '2/2023', '3/2023', '4/2023', '5/2023', '6/2023', '7/2023', '8/2023', '9/2023', '10/2023']
    const responseData = [0, 0, 0, 0, 0, 0, 34, 41, 18, 44, 38, 84, 21]



    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            datalabels: {
                display: (context) => context.dataset.data[context.dataIndex] !== 0,
                align: "top", // You can adjust label alignment (top, center, bottom)
                color: "rgb(28, 73, 102)", // Label color
                font: {
                    weight: "bold", // Label font weight
                },
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                fill: true,

                label: "Live Chat entries",
                data: responseData,
                borderColor: "rgb(28, 73, 102)",
                borderWidth: 3,
                backgroundColor: "rgba(28, 73, 102, 0.3)",
            },
        ],
    };

    return (
        <div style={{ width: "100%" }}>
            <div >

                <H6 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold", }} color="#000">
                    9. Chat entries
                </H6>
            </div>
            <div style={{ width: "100%", marginTop: 10 }}>
                <Line

                    plugins={[ChartDataLabels]}
                    options={options}
                    data={data}
                />
            </div>
        </div>
    );
};

export default ExampleChartEntries;
