import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import { Input, SelectInput } from "../../components/Inputs";
import {
  H5,
  H6,
  H7,
  LoadingBigIcon,
  LoadingIcon,
  TextBold,
} from "../../components/textStyles/style";
import "../../styles/custom-phone-input.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SuccessImage from "../../images/main/green_circle_tick.svg";
import { Divider } from "@mui/material";
import { UserImage, AvatarContainer } from "./styles";
import UserAvatar from "@material-ui/icons/Person";
import AssociationContacts from "./associationContact";
import Donations from "./donations";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const LoadWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
`;

const PhoneInputWrapper = styled.div`
  border: none;
  overflow: hidden;
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const UpdateAssociation = () => {
  const { association_id } = useParams();
  const MySwal = withReactContent(Swal);
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [nameFr, setNameFr] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [descriptionFr, setdescriptionFr] = useState("");
  const [descriptionEn, setdescriptionEn] = useState("");
  const [siret, setSiret] = useState("");
  const [phone, setPhone] = useState("");
  const [image, setImage] = useState("");
  const [bic, setbic] = useState("");
  const [website, setwebsite] = useState("");
  const [donLink, setDonLink] = useState("");

  const [iban, setiban] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactName, setContactName] = useState("");
  const [address, setaddress] = useState("");
  const [statusLoader, setStatusLoader] = useState(false);
  const [status, setStatus] = useState("");
  const [contacts, setContacts] = useState([]);
  const TOKEN = localStorage.getItem("TOKEN");

  const submitHandler = async () => {
    if (!loading && donLink.length > 0) {
      setLoading(true);
      setErrorMessage("");
      const TOKEN = localStorage.getItem("TOKEN");
      try {
        const data = {
          access_token: TOKEN,
          name_fr: nameFr,
          name_en: nameEn,
          description_fr: descriptionFr,
          description_en: descriptionEn,
          siret,
          phone,
          website,
          iban,
          bic,
          image,
          address,
          don_link: donLink,
          id: association_id,
          contactEmail,
          contactName,
        };

        await axios.put(process.env.REACT_APP_ASSOS, data);

        setTimeout(() => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          setLoading(false);
          MySwal.fire({
            title: <H5>Modifications Enregisté</H5>,
            html: <></>,
            imageUrl: SuccessImage,
            imageWidth: 200,
            imageHeight: 150,
            imageAlt: "Custom image",
            timer: 1500,
            showConfirmButton: false,

            timerProgressBar: true,
            padding: "2vw",
            showCloseButton: true,
            showClass: {
              popup: "animate__animated animate__fadeInDown animate__fast",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp animate__faster",
            },
          });
        }, 1000);
      } catch (error) {
        setLoading(false);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(error);
        setErrorMessage("Probléme de Connexion");
      }
    }
  };

  const toggleStatus = async () => {
    if (!statusLoader) {
      setStatusLoader(true);
      setErrorMessage(null);
      const TOKEN = localStorage.getItem("TOKEN");
      try {
        const value = status === "active" ? "inactive" : "active";
        const data = {
          access_token: TOKEN,
          status: value,
          id: association_id,
        };

        await axios.put(process.env.REACT_APP_ASSOS, data);

        setTimeout(() => {
          setStatusLoader(false);
          setStatus(value);
        }, 1000);
      } catch (error) {
        setStatusLoader(false);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(error);
        setErrorMessage("Probléme de Connexion");
      }
    }
  };

  useEffect(() => {
    const getassociationData = async () => {
      setLoader(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_ASSOCIATION}?association_id=${association_id}`,
        config
      );
      const association = response.data;

      // SET INPUTS TO EXPERT VALUES
      setNameFr(association.name_fr);
      setNameEn(association.name_en);
      setSiret(association.siret);
      setPhone(association.phone);
      setContactEmail(association.contactEmail ? association.contactEmail : "");
      setContactName(association.contactName ? association.contactName : "");
      setDonLink(association.don_link ? association.don_link : "");
      setbic(association.bic);
      setwebsite(association.website);
      setiban(association.iban);
      setdescriptionFr(association.description_fr);
      setdescriptionEn(association.description_en);
      setaddress(association.address);
      setImage(association.image);
      setContacts(association.contacts);
      setLoader(false);
    };

    getassociationData();
  }, [association_id, TOKEN]);

  return (
    <Wrapper>
      <ContentWrapper>
        <H5>Update Association</H5>
        {loader ? (
          <LoadWrapper>
            <LoadingBigIcon />
          </LoadWrapper>
        ) : (
          <>
            <Row>
              <TextBold color="#c22222">
                {errorMessage ? errorMessage : " "}
              </TextBold>
            </Row>
            <H6>General informations</H6>
            {/**image assos name  + image*/}
            <Row>
              <div style={{ alignSelf: "flex-start", marginRight: 10 }}>
                {image ? (
                  <UserImage src={`${image}`} />
                ) : (
                  <AvatarContainer>
                    <UserAvatar style={{ fontSize: 70, color: "#A89D9C" }} />
                  </AvatarContainer>
                )}
              </div>
              <Input
                placeholder="Association name (fr) *"
                value={nameFr}
                onChange={(e) => setNameFr(e.target.value)}
              />
              <Input
                placeholder="Association name (en) *"
                value={nameEn}
                onChange={(e) => setNameEn(e.target.value)}
              />
            </Row>
            <Row>
              <Input
                placeholder="Image *"
                value={image}
                onChange={(e) => setImage(e.target.value)}
              />
            </Row>
            <Row>
              <Input
                style={{ marginTop: 20 }}
                placeholder="Description (fr) *"
                value={descriptionFr}
                onChange={(e) => setdescriptionFr(e.target.value)}
              />
            </Row>
            <Row>
              <Input
                style={{ marginTop: 20 }}
                placeholder="Description (en) *"
                value={descriptionEn}
                onChange={(e) => setdescriptionEn(e.target.value)}
              />
            </Row>
            {/** website */}
            <Row>
              <Input
                style={{ marginTop: 20 }}
                placeholder="Website (Optional)"
                value={website}
                onChange={(e) => setwebsite(e.target.value)}
              />
            </Row>
            {/** don_link */}
            <Row>
              <Input
                style={{ marginTop: 20 }}
                placeholder="Direct don Link *"
                value={donLink}
                onChange={(e) => setDonLink(e.target.value)}
              />
            </Row>
            {/** siret */}
            <Row>
              <Input
                placeholder="SIRET (Optional)"
                value={siret}
                onChange={(e) => setSiret(e.target.value)}
              />
            </Row>
            {/** phone - address */}
            <Row>
              <Input
                style={{ marginTop: 20 }}
                placeholder="Address *"
                value={address}
                onChange={(e) => setaddress(e.target.value)}
              />
              <PhoneInputWrapper>
                <PhoneInput
                  placeholder="Phone *"
                  value={phone}
                  onChange={setPhone}
                  defaultCountry="FR"
                  international={false}
                  rules={{ required: true }}
                />
              </PhoneInputWrapper>
            </Row>
            <Row>
              <Input
                placeholder="IBAN (Optional)"
                value={iban}
                onChange={(e) => setiban(e.target.value)}
              />
              <Input
                placeholder="BIC (Optional)"
                value={bic}
                onChange={(e) => setbic(e.target.value)}
              />
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Input
                placeholder="Contact Name (Optional)"
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
              />
              <Input
                placeholder="Contact Email (Optional)"
                value={contactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
              />
            </Row>
            <Row style={{ marginTop: 40 }}>
              <ButtonPrimary onClick={submitHandler}>
                {loading ? <LoadingIcon color="inherit" /> : "Save"}
              </ButtonPrimary>
            </Row>
          </>
        )}
        {!loader && <Divider style={{ marginTop: 20 }} />}
        {!loader && (
          <AssociationContacts
            contacts={contacts}
            association_id={association_id}
          />
        )}
        {!loader && <Divider style={{ marginTop: 50 }} />}
        {!loader && <Donations association_id={association_id} />}
      </ContentWrapper>
    </Wrapper>
  );
};

export default UpdateAssociation;
