import moment from "moment";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import logo from "../../images/main/green-logo.svg";
import "../../styles/custom-phone-input.css";
import { H5, H6, LoadingBigIcon } from "../../components/textStyles/style";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Typography } from "@mui/material";
import {
  companiesPaymentOptions,
  companyTypologieOptions,
  reportTypeOptions,
  sectorsOptions,
} from "../../data/SelectOptions";
import CompanyCoachingStat from "./CompanyCoachingStat";
import CompanyUsersStat from "./CompanyUsersStat";
import CompanyRanksStat from "./CompanyRanksStat";
import CompanyTopEpisodes from "./CompanyTopEpisodes";
import CompanyTopWorkshops from "./CompanyTopWorkshops";
import CompanyEpisodesStats from "./CompanyEpisodesStats";
import CompanyWorkshopsStats from "./CompanyWorkshopsStats";
import CompanyChatEntriesStat from "./CompanyChatEntriesStat";
import CompanyTopTrails from "./CompanyTopTrails";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import html2pdf from "html2pdf.js";
import { Logo } from "../../components/header/style";
import { Print } from "@material-ui/icons";
import { lislColors } from "../../styles/colors";
import CompanyPurchasesUsers from "./CompanyPurchasesUsers";
import CompanyPurchasesStat from "./CompanyPurchasesStat";
import CompanyAge from "./CompanyAge";
import CompanyLanguage from "./CompanyLanguage";
import CompanyGender from "./CompanyGender";
import GlobalEvolution from "./GlobalEvolution";
import Annexe from "./Annexe";
import { SelectInput } from "../../components/Inputs";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 753px;
  padding: 20px;
  border-radius: 20px;
`;
const LoaderContainer = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const RowBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  border-bottom: 1px #484848 dashed;
  padding-bottom: 2px;
`;

const CompanyStat = () => {
  const { company_id } = useParams();
  const [loader, setLoader] = useState(true);
  const [company, setCompany] = useState(null);
  const [contract, setContract] = useState(null);
  const [reportType, setReportType] = useState('full')
  useEffect(() => {
    const getCompanyData = async () => {
      setLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_GET_COMPANY}?company_id=${company_id}`,
        config
      );

      setCompany(response.data);

      const contractResponse = await axios.get(
        `${process.env.REACT_APP_CONTRACTS}/current?company_id=${company_id}`,
        config
      );
      setContract(contractResponse.data ? contractResponse.data : null);

      setLoader(false);
    };
    getCompanyData();
  }, [company_id]);

  if (loader || !company) {
    return (
      <LoaderContainer>
        <LoadingBigIcon />
      </LoaderContainer>
    );
  }

  const typologie = companyTypologieOptions.find(
    (el) => el.value === company.typologie
  );
  const sector = sectorsOptions.find((el) => el.value === company.sector);

  const now = new Date();
  const formattedDate = moment(now).format("DD-MM-YYYY");

  const printHandler = () => {
    const element = document.getElementById("to-print");
    const opt = {
      filename: `${company.name}-${formattedDate}.pdf`,
      margin: 5,

      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf().set(opt).from(element).save();
  };

  return (
    <Wrapper>
      <div
        style={{
          position: "absolute",
          right: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <div style={{ width: 120, marginRight: 10 }}>
          <SelectInput
            values={reportTypeOptions}
            placeholder="Type"
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
          />
        </div>
        <ButtonPrimary onClick={printHandler}>
          <Print />
        </ButtonPrimary>

      </div>
      <ContentWrapper>
        <div>
          {/** general info */}
          <div id="to-print">
            <div style={{ height: "287mm", marginLeft: 15, marginRight: 15, position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  bottom: 10,
                  right: 0,
                  borderBottom: "1px #484848 dashed",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Typography color="#444" textAlign="right" fontSize={10}>
                    1/{reportType === "full" ? "4" : "3"}</Typography>
                </div>
                <div>
                  <Typography color="#444" textAlign="right" fontSize={10}>
                    CONFIDENTIAL
                  </Typography>
                  <Typography color="#484848" fontSize={10}>
                    This document contains confidential and proprietary
                    information. Unauthorized distribution is prohibited.
                  </Typography>
                </div>
              </div>
              <Logo
                style={{
                  width: 400,
                  height: 160,
                  alignSelf: "center",
                  position: "absolute",
                  top: "120mm",
                  opacity: 0.25,
                  left: "45mm",
                }}
                src={logo}
                alt="image"
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Logo
                    style={{
                      width: 200,
                      height: 80,
                      marginLeft: -20,
                      marginBottom: 5,
                    }}
                    src={logo}
                    alt="image"
                  />
                  <div style={{ marginLeft: 10 }}>
                    <Typography color={lislColors.black} fontSize={12}>
                      MASSA TRAILS SAS
                    </Typography>
                    <Typography color="#444" fontSize={10}>
                      47 rue des Batignolles – 75017 PARIS
                    </Typography>
                  </div>
                </div>
                <Typography color="#484848" fontSize={13}>
                  {formattedDate}
                </Typography>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <H5 style={{ textAlign: "center", fontSize: 22 }}>
                  Report - {company.name}
                </H5>
              </div>

              <div style={{ marginTop: 30 }}>
                <H6 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
                  1. General Informations
                </H6>
              </div>
              {/** general infos */}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "stretch",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "48%",
                    borderRight: "0.25px #484848 solid",
                    paddingRight: "2%",
                  }}
                >
                  <RowBetween>
                    <Typography fontSize={13} style={{ color: "#363636" }}>
                      Num order
                    </Typography>
                    <Typography fontSize={13} color="#484848">
                      {company.num_order}
                    </Typography>
                  </RowBetween>
                  <RowBetween>
                    <Typography fontSize={13} style={{ color: "#363636" }}>
                      Typology
                    </Typography>
                    <Typography fontSize={13} color="#484848">
                      {typologie ? typologie.label : " - "}
                    </Typography>
                  </RowBetween>
                  <RowBetween>
                    <Typography fontSize={13} style={{ color: "#363636" }}>
                      Sector
                    </Typography>
                    <Typography fontSize={13} color="#484848">
                      {sector ? sector.label : " - "}
                    </Typography>
                  </RowBetween>
                  <RowBetween>
                    <Typography fontSize={13} style={{ color: "#363636" }}>
                      Group
                    </Typography>
                    <Typography fontSize={13} color="#484848">
                      {company.groupData
                        ? `${company.groupData.name} (${company.groupData.country})`
                        : " - "}
                    </Typography>
                  </RowBetween>
                </div>

                <div style={{ width: "48%" }}>
                  <RowBetween>
                    <Typography fontSize={13} style={{ color: "#363636" }}>
                      Phone number
                    </Typography>
                    <Typography fontSize={13} color="#484848">
                      {company.phone}
                    </Typography>
                  </RowBetween>
                  <RowBetween>
                    <Typography fontSize={13} style={{ color: "#363636" }}>
                      Total Employees
                    </Typography>
                    <Typography fontSize={13} color="#484848">
                      {company.total_employees} Employees
                    </Typography>
                  </RowBetween>
                  <RowBetween>
                    <Typography fontSize={13} style={{ color: "#363636" }}>
                      Payment Method
                    </Typography>
                    <Typography fontSize={13} color="#484848">
                      {company.payment
                        ? companiesPaymentOptions.find(
                          (el) => el.value === company.payment
                        ).label
                        : "-"}
                    </Typography>
                  </RowBetween>

                  <RowBetween>
                    <Typography fontSize={13} style={{ color: "#363636" }}>
                      Creation Date
                    </Typography>
                    <Typography fontSize={13} color="#484848">
                      {moment(company.createdAt).format("LL")}
                    </Typography>
                  </RowBetween>
                </div>
              </div>

              {company.mode === "agreement" ? (
                <div>
                  {/** contract info */}
                  {contract && (
                    <div
                      style={{
                        marginTop: 20,
                        width: "100%",
                        display: "flex",
                        alignItems: "stretch",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          width: "48%",
                          borderRight: "0.25px #484848 solid",
                          paddingRight: "2%",
                        }}
                      >
                        <H6 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
                          2. Current Contract Informations
                        </H6>

                        <RowBetween>
                          <Typography fontSize={13}>Period</Typography>
                          <Typography fontSize={13} color="#484848">
                            {moment(contract.startDate).format("LL")} -{" "}
                            {moment(contract.endDate).format("LL")}
                          </Typography>
                        </RowBetween>
                        {(company.payment === "cy24" || company.payment === "cy35") &&
                          <RowBetween>
                            <Typography fontSize={13}>Global Users Subscription</Typography>
                            <Typography fontSize={13} color="#484848">
                              {contract.subsTotal ? `${contract.subsTotal / 100}  €` : "0.00  €"}
                            </Typography>
                          </RowBetween>
                        }
                        <RowBetween>
                          <Typography fontSize={13}>Trails Access</Typography>
                          <Typography fontSize={13} color="#484848">
                            {contract.number_trails} Trails
                          </Typography>
                        </RowBetween>
                        <RowBetween>
                          <Typography fontSize={13}>Users access</Typography>
                          <Typography fontSize={13} color="#484848">
                            {contract.max_users} Users
                          </Typography>
                        </RowBetween>
                        <RowBetween>
                          <Typography fontSize={13}>
                            Flash Coaching Appoitments
                          </Typography>
                          <Typography fontSize={13} color="#484848">
                            {contract.max_coaching} Appointments
                          </Typography>
                        </RowBetween>
                        <RowBetween>
                          <Typography fontSize={13}>E-books</Typography>
                          <Typography fontSize={13} color="#484848">
                            {contract.ebooks} ebooks
                          </Typography>
                        </RowBetween>
                        <RowBetween>
                          <Typography fontSize={13}>Created</Typography>
                          <Typography fontSize={13} color="#484848">
                            {moment(contract.createdAt).format("LL")}
                          </Typography>
                        </RowBetween>
                      </div>
                      <div style={{ width: "48%" }}>
                        {contract.avenants[0] ? (
                          <div>
                            <H6 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
                              Amendments(s)
                            </H6>
                            <RowBetween>
                              <Typography fontSize={13}>
                                Trails Access
                              </Typography>
                              <Typography fontSize={13} color="#484848">
                                {contract.avenants[0].number_trails} Trails
                              </Typography>
                            </RowBetween>
                            <RowBetween>
                              <Typography fontSize={13}>
                                Users access
                              </Typography>
                              <Typography fontSize={13} color="#484848">
                                {contract.avenants[0].users} Users
                              </Typography>
                            </RowBetween>
                            <RowBetween>
                              <Typography fontSize={13}>
                                Flash Coaching Appoitments
                              </Typography>
                              <Typography fontSize={13} color="#484848">
                                {contract.avenants[0].coaching} Appointments
                              </Typography>
                            </RowBetween>
                            <RowBetween>
                              <Typography fontSize={13}>E-books</Typography>
                              <Typography fontSize={13} color="#484848">
                                {contract.avenants[0].ebooks} ebooks
                              </Typography>
                            </RowBetween>
                            <RowBetween>
                              <Typography fontSize={13}>Created</Typography>
                              <Typography fontSize={13} color="#484848">
                                {moment(contract.avenants[0].createdAt).format(
                                  "LL"
                                )}
                              </Typography>
                            </RowBetween>
                          </div>
                        ) : (
                          <div>
                            <H6 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
                              Amendments(s)
                            </H6>
                            <Typography
                              style={{ marginTop: 10 }}
                              fontSize={13}
                              color="#484848"
                            >
                              No Amendments.
                            </Typography>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                // case mode test
                <div style={{ marginTop: 20 }}>
                  <H6 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
                    2. Test Period Informations
                  </H6>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "stretch",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        width: "48%",
                        borderRight: "0.25px #484848 solid",
                        paddingRight: "2%",
                      }}
                    >
                      <RowBetween>
                        <Typography fontSize={13} style={{ color: "#363636" }}>
                          Test Period Start
                        </Typography>
                        <Typography fontSize={13} color="#484848">
                          {company.start_date_test}
                        </Typography>
                      </RowBetween>
                      <RowBetween>
                        <Typography fontSize={13} style={{ color: "#363636" }}>
                          User Access
                        </Typography>
                        <Typography fontSize={13} color="#484848">
                          {company.max_users_test}
                        </Typography>
                      </RowBetween>
                    </div>

                    <div style={{ width: "48%" }}>
                      <RowBetween>
                        <Typography fontSize={13} style={{ color: "#363636" }}>
                          Test Period End
                        </Typography>
                        <Typography fontSize={13} color="#484848">
                          {company.end_date_test}
                        </Typography>
                      </RowBetween>
                      <RowBetween>
                        <Typography fontSize={13} style={{ color: "#363636" }}>
                          Trail Access
                        </Typography>
                        <Typography fontSize={13} color="#484848">
                          {company.number_trails_test}
                        </Typography>
                      </RowBetween>
                    </div>
                  </div>
                </div>
              )}

              {/** user access */}
              <div
                style={{
                  marginTop: 20,
                  width: "100%",
                  display: "flex",
                  alignItems: "stretch",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "48%",
                    borderRight: "0.25px #484848 solid",
                    paddingRight: "2%",
                  }}
                >
                  <H6 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
                    3. Users Slots
                  </H6>

                  <div
                    style={{
                      width: 300,
                      height: 8,
                      borderRadius: 20,
                      backgroundColor: "#d3d3d3",
                      marginTop: 10,
                    }}
                  >
                    <div
                      style={{
                        width:
                          300 * (company.current_users / company.max_users),

                        height: 8,
                        borderRadius: 20,
                        backgroundColor: "#1b4965",
                        position: "absolute",
                      }}
                    ></div>
                  </div>
                  <RowBetween>
                    <Typography style={{ color: "#363636" }} fontSize={13}>
                      Connected Users
                    </Typography>
                    <Typography color="#484848" fontSize={13}>
                      {company.current_users} users ( ≈
                      {(
                        (company.current_users / company.max_users) *
                        100
                      ).toFixed(0)}
                      % )
                    </Typography>
                  </RowBetween>
                  <RowBetween>
                    <Typography style={{ color: "#363636" }} fontSize={13}>
                      Available Slots
                    </Typography>
                    <Typography color="#484848" fontSize={13}>
                      {company.max_users - company.current_users} users
                    </Typography>
                  </RowBetween>
                  <RowBetween>
                    <Typography fontSize={13} style={{ color: "#363636" }}>
                      Total Slots
                    </Typography>
                    <Typography color="#484848" fontSize={13}>
                      {company.max_users} users
                    </Typography>
                  </RowBetween>
                </div>
                <div style={{ width: "48%" }}>
                  <CompanyUsersStat company={company_id} />
                </div>
              </div>
              {/** flash coaching */}
              <div
                style={{
                  marginTop: 20,
                  width: "100%",
                  display: "flex",
                  alignItems: "stretch",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "48%",
                    borderRight: "0.25px #484848 solid",
                    paddingRight: "2%",
                  }}
                >
                  <div>
                    <H6 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
                      4. Flash Coaching (Via Contract)
                    </H6>

                    <div
                      style={{
                        width: 300,
                        height: 8,
                        borderRadius: 20,
                        backgroundColor: "#d3d3d3",
                        marginTop: 10,
                      }}
                    >
                      <div
                        style={{
                          width:
                            300 * (company.current_users / company.max_users),

                          height: 8,
                          borderRadius: 20,
                          backgroundColor: "#1b4965",
                          position: "absolute",
                        }}
                      ></div>
                    </div>
                    <RowBetween>
                      <Typography style={{ color: "#363636" }} fontSize={13}>
                        Used Flash Coaching Appointments
                      </Typography>
                      <Typography fontSize={13} color="#484848">
                        {company.used_coaching} (
                        {company.used_coaching === 0
                          ? "0"
                          : (
                            (company.used_coaching / company.max_coaching) *
                            100
                          ).toFixed(0)}
                        %)
                      </Typography>
                    </RowBetween>
                    <RowBetween>
                      <Typography style={{ color: "#363636" }} fontSize={13}>
                        Available Flash Coaching Appointments
                      </Typography>
                      <Typography fontSize={13} color="#484848">
                        {company.max_coaching - company.used_coaching}{" "}
                      </Typography>
                    </RowBetween>
                    <RowBetween>
                      <Typography style={{ color: "#363636" }} fontSize={13}>
                        Total Flash Coaching Appointments
                      </Typography>
                      <Typography fontSize={13} color="#484848">
                        {company.max_coaching}
                      </Typography>
                    </RowBetween>
                  </div>
                </div>
                <div style={{ width: "48%" }}>
                  <CompanyCoachingStat company={company_id} />
                </div>
              </div>
            </div>
            {/** second page */}
            <div style={{ height: "286mm", position: "relative", marginLeft: 15, marginRight: 15, }}>
              <div
                style={{
                  position: "absolute",
                  bottom: 25,
                  right: 0,
                  borderBottom: "1px #484848 dashed",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Typography color="#444" textAlign="right" fontSize={10}>
                    2/{reportType === "full" ? "4" : "3"}</Typography>
                </div>
                <div>
                  <Typography color="#444" textAlign="right" fontSize={10}>
                    CONFIDENTIAL
                  </Typography>
                  <Typography color="#484848" fontSize={10}>
                    This document contains confidential and proprietary
                    information. Unauthorized distribution is prohibited.
                  </Typography>
                </div>
              </div>
              <Logo
                style={{
                  width: 400,
                  height: 160,
                  alignSelf: "center",
                  position: "absolute",
                  top: "120mm",
                  opacity: 0.25,
                  left: "45mm",
                }}
                src={logo}
                alt="image"
              />
              <div>
                <div
                  style={{
                    marginTop: 20,
                    width: "100%",
                    display: "flex",
                    alignItems: "stretch",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      width: "48%",
                      borderRight: "0.25px #484848 solid",
                      paddingRight: "2%",
                    }}
                  >
                    <CompanyRanksStat company={company_id} />
                  </div>
                  {reportType === "full" ? <div style={{ width: "48%" }}>
                    <CompanyTopTrails company={company_id} />
                  </div> : <div style={{ width: '48%' }}>
                    <CompanyChatEntriesStat reportType={reportType} company={company_id} />
                  </div>}
                </div>
              </div>
              {/* <div
                style={{
                  marginTop: 40,
                  width: "100%",
                  display: "flex",
                  alignItems: "stretch",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "48%",
                    borderRight: "0.25px #484848 solid",
                    paddingRight: "2%",
                  }}
                >
                  <CompanyTopEpisodes company={company_id} />
                </div>
                <div style={{ width: "48%", marginTop: -10 }}>
                  <CompanyEpisodesStats company={company_id} />
                </div>
                </div>*/}

              {reportType === "full" && <div
                style={{
                  marginTop: 40,
                  width: "100%",
                  display: "flex",
                  alignItems: "stretch",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "48%",
                    borderRight: "0.25px #484848 solid",
                    paddingRight: "2%",
                  }}
                >
                  <CompanyTopWorkshops company={company_id} />
                </div>
                <div style={{ width: "48%", marginTop: 10 }}>
                  <CompanyWorkshopsStats company={company_id} />
                </div>
              </div>}

              <div
                style={{
                  marginTop: reportType === "full" ? 40 : 20,
                  width: "100%",
                  display: "flex",
                  alignItems: "stretch",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "48%",
                    borderRight: "0.25px #484848 solid",
                    paddingRight: "2%",
                  }}
                >
                  <CompanyPurchasesUsers reportType={reportType} company={company_id} />
                </div>
                <div style={{ width: "48%", marginTop: 10 }}>
                  <CompanyPurchasesStat company={company_id} />
                </div>
              </div>
              {company.reporting && reportType === "light" && (
                <div style={{ width: "100%", marginTop: reportType === "full" ? 50 : 25 }}>
                  <GlobalEvolution reportType={reportType} company={company} />
                </div>
              )}
              {reportType === "full" && <div
                style={{
                  marginTop: 40,
                  width: "100%",
                  display: "flex",
                  alignItems: "stretch",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "48%",
                    borderRight: "0.25px #484848 solid",
                    paddingRight: "2%",
                  }}
                >
                  <div>
                    <CompanyChatEntriesStat company={company_id} reportType={reportType} />
                  </div>
                </div>
                <div style={{ width: "48%" }}>
                  <CompanyGender company={company_id} />
                </div>
              </div>}
            </div>
            {/** page 3 */}
            {reportType === "full" && <div style={{ height: "286mm", position: "relative", marginLeft: 15, marginRight: 15, }}>
              <div
                style={{
                  position: "absolute",
                  bottom: 40,
                  right: 0,
                  borderBottom: "1px #484848 dashed",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Typography color="#444" textAlign="right" fontSize={10}>
                    3/4</Typography>
                </div>
                <div>
                  <Typography color="#444" textAlign="right" fontSize={10}>
                    CONFIDENTIAL
                  </Typography>
                  <Typography color="#484848" fontSize={10}>
                    This document contains confidential and proprietary
                    information. Unauthorized distribution is prohibited.
                  </Typography>
                </div>
              </div>
              <Logo
                style={{
                  width: 400,
                  height: 160,
                  alignSelf: "center",
                  position: "absolute",
                  top: "120mm",
                  opacity: 0.25,
                  left: "45mm",
                }}
                src={logo}
                alt="image"
              />
              <div
                style={{
                  marginTop: 20,
                  width: "100%",
                  display: "flex",
                  alignItems: "stretch",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "48%",
                    borderRight: "0.25px #484848 solid",
                    paddingRight: "2%",
                  }}
                >
                  <div>
                    <CompanyAge company={company_id} />
                  </div>
                </div>
                <div style={{ width: "48%" }}>
                  <CompanyLanguage company={company_id} />
                </div>
              </div>

              {company.reporting && (
                <div style={{ width: "100%", marginTop: 50 }}>
                  <GlobalEvolution reportType={reportType} company={company} />
                </div>
              )}
            </div>}
            {/** annexe */}
            <div style={{ height: "267mm", position: "relative", marginLeft: 15, marginRight: 15, }}>
              <div
                style={{
                  position: "absolute",
                  bottom: 20,
                  right: 0,
                  borderBottom: "1px #484848 dashed",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Typography color="#444" textAlign="right" fontSize={10}>
                    {reportType === "full" ? '4/4' : "3/3"}</Typography>
                </div>
                <div>
                  <Typography color="#444" textAlign="right" fontSize={10}>
                    CONFIDENTIAL
                  </Typography>
                  <Typography color="#484848" fontSize={10}>
                    This document contains confidential and proprietary
                    information. Unauthorized distribution is prohibited.
                  </Typography>
                </div>
              </div>
              <Logo
                style={{
                  width: 400,
                  height: 160,
                  alignSelf: "center",
                  position: "absolute",
                  top: "120mm",
                  opacity: 0.25,
                  left: "45mm",
                }}
                src={logo}
                alt="image"
              />
              <Annexe />
            </div>
          </div>
        </div>
      </ContentWrapper>
    </Wrapper>
  );
};

export default CompanyStat;
