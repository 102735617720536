import React from "react";
import { H5 } from "../textStyles/style";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import moment from "moment";
import { SelectInput } from "../Inputs";
import { yearsOptionsWithoutAll } from "../../data/SelectOptions";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

const colors = [
    "#A8C492",
    "#1B4965",
    "#FD7064",
    "#00796b",
    "#FFCE63",
    "#94CDDB",
    "#d50000",
    "#d500f9",
    "#fb8c00",
    "#4527a0",
    "#ff5722",
    "#263238",
];

const ChatDuration = ({ data, data2, year, setYear }) => {
    const minDuration =
        data.durationStats.length > 0 ? data.durationStats[0].minDuration : 0;
    const avgDuration =
        data.durationStats.length > 0 ? data.durationStats[0].avgDuration : 0;
    const maxDuration =
        data.durationStats.length > 0 ? data.durationStats[0].maxDuration : 0;

    function formatDuration(milliseconds) {
        // Create a Moment.js duration object from milliseconds
        const duration = moment.duration(milliseconds, "milliseconds");

        // Format the duration as HH:mm:ss
        const formattedDuration = moment
            .utc(duration.as("milliseconds"))
            .format("HH:mm:ss");

        return formattedDuration;
    }

    const totalChats = data2.reduce((total, item) => total + item.count, 0);

    const labels = data2.map((el) => el._id);
    const entires = data2.map((el) => el.count);

    const pieDataHandler = () => {
        const d = {
            labels,
            datasets: [
                {
                    data: entires,
                    backgroundColor: colors,
                },
            ],
        };
        return d;
    };

    return (
        <div style={{ marginTop: 15, width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <H5 style={{ fontSize: 16, textDecoration: "underline" }}>
                    Live Chat Duration Stats
                </H5>
            </div>
            <div>
                <Table style={{ width: "100%" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                <Typography
                                    style={{ fontSize: 13, color: "#1b4965", fontWeight: "600" }}
                                >
                                    Minimum
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography
                                    style={{ fontSize: 13, color: "#1b4965", fontWeight: "600" }}
                                >
                                    Average
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography
                                    style={{ fontSize: 13, color: "#1b4965", fontWeight: "600" }}
                                >
                                    Maximum
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="center">
                                <Typography
                                    style={{ fontSize: 13, color: "#1b4965", fontWeight: "600" }}
                                >
                                    {formatDuration(minDuration.toFixed(0))}
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography
                                    style={{ fontSize: 13, color: "#1b4965", fontWeight: "600" }}
                                >
                                    {formatDuration(avgDuration.toFixed(0))}
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography
                                    style={{ fontSize: 13, color: "#1b4965", fontWeight: "600" }}
                                >
                                    {formatDuration(maxDuration.toFixed(0))}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
            {/** chat per agent */}
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    marginTop: 10,
                }}
            >
                <H5 style={{ fontSize: 16, textDecoration: "underline" }}>
                    Live Chat Per Agent
                </H5>
                <div style={{ width: 100 }}>
                    <SelectInput
                        values={yearsOptionsWithoutAll}
                        placeholder="Year"
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                    />
                </div>
            </div>
            {data2.length === 0 ? <Typography style={{ textAlign: 'center', color: '#484848', marginTop: 20 }}>No DATA</Typography> : <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: 200 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: 100, height: 15 }}>
                                    <Typography
                                        style={{
                                            fontSize: 10,
                                            color: "#1b4965",
                                            fontWeight: "600",
                                        }}
                                    >
                                        Agent
                                    </Typography>
                                </TableCell>
                                <TableCell style={{ width: 100, height: 15 }}>
                                    <Typography
                                        style={{
                                            fontSize: 10,
                                            color: "#1b4965",
                                            fontWeight: "600",
                                        }}
                                    >
                                        %
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {data2.map((el) => {
                                return (
                                    <TableRow key={el._id}>
                                        <TableCell style={{ width: 100, height: 15 }}>
                                            <Typography
                                                style={{
                                                    fontSize: 10,
                                                    color: "#484848",
                                                    fontWeight: "400",
                                                }}
                                            >
                                                {el._id}
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: 100, height: 15 }}>
                                            <Typography
                                                style={{
                                                    fontSize: 10,
                                                    color: "#484848",
                                                    fontWeight: "400",
                                                }}
                                            >
                                                {totalChats === 0
                                                    ? "0%"
                                                    : ((el.count / totalChats) * 100).toFixed(0) + "%"}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
                <div
                    style={{

                        width: 150,
                        marginTop: 10,
                        marginLeft: 15,
                    }}
                >
                    <Pie
                        plugins={[ChartDataLabels]}
                        options={{

                            plugins: {
                                legend: false,
                                datalabels: {
                                    display: (context) =>
                                        context.dataset.data[context.dataIndex] !== 0,
                                    formatter: (value, ctx) => {
                                        let sum = 0;
                                        let dataArr = ctx.chart.data.datasets[0].data;
                                        dataArr.map((data) => {
                                            sum += data;
                                        });
                                        let percentage = ((value * 100) / sum).toFixed(0) + "%";
                                        return percentage;
                                    },
                                    color: "white", // Label color
                                    font: {
                                        weight: "normal", // Label font weight
                                        size: 10
                                    },
                                },
                            },
                        }}
                        data={pieDataHandler()}
                    />
                </div>
            </div>}
        </div>
    );
};

export default ChatDuration;
