import { Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { H6, LoadingIcon } from "../../components/textStyles/style";
import { CommunityRejectionReasons } from "../../data/SelectOptions";

const UserCommunityRejection = ({ user_id }) => {
  const [loader, setLoader] = useState(true);
  const [commentsRejections, setCommentsRejections] = useState([]);
  const [postsRejections, setPostsRejections] = useState([]);
  useEffect(() => {
    setLoader(true);
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    axios
      .get(
        `${process.env.REACT_APP_GET_USER_REJECTIONS}?user_id=${user_id}`,
        config
      )
      .then((res) => {
        setCommentsRejections(res.data.commentsRejections);
        setPostsRejections(res.data.postsRejections);

        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user_id]);
  if (loader) {
    return (
      <div
        style={{
          margin: 15,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingIcon />
      </div>
    );
  }
  return (
    <div style={{ marginTop: 20 }}>
      <H6 style={{ fontSize: 16 }}>Comments rejections</H6>
      {CommunityRejectionReasons.map((reason) => {
        const data = commentsRejections.filter(
          (item) => item.reason === reason.value
        );
        return (
          <div>
            <Typography
              fontWeight="600"
              color={data.length > 1 ? "#c22222" : "#444"}
              fontSize={13}
            >
              {reason.label} : {data.length}
            </Typography>
          </div>
        );
      })}
      <div style={{ marginTop: 20 }}>
        <H6 style={{ fontSize: 16 }}>Posts rejections</H6>
        {CommunityRejectionReasons.map((reason) => {
          const data = postsRejections.filter(
            (item) => item.reason === reason.value
          );
          return (
            <div>
              <Typography
                fontWeight="600"
                color={data.length > 1 ? "#c22222" : "#444"}
                fontSize={13}
              >
                {reason.label} : {data.length}
              </Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UserCommunityRejection;
