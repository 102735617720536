import React from "react";
import { H6 } from "../../components/textStyles/style";
import { Button, Typography } from "@mui/material";
import { sectionQuestionType } from "../../data/SelectOptions";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import AddSectionQuestion from "../../components/modals/AddSectionQuestion";
import { useState } from "react";
import UpdateSectionQuestion from "../../components/modals/UpdateSectionQuestion";
import { Edit } from "@material-ui/icons";

const SectionQuestions = ({ questions, getSectionData, section }) => {
    const [showAddQuestion, setShowAddQuestion] = useState(false);
    const [showUpdateQuestion, setShowUpdateQuestion] = useState(false);
    const [questionToUpdate, setQuestionToUpdate] = useState(null);
    return (
        <div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <div>
                    <H6>Section Questions</H6>
                </div>
                <div>
                    <ButtonPrimary
                        width="240px"
                        onClick={() => {
                            setShowAddQuestion(true);
                        }}
                    >
                        Add new Question
                    </ButtonPrimary>
                </div>
            </div>
            <div>
                {questions.length == 0 ? (
                    <Typography
                        style={{
                            textAlign: "center",
                            fontSize: 16,
                            color: "#484848",
                            marginTop: 20,
                        }}
                    >
                        No questions found.
                    </Typography>
                ) : (
                    <div style={{ marginTop: 20 }}>
                        {questions.map((q, i) => {
                            const answersString = q.answers.map(
                                (el) => `${el.label} =${el.value}`
                            );
                            const type = sectionQuestionType.find(
                                (el) => el.value == q.type
                            ).label;
                            return (
                                <div
                                    key={q._id}
                                    style={{
                                        backgroundColor: "#fff",
                                        marginTop: 15,
                                        padding: 10,
                                        display: 'flex', alignItems: 'center', justifyContent: 'space-between'
                                    }}
                                >
                                    <div>

                                        <div style={{ display: "flex" }}>
                                            <Typography style={{ color: "#1b4965", fontWeight: "600" }}>
                                                Question {i + 1} ({type}) :{" "}
                                            </Typography>

                                            <Typography marginLeft={1}>{q.label}</Typography>
                                        </div>
                                        <div style={{ display: "flex", marginTop: 10 }}>
                                            <Typography
                                                style={{
                                                    color: "#1b4965",
                                                    fontWeight: "600",
                                                    fontSize: 14,
                                                }}
                                            >
                                                Answers :{" "}
                                            </Typography>

                                            <Typography marginLeft={1} style={{ fontSize: 14 }}>
                                                {answersString.join(", ")}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div >
                                        <Button
                                            onClick={() => {
                                                setQuestionToUpdate(q)
                                                setShowUpdateQuestion(true)
                                            }}
                                        >
                                            <Edit />
                                        </Button>
                                    </div>
                                </div>
                            );
                        })}

                    </div>
                )}
            </div>
            {/** modal add question */}
            <AddSectionQuestion
                isOpen={showAddQuestion}
                section={section}
                setIsOpen={setShowAddQuestion}
                getSectionData={getSectionData}
            />
            {/** modal to update question */}
            {questionToUpdate && <UpdateSectionQuestion
                isOpen={showUpdateQuestion}
                section={section}
                setIsOpen={setShowUpdateQuestion}
                getSectionData={getSectionData}
                questionToUpdate={questionToUpdate}
                setQuestionToUpdate={setQuestionToUpdate}
            />}
        </div>
    );
};

export default SectionQuestions;
