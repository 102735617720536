import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  H5,
  LoadingBigIcon,
  SmallText,
  Text,
} from "../../components/textStyles/style";
import axios from "axios";
import {
  AllFAQSubCategories,
  FAQCategories,
  FAQFacturationSubCategories,
  FAQTechniqueSubCategories,
  FAQWorkshopsSubCategories,
  languages,
} from "../../data/SelectOptions";
import { ButtonPrimaryLink } from "../../components/buttonStyles/style";
import { SearchInput, SelectInput } from "../../components/Inputs";
import { PaginationContainer, SearchContainer } from "../app-users/appUsers";
import moment from "moment";
import "moment/locale/fr";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;

const LoadWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
`;

moment.locale("fr");

const FAQs = () => {
  const categories = [
    { label: "Toutes les catégories", value: "" },
    ...FAQCategories,
  ];

  const [loader, setLoader] = useState(false);
  const [trails, setTrails] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesNumber, setPagesNumber] = useState(null);
  const [searchTotal, setSearchTotal] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [language, setLanguage] = useState("fr");
  const [subCategories, setSubCategories] = useState([]);
  const [subCategory, setSubCategory] = useState("");

  const handleCategorieChange = (e) => {
    setSubCategory("");
    setCurrentPage(1);
    setCategory(e.target.value);
    const array =
      e.target.value == "trail"
        ? trails
        : e.target.value == "facturation"
        ? FAQFacturationSubCategories
        : e.target.value == "technique"
        ? FAQTechniqueSubCategories
        : e.target.value == "workshop"
        ? FAQWorkshopsSubCategories
        : [];
    console.log(array);
    setSubCategories(array);
  };

  useEffect(() => {
    const getTrails = async () => {
      setSubCategory("");

      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      const Response = await axios.get(
        `${process.env.REACT_APP_GET_TRAILS}?language=${language}`,
        config
      );
      const t = [];
      await Response.data.map((trail) => {
        t.push({ label: trail.title, value: trail.title });
      });
      setTrails([{ value: "all_trails", label: "Tous les Trails" }, , ...t]);
    };
    getTrails();
  }, [language]);

  const [category, setCategory] = useState("");
  const getData = async () => {
    setLoader(true);
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const url = `${process.env.REACT_APP_FAQS}?language=${language}&pageNumber=${currentPage}&keyword=${keyword}&category=${category}&subCategory=${subCategory}`;

    const Response = await axios.get(url, config);
    const { faqs, page, pages, count } = Response.data;

    setData(faqs);

    setCurrentPage(page);
    setPagesNumber(pages);
    setSearchTotal(count);
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [currentPage, keyword, language, category, subCategory]);

  return (
    <Wrapper>
      <ContentWrapper>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <ButtonPrimaryLink width="260px" to="/add-faq">
            Ajouter FAQ
          </ButtonPrimaryLink>
        </div>
        <H5>Liste des FAQ</H5>
        <hr />

        <SearchContainer style={{ marginBottom: 15 }}>
          {/** language */}
          <div style={{ width: 250 }}>
            <SelectInput
              values={languages}
              placeholder="Langue"
              value={language}
              onChange={(e) => {
                setCurrentPage(1);
                setLanguage(e.target.value);
              }}
            />
          </div>
          {/** catégorie */}
          <div style={{ width: 250 }}>
            <SelectInput
              values={categories}
              placeholder="Catégorie"
              value={category}
              onChange={handleCategorieChange}
            />
          </div>
          {/** sous catégorie */}
          {subCategories.length > 0 && (
            <div style={{ width: 250 }}>
              <SelectInput
                values={subCategories}
                placeholder="Sous catégorie"
                value={subCategory}
                onChange={(e) => {
                  setCurrentPage(1);
                  setSubCategory(e.target.value);
                }}
              />
            </div>
          )}
          {/** keyword */}
          <SearchInput
            onFocus={() => setCurrentPage(1)}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />

          <SmallText>{searchTotal} résultat(s) trouvée(s)</SmallText>
        </SearchContainer>

        {loader ? (
          <div
            style={{
              marginTop: 100,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBigIcon />
          </div>
        ) : (
          <div>
            {data.length > 0 && pagesNumber && (
              <PaginationContainer>
                <Pagination
                  color="primary"
                  count={pagesNumber}
                  page={currentPage}
                  onChange={(e, val) => setCurrentPage(val)}
                />
              </PaginationContainer>
            )}
            {loader ? (
              <LoadWrapper>
                <LoadingBigIcon />
              </LoadWrapper>
            ) : (
              <div style={{ margin: 30 }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableCell
                        style={{ fontWeight: "bold", color: "#1B4965" }}
                      >
                        Question
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: "bold", color: "#1B4965" }}
                      >
                        Réponse
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: "bold", color: "#1B4965" }}
                      >
                        Catégorie
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: "bold", color: "#1B4965" }}
                      >
                        Sous-Catégorie
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: "bold", color: "#1B4965" }}
                      >
                        Date création
                      </TableCell>

                      <TableCell
                        style={{ fontWeight: "bold", color: "#1B4965" }}
                      ></TableCell>
                    </TableHead>
                    <TableBody>
                      {data.length == 0 ? (
                        <div
                          style={{
                            margin: 10,
                          }}
                        >
                          <Text color="#444">0 résultat</Text>
                        </div>
                      ) : (
                        data.map((f) => {
                          const category = FAQCategories.find(
                            (typ) => typ.value === f.category
                          );

                          const subCategory = AllFAQSubCategories.find(
                            (typ) => typ.value === f.subCategory
                          );
                          return (
                            <TableRow key={f._id}>
                              <TableCell style={{ width: 200 }} align="left">
                                <Typography fontWeight="600" fontSize={13}>
                                  {f.question}
                                </Typography>
                              </TableCell>
                              <TableCell style={{ width: 400 }} align="left">
                                <Typography fontWeight="600" fontSize={13}>
                                  {f.answer}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography fontSize={14} color="#1b4965">
                                  {category.label}
                                </Typography>
                              </TableCell>
                              <TableCell style={{ width: 150 }} align="center">
                                <Typography color="#1b4965" fontSize={13}>
                                  {subCategory
                                    ? subCategory.label
                                    : f.subCategory
                                    ? f.subCategory
                                    : "-"}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography fontSize={12}>
                                  {moment(f.createdAt).format("LLL")}
                                </Typography>
                              </TableCell>

                              <TableCell align="left">
                                <ButtonPrimaryLink
                                  to={`/faq/${f._id}`}
                                  width="40px"
                                  height="40px"
                                >
                                  &#8594;
                                </ButtonPrimaryLink>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </div>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default FAQs;
