import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { H5, LoadingBigIcon } from "../../components/textStyles/style";
import axios from "axios";
import {
    ButtonPrimaryLink,

} from "../../components/buttonStyles/style";
import { SelectInput } from "../../components/Inputs";
import { languages } from "../../data/SelectOptions";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
    width: 100vw;
    min-height: ${Height};
    display: flex;
    justify-content: center;
  `;
const ContentWrapper = styled.div`
    background-color: white;
    width: 90%;
    padding: 30px;
    border-radius: 20px;
    margin: 30px 0;
  `;

const LoadWrapper = styled.div`
    width: 100%;
    display: flex;
    margin-top: 50px;
    align-items: center;
    justify-content: center;
  `;

const BilanAnalysis = () => {
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [lng, setLng] = useState('fr')


    const getData = async () => {
        setLoader(true);

        const Response = await axios.get(`${process.env.REACT_APP_QESTIONNAIRES}/get-bilan-analysis`);

        setData(Response.data);

        setLoader(false);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <Wrapper>
            <ContentWrapper>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div style={{ width: 260 }}>
                        <SelectInput
                            values={languages}
                            placeholder="Language"
                            value={lng}
                            onChange={(e) => setLng(e.target.value)}
                        />
                    </div>
                </div>
                <H5>Personal Assessment - Bilan Analysis</H5>
                {loader ? (
                    <LoadWrapper>
                        <LoadingBigIcon />
                    </LoadWrapper>
                ) : (
                    <div style={{ margin: 30 }}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableCell align="center" style={{ fontSize: 12, fontWeight: "bold", color: "#1B4965" }}>
                                        Name
                                    </TableCell>
                                    <TableCell align="center" style={{ fontSize: 12, fontWeight: "bold", color: "#1B4965" }}>
                                        0-33
                                    </TableCell>
                                    <TableCell align="center" style={{ fontSize: 12, fontWeight: "bold", color: "#1B4965" }}>
                                        34-66
                                    </TableCell>
                                    <TableCell align="center" style={{ fontSize: 12, fontWeight: "bold", color: "#1B4965" }}>
                                        67-100
                                    </TableCell>

                                    <TableCell
                                        style={{ fontWeight: "bold", color: "#1B4965" }}
                                    ></TableCell>
                                </TableHead>
                                <TableBody>
                                    {data.map((item) => {

                                        return (
                                            <TableRow key={item._id}>
                                                <TableCell component="th" align="center" style={{ fontSize: 12, color: '#1b4965' }} scope="row">
                                                    {lng === "fr" ? item.name_fr : item.name_en}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Typography style={{ fontSize: 12, color: '#484848' }}>{lng === "fr" ? item.level1.text_fr : item.level1.text_en}</Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Typography style={{ fontSize: 12, color: '#484848' }}>{lng === "fr" ? item.level2.text_fr : item.level2.text_en}</Typography>
                                                </TableCell>

                                                <TableCell align="center">
                                                    <Typography style={{ fontSize: 12, color: '#484848' }}>{lng === "fr" ? item.level3.text_fr : item.level3.text_en}</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <ButtonPrimaryLink
                                                        to={`/update-bilan-analysis/${item._id}`}
                                                        width="40px"
                                                        height="40px"
                                                    >
                                                        &#8594;
                                                    </ButtonPrimaryLink>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}
            </ContentWrapper>
        </Wrapper>
    );
};

export default BilanAnalysis;
