import React from "react";
import { Typography } from "@mui/material";
import { H5 } from "../../../components/textStyles/style";
import { RowBetween } from "../CompanyStat";

const ExamplePurchases = () => {

    const responseData = {
        "ebooks": 11,
        "trails": 6,
        "coaching": 8
    }

    return (
        <div>
            <H5 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
                8. Users Purchases
            </H5>

            <RowBetween>
                <Typography fontSize={13} color="#484848">
                    Trails
                </Typography>
                <Typography fontSize={13} color="#484848">
                    {responseData.trails} Purchase(s)
                </Typography>
            </RowBetween>
            <RowBetween>
                <Typography fontSize={13} color="#484848">
                    Flash Coaching
                </Typography>
                <Typography fontSize={13} color="#484848">
                    {responseData.coaching} Purchase(s)
                </Typography>
            </RowBetween>
            <RowBetween>
                <Typography fontSize={13} color="#484848">
                    E-books
                </Typography>
                <Typography fontSize={13} color="#484848">
                    {responseData.ebooks} Purchase(s)
                </Typography>
            </RowBetween>
        </div>
    );
};

export default ExamplePurchases;
