import styled from "styled-components";
import { Link } from "react-router-dom";
export const SwiperWrapper = styled.div`
  width: 100%;
  min-height: 350px;
  background-color: white;
  box-sizing: border-box;
  padding: 0 clamp(20px, 10vw, 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: visible;
  position: relative;
  z-index: ${({ isontop }) => (isontop ? 3 : 2)};
`;

export const TitleWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0px 20px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding: 12px;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 20px 0 35px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  scrollbar-width: thin;

  @media screen and (max-width: 768px) {
    padding: 12px 0 27px 12px;
  }
`;

export const Content = styled.div`
  overflow: visible;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const RollOverWrapper = styled.div`
  position: absolute;
  width: 370px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: white;
  -webkit-box-shadow: 2px 8px 13px 0px #00000050;
  box-shadow: 2px 8px 13px 0px #00000050;
  height: ${({ active }) => (active ? "180px" : "0")};
  top: 100%;
  transition: all 0.3s ease-in-out;
  z-index: 20;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    width: 270px;
  }
`;

export const SwiperItemContainer = styled(Link)`
  text-decoration: none;
  height: 240px;
  width: 370px;
  border-radius: 20px;
  border-bottom-left-radius: ${({ active }) => (active ? "0px" : "20px")};
  border-bottom-right-radius: ${({ active }) => (active ? "0px" : "20px")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-right: ${({ marginright }) => (marginright ? "30px" : "0px")};

  user-select: none;
  background: linear-gradient(rgba(27, 73, 101, 0), #1b4965 40%);
  transition: ${({ active }) => (active ? "none" : "all 0.7s ease-in-out")};
  @media screen and (max-width: 768px) {
    width: 270px;
  }
`;

export const ItemTopSection = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const Logo = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
`;

export const NewWrapper = styled.div`
  box-sizing: border-box;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 35px;
`;

export const ItemBottomSection = styled.div`
  width: 100%;
  position: relative;
  padding: 20px 38px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 768px) {
    padding: 20px 24px;
  }
`;

export const SwiperBackground = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
`;

export const SwiperRollWrapper = styled.div`
  position: relative;
  border-radius: 20px;
  width: 100%;
  height: auto;
`;

export const RollContainer = styled.div`
  width: 350px;

  max-height: 0;
  word-wrap: break-word;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 5px;
  padding-left: 10px;
  position: relative;
  @media screen and (max-width: 768px) {
    width: 250px;
  }
`;

export const AddItemImage = styled.img`
  text-decoration: none;
  height: 240px;
  width: 370px;
  border-radius: 20px;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    width: 270px;
  }
`;

export const AddItemContainer = styled.button`
  text-decoration: none;
  height: 240px;
  width: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 0px;
  cursor: pointer;
  background: linear-gradient(rgba(27, 73, 101, 0), #1b4965 40%);
  @media screen and (max-width: 768px) {
    width: 270px;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  gap: 10px;

  border-radius: 20px;
`;

export const RollContent = styled.div`
  margin-top: 10px;
`;
