import { Avatar, Dialog, DialogContent, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  H2,
  H4,
  H5,
  H7,
  LoadingBigIcon,
  LoadingIcon,
} from "../../components/textStyles/style";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../components/buttonStyles/style";
import { Input, SelectInput } from "../../components/Inputs";
import { CommunityRejectionReasons } from "../../data/SelectOptions";
import Comment from "./comment";
import { ChipText } from "../contacts";
import moment from "moment";
import "moment/locale/fr";
moment.locale("fr");

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
  margin-top: 10px;
`;

const RowBetween = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const RowCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const RowRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Post = () => {
  const [loader, setLoader] = useState(true);
  const [answer, setAnswer] = useState("");
  const history = useHistory();
  const [post, setPost] = useState(null);
  const [comments, setComments] = useState([]);
  const { post_id } = useParams();
  const [approveLoader, setApproveLoader] = useState(false);
  const [rejectLoader, setRejectLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [closeLoader, setCloseLoader] = useState(false);
  const [answerLoader, setAnswerLoader] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };

      // GET POST DATA
      const postResponse = await axios.get(
        `${process.env.REACT_APP_COMMUNITY_POST}?post_id=${post_id}`,
        config
      );

      setPost(postResponse.data);

      // GET COMMENTS DATA
      const commentsResponse = await axios.get(
        `${process.env.REACT_APP_COMMUNITY_COMMENTS}?post_id=${post_id}`,

        config
      );
      //  console.log("comments", commentsResponse.data.comments[0]);
      setComments(commentsResponse.data.comments);

      setLoader(false);
    };
    getData();
  }, []);

  if (loader) {
    return (
      <Wrapper style={{ alignItems: "center" }}>
        <LoadingBigIcon />
      </Wrapper>
    );
  }

  const user = post.user_data[0] || {};
  const trail = post.trail_data[0] || {};
  const image = user.image
    ? `${process.env.REACT_APP_BASE_URL}${user.image}`
    : "https://www.pngmart.com/files/21/Account-Avatar-Profile-PNG-Photo.png";

  const closePostHandler = async () => {
    if (!closeLoader) {
      setCloseLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      await axios.put(
        `${process.env.REACT_APP_COMMUNITY_POST}?post_id=${post_id}`,
        {
          access_token: TOKEN,
          closed: true,
          closedAt: new Date(),
        }
      );
      setCloseLoader(false);
      history.go(0);
    }
  };

  const openPostHandler = async () => {
    if (!closeLoader) {
      setCloseLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      await axios.put(
        `${process.env.REACT_APP_COMMUNITY_POST}?post_id=${post_id}`,
        {
          access_token: TOKEN,
          closed: false,
          closedAt: null,
        }
      );
      setCloseLoader(false);
      history.go(0);
    }
  };

  const approveHandler = async () => {
    if (!approveLoader && !rejectLoader) {
      setApproveLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      await axios.put(
        `${process.env.REACT_APP_COMMUNITY_POST}?post_id=${post_id}`,
        {
          access_token: TOKEN,
          status: "active",
          reason: "",
          verifiedAt: new Date(),
          rejectedAt: null,
          rejectedBy: null,
        }
      );
      setApproveLoader(false);
      history.go(0);
    }
  };

  const rejectHandler = async () => {
    if (!approveLoader && !rejectLoader && reason.length > 1) {
      setRejectLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      axios
        .post(
          `${process.env.REACT_APP_COMMUNITY_REJECT_POST}?post_id=${post_id}`,
          {
            access_token: TOKEN,
            firstname: user.firstname,
            lastname: user.lastname,
            reason,
            trail: trail.title,
            email: user.email,
          }
        )
        .then(() => {
          setRejectLoader(false);
          history.go(0);
        })
        .catch((err) => {
          setRejectLoader(false);
          console.log(err);
        });
    }
  };

  const answerPostHandler = async () => {
    if (!answerLoader) {
      setAnswerLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      await axios.post(`${process.env.REACT_APP_COMMUNITY_COMMENTS}`, {
        access_token: TOKEN,
        comment: answer,
        post_id: post_id,
      });
      setAnswerLoader(false);
      history.go(0);
    }
  };

  return (
    <Wrapper>
      <ContentWrapper>
        {/** close || open post */}
        <RowRight>
          <div style={{ width: 240 }}>
            {post.closed ? (
              <ButtonPrimary onClick={openPostHandler} width={240}>
                {closeLoader ? (
                  <LoadingIcon color="#fff" />
                ) : (
                  "Ouvrir la publication"
                )}
              </ButtonPrimary>
            ) : (
              <ButtonPrimary onClick={closePostHandler} width={240}>
                {closeLoader ? (
                  <LoadingIcon color="#fff" />
                ) : (
                  "Clôturer la publication"
                )}
              </ButtonPrimary>
            )}
          </div>
        </RowRight>
        <H5>
          Publication -{" "}
          {post.status == "pending"
            ? "Non publiée"
            : post.status == "active"
            ? "Approuvée"
            : "Rejetée"}
        </H5>
        <H7>{`Trail : ${post.trail_data[0].title} - ${post.season.label}`}</H7>
        <div style={{ width: 240 }}>
          <ChipText
            style={{ padding: 10 }}
            success={!post.closed}
            danger={post.closed}
          >
            {post.closed ? "Publication cloturée" : "Publication ouverte"}
          </ChipText>
          {post.closed && (
            <Typography
              style={{ color: "#444" }}
              fontSize={13}
            >{`Cloturée  le ${moment(post.closedAt).format(
              "LLL"
            )}`}</Typography>
          )}
        </div>
        {/** post */}
        <div style={{ width: "100%" }}>
          {/** top post */}

          <div
            style={{
              marginBottom: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              border: "1 black solid",
            }}
          >
            <Typography
              style={{ color: "#444" }}
              fontSize={13}
            >{`Crée  le ${moment(post.createdAt).format("LLL")}`}</Typography>
            {post.verifiedAt && (
              <Typography
                style={{ color: "#444" }}
                fontSize={13}
              >{`Approuvé  le ${moment(post.verifiedAt).format(
                "LLL"
              )}`}</Typography>
            )}
            {post.rejectedAt && (
              <Typography
                style={{ color: "#444" }}
                fontSize={13}
              >{`Rejetée  le ${moment(post.rejectedAt).format(
                "LLL"
              )}`}</Typography>
            )}
          </div>

          <RowBetween>
            {/** left section */}
            <Link
              style={{ color: "#1b4965", fontSize: 13, display: "flex" }}
              target="_blank"
              to={`/app-user/${user._id}`}
            >
              <div>
                <Avatar
                  style={{ width: 80, height: 80, marginRight: 10 }}
                  alt="user avatar"
                  src={image}
                />
              </div>
              <div>
                <Typography
                  fontWeight="600"
                  style={{ color: "#1b4965", fontSize: 15 }}
                >
                  {user.firstname} {user.lastname}
                </Typography>
                <Typography style={{ color: "#444", fontSize: 13 }}>
                  {user.pseudo}
                </Typography>
                <Typography
                  fontWeight="600"
                  style={{ color: "#444", fontSize: 14 }}
                >
                  {user.email}
                </Typography>
              </div>
            </Link>
            {/** right section */}
            <div>
              <div
                style={{
                  marginTop: 15,
                  display: "flex",
                }}
              >
                {post.status !== "active" && (
                  <div style={{ width: 240 }}>
                    <ButtonPrimary onClick={approveHandler} width={240}>
                      {approveLoader ? (
                        <LoadingIcon color="#fff" />
                      ) : (
                        "Approuver la publication"
                      )}
                    </ButtonPrimary>
                  </div>
                )}
                {post.status !== "rejected" && (
                  <div style={{ width: 240, marginLeft: 10 }}>
                    <ButtonSecondary
                      onClick={() => setIsOpen(true)}
                      width={240}
                    >
                      Rejeter la publication
                    </ButtonSecondary>
                  </div>
                )}
              </div>
            </div>
          </RowBetween>
          <hr />
          {/*/ title + text*/}
          <Typography style={{ marginTop: 30, color: "#222", fontSize: 15 }}>
            {post.title}
          </Typography>
          <Typography
            style={{
              marginTop: 15,
              marginBottom: 30,
              color: "#444",
              fontSize: 15,
            }}
          >
            {post.text}
          </Typography>
        </div>
        <hr style={{ marginTop: 20, marginBottom: 20 }} />
        {/** reponse by moderator */}
        <RowBetween style={{ alignItems: "flex-end" }}>
          <Input
            placeholder="Commenter la publication en tant que modérateur ..."
            multiline
            rows={5}
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          />
          <div style={{ width: 240 }}>
            <ButtonPrimary
              onClick={answerPostHandler}
              width={240}
              style={{ marginLeft: 15, marginRight: 15 }}
            >
              {answerLoader ? <LoadingIcon color="#fff" /> : "Commenter"}
            </ButtonPrimary>
          </div>
        </RowBetween>
        {/** comments */}
        {comments.length === 0 ? (
          <RowCenter style={{ width: "100%" }}>
            <H2 style={{ textAlign: "center", fontSize: 14, marginTop: 20 }}>
              No Comments ...
            </H2>
          </RowCenter>
        ) : (
          <div style={{ width: "95%", marginLeft: "2.5%" }}>
            {comments.map((comment) => {
              return (
                <Comment
                  key={comment._id}
                  comment={comment}
                  trail={trail.title}
                />
              );
            })}
          </div>
        )}
      </ContentWrapper>

      {/** dialog rejection post */}
      <Dialog fullWidth maxWidth="sm" open={isOpen}>
        <DialogContent>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <H4 style={{ fontSize: 20, marginTop: 20, marginBottom: 20 }}>
              Rejeter la publication
            </H4>
            <SelectInput
              onChange={(e) => setReason(e.target.value)}
              value={reason}
              placeholder="Raison de rejet"
              values={CommunityRejectionReasons}
            />
            <RowCenter style={{ gap: 15 }}>
              <div style={{ width: 250 }}>
                <ButtonPrimary
                  width={250}
                  onClick={rejectHandler}
                  style={{ marginTop: 30 }}
                >
                  {rejectLoader ? (
                    <LoadingIcon color="#fff" />
                  ) : (
                    "Rejeter la publication"
                  )}
                </ButtonPrimary>
              </div>
              <div style={{ width: 250 }}>
                <ButtonSecondary
                  width={250}
                  onClick={() => {
                    if (!rejectLoader) {
                      setIsOpen(false);
                      setReason("");
                    }
                  }}
                  style={{ marginTop: 30 }}
                >
                  Fermer
                </ButtonSecondary>
              </div>
            </RowCenter>
          </div>
        </DialogContent>
      </Dialog>
    </Wrapper>
  );
};

export default Post;
