import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Check, Delete, HighlightOffSharp, Restore } from "@material-ui/icons";
import { useState } from "react";
import { H4, LoadingIcon } from "../../components/textStyles/style";
import { SelectInput } from "../../components/Inputs";
import { CommunityRejectionReasons } from "../../data/SelectOptions";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../components/buttonStyles/style";
import axios from "axios";
import { ChipText } from "../contacts";
import moment from "moment";
import "moment/locale/fr";
moment.locale("fr");

const RowBetween = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const RowCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Comment = ({ comment, trail }) => {
  const [rejectLoader, setRejectLoader] = useState(false);
  const [approveLoader, setApproveLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [reason, setReason] = useState("");
  const history = useHistory();

  const deleteModeratorComment = async () => {
    if (!rejectLoader) {
      setRejectLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      axios
        .put(
          `${process.env.REACT_APP_COMMUNITY_COMMENT}?comment_id=${comment._id}`,
          {
            access_token: TOKEN,
            deletedAt: new Date(),
          }
        )
        .then(() => {
          setRejectLoader(false);
          history.go(0);
        })
        .catch((err) => {
          setRejectLoader(false);
          console.log(err);
        });
    }
  };

  const restoreModeratorCommentHandler = async () => {
    if (!rejectLoader) {
      setRejectLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      axios
        .put(
          `${process.env.REACT_APP_COMMUNITY_COMMENT}?comment_id=${comment._id}`,
          {
            access_token: TOKEN,
            deletedAt: null,
          }
        )
        .then(() => {
          setRejectLoader(false);
          history.go(0);
        })
        .catch((err) => {
          setRejectLoader(false);
          console.log(err);
        });
    }
  };

  if (comment.moderator) {
    return (
      <div
        style={{
          marginTop: 15,
          boxShadow: "1px 2px 9px #00000030",
          padding: 15,
          borderRadius: 10,
        }}
      >
        <RowBetween>
          <div style={{ display: "flex" }}>
            <div>
              <Avatar
                style={{ width: 50, height: 50, marginRight: 10 }}
                alt="user avatar"
                src="https://media.licdn.com/dms/image/D4E0BAQGGWTLrNDkTqg/company-logo_200_200/0/1665849058010?e=1683763200&v=beta&t=7Ci23K1-9_hPbstbCs3DnmEbxTKcQRDQZCgxtCYJOfg"
              />
            </div>
            <div>
              <Typography
                fontWeight="600"
                style={{ color: "#1b4965", fontSize: 13, marginTop: 15 }}
              >
                LISL UP Moderator
              </Typography>
              <Typography style={{ color: "#444", fontSize: 12 }}></Typography>
              <Typography
                fontWeight="600"
                style={{ color: "#444", fontSize: 12 }}
              ></Typography>
            </div>
          </div>
          <div>
            <Typography
              style={{ color: "#444" }}
              fontSize={13}
            >{`Crée  le ${moment(comment.createdAt).format(
              "LLL"
            )}`}</Typography>
            {comment.deletedAt && (
              <Typography
                style={{ color: "#c92222" }}
                fontWeight="700"
                fontSize={13}
              >{`Supprimé  le ${moment(comment.deletedAt).format(
                "LLL"
              )}`}</Typography>
            )}
          </div>
        </RowBetween>
        {/** comment text */}
        <Typography
          style={{
            margin: 12,
            color: "#444",
            fontSize: 15,
          }}
        >
          {comment.comment}
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {comment.deletedAt ? (
            <Button
              style={{ textTransform: "capitalize" }}
              onClick={restoreModeratorCommentHandler}
            >
              {approveLoader ? (
                <LoadingIcon color="#1b4965" />
              ) : (
                <Restore style={{ marginRight: 3 }} />
              )}
              Restaurer
            </Button>
          ) : (
            <Button
              style={{ textTransform: "capitalize" }}
              onClick={deleteModeratorComment}
            >
              {rejectLoader ? (
                <LoadingIcon color="#1b4965" />
              ) : (
                <Delete style={{ marginRight: 3 }} />
              )}
              Supprimer
            </Button>
          )}
        </div>
      </div>
    );
  }

  const user = comment.user_data[0];
  const image = user.image
    ? `${process.env.REACT_APP_BASE_URL}${user.image}`
    : "https://www.pngmart.com/files/21/Account-Avatar-Profile-PNG-Photo.png";

  const approveHandler = async () => {
    if (!approveLoader) {
      setApproveLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      axios
        .put(
          `${process.env.REACT_APP_COMMUNITY_COMMENT}?comment_id=${comment._id}`,
          {
            access_token: TOKEN,
            status: "active",
            reason: "",
            rejectedAt: null,
            rejectedBy: null,
          }
        )
        .then(() => {
          setApproveLoader(false);
          history.go(0);
        })
        .catch((err) => {
          setApproveLoader(false);
          console.log(err);
        });
    }
  };

  const rejectHandler = async () => {
    if (!rejectLoader && reason.length > 1) {
      setRejectLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      const data = {
        access_token: TOKEN,
        firstname: user.firstname,
        lastname: user.lastname,
        reason,
        trail,
        email: user.email,
      };
      axios
        .post(
          `${process.env.REACT_APP_COMMUNITY_REJECT_COMMENT}?comment_id=${comment._id}`,
          data
        )
        .then(() => {
          setRejectLoader(false);
          history.go(0);
        })
        .catch((err) => {
          setRejectLoader(false);
          console.log(err);
        });
    }
  };

  return (
    <div
      style={{
        marginTop: 15,
        boxShadow: "1px 2px 9px #00000030",
        padding: 15,
        borderRadius: 10,
      }}
    >
      <RowBetween>
        {/** left section */}
        <Link
          style={{ color: "#1b4965", fontSize: 13, display: "flex" }}
          target="_blank"
          to={`/app-user/${user._id}`}
        >
          <div>
            <Avatar
              style={{ width: 50, height: 50, marginRight: 10 }}
              alt="user avatar"
              src={image}
            />
          </div>
          <div>
            <Typography
              fontWeight="600"
              style={{ color: "#1b4965", fontSize: 13 }}
            >
              {user.firstname} {user.lastname}
            </Typography>
            <Typography style={{ color: "#444", fontSize: 12 }}>
              {user.pseudo}
            </Typography>
            <Typography
              fontWeight="600"
              style={{ color: "#444", fontSize: 12 }}
            >
              {user.email}
            </Typography>
          </div>
        </Link>
        {/** right section */}
        <div
          style={{
            alignItems: "flex-end",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            style={{ color: "#444" }}
            fontSize={13}
          >{`Crée  le ${moment(comment.createdAt).format("LLL")}`}</Typography>
          {comment.status == "rejected" ? (
            <Typography
              style={{ color: "#444" }}
              fontSize={13}
            >{`Rejeté  le ${moment(comment.rejectedAt).format(
              "LLL"
            )}`}</Typography>
          ) : (
            <Typography
              style={{ color: "#444" }}
              fontSize={13}
            >{``}</Typography>
          )}
          {comment.status == "rejected" ? (
            <ChipText danger>Rejeté</ChipText>
          ) : (
            <></>
          )}
          <Typography
            style={{ color: comment.reports > 0 ? "#e35340" : "#75c957" }}
            fontSize={13}
            fontWeight="600"
          >{`Reported : ${comment.reports} times`}</Typography>
        </div>
      </RowBetween>
      {/** comment text */}
      <Typography
        style={{
          margin: 12,
          color: "#444",
          fontSize: 15,
        }}
      >
        {comment.comment}
      </Typography>

      {/** buttons */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {comment.status == "rejected" ? (
          <Button
            style={{ textTransform: "capitalize" }}
            onClick={approveHandler}
          >
            <Restore style={{ marginRight: 3 }} /> Restaurer
          </Button>
        ) : (
          <RowCenter>
            <Button
              style={{ textTransform: "capitalize" }}
              onClick={() => setIsOpen(true)}
            >
              <HighlightOffSharp style={{ marginRight: 3 }} /> Rejeter
            </Button>
          </RowCenter>
        )}
      </div>
      {/** dialog rejection comment */}
      <Dialog fullWidth maxWidth="sm" open={isOpen}>
        <DialogContent>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <H4 style={{ fontSize: 20, marginTop: 20, marginBottom: 20 }}>
              Rejeter le commentaire
            </H4>
            <SelectInput
              onChange={(e) => setReason(e.target.value)}
              value={reason}
              placeholder="Raison de rejet"
              values={CommunityRejectionReasons}
            />
            <RowCenter style={{ gap: 15 }}>
              <div style={{ width: 250 }}>
                <ButtonPrimary
                  width={250}
                  onClick={rejectHandler}
                  style={{ marginTop: 30 }}
                >
                  {rejectLoader ? (
                    <LoadingIcon color="#fff" />
                  ) : (
                    "Rejeter le commentaire"
                  )}
                </ButtonPrimary>
              </div>
              <div style={{ width: 250 }}>
                <ButtonSecondary
                  width={250}
                  onClick={() => {
                    if (!rejectLoader) {
                      setIsOpen(false);
                      setReason("");
                    }
                  }}
                  style={{ marginTop: 30 }}
                >
                  Fermer
                </ButtonSecondary>
              </div>
            </RowCenter>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Comment;
