import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  H4,
  H6,
  LoadingBigIcon,
  LoadingIcon,
  Text,
  TextBold,
} from "../../components/textStyles/style";
import { Checkbox, FormControlLabel } from "@mui/material";
import styled from "styled-components";
import { Input, SelectInput } from "../../components/Inputs";
import { questionType } from "../../data/SelectOptions";
import AnswersInput from "./AnswersInput";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import axios from "axios";

const MySwal = withReactContent(Swal);

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
  margin-top: 0px;
`;

const LoadWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
`;

const UpdateQuestion = () => {
  const [q, setQ] = useState("");
  const history = useHistory();
  const [type, setType] = useState("");
  const [answers, setAnswers] = useState([]);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [lastSeason, setLastSeason] = useState(false);
  const [condition, setCondition] = useState("");
  const { question_id } = useParams();
  const [loader, setLoader] = useState(true);
  console.log("quesiton", question_id);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_QESTIONNAIRES}/get-question?question_id=${question_id}`
      )
      .then((res) => {
        setType(res.data.type);
        setQ(res.data.question);
        setCondition(res.data.condition ? res.data.condition : "");
        setAnswers(res.data.answers);
        setLoader(false);
        setLastSeason(res.data.last_season ? res.data.last_season : false);
      });
  }, [question_id]);

  const submitHandler = async () => {
    if (q.length < 3 || type === "") {
      MySwal.fire({
        title: <H4>Oops...</H4>,
        html: <Text>Please put a valid data (type,question ,answers)</Text>,
        iconColor: "#FD7064",
        icon: "error",
        confirmButtonText: <Text color="white">Try again</Text>,
        confirmButtonColor: "#FD7064",
        confirmButtonAriaLabel: "Try again",
      });
    } else {
      if (!submitLoader) {
        setErrorMessage("");
        setSubmitLoader(true);
        const TOKEN = localStorage.getItem("TOKEN");
        try {
          const data = {
            access_token: TOKEN,
            question_id,
            question: q,
            answers,
            condition,
            type,
          };

          await axios.put(
            `${process.env.REACT_APP_QESTIONNAIRES}/update-question`,
            data
          );
          setSubmitLoader(false);
          history.goBack();
        } catch (error) {
          setSubmitLoader(false);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          console.log(error);
          setErrorMessage("Problem connexion with server");
        }
      }
    }
  };
  return (
    <Wrapper>
      {loader ? (
        <LoadWrapper>
          <LoadingBigIcon />
        </LoadWrapper>
      ) : (
        <ContentWrapper>
          <Row>
            <TextBold color="#c22222">
              {errorMessage ? errorMessage : " "}
            </TextBold>
          </Row>
          <H6>Update Question</H6>
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <FormControlLabel
              style={{ marginRight: 30, marginTop: 10 }}
              control={
                <Checkbox
                  checked={lastSeason}
                  onChange={(e) => {
                    setLastSeason(e.target.checked);
                    axios.put(
                      `${process.env.REACT_APP_QESTIONNAIRES}/toggle-last-season`,
                      { question_id, last_season: e.target.checked }
                    );
                  }}
                  color="success"
                />
              }
              label="Show Question only if last season"
            />
          </div>
          <Row>
            <SelectInput
              values={questionType}
              placeholder="Question Type *"
              value={type}
              onChange={(e) => {
                setType(e.target.value);
              }}
            />
          </Row>
          <Row>
            <Input
              placeholder="Question *"
              value={q}
              onChange={(e) => setQ(e.target.value)}
            />
          </Row>
          <Row>
            <Input
              placeholder="Condition"
              value={condition}
              onChange={(e) => setCondition(e.target.value)}
            />
          </Row>
          <AnswersInput inputs={answers} setInputs={setAnswers} />
          {answers.length >= 2 || type === "free" ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ButtonPrimary onClick={submitHandler}>
                {submitLoader ? (
                  <LoadingIcon color="inherit" />
                ) : (
                  "Save Question"
                )}
              </ButtonPrimary>
            </div>
          ) : null}
        </ContentWrapper>
      )}
    </Wrapper>
  );
};

export default UpdateQuestion;
