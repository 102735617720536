import moment from "moment";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import logo from "../../images/main/green-logo.svg";
import "../../styles/custom-phone-input.css";
import { H5, H6, LoadingBigIcon } from "../../components/textStyles/style";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { ButtonPrimary } from "../../components/buttonStyles/style";
import html2pdf from "html2pdf.js";
import { Logo } from "../../components/header/style";
import { Print } from "@material-ui/icons";
import { lislColors } from "../../styles/colors";
import GroupAge from "./group/GroupAge";
import GroupLanguage from "./group/GroupLanguage";
import GroupGender from "./group/GroupGender";
import GroupChatEntries from "./group/GroupChatEntries";
import GroupGlobalEvolution from "./group/GroupGlobalEvolution";
import Annexe from "./Annexe";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 753px;
  padding: 20px;
  border-radius: 20px;
`;
const LoaderContainer = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const RowBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  border-bottom: 1px #484848 dashed;
  padding-bottom: 2px;
`;

const GroupStat = () => {
  const { group_id } = useParams();
  const [loader, setLoader] = useState(true);
  const [group, setGroup] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [liveChatEntries, setLiveChatEntries] = useState([]);
  const [usersStats, setUsersStats] = useState(null);
  const [chatEntriesByMonth, setChatEntriesByMonth] = useState(null);
  const [contractsAmount, setContractsAmount] = useState([])
  const [avenantsAmount, setAvenantsAmount] = useState([])
  const [trails, setTrails] = useState([])
  const [ebooks, setEbooks] = useState([])
  const [coaching, setCoaching] = useState([])
  useEffect(() => {
    const getGroupData = async () => {
      setLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_COMPANY_STAT}/get-group?group_id=${group_id}`,
        config
      );

      setGroup(response.data.group);
      setCompanies(response.data.companies);
      setLiveChatEntries(response.data.chatEntires);
      setContractsAmount(response.data.contractsAmount)
      setAvenantsAmount(response.data.avenantsAmount)
      setTrails(response.data.trails)
      setEbooks(response.data.ebooks)
      setCoaching(response.data.coaching)

      const usersStatsResponse = await axios.get(
        `${process.env.REACT_APP_COMPANY_STAT}/group-users-stats?group_id=${group_id}`,
        config
      );
      setUsersStats(usersStatsResponse.data);

      const chatEntriesResponse = await axios.get(
        `${process.env.REACT_APP_COMPANY_STAT}/group-chat-entries?group_id=${group_id}`,
        config
      );
      setChatEntriesByMonth(chatEntriesResponse.data);

      setLoader(false);
    };
    getGroupData();
  }, [group_id]);

  const sumOfChatEntries = liveChatEntries.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.count;
  }, 0);


  const sumOfMaxCoaching = companies.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.max_coaching;
  }, 0);

  const sumOfUsedCoaching = companies.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.used_coaching;
  }, 0);

  const sumOfMaxUsers = companies.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.max_users;
  }, 0);

  const sumOfCurrentUsers = companies.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.current_users;
  }, 0);

  const sumOfContractsAmounts = contractsAmount.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.totalSum;
  }, 0);
  const sumOfAvenantsAmounts = avenantsAmount.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.totalSum;
  }, 0);

  const sumOfCoaching = coaching.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.count;
  }, 0);
  const sumOfTrails = trails.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.count;
  }, 0);
  const sumOfEbooks = ebooks.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.count;
  }, 0);

  const totalCaEntries = sumOfContractsAmounts + sumOfAvenantsAmounts


  if (loader || !group) {
    return (
      <LoaderContainer>
        <LoadingBigIcon />
      </LoaderContainer>
    );
  }

  const now = new Date();
  const formattedDate = moment(now).format("DD-MM-YYYY");

  const printHandler = () => {
    const element = document.getElementById("to-print");
    const opt = {
      filename: `${group.name}-${formattedDate}.pdf`,
      margin: 5,

      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf().set(opt).from(element).save();
  };



  const data = [
    "Entity",
    "Start Date",
    "End Date",
    "CA (Entity)",
    "Users Purchases",
  ];

  const data2 = [
    "Entity",
    "Registred Users",
    "Nb. Coaching (Entity)",

    "Top 5 Trails",
    "Chat Entries",
  ];
  // second table
  const RenderSecondTable = ({ company, i }) => {
    const [topTrails, setTopTrails] = useState([])
    useEffect(() => {
      const getData = async () => {

        axios
          .get(
            `${process.env.REACT_APP_COMPANY_TOP_TRAILS}?company_id=${company._id}`
          )
          .then((res) => {
            setTopTrails(res.data);

          });
      };
      getData();
    }, [company]);
    const obj = liveChatEntries.find(
      (el) => el._id === company._id
    );
    const topWatchedTrailsCodes = topTrails.map(el => el._id)

    const numberOfLiveChatVisit = obj ? obj.count : "0";
    return (
      <TableRow key={company._id}>
        <TableCell
          align="center"
          style={{
            fontWeight: "bold",
            color: "#1B4965",
          }}
        >
          <Typography
            style={{ color: "#484848" }}
            fontSize={13}
          >
            {company.name} ({company.mode})
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          style={{
            fontWeight: "bold",
            color: "#1B4965",
          }}
        >
          <Typography
            style={{ color: "#484848" }}
            fontSize={13}
          >
            {company.current_users} /{" "}
            {company.max_users}
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          style={{
            fontWeight: "bold",
            color: "#1B4965",
          }}
        >
          <Typography
            style={{ color: "#484848" }}
            fontSize={13}
          >
            {company.used_coaching} /{" "}
            {company.max_coaching}
          </Typography>
        </TableCell>

        <TableCell
          align="center"
          style={{
            fontWeight: "bold",
            color: "#1B4965",
          }}
        >
          <Typography
            style={{ color: "#484848" }}
            fontSize={12}
          >
            {topWatchedTrailsCodes.length > 0 ? topWatchedTrailsCodes.join(`,\n`) : '-'}
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          style={{
            fontWeight: "bold",
            color: "#1B4965",
          }}
        >
          <Typography
            style={{ color: "#484848" }}
            fontSize={13}
          >
            {numberOfLiveChatVisit}
          </Typography>
        </TableCell>
      </TableRow>)
  }
  // principal ecran
  return (
    <Wrapper>
      <div
        style={{
          position: "absolute",
          right: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <ButtonPrimary onClick={printHandler}>
          <Print />
        </ButtonPrimary>
      </div>
      <ContentWrapper>
        <div>
          {/** general info */}
          <div id="to-print">
            <div>
              {/** page 1 */}
              <div style={{ height: "287mm", position: 'relative', marginLeft: 15, marginRight: 15 }}>
                <div
                  style={{
                    position: "absolute",
                    bottom: 10,
                    right: 0,
                    borderBottom: "1px #484848 dashed",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography color="#444" textAlign="right" fontSize={10}>
                      1/4</Typography>
                  </div>
                  <div>
                    <Typography color="#444" textAlign="right" fontSize={10}>
                      CONFIDENTIAL
                    </Typography>
                    <Typography color="#484848" fontSize={10}>
                      This document contains confidential and proprietary
                      information. Unauthorized distribution is prohibited.
                    </Typography>
                  </div>
                </div>
                <Logo
                  style={{
                    width: 400,
                    height: 160,
                    alignSelf: "center",
                    position: "absolute",
                    top: "120mm",
                    opacity: 0.25,
                    left: "45mm",
                  }}
                  src={logo}
                  alt="image"
                />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <Logo
                      style={{
                        width: 200,
                        height: 80,
                        marginLeft: -20,
                        marginBottom: 5,
                      }}
                      src={logo}
                      alt="image"
                    />

                  </div>
                  <div>


                    <Typography color="#484848" textAlign="right" fontSize={13}>
                      {formattedDate}
                    </Typography>
                    <div style={{ marginTop: 10 }}>
                      <Typography textAlign="right" color={lislColors.black} fontSize={12}>
                        MASSA TRAILS SAS
                      </Typography>
                      <Typography textAlign="right" color="#444" fontSize={10}>
                        47 rue des Batignolles – 75017 PARIS
                      </Typography>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 15,
                  }}
                >
                  <H5 style={{ textAlign: "center", fontSize: 22 }}>
                    Report GROUP - {group.name}
                  </H5>
                </div>

                <div style={{ marginTop: 20 }}>
                  <H6 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
                    1. General Informations
                  </H6>
                  <div style={{ margin: 5 }}>
                    <TableContainer style={{ paddingLeft: 10, margintop: 15 }}>
                      <Table>
                        <TableHead>
                          {data.map((el) => {
                            return (
                              <TableCell
                                width="20%"
                                align="center"
                                style={{
                                  fontWeight: "bold",
                                  color: "#222",
                                  fontSize: 13,
                                }}
                              >
                                {el}
                              </TableCell>
                            );
                          })}
                        </TableHead>
                        <TableBody>
                          {companies.map((company) => {

                            const amountContracts = contractsAmount.find(el => el._id == company._id) || null
                            const amountAvenants = avenantsAmount.find(el => el._id == company._id) || null
                            const totalCaEntries =
                              (amountContracts == null && amountAvenants == null) ? "-" :
                                (amountContracts != null && amountAvenants == null) ? amountContracts.totalSum :
                                  (amountContracts == null && amountAvenants != null) ? amountAvenants.totalSum :
                                    amountContracts.totalSum + amountAvenants.totalSum

                            const trailPurchases = trails.find(el => el._id == company._id) || null
                            const coachingPurchases = coaching.find(el => el._id == company._id) || null
                            const ebooksPurchases = ebooks.find(el => el._id == company._id) || null

                            return (
                              <TableRow key={company._id}>
                                <TableCell
                                  align="center"
                                  style={{
                                    fontWeight: "bold",
                                    color: "#1B4965",
                                  }}
                                >
                                  <Typography
                                    style={{ color: "#484848" }}
                                    fontSize={13}
                                  >
                                    {company.name} ({company.mode})
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    fontWeight: "bold",
                                    color: "#1B4965",
                                  }}
                                >
                                  <Typography
                                    style={{ color: "#484848" }}
                                    fontSize={13}
                                  >
                                    {company.start_date}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    fontWeight: "bold",
                                    color: "#1B4965",
                                  }}
                                >
                                  <Typography
                                    style={{ color: "#484848" }}
                                    fontSize={13}
                                  >
                                    {company.end_date}
                                  </Typography>
                                </TableCell>
                                {/** capital */}
                                <TableCell
                                  align="center"
                                  style={{
                                    fontWeight: "bold",
                                    color: "#1B4965",
                                  }}
                                >
                                  <Typography
                                    style={{ color: "#484848" }}
                                    fontSize={13}
                                  >
                                    {totalCaEntries} €
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    fontWeight: "bold",
                                    color: "#1B4965",
                                  }}
                                >
                                  <Typography
                                    style={{ color: "#484848" }}
                                    fontSize={10}
                                  >
                                    {trailPurchases == null ? 0 : trailPurchases.count} Trail(s) access
                                  </Typography>
                                  <Typography
                                    style={{ color: "#484848" }}
                                    fontSize={10}
                                  >
                                    {coachingPurchases == null ? 0 : coachingPurchases.count} Flash Coaching
                                  </Typography>
                                  <Typography
                                    style={{ color: "#484848" }}
                                    fontSize={10}
                                  >
                                    {ebooksPurchases ? ebooksPurchases.count : 0} Ebooks
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                          {/** total */}
                          <TableRow>
                            <TableCell
                              align="center"
                            >
                              <Typography
                                style={{ color: "#1B4965", fontWeight: 'bold' }}
                                fontSize={13}
                              >
                                TOTAL
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                            >
                              <Typography
                                style={{ color: "#484848" }}
                                fontSize={13}
                              >
                                -
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                            >
                              <Typography
                                style={{ color: "#484848" }}
                                fontSize={13}
                              >
                                -
                              </Typography>
                            </TableCell>
                            {/** capital */}
                            <TableCell
                              align="center"
                            >
                              <Typography
                                style={{ color: "#484848" }}
                                fontSize={13}
                              >
                                {totalCaEntries} €
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                            >
                              <Typography
                                style={{ color: "#484848" }}
                                fontSize={10}
                              >
                                {sumOfTrails} Trail(s) access
                              </Typography>
                              <Typography
                                style={{ color: "#484848" }}
                                fontSize={10}
                              >
                                {sumOfCoaching} Flash Coaching
                              </Typography>
                              <Typography
                                style={{ color: "#484848" }}
                                fontSize={10}
                              >
                                {sumOfEbooks} Ebooks
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <div style={{ margin: 5, marginTop: 10 }}>
                    <TableContainer style={{ paddingLeft: 10, margintop: 15 }}>
                      <Table>
                        <TableHead>
                          {data2.map((el) => {
                            return (
                              <TableCell
                                width="20%"
                                align="center"
                                style={{
                                  fontWeight: "bold",
                                  color: "#222",
                                  fontSize: 13,
                                }}
                              >
                                {el}
                              </TableCell>
                            );
                          })}
                        </TableHead>
                        <TableBody>
                          {companies.map((company, i) => <RenderSecondTable key={company._id} company={company} i={i} />)}
                          {/** total 2 */}
                          <TableRow>
                            <TableCell
                              align="center"
                            >
                              <Typography
                                style={{ color: "#1B4965", fontWeight: 'bold' }}
                                fontSize={13}
                              >
                                TOTAL
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                            >
                              <Typography
                                style={{ color: "#484848" }}
                                fontSize={13}
                              >
                                {sumOfCurrentUsers} / {sumOfMaxUsers}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                            >
                              <Typography
                                style={{ color: "#484848" }}
                                fontSize={13}
                              >
                                {sumOfUsedCoaching} / {sumOfMaxCoaching}
                              </Typography>
                            </TableCell>


                            <TableCell
                              align="center"
                            >
                              <Typography
                                style={{ color: "#484848" }}
                                fontSize={13}
                              >
                                -
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                            >
                              <Typography
                                style={{ color: "#484848" }}
                                fontSize={13}
                              >
                                {sumOfChatEntries}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>

                  </div>
                </div>

                <div style={{ marginTop: 25 }}>

                  <H6 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
                    2. Global Distribution
                  </H6>


                  <div
                    style={{
                      marginTop: 10,
                      width: "100%",
                      display: "flex",
                      alignItems: "stretch",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        width: "48%",
                        borderRight: "0.25px #484848 solid",
                        paddingRight: "2%",
                      }}
                    >
                      <div>
                        <GroupChatEntries
                          chatEntriesByMonth={chatEntriesByMonth}
                        />
                      </div>
                    </div>
                    <div style={{ width: "48%" }}>
                      <GroupGender
                        genders={usersStats.genders}
                        total={usersStats.total}
                      />
                    </div>
                  </div>
                </div>

              </div>
              {/** page 2 */}
              <div style={{ height: "280mm", position: 'relative', marginLeft: 15, marginRight: 15 }}>
                <div
                  style={{
                    position: "absolute",
                    bottom: 25,
                    right: 0,
                    borderBottom: "1px #484848 dashed",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography color="#444" textAlign="right" fontSize={10}>
                      2/4</Typography>
                  </div>
                  <div>
                    <Typography color="#444" textAlign="right" fontSize={10}>
                      CONFIDENTIAL
                    </Typography>
                    <Typography color="#484848" fontSize={10}>
                      This document contains confidential and proprietary
                      information. Unauthorized distribution is prohibited.
                    </Typography>
                  </div>
                </div>
                <Logo
                  style={{
                    width: 400,
                    height: 160,
                    alignSelf: "center",
                    position: "absolute",
                    top: "120mm",
                    opacity: 0.25,
                    left: "45mm",
                  }}
                  src={logo}
                  alt="image"
                />
                <div
                  style={{
                    marginTop: 40,

                    width: "100%",
                    display: "flex",
                    alignItems: "stretch",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      width: "48%",
                      borderRight: "0.25px #484848 solid",
                      paddingRight: "2%",
                    }}
                  >
                    <div>
                      <GroupAge
                        ages={usersStats.ages}
                        total={usersStats.total}
                      />
                    </div>
                  </div>
                  <div style={{ width: "48%" }}>
                    <GroupLanguage
                      lngs={usersStats.languages}
                      total={usersStats.total}
                    />
                  </div>
                </div>
                {group.reporting && <div style={{ marginTop: 30 }}>
                  <GroupGlobalEvolution group={group} />
                </div>}
              </div>
              {/** page 3 */}
              <div style={{ height: "270mm", position: 'relative', marginLeft: 15, marginRight: 15 }}>
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    borderBottom: "1px #484848 dashed",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography color="#444" textAlign="right" fontSize={10}>
                      3/4</Typography>
                  </div>
                  <div>
                    <Typography color="#444" textAlign="right" fontSize={10}>
                      CONFIDENTIAL
                    </Typography>
                    <Typography color="#484848" fontSize={10}>
                      This document contains confidential and proprietary
                      information. Unauthorized distribution is prohibited.
                    </Typography>
                  </div>
                </div>
                <Logo
                  style={{
                    width: 400,
                    height: 160,
                    alignSelf: "center",
                    position: "absolute",
                    top: "120mm",
                    opacity: 0.25,
                    left: "45mm",
                  }}
                  src={logo}
                  alt="image"
                />
                <div>
                  <Annexe></Annexe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </Wrapper>
  );
};

export default GroupStat;
