import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import axios from "axios";
import { H5 } from "../../components/textStyles/style";
import { RowBetween } from "./CompanyStat";

const CompanyTopWorkshops = ({ company }) => {
  const [responseData, setResponseData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      axios
        .get(
          `${process.env.REACT_APP_COMPANY_TOP_WORKSHOPS}?company_id=${company}`
        )
        .then((res) => {
          setResponseData(res.data);

        });
    };
    getData();
  }, [company]);

  return (
    <div>
      <H5 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
        7. Top Watched Workshops
      </H5>
      {responseData.map((item) => {
        const workshop = item.workshopData ? item.workshopData[0] : null;
        return (
          <RowBetween key={item._id}>
            <Typography fontSize={13} color="#444">
              {workshop ? workshop.title : "-"}
            </Typography>
            <Typography fontSize={13} color="#484848">
              {item.count} view(s)
            </Typography>
          </RowBetween>
        );
      })}
    </div>
  );
};

export default CompanyTopWorkshops;
