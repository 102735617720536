import { Email, Person } from "@material-ui/icons";
import {
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import SendEmailModal from "../../../components/modals/SendEmailModal";

const ExpertInfo = ({ expert, appointment_id }) => {
  const [showEmailModal, setShowEmailModal] = useState(false);
  return (
    <>
      <Table>
        <TableHead>
          <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
            Lastname
          </TableCell>
          <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
            Firstname
          </TableCell>
          <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
            Mail
          </TableCell>
          <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
            Phone
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableHead>
        <TableBody>
          {/** destinataire */}
          <TableRow>
            <TableCell>{expert.lastname}</TableCell>
            <TableCell> {expert.firstname}</TableCell>
            <TableCell>{expert.email_pro ? expert.email_pro : " - "}</TableCell>
            <TableCell>{expert.phone_pro ? expert.phone_pro : " - "}</TableCell>

            <TableCell>
              {
                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  <Button
                    onClick={() => {
                      window.open(`/expert/${expert._id}`);
                    }}
                  >
                    <Person />
                  </Button>
                  <Button
                    disabled={expert.email_pro.length < 4}
                    onClick={() => {
                      setShowEmailModal(true);
                    }}
                  >
                    <Email />
                  </Button>
                </ButtonGroup>
              }
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <SendEmailModal
        email={expert.email_pro}
        isOpen={showEmailModal}
        setIsOpen={setShowEmailModal}
        coaching={true}
        expert={true}
        appointment_id={appointment_id}
      />
    </>
  );
};

export default ExpertInfo;
