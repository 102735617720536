import React, { useEffect, useState } from "react";
import { H5 } from "../textStyles/style";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Button, Typography } from "@mui/material";
import axios from "axios";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const EpisodesStat = () => {
  const [select, setSelect] = useState("months");
  const [responseData, setResponseData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [freeResponseData, setFreeResponseData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      axios
        .get(
          `${process.env.REACT_APP_FINISHED_EPISODES_STATS}?select=${select}`
        )
        .then((res) => {
          setResponseData(res.data.map((el) => el.count));

          setLabels(
            res.data.map((el) =>
              select === "days"
                ? `${el._id.day}/${el._id.month}/${el._id.year}`
                : select === "months"
                  ? `${el._id.month}/${el._id.year}`
                  : el._id.year
            )
          );
          axios
            .get(
              `${process.env.REACT_APP_FINISHED_FREE_EPISODES_STATS}?select=${select}`
            )
            .then((freeRes) => {
              setFreeResponseData(freeRes.data.map((el) => el.count));
            });
        });
    };
    getData();
  }, [select]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      datalabels: {
        display: (context) =>
          context.dataset.data[context.dataIndex] !== 0 &&
          context.dataset.label === "Completed Episodes",
        formatter: (value, ctx) => {
          return value;
        },
        anchor: "end",
        align: "left",
        offset: 4,
        color: "#333",
        font: {
          weight: "bold", // Label font weight
          size: 11,
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Completed Episodes",
        data: responseData,
        borderColor: "rgb(123, 150, 101)",
        borderWidth: 3,
        backgroundColor: "rgba(123, 150, 101, 0.5)",
      },
      {
        label: "Free Episodes",
        data: freeResponseData,
        borderColor: "rgb(253, 112, 100)",
        borderWidth: 3,
        backgroundColor: "rgba(253, 112, 100, 0.5)",
      },
      {
        label: "Paid Episodes",
        data: responseData.map((el, i) => el - freeResponseData[i]),
        borderColor: "rgb(27, 73, 101)",
        borderWidth: 3,
        backgroundColor: "rgba(27, 73, 101, 0.5)",
      },
    ],
  };

  return (
    <div style={{ padding: 15 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <H5 style={{ fontSize: 20, textDecoration: "underline" }}>Episodes</H5>

        <div style={{}}>
          <Button
            style={{ marginRight: 5 }}
            onClick={() => setSelect("days")}
            variant="contained"
            color={select !== "days" ? "inherit" : "primary"}
          >
            <Typography fontSize={12}>7 days</Typography>
          </Button>
          <Button
            style={{ marginRight: 5 }}
            onClick={() => setSelect("months")}
            variant="contained"
            color={select !== "months" ? "inherit" : "primary"}
          >
            <Typography fontSize={12}>12 months</Typography>
          </Button>
          <Button
            onClick={() => setSelect("years")}
            variant="contained"
            color={select !== "years" ? "inherit" : "primary"}
          >
            <Typography fontSize={12}>5 years</Typography>
          </Button>
        </div>
      </div>
      <div style={{ marginTop: 20, }}>
        <Line style={{ height: 200 }} plugins={[ChartDataLabels]} options={options} data={data} />
      </div>
    </div>
  );
};

export default EpisodesStat;
