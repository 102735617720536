import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AnswersInput from "./AnswersInput";
import {
  H4,
  H5,
  H6,
  H7,
  LoadingIcon,
  Text,
  TextBold,
} from "../../components/textStyles/style";
import styled from "styled-components";
import { Input, SelectInput } from "../../components/Inputs";
import { questionType } from "../../data/SelectOptions";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import {
  ButtonPrimary,
  ButtonPrimaryLink,
} from "../../components/buttonStyles/style";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const AddQuestion = ({ id }) => {
  const [q, setQ] = useState("");
  const history = useHistory();
  const [type, setType] = useState("");
  const [answers, setAnswers] = useState([]);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [condition, setCondition] = useState("");
  const submitHandler = async () => {
    if (q.length < 3 || type === "") {
      MySwal.fire({
        title: <H4>Oops...</H4>,
        html: <Text>Please put a valid data (type,question ,answers)</Text>,
        iconColor: "#FD7064",
        icon: "error",
        confirmButtonText: <Text color="white">Try again</Text>,
        confirmButtonColor: "#FD7064",
        confirmButtonAriaLabel: "Try again",
      });
    } else {
      if (!submitLoader) {
        setErrorMessage("");
        setSubmitLoader(true);
        const TOKEN = localStorage.getItem("TOKEN");
        try {
          const data = {
            access_token: TOKEN,
            id,
            question: q,
            answers,
            condition,
            type,
          };

          await axios.put(process.env.REACT_APP_QESTIONNAIRES, data);
          setSubmitLoader(false);
          history.go(0);
        } catch (error) {
          setSubmitLoader(false);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          console.log(error);
          setErrorMessage("Problem connexion with server");
        }
      }
    }
  };
  return (
    <div>
      <Divider style={{ marginBottom: 25, marginTop: 40 }} />
      <Row>
        <TextBold color="#c22222">{errorMessage ? errorMessage : " "}</TextBold>
      </Row>
      <H6>Create new Question</H6>
      <Row>
        <SelectInput
          values={questionType}
          placeholder="Question Type *"
          value={type}
          onChange={(e) => {
            setType(e.target.value);
          }}
        />
      </Row>
      <Row>
        <Input
          placeholder="Question *"
          value={q}
          onChange={(e) => setQ(e.target.value)}
        />
        <Input
          placeholder="Condition"
          value={condition}
          onChange={(e) => setCondition(e.target.value)}
        />
      </Row>
      <AnswersInput inputs={answers} setInputs={setAnswers} />
      {answers.length >= 2 || type == "free" ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ButtonPrimary onClick={submitHandler}>
            {submitLoader ? <LoadingIcon color="inherit" /> : "Save Question"}
          </ButtonPrimary>
        </div>
      ) : null}
    </div>
  );
};

const RenderQuestion = ({
  id,
  index,
  item,
  type,
  setDeleteModal,
  setSelectedQuestion,
}) => {
  return (
    <div>
      <div style={{ marginTop: 15 }}>
        <H7>
          Question {index + 1} - {type ? type.label : " - "}
        </H7>
      </div>

      <div
        style={{
          marginTop: 10,
          display: "flex",
        }}
      >
        <div
          style={{
            width: "70%",
            marginRight: 15,
          }}
        >
          <Typography
            style={{ fontSize: 14, fontWeight: "bold", color: "#484848" }}
          >
            Question : {item.question}
          </Typography>
          <Typography
            style={{
              fontSize: 14,
              marginBottom: 5,
              marginTop: 10,
              fontWeight: "bold",
              color: "#484848",
            }}
          >
            Answers :{" "}
          </Typography>
          {item.answers.map((el) => (
            <Typography style={{ marginLeft: 10, fontSize: 14 }} key={el}>
              ▪ {el}
            </Typography>
          ))}
          <div
            style={{
              marginTop: 20,
              marginBottom: 10,
              display: "flex",
              alignItems: "center",
            }}
          >
            {/**onClick={updateConditionHandler} */}

            <ButtonPrimaryLink to={`/update-question/${item._id}`}>
              Modify
            </ButtonPrimaryLink>
            <Button
              style={{ marginLeft: 20 }}
              variant="outlined"
              color="error"
              onClick={() => {
                setSelectedQuestion(item);
                setDeleteModal(true);
              }}
            >
              <DeleteIcon />
            </Button>
          </div>
        </div>
        <div style={{ width: "30%" }}>
          <Input
            disabled
            multiline
            placeholder="Condition..."
            value={item.condition ? item.condition : "-"}
          />
        </div>
      </div>
    </div>
  );
};

const Questions = ({ data, id }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const history = useHistory();
  const deleteHandler = () => {
    if (!deleteLoader) {
      setDeleteLoader(true);
      try {
        axios.delete(
          `${process.env.REACT_APP_QESTIONNAIRES}/delete-question?question_id=${selectedQuestion._id}`
        );
        setDeleteLoader(false);
        setDeleteModal(false);
        history.go(0);
      } catch (error) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(error);

        setDeleteLoader(false);
        setDeleteModal(false);
      }
    }
  };
  if (data.length === 0) {
    return (
      <>
        <Typography textAlign="center">
          No questions created. Please fill some content
        </Typography>
        <AddQuestion id={id} />
      </>
    );
  }
  return (
    <div>
      {data.map((item, index) => {
        const type = questionType.find((el) => el.value === item.type);
        return (
          <div
            key={item._id}
            style={{
              borderTop: "1px #484848 solid",
              padding: 10,
            }}
          >
            <RenderQuestion
              setDeleteModal={setDeleteModal}
              setSelectedQuestion={setSelectedQuestion}
              type={type}
              item={item}
              id={id}
              index={index}
            />
          </div>
        );
      })}
      <AddQuestion id={id} />
      {/** dialog to confirm delete */}
      <Dialog
        open={deleteModal}
        onClose={setDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete this questionnaire?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to permanently delete this question?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "10px" }}>
          <Button onClick={deleteHandler}>
            {deleteLoader ? <LoadingIcon /> : "Delete"}
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setDeleteModal(false);
              setSelectedQuestion(null);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Questions;
