import React, { useEffect, useState } from "react";
import axios from "axios";
import { H5 } from "../../components/textStyles/style";
import { RowBetween } from "./CompanyStat";
import { Typography } from "@mui/material";

const CompanyRanksStat = ({ company }) => {
  const [hikerCount, setHikerCount] = useState(0);
  const [exploratorCount, setExploratorCount] = useState(0);
  const [sherpaCount, setSherpaCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    const getData = async () => {
      axios
        .get(
          `${process.env.REACT_APP_COMPANY_RANKS_STAT}?company_id=${company}`
        )
        .then((res) => {
          setHikerCount(res.data.randonneur);
          setExploratorCount(res.data.explorator);
          setSherpaCount(res.data.sherpa);
          setTotalCount(res.data.total);
        });
    };
    getData();
  }, [company]);

  return (
    <div>
      <H5 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
        5. Users Per Rank
      </H5>

      <RowBetween>
        <Typography style={{ color: "#484848" }} fontSize={13}>
          Hiker Users
        </Typography>
        <Typography fontSize={13} color="#484848">
          {hikerCount}
        </Typography>
      </RowBetween>
      <RowBetween>
        <Typography style={{ color: "#484848" }} fontSize={13}>
          Explorator Users
        </Typography>
        <Typography fontSize={13} color="#484848">
          {exploratorCount}
        </Typography>
      </RowBetween>
      <RowBetween>
        <Typography style={{ color: "#484848" }} fontSize={13}>
          Sherpa Users
        </Typography>
        <Typography fontSize={13} color="#484848">
          {sherpaCount}
        </Typography>
      </RowBetween>
      <RowBetween>
        <Typography style={{ color: "#484848" }} fontSize={13}>
          total Users
        </Typography>
        <Typography fontSize={13} color="#484848">
          {totalCount}
        </Typography>
      </RowBetween>
    </div>
  );
};

export default CompanyRanksStat;
