import React from "react";
import { H5 } from "../../../components/textStyles/style";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";


const ExampleReporting = () => {


    return (
        <div>
            <H5 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "600", }} color="#000">
                13. Global Evolution
            </H5>
            <div style={{ margin: 10, marginTop: 0 }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableCell
                                align="center"
                                style={{
                                    fontWeight: "600",
                                    color: "#444",
                                    fontSize: 13,
                                }}
                            ></TableCell>
                            <TableCell
                                align="center"
                                style={{
                                    fontWeight: "600",
                                    color: "#222",
                                    fontSize: 13,
                                }}
                            >
                                I1
                            </TableCell>
                            <TableCell
                                align="center"
                                style={{
                                    fontWeight: "600",
                                    color: "#222",
                                    fontSize: 13,
                                }}
                            >
                                I2
                            </TableCell>
                            <TableCell
                                align="center"
                                style={{
                                    fontWeight: "600",
                                    color: "#222",
                                    fontSize: 13,
                                }}
                            >
                                I3
                            </TableCell>
                        </TableHead>
                        <TableBody>

                            <TableRow>
                                <TableCell
                                    align="center"
                                    style={{
                                        fontWeight: "600",
                                        color: "#111",
                                        fontSize: 13,
                                    }}
                                >
                                    Stress
                                </TableCell>
                                {/** i1 */}

                                <TableCell align="center" style={{ color: "#484848", fontSize: 13 }}>
                                    100%
                                </TableCell>
                                {/** i2 */}

                                <TableCell align="center" style={{ color: "#484848", fontSize: 13 }}>
                                    50%
                                </TableCell>
                                {/** i3 */}
                                <TableCell align="center" style={{ color: "#484848", fontSize: 13 }}>
                                    100%
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell
                                    align="center"
                                    style={{
                                        fontWeight: "600",
                                        color: "#111",
                                        fontSize: 13,
                                    }}
                                >
                                    Emotional
                                </TableCell>
                                {/** i1 */}

                                <TableCell align="center" style={{ color: "#484848", fontSize: 13 }}>
                                    66.67 %
                                </TableCell>
                                {/** i2 */}

                                <TableCell align="center" style={{ color: "#484848", fontSize: 13 }}>
                                    75%
                                </TableCell>
                                {/** i3 */}
                                <TableCell align="center" style={{ color: "#484848", fontSize: 13 }}>
                                    100%
                                </TableCell>
                            </TableRow>



                            {/** mean  */}
                            <TableRow>
                                <TableCell
                                    align="center"
                                    style={{
                                        fontWeight: "600",
                                        color: "#66854e",
                                        fontSize: 13,
                                    }}
                                >
                                    All Trails
                                </TableCell>
                                {/** i1 */}

                                <TableCell align="center" style={{
                                    fontWeight: "600",
                                    color: "#66854e",
                                    fontSize: 13,
                                }}>
                                    83.33 %
                                </TableCell>
                                {/** i2 */}

                                <TableCell align="center" style={{
                                    fontWeight: "600",
                                    color: "#66854e",
                                    fontSize: 13,
                                }}>
                                    62.5%
                                </TableCell>
                                {/** i3 */}
                                <TableCell align="center" style={{
                                    fontWeight: "600",
                                    color: "#66854e",
                                    fontSize: 13,
                                }}>
                                    100%
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                </TableContainer>
            </div>
        </div>
    );
};

export default ExampleReporting;
