import styled from "styled-components";
import { H5 } from "../textStyles/style";
import axios from "axios";
import icon_post from "../../images/main/icon_home/image26.png";
import icon_rejected_user from "../../images/main/icon_home/image12.png";
import { SelectInput } from "../../components/Inputs";
import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { languagesWithAll } from "../../data/SelectOptions";

const Wrapper = styled.div`
  align-items: left;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
`;

const ElementsContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin: 10px;
  margin-top: 20px;
`;

const ElementContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  padding-top: 10px;
  width: 19%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;
  gap: 10px;
`;

const CommunityStat = () => {
  const [loader, setLoader] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("all");
  const [data, setData] = useState(null);

  const handleChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  useEffect(() => {
    const getData = async () => {
      setLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_GET_COMMUNITY_INFO}`,
          config
        );
        const result = response.data;
        setData(result);
        console.log(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoader(false);
      }
    };

    getData();
  }, []);

  return (
    <Wrapper>
      <div style={{ paddingLeft: 10, paddingRight: 10 }}>
        <H5 style={{ fontSize: 22, textDecoration: "underline" }}>Community</H5>
      </div>
      <ElementsContainer>
        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Banned Users
          </Typography>

          <img height={50} src={icon_rejected_user} alt="Client Icon" />

          <Typography>
            {data && data.userBanned ? data.userBanned : 0}
          </Typography>
        </ElementContainer>

        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Posts
          </Typography>

          <img height={50} src={icon_post} alt="Leads Icon" />

          <Typography>
            {data &&
              data.post &&
              (selectedLanguage === "all" // condition pour "All"
                ? data.post.reduce((acc, cur) => acc + cur.count, 0) // obtenir le nombre total de post
                : data.post.find(
                    (postCount) => postCount._id === selectedLanguage
                  )?.count || 0)}
          </Typography>
          <div style={{ width: "90%", marginBottom: 10 }}>
            <SelectInput
              values={languagesWithAll}
              placeholder="Language"
              value={selectedLanguage}
              onChange={handleChange}
            />
          </div>
        </ElementContainer>
        <ElementContainer
          style={{ backgroundColor: "transparent" }}
        ></ElementContainer>
        <ElementContainer
          style={{ backgroundColor: "transparent" }}
        ></ElementContainer>
        <ElementContainer
          style={{ backgroundColor: "transparent" }}
        ></ElementContainer>
      </ElementsContainer>
    </Wrapper>
  );
};

export default CommunityStat;
