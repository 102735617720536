import { createTheme } from "@mui/material";

const palette = {
  primary: {
    main: "#1B4965",
  },
  grey: {
    900: "#c22222",
  },
  greenTwo: "#88C1A3",
};

export const theme = createTheme({

  palette: palette,
  typography: {
    fontFamily: "Oxygen",
  },
  components: {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          marginRight: 0
        }
      }
    }
    ,
    // Inputs styles
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: `2px solid ${palette.greenTwo}`,
            },
          },
        },
      },
    },
  },
});
