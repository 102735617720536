import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ButtonPrimary, ButtonSecondary } from "../buttonStyles/style";
import { H6, SmallBoldText } from "../textStyles/style";
import axios from "axios";
import { Alert } from "@mui/material";

export default function SendSmsModal({ phone, isOpen, setIsOpen }) {
  const [text, setText] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [submitLoader, setSubmitLoader] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const handleClose = () => {
    setIsOpen(false);
    setErrorMessage("");
    setShowSuccess(false);
  };

  const submitHandler = () => {
    if (!submitLoader) {
      setErrorMessage("");
      setSubmitLoader(true);
      if (text.length < 4) {
        setErrorMessage("message too short");
        setSubmitLoader(false);
      } else {
        const TOKEN = localStorage.getItem("TOKEN");
        axios
          .post(process.env.REACT_APP_SEND_SMS, {
            phone,
            text,
            access_token: TOKEN,
          })
          .then(() => {
            setShowSuccess(true);
            setText("");
            setSubmitLoader(false);
          })
          .catch((err) => {
            setSubmitLoader(false);
            setErrorMessage("Problem of sending, Please check Vonage");
          });
      }
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen}>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 30,
          marginBottom: 10,
        }}
      >
        <H6> Send SMS to {phone}</H6>
      </div>
      {showSuccess && <Alert severity="success"> SMS has been send !</Alert>}
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="smstext"
          label="LISL UP - sms message"
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          fullWidth
          variant="standard"
        />
        <SmallBoldText
          style={{ textAlign: "center", fontSize: 12, color: "#c22222" }}
        >
          {errorMessage ? errorMessage : " - "}
        </SmallBoldText>
      </DialogContent>
      <DialogActions style={{ marginTop: -10, marginBottom: 15 }}>
        {!showSuccess && (
          <ButtonPrimary onClick={submitHandler}>Send</ButtonPrimary>
        )}
        <ButtonSecondary onClick={handleClose}>Close</ButtonSecondary>
      </DialogActions>
    </Dialog>
  );
}
