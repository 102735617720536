import styled from "styled-components";
import { H5 } from "../textStyles/style";
import axios from "axios";
import icon_client from "../../images/main/icon_home/image8.png";
import icon_ActiveUser from "../../images/main/icon_home/image1.png";
import icon_message from "../../images/main/icon_home/image11.png";
import icon_Leads from "../../images/main/icon_home/image33.png";
import icon_www from "../../images/main/icon_home/image20.png";
import icon_paid from "../../images/main/icon_home/image22.png";
import { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@mui/material";

const Wrapper = styled.div`
  align-items: left;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
`;
const RowBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ElementsContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin: 10px;
  margin-top: 20px;
`;

const ElementContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  padding-top: 10px;
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const EarningStat = () => {
  const [loader, setLoader] = useState(false);
  const [language, setLanguage] = useState("all");
  const [year, setYear] = useState(new Date().getFullYear());
  const [data, setData] = useState([]);
  const getData = async () => {
    setLoader(true);
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };

    // will need a proprer API call !

    const Response = await axios.get(
      `${process.env.REACT_APP_GET_EARNING}?year=${year}`,
      config
    );
    const Result = Response.data;

    setData(Result);
    setLoader(false);
  };
  useEffect(() => {
    getData();
  }, [year]);

  return (
    <Wrapper>
      <RowBetween style={{ paddingLeft: 10, paddingRight: 10 }}>
        <H5 style={{ fontSize: 22, textDecoration: "underline" }}>Earning</H5>

        <TextField
          id="year"
          label="Year"
          style={{ width: "150px" }}
          type="number"
          value={year}
          onChange={(e) => setYear(e.target.value)}
        />
      </RowBetween>
      <ElementsContainer>
        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            total revenu
          </Typography>
          <img height={50} src={icon_client} alt="Client Icon" />
          <Typography style={{ textAlign: 'center' }} >users: {data.earning_user}€, contracts: {data.earning_company}€, amendements: {data.earning_company_avenants}€</Typography>
          <Typography color="#1b4965" fontWeight={"800"} fontSize={20} textAlign="center">{data.total_earning}€</Typography>
        </ElementContainer>

        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Total sub
          </Typography>
          <img height={50} src={icon_Leads} alt="Leads Icon" />
          <Typography>{data.totals_sub}</Typography>
        </ElementContainer>

        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Live Chat
          </Typography>
          <img height={50} src={icon_www} alt="Leads Icon" />
          <Typography>{data.chat}</Typography>
        </ElementContainer>

        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Paid Sub B2C / TOTAL B2C
          </Typography>
          <img height={50} src={icon_paid} alt="Leads Icon" />
          <Typography>
            {data.B2CUsersSubs} /{data.B2CUsers} ({(data.B2CUsersSubs / data.B2CUsers).toFixed(2) * 100}%)

          </Typography>
        </ElementContainer>

        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Cancellation
          </Typography>
          <img height={50} src={icon_ActiveUser} alt="Active Icon" />
          <Typography>{data.cancelled}</Typography>
        </ElementContainer>

        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Coaching
          </Typography>
          <img height={50} src={icon_message} alt="Message Icon" />
          <Typography>{data.coaching}</Typography>
        </ElementContainer>
      </ElementsContainer>
    </Wrapper>
  );
};

export default EarningStat;
