import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ButtonSecondary } from "../buttonStyles/style";
import { H6 } from "../textStyles/style";
import MessagesHistory from "../../pages/coaching-appointments/appointment/messagesHistory";
import { Typography } from "@mui/material";

export default function MesssagesModal({ messages, isOpen, setIsOpen }) {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth="xl" open={isOpen}>
      <DialogActions style={{ marginRight: 20, marginTop: 10 }}>
        <ButtonSecondary onClick={handleClose}>Close</ButtonSecondary>
      </DialogActions>
      <DialogContent>
        <div
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            marginTop: -20,
            marginBottom: 20,
          }}
        >
          <H6 style={{ marginLeft: 20 }}>Mails History</H6>
          {messages.length === 0 ? (
            <Typography
              fontWeight="400"
              style={{
                color: "#333",
                fontSize: 15,
                marginLeft: 20,
                marginTop: 15,
              }}
            >
              no mails has been sent.
            </Typography>
          ) : (
            <div>
              <MessagesHistory messages={messages} />
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
