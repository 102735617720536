import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ButtonPrimary, ButtonSecondary } from "../buttonStyles/style";
import { H6, LoadingIcon, SmallBoldText } from "../textStyles/style";
import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import { Input } from "../Inputs";
import { useHistory } from "react-router-dom";
import { FormHelperText } from "@mui/material";
import moment from "moment";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export default function AddAvenants({
  isOpen,
  setIsOpen,
  company_id,
  selectedContract,
}) {
  const [amountSub, setAmountSub] = useState("");
  const [amountCoaching, setAmountCoaching] = useState("");
  const [amountTrails, setAmountTrails] = useState("");
  const [amountEbooks, setAmountEbooks] = useState("");
  const [name, setName] = useState("");
  const [numberTrails, setNumberTrails] = useState("");
  const [ebooks, setEbooks] = useState("");
  const [coaching, setCoaching] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [users, setUsers] = useState("");
  const end = moment(selectedContract.endDate).format("yyyy-MM-DD");
  const [endDate, setEndDate] = useState(end);

  const history = useHistory();

  const handleClose = () => {
    if (!submitLoader) {
      setIsOpen(false);
      setName("");
      setNumberTrails("");
      setErrorMessage("");
      setAmountSub("");
      setAmountTrails("");
      setAmountCoaching("");
      setAmountEbooks("");
      setEbooks("");
    }
  };

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
  };

  const submitHandler = async (event) => {
    if (!submitLoader) {
      if (file == null) {
        setSubmitLoader(false);
        setErrorMessage("Please select a pdf file to upload.");
      } else if (
        amountSub === "" ||
        amountTrails === "" ||
        amountSub === "" ||
        amountEbooks === ""
      ) {
        setSubmitLoader(false);
        setErrorMessage("Please select a valid amounts.");
      } else {
        const fileExtension = file.name.split(".").pop();
        if (fileExtension === "pdf") {
          setSubmitLoader(true);
          event.preventDefault();

          const formData = new FormData();
          formData.append("file", file);
          formData.append("end_date", endDate);
          formData.append("company_id", company_id);
          formData.append("contract_id", selectedContract._id);
          formData.append("number_trails", Number(numberTrails));
          formData.append("coaching", Number(coaching));
          formData.append("users", Number(users));
          formData.append("amount_sub", Number(amountSub));
          formData.append("amount_coaching", Number(amountCoaching));
          formData.append("amount_trails", Number(amountTrails));
          formData.append("amount_ebooks", Number(amountEbooks));
          formData.append("ebooks", ebooks);
          formData.append("name", name);
          try {
            await axios.post(
              `${process.env.REACT_APP_CONTRACTS}/avenants`,
              formData
            );
            history.go(0);
            setSubmitLoader(false);
          } catch (error) {
            setSubmitLoader(false);
            console.log(error);
          }
        } else {
          setSubmitLoader(false);
          setErrorMessage("Wrong Document. Only PDF files are accepted!");
        }
      }
    }
  };
  if (selectedContract == null) {
    return <></>;
  }
  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
      <TitleContainer>
        <H6>Add New Amendment</H6>
      </TitleContainer>

      <DialogContent>
        {/** form */}
        <Row style={{ marginTop: 20 }}>
          <Input
            placeholder="Amendment Reference ..."
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Input
            type="number"
            placeholder="Users access to add *"
            value={users}
            onChange={(e) => setUsers(e.target.value)}
          />
          <Input
            type="number"
            placeholder="Trails access To add *"
            value={numberTrails}
            onChange={(e) => setNumberTrails(e.target.value)}
          />
        </Row>

        <Row style={{ marginTop: 20 }}>
          <Input
            type="number"
            placeholder="Flash Coaching Appointments to add *"
            value={coaching}
            onChange={(e) => setCoaching(e.target.value)}
          />
          <Input
            type="number"
            placeholder="Ebooks To add *"
            value={ebooks}
            onChange={(e) => setEbooks(e.target.value)}
          />
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Input
            type="number"
            placeholder="amount Subscriptions in € (HT)*"
            value={amountSub}
            onChange={(e) => setAmountSub(e.target.value)}
          />
          <Input
            type="number"
            placeholder="amount Trails access in € (HT)*"
            value={amountTrails}
            onChange={(e) => setAmountTrails(e.target.value)}
          />
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Input
            type="number"
            placeholder="amount Flash Coaching in € (HT)*"
            value={amountCoaching}
            onChange={(e) => setAmountCoaching(e.target.value)}
          />
          <Input
            type="number"
            placeholder="amount Ebook in € (HT)*"
            value={amountEbooks}
            onChange={(e) => setAmountEbooks(e.target.value)}
          />
        </Row>

        <div style={{ marginTop: 20 }}>
          <Input id="file" type="file" onChange={handleFileChange} />

          <FormHelperText>Only PDF files are allowed.</FormHelperText>
        </div>

        <SmallBoldText
          style={{ textAlign: "center", fontSize: 12, color: "#c22222" }}
        >
          {errorMessage ? errorMessage : " - "}
        </SmallBoldText>
      </DialogContent>

      <DialogActions style={{ marginTop: -10, marginBottom: 15 }}>
        <ButtonPrimary onClick={submitHandler}>
          {submitLoader ? <LoadingIcon color="#ffffff" /> : "Save"}
        </ButtonPrimary>

        <ButtonSecondary onClick={handleClose}>Close</ButtonSecondary>
      </DialogActions>
    </Dialog>
  );
}
