import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
} from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";

export const SearchInput = ({
  onChange,
  value,
  id,
  onClick,
  onFocus,
  style,
  label,
}) => {
  return (
    /* <OutlinedInput
      label="Search"
      id={id}
      onChange={onChange}
      value={value}
      style={{ backgroundColor: "#ffffff" }}
      endAdornment={
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      }
    />*/
    <FormControl style={style ? style : {}} variant="outlined">
      <InputLabel htmlFor={id}>{label ? label : "Search"}</InputLabel>
      <OutlinedInput
        onFocus={onFocus}
        id={id}
        type="text"
        value={value}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="search button"
              onClick={onClick || null}
              onMouseDown={() => {}}
              edge="end"
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
        label="Search"
      />
    </FormControl>
  );
};

export const Input = ({
  onChange,
  value,
  placeholder,
  multiline,
  type,
  disabled,
  required,
  id,
  rows,
}) => {
  return (
    <TextField
      id={id || null}
      required={required ? true : false}
      disabled={disabled || false}
      label={placeholder}
      type={type || "text"}
      variant="outlined"
      multiline={multiline || false}
      rows={rows || 4}
      size="medium"
      fullWidth
      inputProps={{
        autoComplete: "a",
        form: {
          autoComplete: "off",
        },
      }}
      onChange={onChange}
      value={value}
    />
  );
};

export const InputTextarea = ({
  onChange,
  value,
  placeholder,
  multiline,
  type,
  disabled,
}) => {
  return (
    <TextField
      disabled={disabled || false}
      label={placeholder}
      type={type || "text"}
      variant="outlined"
      multiline={multiline || false}
      rows={10}
      fullWidth
      onChange={onChange}
      value={value}
    />
  );
};

export const SelectInput = ({
  onChange,
  value,
  placeholder,
  values,
  small,
  disabled,
}) => {
  return (
    <TextField
      disabled={disabled || false}
      label={placeholder}
      select
      variant="standard"
      fullWidth
      size={small ? "small" : "medium"}
      onChange={onChange}
      value={value}
    >
      {values.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
