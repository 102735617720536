import {
  Backdrop,
  Button,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  H5,
  LoadingBigIcon,
  SmallText,
} from "../../components/textStyles/style";
import axios from "axios";
import {
  ButtonPrimary,
  ButtonPrimaryLink,
} from "../../components/buttonStyles/style";
import { Image } from "../../components/tables/styles";
import { SearchInput } from "../../components/Inputs";
import moment from "moment";
import { ChipText } from "../contacts";
import { useHistory } from "react-router-dom";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;

const LoadWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
`;

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
`;

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;

  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
`;

const Messages = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesNumber, setPagesNumber] = useState(null);
  const [searchTotal, setSearchTotal] = useState(null);
  const [keyword, setKeyword] = useState("");
  const history = useHistory();

  const markAsShowedHandler = async (id) => {
    const TOKEN = localStorage.getItem("TOKEN");
    axios
      .post(`${process.env.REACT_APP_SHOWED_MESSAGE}?message_id=${id}`, {
        access_token: TOKEN,
      })
      .then(() => {
        history.go(0);
      });
  };

  const getData = async () => {
    setLoader(true);
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const Response = await axios.get(
      `${process.env.REACT_APP_MESSAGES}?keyword=${keyword}&pageNumber=${currentPage}`,
      config
    );
    const { messages, page, pages, count } = Response.data;

    setData(messages);
    setCurrentPage(page);
    setPagesNumber(pages);
    setSearchTotal(count);
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [currentPage, keyword]);

  return (
    <Wrapper>
      <ContentWrapper>
        <H5>Monthly messages list - LISL UP</H5>
        <hr />
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ width: 400 }}>
            <ButtonPrimaryLink to="/new-monthly-message">
              Add new message
            </ButtonPrimaryLink>
          </div>
          <SearchContainer>
            <SearchInput
              onFocus={() => setCurrentPage(1)}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />

            <SmallText>{searchTotal} messages(s) found</SmallText>
          </SearchContainer>
        </div>

        {data.length > 0 && pagesNumber && (
          <PaginationContainer>
            <Pagination
              color="primary"
              count={pagesNumber}
              page={currentPage}
              onChange={(e, val) => setCurrentPage(val)}
            />
          </PaginationContainer>
        )}

        {loader ? (
          <LoadWrapper>
            <LoadingBigIcon />
          </LoadWrapper>
        ) : data.length > 0 ? (
          <div style={{ margin: 30, marginTop: 10 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Image
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Message (FR)
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Message (EN)
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Created date
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Display month
                  </TableCell>
                  <TableCell></TableCell>
                </TableHead>
                <TableBody>
                  {data.map((msg) => {
                    const createdAt = moment(msg.createdAt).format(
                      "DD/MM/YYYY"
                    ); // January 3, 2023 9:09 PM

                    return (
                      <TableRow key={msg._id}>
                        <TableCell align="center">
                          <Image src={msg.picture} />
                        </TableCell>
                        <TableCell align="center">{msg.text_fr}</TableCell>
                        <TableCell align="center">{msg.text_en}</TableCell>
                        <TableCell align="center" scope="row">
                          {createdAt}
                        </TableCell>
                        <TableCell
                          style={{ fontWeight: "bold", color: "#1B4965" }}
                        >{`${msg.month} / ${msg.year}`}</TableCell>
                        <TableCell align="left">
                          <ButtonPrimaryLink
                            to={`/update-monthly-message/${msg._id}`}
                            width="40px"
                            height="40px"
                          >
                            &#8594;
                          </ButtonPrimaryLink>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {pagesNumber && (
              <PaginationContainer style={{ marginTop: 20 }}>
                <Pagination
                  color="primary"
                  count={pagesNumber}
                  page={currentPage}
                  onChange={(e, val) => setCurrentPage(val)}
                />
              </PaginationContainer>
            )}
          </div>
        ) : (
          <></>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default Messages;
