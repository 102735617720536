import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Button } from "@mui/material";
import axios from "axios";
import { H5 } from "../../components/textStyles/style";

ChartJS.register(
  CategoryScale,
  LinearScale,
  Filler,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const CompanyEpisodesStats = ({ company }) => {
  const [select, setSelect] = useState("months");
  const [responseData, setResponseData] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    const getData = async () => {
      axios
        .get(
          `${process.env.REACT_APP_COMPANY_EPISODES_STAT}?select=${select}&company_id=${company}`
        )
        .then((res) => {
          setResponseData(res.data.map((el) => el.count));

          setLabels(
            res.data.map((el) =>
              select === "days"
                ? `${el._id.day}/${el._id.month}/${el._id.year}`
                : select === "months"
                ? `${el._id.month}/${el._id.year}`
                : el._id.year
            )
          );
        });
    };
    getData();
  }, [select, company]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Episodes",
        data: responseData,
        borderColor: "rgb(128, 179, 87)",
        borderWidth: 3,
        backgroundColor: "rgba(128, 179, 87, 0.3)",
      },
    ],
  };

  return (
    <div style={{ marginTop: 40 }}>
      <div style={{ marginTop: 15 }}>
        {/* <Button
          style={{ marginRight: 8 }}
          onClick={() => setSelect("days")}
          variant="contained"
          color={select !== "days" ? "inherit" : "primary"}
        >
          Last 7 days
        </Button>
        <Button
          style={{ marginRight: 8 }}
          onClick={() => setSelect("months")}
          variant="contained"
          color={select !== "months" ? "inherit" : "primary"}
        >
          Last 12 months
        </Button>
        <Button
          style={{ marginRight: 8 }}
          onClick={() => setSelect("years")}
          variant="contained"
          color={select !== "years" ? "inherit" : "primary"}
        >
          Last 5 years
  </Button>*/}
        <div>
          <Line options={options} data={data} />
        </div>
      </div>
    </div>
  );
};

export default CompanyEpisodesStats;
