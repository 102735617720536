import styled from "styled-components";
import { H5 } from "../textStyles/style";
import axios from "axios";
import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import TextField from "@material-ui/core/TextField";
import { Button, Typography } from "@mui/material";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";

ChartJS.register(
  BarElement,
  LineElement,
  CategoryScale,
  PointElement,
  LinearScale,
  Tooltip,
  Legend
);

const RowCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Marge = styled.div`
  margin-left: 20px;
`;

const UserGraphEvolution = () => {
  const [loader, setLoader] = useState(false);
  const [threeMonthsData, setThreeMonthsData] = useState(false);
  const [data, setData] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleChange = (e) => {
    setYear((prevYear) => {
      getData(e.target.value);
      return e.target.value;
    });
  };

  const getData = async () => {
    if (data.length == 0) {
      setLoader(true);
    }
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    axios
      .get(
        `${process.env.REACT_APP_GET_USER_EVOLUTION}?year=${year}&month=${threeMonthsData ? "true" : ""
        }`,
        config
      )
      .then(async (res) => {
        setData(res.data);

        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getData();
  }, [year, threeMonthsData]);

  // BUILD DATE

  const option = {
    plugins: {
      legend: {
        position: "bottom",
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: "Subscription Number",
        },
      },
    },
  };

  const ChartdataYear = {
    labels: [year - 2, year - 1, year],
    datasets: [
      {
        label: "total",
        fill: false,
        tension: 0.15,
        data: (() => {
          let cumulativeSum = data.beforeYear;
          return [
            data.dataByYear && data.dataByYear[year - 2]
              ? (cumulativeSum += 0)
              : cumulativeSum,
            data.dataByYear && data.dataByYear[year - 1]
              ? (cumulativeSum += data.dataByYear[year - 1])
              : cumulativeSum,
            data.dataByYear && data.dataByYear[year]
              ? (cumulativeSum += data.dataByYear[year])
              : cumulativeSum,
          ];
        })(),
        borderColor: ["#1B4965"],
        backgroundColor: ["#1B4965"],
      },
      {
        label: "B2B",
        fill: false,
        tension: 0.15,
        data: (() => {
          let cumulativeSum = data.beforeYearBTB;
          return [
            data.dataByYearBTB && data.dataByYearBTB[year - 2]
              ? (cumulativeSum += data.dataByYearBTB[year - 2])
              : cumulativeSum,
            data.dataByYearBTB && data.dataByYearBTB[year - 1]
              ? (cumulativeSum += data.dataByYearBTB[year - 1])
              : cumulativeSum,
            data.dataByYearBTB && data.dataByYearBTB[year]
              ? (cumulativeSum += data.dataByYearBTB[year])
              : cumulativeSum,
          ];
        })(),
        borderColor: ["#FFCE63"],
        backgroundColor: ["#FFCE63"],
      },
      {
        label: "B2C",
        fill: false,
        tension: 0.15,
        data: (() => {
          let cumulativeSum = data.beforeYearBTC;
          return [
            data.dataByYearBTC && data.dataByYearBTC[year - 2]
              ? (cumulativeSum += data.dataByYearBTC[year - 2])
              : cumulativeSum,
            data.dataByYearBTC && data.dataByYearBTC[year - 1]
              ? (cumulativeSum += data.dataByYearBTC[year - 1])
              : cumulativeSum,
            data.dataByYearBTC && data.dataByYearBTC[year]
              ? (cumulativeSum += data.dataByYearBTC[year])
              : cumulativeSum,
          ];
        })(),
        borderColor: ["#ff5722"],
        backgroundColor: ["#ff5722"],
      },
      {
        label: "Life Subs",
        fill: false,
        tension: 0.15,
        data: (() => {
          let cumulativeSum = data.beforeYearLifeTime;
          return [
            data.dataByYearLifeTime && data.dataByYearLifeTime[year - 2]
              ? (cumulativeSum += data.dataByYearLifeTime[year - 2])
              : cumulativeSum,
            data.dataByYearLifeTime && data.dataByYearLifeTime[year - 1]
              ? (cumulativeSum += data.dataByYearLifeTime[year - 1])
              : cumulativeSum,
            data.dataByYearLifeTime && data.dataByYearLifeTime[year]
              ? (cumulativeSum += data.dataByYearLifeTime[year])
              : cumulativeSum,
          ];
        })(),
        borderColor: ["#FD7064"],
        backgroundColor: ["#FD7064"],
      },
      {
        label: "Freemium",
        fill: false,
        tension: 0.15,
        data: (() => {
          let cumulativeSum = 0;
          return [
            data.dataByYear &&
              data.dataByYearBTB &&
              data.dataByYearBTC &&
              data.dataByYearLifeTime &&
              data.dataByYear[year - 2]
              ? (cumulativeSum +=
                data.dataByYear[year - 2] -
                data.dataByYearBTB[year - 2] -
                data.dataByYearBTC[year - 2] -
                data.dataByYearLifeTime[year - 2])
              : cumulativeSum,
            data.dataByYear &&
              data.dataByYearBTB &&
              data.dataByYearBTC &&
              data.dataByYearLifeTime &&
              data.dataByYear[year - 1]
              ? (cumulativeSum +=
                data.dataByYear[year - 1] -
                data.dataByYearBTB[year - 1] -
                data.dataByYearBTC[year - 1] -
                data.dataByYearLifeTime[year - 1])
              : cumulativeSum,
            data.dataByYear &&
              data.dataByYearBTB &&
              data.dataByYearBTC &&
              data.dataByYearLifeTime &&
              data.dataByYear[year]
              ? (cumulativeSum +=
                data.dataByYear[year] -
                data.dataByYearBTB[year] -
                data.dataByYearBTC[year] -
                data.dataByYearLifeTime[year])
              : cumulativeSum,
          ];
        })(),
        borderColor: ["#77AF57"],
        backgroundColor: ["#77AF57"],
      },
    ],
  };
  const monthlyData = data.dataByYear || {};
  const cumulativeData = [];

  let cumulativeSum = data.beforeYear;
  for (let i = 1; i <= 12; i++) {
    const currentData = monthlyData[i] || 0;
    cumulativeSum += currentData;
    cumulativeData.push(cumulativeSum);
  }

  const monthlyDataBTB = data.dataByYearBTB || {};
  const cumulativeDataBTB = [];

  let cumulativeSumBTB = data.beforeYearBTB;
  for (let i = 1; i <= 12; i++) {
    const currentDataBTB = monthlyDataBTB[i] || 0;
    cumulativeSumBTB += currentDataBTB;
    cumulativeDataBTB.push(cumulativeSumBTB);
  }

  const monthlyDataBTC = data.dataByYearBTC || {};
  const cumulativeDataBTC = [];

  let cumulativeSumBTC = data.beforeYearBTC;
  for (let i = 1; i <= 12; i++) {
    const currentDataBTC = monthlyDataBTC[i] || 0;
    cumulativeSumBTC += currentDataBTC;
    cumulativeDataBTC.push(cumulativeSumBTC);
  }

  const monthlyDataLifeTime = data.dataByYearLifeTime || {};
  const cumulativeDataLifeTime = [];

  let cumulativeSumLifeTime = data.beforeYearLifeTime;
  for (let i = 1; i <= 12; i++) {
    const currentDataLifeTime = monthlyDataLifeTime[i] || 0;
    cumulativeSumLifeTime += currentDataLifeTime;
    cumulativeDataLifeTime.push(cumulativeSumLifeTime);
  }

  let cumulativeDataFree = cumulativeData.map(
    (value, index) =>
      value -
      cumulativeDataBTB[index] -
      cumulativeDataBTC[index] -
      cumulativeDataLifeTime[index]
  );

  const ChartdataMonth = {
    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    datasets: [
      //data for revenu
      {
        label: "total",
        fill: false,
        tension: 0.15,
        data: cumulativeData,
        borderColor: ["#1B4965"],
        backgroundColor: ["#1B4965"],
      },
      {
        label: "B2B",
        fill: false,
        tension: 0.15,
        data: cumulativeDataBTB,
        borderColor: ["#FFCE63"],
        backgroundColor: ["#FFCE63"],
      },
      {
        label: "B2C",
        fill: false,
        tension: 0.15,
        data: cumulativeDataBTC,
        borderColor: ["#ff5722"],
        backgroundColor: ["#ff5722"],
      },
      {
        label: "Life Subs",
        fill: false,
        tension: 0.15,
        data: cumulativeDataLifeTime,
        borderColor: ["#FD7064"],
        backgroundColor: ["#FD7064"],
      },
      {
        label: "Freemium",
        fill: false,
        tension: 0.15,
        data: cumulativeDataFree,
        borderColor: ["#77AF57"],
        backgroundColor: ["#77AF57"],
      },
    ],
  };

  return (
    <Wrapper>
      <H5
        style={{
          fontSize: 20,
          marginTop: 10,
          marginBottom: 20,
          textAlign: "center",
        }}
      >
        Evolution Subscription
      </H5>
      <RowCenter style={{ alignItems: "flex-end" }}>
        <TextField
          id="year"
          label="Year"
          style={{ width: "200px" }}
          type="number"
          value={year}
          onChange={(e) => setYear(e.target.value)}
        />

        <Button
          onClick={() => setThreeMonthsData(false)}
          variant="contained"
          style={{ marginLeft: 10, marginRight: 5 }}
          color={threeMonthsData ? "inherit" : "primary"}
        >
          <Typography fontSize={13}>Year</Typography>
        </Button>
        <Button
          onClick={() => setThreeMonthsData(true)}
          variant="contained"
          color={!threeMonthsData ? "inherit" : "primary"}
        >
          <Typography fontSize={13}>Months</Typography>
        </Button>
      </RowCenter>
      <div style={{ marginTop: 20, width: "100%", alignItems: "center" }}>
        <Line
          style={{ width: "100%" }}
          data={threeMonthsData ? ChartdataMonth : ChartdataYear}
          options={option}
        />
      </div>

      {/* <div style={{ display: isCollapsed ? "none" : "block" }}>
          <p>
            counts the number of users by user category and month of creation,
            and returns the results organized by these categories and months.
            For year is does the same but with, the past, current, and next year
            and regroup data by year instead of month
          </p>
        </div>
      <Button onClick={() => setIsCollapsed(!isCollapsed)}>Info on data</Button>*/}
    </Wrapper>
  );
};

export default UserGraphEvolution;
