import React from "react";
import { Typography } from "@mui/material";
import { H5 } from "../../../components/textStyles/style";
import { RowBetween } from "../CompanyStat";

const ExampleWorkshop = () => {

    const responseData = [
        {
            "_id": "M000-T000-S0-E0-U-FR",
            "count": 226,
            "workshopData": [
                {
                    "_id": "6357de6db05fe8e4d30eae18",
                    "title": "Préalable Ateliers \"Conscience\""
                }
            ]
        },
        {
            "_id": "F015-T001-S1-E1-S-FR",
            "count": 114,
            "workshopData": [
                {
                    "_id": "6357de87b05fe8e4d30eae5c",
                    "title": "Course de Grenouilles"
                }
            ]
        },
        {
            "_id": "F010-T005-S4-E1-S-FR",
            "count": 86,
            "workshopData": [
                {
                    "_id": "6357de86b05fe8e4d30eae57",
                    "title": "L'érable en Automne"
                }
            ]
        },
        {
            "_id": "B001-T001-S1-E0-U-FR",
            "count": 84,
            "workshopData": [
                {
                    "_id": "6357de81b05fe8e4d30eae48",
                    "title": "01 - BG - Mvt croisé genou"
                }
            ]
        },
        {
            "_id": "S005-T000-S0-E0-U-FR",
            "count": 79,
            "workshopData": [
                {
                    "_id": "635ba7ba2e158a7d9351d9a5",
                    "title": "La TAICHI GONG : qu'est-ce que c'est?"
                }
            ]
        }
    ]
    return (
        <div>
            <H5 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
                7. Top Watched Workshops
            </H5>
            {responseData.map((item) => {
                const workshop = item.workshopData ? item.workshopData[0] : null;
                return (
                    <RowBetween key={item._id}>
                        <Typography fontSize={13} color="#444">
                            {workshop ? workshop.title : "-"}
                        </Typography>
                        <Typography fontSize={13} color="#484848">
                            {item.count} view(s)
                        </Typography>
                    </RowBetween>
                );
            })}
        </div>
    );
};

export default ExampleWorkshop;
