import React from "react";

import { H6 } from "../../../components/textStyles/style";
import { RowBetween } from "../CompanyStat";
import { Typography } from "@mui/material";

const ExampleRank = () => {

    return (
        <div>
            <H6 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
                5. Users Per Rank
            </H6>

            <RowBetween>
                <Typography style={{ color: "#484848" }} fontSize={13}>
                    Hiker Users
                </Typography>
                <Typography fontSize={13} color="#484848">
                    180
                </Typography>
            </RowBetween>
            <RowBetween>
                <Typography style={{ color: "#484848" }} fontSize={13}>
                    Explorator Users
                </Typography>
                <Typography fontSize={13} color="#484848">
                    60
                </Typography>
            </RowBetween>
            <RowBetween>
                <Typography style={{ color: "#484848" }} fontSize={13}>
                    Sherpa Users
                </Typography>
                <Typography fontSize={13} color="#484848">
                    40
                </Typography>
            </RowBetween>
            <RowBetween>
                <Typography style={{ color: "#484848" }} fontSize={13}>
                    total Users
                </Typography>
                <Typography fontSize={13} color="#484848">
                    280
                </Typography>
            </RowBetween>
        </div>
    );
};

export default ExampleRank;
