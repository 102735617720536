export const lislColors = {
  green0: "#E1E8DC",
  green1: "#C9DCC5",
  // green2: "#9DC554",
  green2: "#A8C492",
  green3: "#A0C460",
  green4: "#88C1A3",
  blue0: "#CAE9FF",
  blue1: "#BEE9E8",
  blue2: "#94CDDB",
  blue3: "#1B4965",
  yellow: "#FFCE63",
  red0: "#FEC9B4",
  red1: "#FD7064",
  grayLabel: "#9e9898",
  grayBorder: "#D4CBCA",
  grayBackground: "#F4F0EF",
  white: "#ffffff",
  black: "#302B2A",
  beige: "#FBF6F3",
  beigeWithOpacity: "#FBF6F315",
};
