import styled from "styled-components";

export const Title = styled.p`
  font-size: "Oxygen";
  color: #444;
  font-weight: 600;
  font-size: 13px;
  margin: 0px;
  padding: 0px;
`;

export const Time = styled.p`
  font-size: "Oxygen";
  color: #444;
  font-weight: 500;
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  margin-top: 5px;
`;
