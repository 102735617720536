import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import { Input, SelectInput } from "../../components/Inputs";
import LazyLoading from "../../components/lazyLoading";
import {
  H5,
  H6,
  LoadingIcon,
  TextBold,
} from "../../components/textStyles/style";
import { usersRolesOptions } from "../../data/SelectOptions";
let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const LoadingWrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;

const AddUser = () => {
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [experts, setExperts] = useState([]);
  const [role, setRole] = useState("");
  const [expert, setExpert] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const submitHandler = async () => {
    if (!loading) {
      setLoading(true);
      setErrorMessage("");

      if (password.length < 8) {
        setLoading(false);
        setErrorMessage("Password must have at least 8 chars");
      } else {
        const TOKEN = localStorage.getItem("TOKEN");
        try {
          const data = {
            access_token: TOKEN,
            username,
            password,
            role,
            firstname,
            lastname,
            expert: role === "expert" ? expert : "",
          };

          await axios.post(process.env.REACT_APP_DASHBOARD_USER, data);
          setLoading(false);
          history.goBack();
        } catch (error) {
          if (error.response.status === 409) {
            setErrorMessage("An account exist with provided mail");
          } else {
            setErrorMessage("Problem connexion with the server");
          }
          setLoading(false);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
      }
    }
  };

  const getExpertsData = async () => {
    setLoader(true);
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    // get experts list
    const ex = await axios.get(
      `${process.env.REACT_APP_GET_EXPERTS_DROPDOWN}?category=1`,
      config
    );

    setExperts(ex.data);
    console.log(ex.data);

    setLoader(false);
  };

  useEffect(() => {
    getExpertsData();
  }, []);

  const onChangeExpertHandler = async (e) => {
    const expert_id = e.target.value;
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };

    const response = await axios.get(
      `${process.env.REACT_APP_GET_EXPERT}?expert_id=${expert_id}`,
      config
    );
    setFirstname(response.data.firstname);
    setLastname(response.data.lastname);
    setUsername(response.data.email_pro);
  };

  if (loader) {
    return (
      <LoadingWrapper>
        <LazyLoading height={Height} />
      </LoadingWrapper>
    );
  }

  return (
    <Wrapper>
      <ContentWrapper>
        <H5>New User</H5>
        <Row>
          <TextBold color="#c22222">
            {errorMessage ? errorMessage : " "}
          </TextBold>
        </Row>

        <H6>Account Informations</H6>
        <Row>
          <SelectInput
            values={usersRolesOptions}
            placeholder="Role *"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          />
          {role === "expert" && (
            <SelectInput
              values={experts}
              placeholder="Expert *"
              value={expert}
              onChange={(e) => {
                setExpert(e.target.value);
                onChangeExpertHandler(e);
              }}
            />
          )}
        </Row>
        <Row>
          <Input
            placeholder="Email *"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Input
            placeholder="Password *"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Row>
        <div style={{ marginTop: 25, marginBottom: 20 }}>
          <H6>General Informations</H6>
        </div>

        {/** firstname - lastname */}
        <Row style={{ marginBottom: 40, marginTop: 10 }}>
          <Input
            placeholder="Firstname *"
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
          />
          <Input
            placeholder="Lastname *"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />
        </Row>

        <Row style={{ marginTop: 40 }}>
          <ButtonPrimary onClick={submitHandler}>
            {loading ? <LoadingIcon color="inherit" /> : "Save user"}
          </ButtonPrimary>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default AddUser;
