import React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import styled from "styled-components";
import logo from "../images/main/green-logo.svg";
import { useState } from "react";
import { H4, LoadingIcon, Text } from "../components/textStyles/style";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useHistory } from "react-router-dom";
const MySwal = withReactContent(Swal);

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © LISL UP - v1.0.0 "}
      <Link color="primary" href="https://lislup.com/" target="_blank" pl="10">
        (https://lislup.com/)
      </Link>
    </Typography>
  );
}

const Logo = styled.img`
  width: 90%;
  max-width: 200px;
  margin: auto 0px;
`;

const LogoWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const theme = createTheme();

export default function ForgotPassword() {
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [passwordSection, setPasswordSection] = useState(false);
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const history = useHistory();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!loader) {
      setLoader(true);
      axios
        .post(process.env.REACT_APP_FORGOT_PASSWORD, { email })
        .then(() => {
          setLoader(false);
          setPasswordSection(true);
        })
        .catch((err) => {
          setLoader(false);
          if (err.response && err.response.status === 400) {
            MySwal.fire({
              title: <H4>Oops...</H4>,
              html: <Text>Invalid Mail Adress.</Text>,
              iconColor: "#FD7064",
              icon: "error",
              confirmButtonText: <Text color="white">Try again</Text>,
              confirmButtonColor: "#FD7064",
              confirmButtonAriaLabel: "Try again",
            });
          } else if (err.response && err.response.status === 404) {
            MySwal.fire({
              title: <H4>Oops...</H4>,
              html: <Text>No user found.</Text>,
              iconColor: "#FD7064",
              icon: "error",
              confirmButtonText: <Text color="white">Try again</Text>,
              confirmButtonColor: "#FD7064",
              confirmButtonAriaLabel: "Try again",
            });
          } else {
            MySwal.fire({
              title: <H4>Oops...</H4>,
              html: <Text>Problem to Send Code.</Text>,
              iconColor: "#FD7064",
              icon: "error",
              confirmButtonText: <Text color="white">Try again</Text>,
              confirmButtonColor: "#FD7064",
              confirmButtonAriaLabel: "Try again",
            });
          }
        });
    }
  };

  const handleReset = async (e) => {
    e.preventDefault();
    if (password.length < 8) {
      MySwal.fire({
        title: <H4>Oops...</H4>,
        html: <Text>New password must be at least 8 characters long</Text>,
        iconColor: "#FD7064",
        icon: "error",
        confirmButtonText: <Text color="white">Try again</Text>,
        confirmButtonColor: "#FD7064",
        confirmButtonAriaLabel: "Try again",
      });
    } else {
      if (!submitLoader) {
        setSubmitLoader(true);
        axios
          .post(process.env.REACT_APP_RESET_PASSWORD, { email, code, password })
          .then(() => {
            setSubmitLoader(false);
            MySwal.fire({
              title: <H4>Success</H4>,
              html: <Text>Password has been changed.</Text>,
              iconColor: "#88c1a3",
              icon: "success",
            });
            setTimeout(() => {
              history.replace("/");
            }, 1000);
          })
          .catch((err) => {
            setSubmitLoader(false);
            if (err.response && err.response.status === 400) {
              MySwal.fire({
                title: <H4>Oops...</H4>,
                html: <Text>Invalid Code</Text>,
                iconColor: "#FD7064",
                icon: "error",
                confirmButtonText: <Text color="white">Try again</Text>,
                confirmButtonColor: "#FD7064",
                confirmButtonAriaLabel: "Try again",
              });
            } else {
              MySwal.fire({
                title: <H4>Oops...</H4>,
                html: <Text>Problem communicating with server.</Text>,
                iconColor: "#FD7064",
                icon: "error",
                confirmButtonText: <Text color="white">Try again</Text>,
                confirmButtonColor: "#FD7064",
                confirmButtonAriaLabel: "Try again",
              });
            }
          });
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "90vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          sx={{
            backgroundImage:
              "url(https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/signin.jpg?alt=media&token=f81778eb-364f-427c-a87f-36f309b2f30b)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={6}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <LogoWrapper>
              <Logo src={logo} alt="image" />
            </LogoWrapper>

            {passwordSection ? (
              <div>
                <Typography textAlign="center" color="#222" variant="h6" mb={2}>
                  Change Your password
                </Typography>
                <Typography textAlign="center" color="#444" variant="p">
                  Please enter the verification code that was sent to your email
                  and a new password in the fields below to complete the
                  password reset process.
                </Typography>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleReset}
                  sx={{ mt: 1, width: "90%" }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="code"
                    label="Code"
                    name="code"
                    type="number"
                    autoFocus
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="new-password"
                    label="New Password"
                    name="new password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    style={{
                      backgroundColor: "#88c1a3",
                      height: 45,
                      color: "#111111",
                    }}
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {submitLoader ? (
                      <LoadingIcon color="white" />
                    ) : (
                      "Reset Password"
                    )}
                  </Button>

                  <Copyright sx={{ mt: 5 }} />
                </Box>
              </div>
            ) : (
              <div>
                <Typography textAlign="center" color="#222" variant="h6" mb={2}>
                  Forgot Password ?
                </Typography>
                <Typography textAlign="center" color="#444" variant="p">
                  Please enter your email address below to receive a
                  verification code. This code will be sent to your email and
                  you will need to enter it in the next step along with your new
                  password.
                </Typography>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{ mt: 1, width: "90%" }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    style={{
                      backgroundColor: "#88c1a3",
                      height: 45,
                      color: "#111111",
                    }}
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {loader ? <LoadingIcon color="white" /> : "Send Code"}
                  </Button>

                  <Copyright sx={{ mt: 5 }} />
                </Box>
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
