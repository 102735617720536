import React from 'react'
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { H5, H6, LoadingBigIcon, SmallBoldText } from '../../components/textStyles/style';
import { ButtonPrimary } from '../../components/buttonStyles/style';
import { Divider } from '@mui/material';
import { useEffect } from 'react';
import axios from 'axios';
import { languages } from '../../data/SelectOptions';
import { Input, SelectInput } from '../../components/Inputs';
import { lislColors } from '../../styles/colors';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import SuccessImage from "../../images/main/green_circle_tick.svg";
import SectionQuestions from './SectionQuestions';

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 20px;
`;



const LoadWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
`;


const Section = () => {
    const { section_id } = useParams();
    const [name, setName] = useState("");
    const [language, setLanguage] = useState("");
    const [showName, setShowName] = useState('')
    const [errorMessage, setErrorMessage] = useState("");
    const [questions, setQuestions] = useState([])
    const [published, setPublished] = useState(false)
    const [submitLoader, setSubmitLoader] = useState(false);
    const [loader, setLoader] = useState(true)
    const MySwal = withReactContent(Swal);

    const getSectionData = async () => {
        setLoader(true);
        const response = await axios.get(`${process.env.REACT_APP_SECTIONS}/get-section?section=${section_id}`,);
        const s = response.data
        setShowName(s.name)
        setLanguage(s.language)
        setQuestions(s.questions)
        setName(s.name)
        setPublished(s.published)
        setLoader(false)
    }

    useEffect(() => {

        getSectionData()
    }, [section_id])

    return (
        <Wrapper>
            <ContentWrapper>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <H5>Section - {showName}</H5>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {!loader && <div >
                            <ButtonPrimary width='240px' style={{ backgroundColor: published ? lislColors.red1 : lislColors.green2, }}
                                onClick={() => {
                                    axios.put(`${process.env.REACT_APP_SECTIONS}/update-section?section=${section_id}`, { published: !published }).then(() => setPublished(!published))
                                }
                                }
                            >
                                {published ? 'Unpublish Section' : 'Publish Section'}
                            </ButtonPrimary>
                        </div>}
                    </div>
                </div>
                <Divider style={{ marginTop: 10, marginBottom: 20 }} />
                {loader ? (
                    <LoadWrapper>
                        <LoadingBigIcon />
                    </LoadWrapper>) : <div>
                    {/** general infos */}
                    <div style={{ backgroundColor: lislColors.beige, padding: 20 }}>
                        <H6>General Informations</H6>
                        <Row>
                            <div style={{ width: '48%' }}>
                                <SelectInput
                                    values={languages}
                                    placeholder="Section Language *"
                                    value={language}
                                    onChange={(e) => setLanguage(e.target.value)}
                                />
                            </div>
                            <div style={{ width: '2%' }}></div>
                            <div style={{ width: '48%' }}>
                                <Input
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </Row>

                        {/** error message */}
                        <SmallBoldText
                            style={{ textAlign: "center", fontSize: 12, color: "#c22222" }}
                        >
                            {errorMessage ? errorMessage : " - "}
                        </SmallBoldText>
                        <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 15 }}>
                            <ButtonPrimary width='240px'
                                onClick={() => {
                                    axios.put(`${process.env.REACT_APP_SECTIONS}/update-section?section=${section_id}`, { name, language }).then(() => {
                                        setShowName(name)
                                        MySwal.fire({
                                            title: <H5>Modifications Enregisté</H5>,
                                            html: <></>,
                                            imageUrl: SuccessImage,
                                            imageWidth: 200,
                                            imageHeight: 150,
                                            imageAlt: "Custom image",
                                            timer: 1500,
                                            showConfirmButton: false,

                                            timerProgressBar: true,
                                            padding: "2vw",
                                            showCloseButton: true,
                                            showClass: {
                                                popup: "animate__animated animate__fadeInDown animate__fast",
                                            },
                                            hideClass: {
                                                popup: "animate__animated animate__fadeOutUp animate__faster",
                                            },
                                        });
                                    })
                                }
                                }
                            >
                                Save General Informations
                            </ButtonPrimary>
                        </div>
                    </div>
                    {/** Questions of section */}
                    <div style={{ backgroundColor: lislColors.beige, padding: 20, marginTop: 20 }}>
                        <SectionQuestions section={section_id} questions={questions} getSectionData={getSectionData} />
                    </div>
                </div>
                }

            </ContentWrapper>

        </Wrapper>
    )
}

export default Section