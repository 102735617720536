import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ButtonPrimary } from "../../../components/buttonStyles/style";
import { Input, SelectInput } from "../../../components/Inputs";
import LazyLoading from "../../../components/lazyLoading";
import {
    H5,
    H6,
    LoadingIcon,
    TextBold,
} from "../../../components/textStyles/style";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SuccessImage from "../../../images/main/green_circle_tick.svg";
import { dominosPieces } from "../../../data/SelectOptions";
import MultipleSelect from "../../../components/MulitpleSelect";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const NewDomino = () => {
    const MySwal = withReactContent(Swal);
    const history = useHistory();

    const [text1Fr, setText1Fr] = useState("Observez attentivement la série de dominos ci-dessous. Un jeu de dominos va de zéro (blanc) à 9.");
    const [text1En, setText1En] = useState("Carefully observe the series of dominoes below. A set of dominoes ranges from zero (white) to 9.");
    const [text2Fr, setText2Fr] = useState("Choisissez en cliquant, parmi les 4 choix ci-dessous, quel domino complète logiquement la série.");
    const [text2En, setText2En] = useState("Choose by clicking, from the 4 choices below, which domino logically completes the series.");
    const [gamePieces, setGamePieces] = useState([])
    const [choisePieces, setChoisePieces] = useState([])
    const [correctPiece, setCorrectPiece] = useState(null)

    const [submitLoading, setSubmitLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const TOKEN = localStorage.getItem("TOKEN");

    // UPDATE SUBMIT
    const submitHandler = async () => {
        setSubmitLoading(true);
        setErrorMessage("");
        if (text1Fr === "" || text2Fr === "" || text1En === '' || text2En === "" || correctPiece === null || gamePieces.length == 0 || choisePieces.length == 0) {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            setSubmitLoading(false);
            setErrorMessage("Invalid data");

        } else {
            try {
                const data = {
                    access_token: TOKEN,
                    text1_fr: text1Fr,
                    text1_en: text1En,
                    gamePieces: gamePieces.map(el => el.value),
                    text2_fr: text2Fr,
                    text2_en: text2En,
                    choisePieces: choisePieces.map(el => el.value),
                    correctPiece
                };

                await axios.post(`${process.env.REACT_APP_GAMES}/add-domino`, data);
                setSubmitLoading(false);
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                MySwal.fire({
                    title: <H5>New Level Saved</H5>,
                    html: <></>,
                    imageUrl: SuccessImage,
                    imageWidth: 200,
                    imageHeight: 150,
                    imageAlt: "Custom image",
                    timer: 1500,
                    showConfirmButton: false,
                    timerProgressBar: true,
                    padding: "2vw",
                    showCloseButton: true,
                    showClass: {
                        popup: "animate__animated animate__fadeInDown animate__fast",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp animate__faster",
                    },
                }).then(() => {
                    history.goBack();
                });
            } catch (error) {
                setSubmitLoading(false);
                console.log(error)
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                if (error.response.status === 400) {
                    setErrorMessage("Invalid data");
                } else {
                    setErrorMessage("Probléme de Connexion");
                }
            }
        }

    };

    const renderDomino = (dom) => {
        return `${dom.up} | ${dom.down}`
    }

    const dominosArray = dominosPieces.map(item => {
        return { label: renderDomino(item), value: item }
    })

    return (
        <Wrapper>

            <ContentWrapper>
                <H5>New Dominos Level</H5>
                <Row>
                    <TextBold color="#c22222">
                        {errorMessage ? errorMessage : " "}
                    </TextBold>
                </Row>
                <div style={{ marginTop: 20 }}>
                    <H6>Problem Text + Pieces</H6>
                </div>
                {/**  title fr + title en */}
                <Row>
                    <Input
                        placeholder="Problem text - FR"
                        value={text1Fr}
                        onChange={(e) => setText1Fr(e.target.value)}
                    />

                </Row>
                <Row>
                    <Input
                        placeholder="Problem text - EN"
                        value={text1En}
                        onChange={(e) => setText1En(e.target.value)}
                    />
                </Row>
                <Row>
                    <MultipleSelect
                        placeholder="Problem Pieces"
                        options={dominosArray}
                        selectedValues={gamePieces}
                        setSelectedValues={setGamePieces}
                    />
                </Row>
                {/** gamePieces  */}
                <div style={{ marginTop: 20 }}>
                    <H6>Choices Text + Pieces</H6>
                </div>
                <Row>
                    <Input
                        placeholder="Choice text - FR"
                        value={text2Fr}
                        onChange={(e) => setText2Fr(e.target.value)}
                    />

                </Row>
                <Row>
                    <Input
                        placeholder="Choice text - EN"
                        value={text2En}
                        onChange={(e) => setText2En(e.target.value)}
                    />
                </Row>
                {/** choisePieces */}
                <Row>
                    <MultipleSelect
                        placeholder="Choice Pieces"
                        options={dominosArray}
                        selectedValues={choisePieces}
                        setSelectedValues={setChoisePieces}
                    />
                </Row>
                <div style={{ marginTop: 20 }}>
                    <H6>Select The correct Answer</H6>
                </div>
                {/** correctPiece */}
                <Row>
                    <SelectInput
                        values={choisePieces}
                        placeholder="Correct Piece"
                        value={correctPiece}
                        onChange={(e) => setCorrectPiece(e.target.value)}
                    /></Row>

                <Row style={{ marginTop: 40 }}>
                    <ButtonPrimary onClick={submitHandler}>
                        {submitLoading ? (
                            <LoadingIcon color="inherit" />
                        ) : (
                            "Save new level"
                        )}
                    </ButtonPrimary>
                </Row>
            </ContentWrapper>

        </Wrapper>
    );
};

export default NewDomino;
