import { AccountCircle, NotificationImportant } from "@material-ui/icons";
import { Chip, IconButton, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import styled from "styled-components";
import { UserContext } from "../../App";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../components/buttonStyles/style";
import { H5 } from "../../components/textStyles/style";
import { usersRolesOptions } from "../../data/SelectOptions";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import SeeYouImg from "../../images/main/see_you_popup.svg";
import ChangeUserPassword from "../../components/modals/ChangeUserPassword";
import { useState } from "react";
import ChangeUserCalendly from "../../components/modals/ChangeUserCalendly";
import axios from "axios";

const ContentWrapper = styled.div`
  margin-bottom: 20px;
`;
const UserWrapper = styled.div`
  display: flex;
  align-items: center;

  justify-content: space-between;
  background-color: #f5f5f5;

  padding: 20px;
  border-radius: 15px;
`;
const InfoUserWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const TextWrapper = styled.div``;
const ButtonsContainer = styled.div`
  width: 540px;
  display: flex;
  gap: 5px;
  margin-right: 5px;
`;

const UserAccount = () => {
  const { user, setUser } = useContext(UserContext);
  const history = useHistory();

  const MySwal = withReactContent(Swal);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showChangeCalendly, setShowChangeCalendly] = useState(false);
  const [alerts, setAlerts] = useState(false);
  const logoutHandler = () => {
    MySwal.fire({
      title: <H5>Merci de votre visite</H5>,
      html: <></>,
      imageUrl: SeeYouImg,
      imageWidth: 200,
      imageHeight: 150,
      imageAlt: "Custom image",
      timer: 3000,
      showConfirmButton: false,

      timerProgressBar: true,
      padding: "2vw",
      showCloseButton: true,
      showClass: {
        popup: "animate__animated animate__fadeInDown animate__fast",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp animate__faster",
      },
    }).then(() => {
      localStorage.removeItem("TOKEN");
      setUser({});
      history.replace("/signin");
    });
  };

  const role = usersRolesOptions.find((r) => r.value === user.role);

  const getData = async () => {
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };

    const today = new Date();
    const year = today.getFullYear();

    // will need a proprer API call !

    const Response = await axios.get(
      `${process.env.REACT_APP_GET_WARNING_INFO}?language=all&year=${year}
      }`,
      config
    );

    const Result = Response.data;
    console.log(Result);
    if (
      Result.messsage > 0 ||
      Result.companies.length > 0 ||
      Result.coaching.length > 0 ||
      Result.canceledCoachingByExpert.length > 0 ||
      Result.canceledCoachingToRefund.length > 0
    ) {
      if (user.role == "admin") {
        setAlerts(true);
      }
    }
  };

  useEffect(() => {
    getData();
  }, [user]);

  if (!user) {
    return <></>;
  }

  return (
    <ContentWrapper>
      <UserWrapper
        style={{
          justifyContent: "space-between",
        }}
      >
        <InfoUserWrapper>
          <AccountCircle
            style={{ fontSize: 80, color: "#1b4965", marginRight: 5 }}
          />
          <TextWrapper>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{ marginRight: 10 }}
                color="#444 "
                variant="h7"
              >
                LISL UP Dashboard
              </Typography>
              {alerts && (
                <IconButton
                  sx={{ borderRadius: 4, backgroundColor: "#ef535010" }}
                  color="error"
                  onClick={() =>
                    document.getElementById("alerts").scrollIntoView()
                  }
                >
                  <NotificationImportant />
                  <Typography fontSize={13} fontWeight="600">
                    Check Warnings
                  </Typography>
                </IconButton>
              )}
            </div>
            <Typography variant="h6">
              {user.firstname} {user.lastname}
            </Typography>
            <Typography fontWeight="500" letterSpacing={1} fontSize={14}>
              @{user.username}
            </Typography>
            <Typography color="#1b4965" variant="subtitle2">
              {" "}
              {role ? role.label : ""}
            </Typography>
            {role &&
              role.value == "expert" &&
              user.expert_data[0].category.map((cat) => (
                <Chip
                  key={cat.value}
                  label={cat.label}
                  style={{ marginRight: 4, marginTop: 5 }}
                />
              ))}
          </TextWrapper>
        </InfoUserWrapper>
        {/** buttons */}
        <ButtonsContainer>
          {role && role.value == "admin" && <div id="alert-icon"></div>}
          {role && role.value == "expert" && (
            <ButtonSecondary
              width="100%"
              onClick={() => {
                if (user.expert_data[0].calendly) {
                  // update
                  setShowChangeCalendly(true);
                  console.log(user.expert_data[0].calendly);
                } else {
                  // go tuto
                  history.push("/coaching-appointments");
                }
              }}
            >
              Setup Calendly
            </ButtonSecondary>
          )}
          <ButtonPrimary width="100%" onClick={setShowChangePassword}>
            Change Password
          </ButtonPrimary>
          <ButtonSecondary
            style={{
              background: "#c2222290",
              borderColor: "transparent",
              color: "#ffffff",
            }}
            width="100%"
            onClick={logoutHandler}
          >
            Logout
          </ButtonSecondary>
        </ButtonsContainer>
      </UserWrapper>
      {/** modal to change password */}
      <ChangeUserPassword
        isOpen={showChangePassword}
        setIsOpen={setShowChangePassword}
      />
      {/** modal to change calendly */}
      {role && role.value == "expert" && (
        <ChangeUserCalendly
          isOpen={showChangeCalendly}
          setIsOpen={setShowChangeCalendly}
        />
      )}
    </ContentWrapper>
  );
};

export default UserAccount;
