import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import { Input } from "../../components/Inputs";
import LazyLoading from "../../components/lazyLoading";
import EpisodesList from "../../components/Lists/EpisodesList";
import { H5, LoadingIcon, TextBold } from "../../components/textStyles/style";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SuccessImage from "../../images/main/green_circle_tick.svg";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const LoadingWrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: ${Height};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  margin: 30px 0;
`;

const FormWrapper = styled.div`
  background-color: white;
  width: 50%;
  padding: 30px;
  border-radius: 20px;
`;

const InfosWrapper = styled.div`
  background-color: white;
  width: 35%;
  padding: 30px;
  border-radius: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;
const UpdateSeason = () => {
  const { i18n } = useTranslation();
  const MySwal = withReactContent(Swal);
  const { season_code } = useParams();
  const [code, setCode] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [showTitle, setShowTitle] = useState("");
  const [poster, setPoster] = useState("");
  const [keywords, setKeywords] = useState("");
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [episodes, setEpisodes] = useState([]);
  const [relatedWorkshops, setRelatedWorkshops] = useState("");
  const TOKEN = localStorage.getItem("TOKEN");

  useEffect(() => {
    const getSeasonData = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_GET_SEASON}/${season_code}?language=${i18n.language}`,
        config
      );
      const season = response.data;
      // SET INPUTS TO WORKSHOP VALUES
      setCode(season.season_code);
      setTitle(season.title);
      setShowTitle(season.title);
      setRelatedWorkshops(season.related_workshops.join(","));
      setDescription(season.description);
      setPoster(season.poster_link);
      setKeywords(season.keywords);
      setEpisodes(season.episodes);
      setLoading(false);
    };
    getSeasonData();
  }, [i18n, season_code, TOKEN]);

  // UPDATE SUBMIT
  const submitHandler = async () => {
    setSubmitLoading(true);
    setErrorMessage("");
    try {
      const data = {
        access_token: TOKEN,
        season_code: code,
        language: i18n.language,
        title,
        related_workshops: relatedWorkshops ? relatedWorkshops.split(",") : [],
        description,
        poster_link: poster,
        keywords,
      };
      await axios.put(process.env.REACT_APP_UPDATE_SEASON, data);
      setTimeout(() => {
        setSubmitLoading(false);
        setShowTitle(title);

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        MySwal.fire({
          title: <H5>Modifications Enregisté</H5>,
          html: <></>,
          imageUrl: SuccessImage,
          imageWidth: 200,
          imageHeight: 150,
          imageAlt: "Custom image",
          timer: 1500,
          showConfirmButton: false,

          timerProgressBar: true,
          padding: "2vw",
          showCloseButton: true,
          showClass: {
            popup: "animate__animated animate__fadeInDown animate__fast",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp animate__faster",
          },
        });
      }, 1000);
    } catch (error) {
      setSubmitLoading(false);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setErrorMessage("Probléme de Connexion");
    }
  };

  if (loading) {
    return (
      <LoadingWrapper>
        <LazyLoading height={Height} />
      </LoadingWrapper>
    );
  }

  return (
    <Wrapper>
      <FormWrapper>
        <H5>Update Season : {showTitle}</H5>
        <Row>
          <TextBold color="#c22222">
            {errorMessage ? errorMessage : " "}
          </TextBold>
        </Row>
        {/** season code + title */}
        <Row>
          <Input
            placeholder="Season Code"
            disabled={true}
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <Input
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Row>

        {/** description */}
        <Row>
          <Input
            placeholder="Description"
            multiline={true}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Row>

        {/** poster link */}
        <Row>
          <Input
            placeholder="Poster Link"
            value={poster}
            onChange={(e) => setPoster(e.target.value)}
          />
        </Row>

        {/** keywords */}
        <Row>
          <Input
            placeholder="Keywords (séparés avec ,) ex: confiance,émotion,stress"
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
          />
        </Row>
        {/** related workshops */}
        <Row>
          <Input
            placeholder="Ateliers recommandés (séparés par , et sans espace)"
            value={relatedWorkshops}
            onChange={(e) => setRelatedWorkshops(e.target.value)}
          />
        </Row>

        <Row style={{ marginTop: 40 }}>
          <ButtonPrimary onClick={submitHandler}>
            {submitLoading ? <LoadingIcon color="inherit" /> : "Enregister"}
          </ButtonPrimary>
        </Row>
      </FormWrapper>
      <InfosWrapper>
        <EpisodesList episodes={episodes} season_code={season_code} />
      </InfosWrapper>
    </Wrapper>
  );
};

export default UpdateSeason;
