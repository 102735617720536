import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import { Input, SelectInput } from "../../components/Inputs";
import LazyLoading from "../../components/lazyLoading";
import { H5, LoadingIcon, TextBold } from "../../components/textStyles/style";
import { funSubTypes, srtOptions, types } from "../../data/SelectOptions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SuccessImage from "../../images/main/green_circle_tick.svg";
import { Checkbox, FormControlLabel } from "@mui/material";
import MultipleSelect from "../../components/MulitpleSelect";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const MultiRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const UpdateWorkshop = () => {
  const MySwal = withReactContent(Swal);
  const { i18n } = useTranslation();
  const { workshop_code } = useParams();
  const [code, setCode] = useState("");
  const [title, setTitle] = useState("");
  const [showTitle, setShowTitle] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [duration, setDuration] = useState("");
  const [poster, setPoster] = useState("");
  const [type, setType] = useState("");
  const [studio, setStudio] = useState([]);
  const [author, setAuthor] = useState([]);
  const [voice, setVoice] = useState([]);
  const [srt, setSrt] = useState([]);
  const [keywords, setKeywords] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [subType, setSubType] = useState("");
  const [free, setFree] = useState(false);
  const [experts, setExperts] = useState([]);
  const TOKEN = localStorage.getItem("TOKEN");

  // UPDATE SUBMIT
  const submitHandler = async () => {
    setSubmitLoading(true);
    setErrorMessage("");
    try {
      const data = {
        access_token: TOKEN,
        workshop_code: code,
        language: i18n.language,
        link,
        title,
        description,
        poster_link: poster,
        duration: Number(duration),
        type,
        subType: type === "fun" ? subType : null,
        author,
        free,
        voice,
        studio,
        srt,
        keywords,
      };

      await axios.put(process.env.REACT_APP_UPDATE_WORKSHOP, data);
      setSubmitLoading(false);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      MySwal.fire({
        title: <H5>Modifications Enregisté</H5>,
        html: <></>,
        imageUrl: SuccessImage,
        imageWidth: 200,
        imageHeight: 150,
        imageAlt: "Custom image",
        timer: 1500,
        showConfirmButton: false,

        timerProgressBar: true,
        padding: "2vw",
        showCloseButton: true,
        showClass: {
          popup: "animate__animated animate__fadeInDown animate__fast",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp animate__faster",
        },
      });
    } catch (error) {
      setSubmitLoading(false);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setErrorMessage("Connexion Problem");
    }
  };

  useEffect(() => {
    const getWorkshopData = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      // get experts list
      const ex = await axios.get(
        process.env.REACT_APP_GET_EXPERTS_DROPDOWN,
        config
      );

      setExperts(ex.data);
      const response = await axios.get(
        `${process.env.REACT_APP_GET_WORKSHOPS}/${workshop_code}?language=${i18n.language}`,
        config
      );
      const workshop = response.data;
      // SET INPUTS TO WORKSHOP VALUES
      setCode(workshop.workshop_code);

      setTitle(workshop.title);
      setShowTitle(workshop.title);
      setDescription(workshop.description);
      setLink(workshop.link);
      setDuration(Number(workshop.duration));
      setPoster(workshop.poster_link);
      setType(workshop.type);
      setSubType(workshop.subType ? workshop.subType : "");
      setStudio(workshop.studio);
      setAuthor(workshop.author);
      setVoice(workshop.voice);
      setSrt(workshop.srt);
      setKeywords(workshop.keywords);
      setFree(workshop.free);
      setLoading(false);
    };
    getWorkshopData();
  }, [TOKEN, i18n, workshop_code]);
  return (
    <Wrapper>
      {loading ? (
        <LazyLoading height={Height} />
      ) : (
        <ContentWrapper>
          {/** iframe if streaming */}
          {link.endsWith(".m3u8") && (
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 30,
              }}
            >
              <iframe
                title="workshop video"
                src={`https://iframe.mediadelivery.net/embed/68396/${
                  link.split("/")[3]
                }?autoplay=false&preload=false`}
                loading="lazy"
                width="500"
                style={{ border: "none", aspectRatio: "16 / 9" }}
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowFullScreen={true}
              ></iframe>
            </div>
          )}
          <H5>Update Workshop : {showTitle}</H5>
          <Row>
            <TextBold color="#c22222">
              {errorMessage ? errorMessage : " "}
            </TextBold>
          </Row>
          {/** workshop code + title */}
          <Row>
            <Input
              disabled={true}
              placeholder="Workshop Code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <Input
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Row>

          {/** description */}
          <Row>
            <Input
              placeholder="Description"
              multiline={true}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Row>
          {/** type */}
          <Row>
            <SelectInput
              values={types}
              placeholder="Type"
              value={type}
              onChange={(e) => setType(e.target.value)}
            />
          </Row>
          {/** sub type if exist */}
          {type === "fun" && (
            <Row>
              <SelectInput
                values={funSubTypes}
                placeholder="Sub-type"
                value={subType}
                onChange={(e) => setSubType(e.target.value)}
              />
            </Row>
          )}
          {/** link + duration */}
          <Row>
            <Input
              placeholder="Video/Audio Link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
            <Input
              type="number"
              placeholder="Duration in minutes"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            />
          </Row>
          {/** poster_link */}
          <Row>
            <Input
              placeholder="Poster Link"
              value={poster}
              onChange={(e) => setPoster(e.target.value)}
            />
          </Row>

          {/** author + voice */}
          <MultiRow>
            <MultipleSelect
              placeholder="Author"
              options={experts}
              selectedValues={author}
              setSelectedValues={setAuthor}
            />
            <MultipleSelect
              placeholder="Voice"
              options={experts}
              selectedValues={voice}
              setSelectedValues={setVoice}
            />
          </MultiRow>
          {/** studio + srt */}
          <Row>
            <MultipleSelect
              placeholder="Studio"
              options={experts}
              selectedValues={studio}
              setSelectedValues={setStudio}
            />
            <MultipleSelect
              placeholder="SRT Languages"
              options={srtOptions}
              selectedValues={srt}
              setSelectedValues={setSrt}
            />
          </Row>
          {/** keywords */}
          <Row>
            <Input
              placeholder="Keywords (sperated with ,) ex: confiance,émotion,stress"
              value={keywords}
              onChange={(e) => setKeywords(e.target.value)}
            />
          </Row>

          {/** freemium access */}
          <FormControlLabel
            style={{ marginRight: 30, marginTop: 10 }}
            control={
              <Checkbox
                checked={free}
                onChange={() => setFree(!free)}
                color="success"
              />
            }
            label="Free Access for Freemium ?"
          />
          <Row style={{ marginTop: 40 }}>
            <ButtonPrimary onClick={submitHandler}>
              {submitLoading ? <LoadingIcon color="inherit" /> : "Save"}
            </ButtonPrimary>
          </Row>
        </ContentWrapper>
      )}
    </Wrapper>
  );
};

export default UpdateWorkshop;
