import axios from "axios";
import { useState } from "react";
import PhoneInput from "react-phone-number-input";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import { Input, SelectInput } from "../../components/Inputs";
import MultipleSelect from "../../components/MulitpleSelect";
import {
  H5,
  H6,
  LoadingIcon,
  TextBold,
} from "../../components/textStyles/style";
import { expertCategories, genderOptions } from "../../data/SelectOptions";
import "../../styles/custom-phone-input.css";
let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const PhoneInputWrapper = styled.div`
  border: none;
  overflow: hidden;
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const NewExpert = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [code, setCode] = useState("");
  const [codeEn, setCodeEn] = useState("");
  const [category, setCategory] = useState([]);
  const [gender, setGender] = useState("");
  const [titleFr, setTitleFr] = useState("");
  const [titleEn, setTitleEn] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [company, setCompany] = useState("");
  const [siret, setSiret] = useState("");
  const [tva, setTva] = useState("");
  const [addressPro, setAddressPro] = useState("");
  const [addressPerso, setAddressPerso] = useState("");
  const [emailPro, setEmailPro] = useState("");
  const [emailPerso, setEmailPerso] = useState("");
  const [phonePro, setPhonePro] = useState("");
  const [phonePerso, setPhonePerso] = useState("");
  const [logo, setLogo] = useState("");
  const [website, setWebsite] = useState("");

  const submitHandler = async () => {
    if (!loading) {
      setLoading(true);
      setErrorMessage("");
      const TOKEN = localStorage.getItem("TOKEN");
      try {
        const data = {
          access_token: TOKEN,
          code,
          code_en: codeEn,
          category,
          tva,
          gender,
          lastname,
          firstname,
          title_fr: titleFr,
          title_en: titleEn,
          company,
          siret,
          address_pro: addressPro,
          address_perso: addressPerso,
          email_pro: emailPro,
          email_perso: emailPerso,
          phone_pro: phonePro,
          phone_perso: phonePerso,
          logo,
          website,
        };

        await axios.post(process.env.REACT_APP_NEW_EXPERT, data);
        setLoading(false);
        history.goBack();
      } catch (error) {
        setLoading(false);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(error);
        setErrorMessage("Probléme de Connexion");
      }
    }
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <H5>New Expert</H5>
        <Row>
          <TextBold color="#c22222">
            {errorMessage ? errorMessage : " "}
          </TextBold>
        </Row>
        <H6>General Informations</H6>
        {/** Exprt code - gender - category */}
        <Row>
          <Input
            placeholder="FR Display"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <Input
            placeholder="EN Display"
            value={codeEn}
            onChange={(e) => setCodeEn(e.target.value)}
          />
        </Row>
        <Row>
          <SelectInput
            values={genderOptions}
            placeholder="Civility"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          />
          <MultipleSelect
            placeholder="Category"
            options={expertCategories}
            selectedValues={category}
            setSelectedValues={setCategory}
          />
        </Row>
        {/** firstname - lastname */}
        <Row>
          <Input
            placeholder="Firstname"
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
          />
          <Input
            placeholder="Lastname"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />
        </Row>
        {/** firstname - lastname */}
        <Row style={{ marginBottom: 20 }}>
          <Input
            placeholder="Fr title"
            value={titleFr}
            onChange={(e) => setTitleFr(e.target.value)}
          />
          <Input
            placeholder="En title"
            value={titleEn}
            onChange={(e) => setTitleEn(e.target.value)}
          />
        </Row>
        <H6>Adresses</H6>
        {/** address pro - address perso */}
        <Row style={{ marginBottom: 20 }}>
          <Input
            placeholder="Professionnal Adresse"
            value={addressPro}
            onChange={(e) => setAddressPro(e.target.value)}
          />
          <Input
            placeholder="Personal Adresse"
            value={addressPerso}
            onChange={(e) => setAddressPerso(e.target.value)}
          />
        </Row>
        <H6>Emails</H6>
        {/** email pro - email perso */}
        <Row style={{ marginBottom: 20 }}>
          <Input
            placeholder="Professionnal Mail"
            value={emailPro}
            onChange={(e) => setEmailPro(e.target.value)}
          />
          <Input
            placeholder="Mail Personel"
            value={emailPerso}
            onChange={(e) => setEmailPerso(e.target.value)}
          />
        </Row>
        <H6>Téléphone</H6>
        {/** phone pro - phone  perso */}
        <Row style={{ marginBottom: 20 }}>
          <PhoneInputWrapper>
            <PhoneInput
              placeholder="Professionnal Phone"
              value={phonePro}
              onChange={setPhonePro}
              defaultCountry="FR"
              international={false}
              rules={{ required: true }}
            />
          </PhoneInputWrapper>
          <PhoneInputWrapper>
            <PhoneInput
              placeholder="Personel Phone"
              value={phonePerso}
              onChange={setPhonePerso}
              defaultCountry="FR"
              international={false}
              rules={{ required: true }}
            />
          </PhoneInputWrapper>
        </Row>

        <H6>Société</H6>
        {/** logo - company  */}
        <Row>
          <Input
            placeholder="Logo url"
            value={logo}
            onChange={(e) => setLogo(e.target.value)}
          />
          <Input
            placeholder="Company Name"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
        </Row>
        {/** siret + tva*/}
        <Row>
          <Input
            placeholder="Intra-community VAT number"
            value={tva}
            onChange={(e) => setTva(e.target.value)}
          />
          <Input
            placeholder="SIRET"
            value={siret}
            onChange={(e) => setSiret(e.target.value)}
          />
        </Row>
        {/** website */}
        <Row>
          <Input
            placeholder="Website url"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
          />
        </Row>
        <Row style={{ marginTop: 40 }}>
          <ButtonPrimary onClick={submitHandler}>
            {loading ? (
              <LoadingIcon color="inherit" />
            ) : (
              "Ajouter nouveau expert"
            )}
          </ButtonPrimary>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default NewExpert;
