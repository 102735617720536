import axios from "axios";
import { useState } from "react";
import PhoneInput from "react-phone-number-input";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import { Input } from "../../components/Inputs";
import {
  H5,
  H6,
  LoadingIcon,
  TextBold,
} from "../../components/textStyles/style";

import "../../styles/custom-phone-input.css";
import { UserImage, AvatarContainer } from "./styles";
import UserAvatar from "@material-ui/icons/Person";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const PhoneInputWrapper = styled.div`
  border: none;
  overflow: hidden;
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const NewAssociation = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [nameFr, setNameFr] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [descriptionFr, setdescriptionFr] = useState("");
  const [descriptionEn, setdescriptionEn] = useState("");
  const [siret, setSiret] = useState("");
  const [phone, setPhone] = useState("");
  const [bic, setbic] = useState("");
  const [image, setImage] = useState("");
  const [website, setwebsite] = useState("");
  const [donLink, setDonLink] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactName, setContactName] = useState("");
  const [iban, setiban] = useState("");
  const [address, setaddress] = useState("");

  const submitHandler = async () => {
    if (!loading) {
      setLoading(true);
      setErrorMessage("");
      const TOKEN = localStorage.getItem("TOKEN");
      try {
        const data = {
          access_token: TOKEN,
          name_fr: nameFr,
          name_en: nameEn,
          description_fr: descriptionFr,
          description_en: descriptionEn,
          siret,
          phone,
          website,
          iban,
          bic,
          address,
          image,
          contactEmail,
          don_link: donLink,
          contactName,
        };
        console.log(data);
        await axios.post(process.env.REACT_APP_ASSOS, data);
        setLoading(false);
        history.goBack();
      } catch (error) {
        setLoading(false);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(error);
        setErrorMessage("Invalid data");
      }
    }
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <H5>New Association</H5>
        <Row>
          <TextBold color="#c22222">
            {errorMessage ? errorMessage : " "}
          </TextBold>
        </Row>
        <H6>Informations génerales</H6>
        {/** assos name  + image */}
        <Row>
          <div style={{ alignSelf: "flex-start", marginRight: 10 }}>
            {image ? (
              <UserImage src={`${image}`} />
            ) : (
              <AvatarContainer>
                <UserAvatar style={{ fontSize: 70, color: "#A89D9C" }} />
              </AvatarContainer>
            )}
          </div>
          <Input
            placeholder="Association name (fr) *"
            value={nameFr}
            onChange={(e) => setNameFr(e.target.value)}
          />
          <Input
            placeholder="Association name (en) *"
            value={nameEn}
            onChange={(e) => setNameEn(e.target.value)}
          />
        </Row>
        <Row>
          <Input
            placeholder="Image *"
            value={image}
            onChange={(e) => setImage(e.target.value)}
          />
        </Row>
        <Row>
          <Input
            style={{ marginTop: 20 }}
            placeholder="Description (fr) *"
            value={descriptionFr}
            onChange={(e) => setdescriptionFr(e.target.value)}
          />
        </Row>
        <Row>
          <Input
            style={{ marginTop: 20 }}
            placeholder="Description (en) *"
            value={descriptionEn}
            onChange={(e) => setdescriptionEn(e.target.value)}
          />
        </Row>
        {/** website */}
        <Row>
          <Input
            style={{ marginTop: 20 }}
            placeholder="Association website (Optional)"
            value={website}
            onChange={(e) => setwebsite(e.target.value)}
          />
        </Row>
        {/** don_link */}
        <Row>
          <Input
            style={{ marginTop: 20 }}
            placeholder="Direct don Link *"
            value={donLink}
            onChange={(e) => setDonLink(e.target.value)}
          />
        </Row>
        {/** siret */}
        <Row>
          <Input
            placeholder="SIRET (Optional)"
            value={siret}
            onChange={(e) => setSiret(e.target.value)}
          />
        </Row>
        {/** phone - address */}
        <Row>
          <Input
            style={{ marginTop: 20 }}
            placeholder="Address *"
            value={address}
            onChange={(e) => setaddress(e.target.value)}
          />
          <PhoneInputWrapper>
            <PhoneInput
              placeholder="Phone *"
              value={phone}
              onChange={setPhone}
              defaultCountry="FR"
              international={false}
              rules={{ required: true }}
            />
          </PhoneInputWrapper>
        </Row>
        <Row>
          <Input
            placeholder="IBAN (Optional)"
            value={iban}
            onChange={(e) => setiban(e.target.value)}
          />
          <Input
            placeholder="BIC (Optional)"
            value={bic}
            onChange={(e) => setbic(e.target.value)}
          />
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Input
            placeholder="Contact Name (Optional)"
            value={contactName}
            onChange={(e) => setContactName(e.target.value)}
          />
          <Input
            placeholder="Contact Email (Optional)"
            value={contactEmail}
            onChange={(e) => setContactEmail(e.target.value)}
          />
        </Row>
        <Row style={{ marginTop: 40 }}>
          <ButtonPrimary onClick={submitHandler}>
            {loading ? (
              <LoadingIcon color="inherit" />
            ) : (
              "Ajouter nouveau l'association"
            )}
          </ButtonPrimary>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default NewAssociation;
