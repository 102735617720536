import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import { Input, SelectInput } from "../../components/Inputs";
import { H5, LoadingIcon, TextBold } from "../../components/textStyles/style";
import { monthsOptions } from "../../data/SelectOptions";
import "../../styles/custom-phone-input.css";
let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const NewMessage = () => {
  const history = useHistory();
  const todayYear = new Date().getFullYear();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [textFr, setTextFr] = useState("");
  const [textEn, setTextEn] = useState("");
  const [picture, setPicture] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState(todayYear);
  const submitHandler = async () => {
    if (!loading) {
      setLoading(true);
      setErrorMessage("");
      const TOKEN = localStorage.getItem("TOKEN");
      try {
        const data = {
          access_token: TOKEN,
          text_fr: textFr,
          text_en: textEn,
          picture,
          month,
          year,
        };

        await axios.post(process.env.REACT_APP_MESSAGE, data);
        setLoading(false);
        history.goBack();
      } catch (error) {
        setLoading(false);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(error);
        setErrorMessage("Problem connexion with server");
      }
    }
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <H5>New Message</H5>
        <Row>
          <TextBold color="#c22222">
            {errorMessage ? errorMessage : " "}
          </TextBold>
        </Row>

        <Row>
          <Input
            multiline
            placeholder="Message - FR"
            value={textFr}
            onChange={(e) => setTextFr(e.target.value)}
          />
          <Input
            multiline
            placeholder="Message - EN"
            value={textEn}
            onChange={(e) => setTextEn(e.target.value)}
          />
        </Row>

        <Row>
          <SelectInput
            values={monthsOptions}
            placeholder="Message display month *"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
          />
          <Input
            placeholder="Year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />
        </Row>
        <Row>
          <Input
            placeholder="Image Link"
            value={picture}
            onChange={(e) => setPicture(e.target.value)}
          />
        </Row>
        <Row style={{ marginTop: 40 }}>
          <ButtonPrimary onClick={submitHandler}>
            {loading ? <LoadingIcon color="inherit" /> : "Add new message"}
          </ButtonPrimary>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default NewMessage;
