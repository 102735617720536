import React, { useEffect, useState } from "react";
import axios from "axios";
import { H5 } from "../../../components/textStyles/style";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,

} from "@mui/material";
import { reportingOptions } from "../../../data/SelectOptions";

const GroupGlobalEvolution = ({ group }) => {

  // if reporting list then select . if not all reporting options
  const dataToMap = reportingOptions
  const startArray = Array.from({ length: dataToMap.length }, () => ({ i1: "-", i2: "-", i3: "-", total: "-" }));
  const [data, setData] = useState(startArray)
  const [i1Mean, setI1Mean] = useState(0)
  const [i2Mean, setI2Mean] = useState(0)
  const [i3Mean, setI3Mean] = useState(0)
  function calculateArrayMean(arr) {
    const sum = arr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const mean = sum / arr.length;
    return mean * 100;
  }

  const calculatesMeans = (arr) => {

    const i1s = arr.map(el => el.i1).filter(el => el !== "-")
    const i2s = arr.map(el => el.i2).filter(el => el !== "-")
    const i3s = arr.map(el => el.i3).filter(el => el !== "-")

    const i1Mean = calculateArrayMean(i1s)
    const i2Mean = calculateArrayMean(i2s)
    const i3Mean = calculateArrayMean(i3s)

    setI1Mean(i1Mean)
    setI2Mean(i2Mean)
    setI3Mean(i3Mean)

  }

  const TrailScoresRender = ({ el, i }) => {
    const [dataToShow, setDataToShow] = useState({ i1: "-", i2: "-", i3: "-", total: "-" });



    useEffect(() => {
      if (el.value !== "") {
        axios
          .get(
            `${process.env.REACT_APP_GROUP_INDICATORS}-group?trail=${el.value}&group=${group._id}`
          )
          .then((res) => {
            setDataToShow(res.data);
            const newArr = data
            newArr[i] = res.data
            calculatesMeans(newArr)
            setData(newArr)

          });
      }
    }, []);

    const isInReportingList = () => {
      if (group.reporting) {
        const element = group.reporting.find(x => x.value == el.value)
        if (element) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }

    const isInReport = isInReportingList()


    if (dataToShow.i1 === "-" && dataToShow.i2 === "-" && dataToShow.i3 === "-") {
      return (
        null
      )
    }

    if (!isInReport) {
      return (
        false
      )
    }

    return (
      <TableRow>
        <TableCell
          align="center"
          style={{
            fontWeight: "bold",
            color: "#111",
            fontSize: 13,
          }}
        >
          {el.label}
        </TableCell>
        {/** i1 */}

        <TableCell align="center" style={{ color: "#484848", fontSize: 13 }}>
          {dataToShow.i1 !== "-" ? (dataToShow.i1 * 100).toFixed(2) + " %" : "-"}
        </TableCell>
        {/** i2 */}

        <TableCell align="center" style={{ color: "#484848", fontSize: 13 }}>
          {dataToShow.i2 !== "-" ? (dataToShow.i2 * 100).toFixed(2) + " %" : "-"}
        </TableCell>
        {/** i3 */}
        <TableCell align="center" style={{ color: "#484848", fontSize: 13 }}>
          {dataToShow.i3 !== "-" ? (dataToShow.i3 * 100).toFixed(2) + " %" : "-"}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div>
      <H5 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
        3. Global Evolution
      </H5>
      <div style={{ margin: 10, marginTop: 0 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableCell
                align="center"
                style={{
                  fontWeight: "bold",
                  color: "#1B4965",
                  fontSize: 13,
                }}
              ></TableCell>
              <TableCell
                align="center"
                style={{
                  fontWeight: "bold",
                  color: "#222",
                  fontSize: 13,
                }}
              >
                I1
              </TableCell>
              <TableCell
                align="center"
                style={{
                  fontWeight: "bold",
                  color: "#222",
                  fontSize: 13,
                }}
              >
                I2
              </TableCell>
              <TableCell
                align="center"
                style={{
                  fontWeight: "bold",
                  color: "#222",
                  fontSize: 13,
                }}
              >
                I3
              </TableCell>
            </TableHead>
            <TableBody>
              {dataToMap.map((el, i) => {
                return <TrailScoresRender el={el} i={i} key={el.label} />;
              })}
              {/** mean  */}
              <TableRow>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: "bold",
                    color: "#66854e",
                    fontSize: 13,
                  }}
                >
                  All Trails
                </TableCell>
                {/** i1 */}

                <TableCell align="center" style={{
                  fontWeight: "bold",
                  color: "#66854e",
                  fontSize: 13,
                }}>
                  {i1Mean.toFixed(2) + ' %'}
                </TableCell>
                {/** i2 */}

                <TableCell align="center" style={{
                  fontWeight: "bold",
                  color: "#66854e",
                  fontSize: 13,
                }}>
                  {i2Mean.toFixed(2) + ' %'}
                </TableCell>
                {/** i3 */}
                <TableCell align="center" style={{
                  fontWeight: "bold",
                  color: "#66854e",
                  fontSize: 13,
                }}>
                  {i3Mean.toFixed(2) + ' %'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

        </TableContainer>
      </div>
    </div>
  );
};

export default GroupGlobalEvolution;
