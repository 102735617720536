import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { H7, LoadingBigIcon, Text } from "../../../components/textStyles/style";
import { RowCenter } from "../styles";

const Row = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
`;

const TrailsContainer = styled.div`
  width: 48%;
  border-right: 1px solid #cccccc;
`;

const FinshedTrails = ({ user, currentTrails }) => {
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      setLoader(true);

      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      axios
        .get(`${process.env.REACT_APP_FINISHED_TRAILS}?user_id=${user}`, config)
        .then(async (res) => {
          setData(res.data);

          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getData();
  }, [user]);

  return (
    <Row>
      {/** current trails */}
      <TrailsContainer>
        {currentTrails.length === 0 ? (
          <>
            <H7>Current Trails</H7>
            <Text color="#444444"> No trail started yet.</Text>
          </>
        ) : (
          <>
            <H7>Current trails - {currentTrails.length} trail(s)</H7>

            <Table>
              <TableHead>
                <TableCell>
                  <Typography fontWeight="600" color="#1B4965" fontSize={12}>
                    Trail code
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="600" color="#1B4965" fontSize={12}>
                    Title
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    fontWeight="600"
                    color="#1B4965"
                    fontSize={12}
                  ></Typography>
                </TableCell>
              </TableHead>
              <TableBody>
                {currentTrails.map((trail) => {
                  return (
                    <TableRow>
                      <TableCell>
                        <Typography fontSize={12}>
                          {trail.trail_code}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Link
                          style={{ color: "#1b4965" }}
                          to={`/trail/${trail.trail_code}`}
                          target="_blank"
                        >
                          <Typography fontSize={12}>{trail.title}</Typography>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Typography fontSize={12}></Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </>
        )}
      </TrailsContainer>
      {/** finished trails */}
      <TrailsContainer>
        {loader ? (
          <RowCenter style={{ height: "100%" }}>
            <LoadingBigIcon />
          </RowCenter>
        ) : data.length === 0 ? (
          <>
            <H7>Finished Trails</H7>
            <Text color="#444444"> No finished Trails yet.</Text>
          </>
        ) : (
          <>
            <H7>Finished Trails - {data.length} trail(s)</H7>

            <Table>
              <TableHead>
                <TableCell>
                  <Typography fontWeight="600" color="#1B4965" fontSize={12}>
                    Trail code
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="600" color="#1B4965" fontSize={12}>
                    title
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="600" color="#1B4965" fontSize={12}>
                    Finished at
                  </Typography>
                </TableCell>
              </TableHead>
              <TableBody>
                {data.map((result) => {
                  const trail = result.trail_data[0];
                  return (
                    <TableRow>
                      <TableCell>
                        <Typography fontSize={12}>
                          {result.ressource}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Link
                          style={{ color: "#1b4965" }}
                          to={`/trail/${trail.trail_code}`}
                          target="_blank"
                        >
                          <Typography fontSize={12}>{trail.title}</Typography>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Typography fontSize={12}>
                          {moment(result.createdAt).format("DD/MM/YYYY HH:mm")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </>
        )}
      </TrailsContainer>
    </Row>
  );
};

export default FinshedTrails;
