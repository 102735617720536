import { Checkbox, FormControlLabel } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import { Input, SelectInput } from "../../components/Inputs";
import MultipleSelect from "../../components/MulitpleSelect";
import {
  H5,
  LoadingBigIcon,
  LoadingIcon,
  TextBold,
} from "../../components/textStyles/style";
import { categories } from "../../data/SelectOptions";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const LoadingWrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const MultiRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const NewTrail = () => {
  const history = useHistory();
  const { i18n } = useTranslation();
  const [code, setCode] = useState("");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [episodesNumber, setEpisodesNumber] = useState("");
  const [poster, setPoster] = useState("");
  const [source, setSource] = useState("");
  const [category, setCategory] = useState("");
  const [studioImage, setStudioImage] = useState([]);
  const [studioAudio, setStudioAudio] = useState([]);
  const [translationAgency, setTranslationAgency] = useState([]);
  const [author, setAuthor] = useState([]);
  const [voice, setVoice] = useState([]);
  const [scriptwriter, setScriptwriter] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [srt, setSrt] = useState(false);
  const [free, setFree] = useState(false);
  const [dailyLimit, setDailyLimit] = useState(false);
  const [experts, setExperts] = useState([]);
  const [loader, setLoader] = useState(true);

  const getExpertsData = async () => {
    setLoader(true);
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    // get experts list
    const ex = await axios.get(
      process.env.REACT_APP_GET_EXPERTS_DROPDOWN,
      config
    );

    setExperts(ex.data);
    setLoader(false);
  };

  useEffect(() => {
    getExpertsData();
  }, []);

  const submitHandler = async () => {
    setLoading(true);
    setErrorMessage("");
    const TOKEN = localStorage.getItem("TOKEN");
    if (code.length !== 4) {
      setLoading(false);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setErrorMessage("code trail invalid");
    } else {
      try {
        const data = {
          access_token: TOKEN,
          trail_code: code,
          language: i18n.language,
          link,
          title,
          description,
          poster_link: poster,
          author,
          source,
          voice,
          audio_studio: studioAudio,
          image_studio: studioImage,
          translation_agency: translationAgency,
          keywords,
          episodes_number: Number(episodesNumber),
          srt,
          category,
          scriptwriter,
          free,
          daily_limit: dailyLimit,
        };

        await axios.post(process.env.REACT_APP_NEW_TRAIL, data);
        history.replace(`/trail/${code}`);
      } catch (error) {
        setLoading(false);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        if (error.response.status === 400) {
          setErrorMessage(
            "Données Invalides, Veuillez remplir tous les champs"
          );
        } else if (error.response.status === 409) {
          setErrorMessage("Un Trail existe avec le code fourni");
        } else {
          setErrorMessage("Probléme de Connexion");
        }
      }
    }
  };

  if (loader) {
    return (
      <LoadingWrapper>
        <LoadingBigIcon />
      </LoadingWrapper>
    );
  }

  return (
    <Wrapper>
      <ContentWrapper>
        <H5>New Trail</H5>
        <Row>
          <TextBold color="#c22222">
            {errorMessage ? errorMessage : " "}
          </TextBold>
        </Row>
        {/** trail code + title */}
        <Row>
          <Input
            placeholder="Trail Code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <Input
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Row>

        {/** description */}
        <Row>
          <Input
            placeholder="Description"
            multiline={true}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Row>
        {/** category */}
        <Row>
          <SelectInput
            values={categories}
            placeholder="Catégorie"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
          <Input
            type="number"
            placeholder="Nombre Total des épisodes"
            value={episodesNumber}
            onChange={(e) => setEpisodesNumber(e.target.value)}
          />
        </Row>
        {/** link + poster link */}
        <Row>
          <Input
            placeholder="Trailer Link (not required)"
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
          <Input
            placeholder="Poster Link"
            value={poster}
            onChange={(e) => setPoster(e.target.value)}
          />
        </Row>
        {/** author + voice */}
        <MultiRow>
          <MultipleSelect
            placeholder="Auteur"
            options={experts}
            selectedValues={author}
            setSelectedValues={setAuthor}
          />
          <MultipleSelect
            placeholder="Voix"
            options={experts}
            selectedValues={voice}
            setSelectedValues={setVoice}
          />
        </MultiRow>
        {/** source */}
        <Row>
          <Input
            placeholder="Source"
            value={source}
            onChange={(e) => setSource(e.target.value)}
          />
        </Row>
        {/** studio audio + studio image */}
        <MultiRow>
          <MultipleSelect
            placeholder="Studio Audio"
            options={experts}
            selectedValues={studioAudio}
            setSelectedValues={setStudioAudio}
          />
          <MultipleSelect
            placeholder="Studio Image"
            options={experts}
            selectedValues={studioImage}
            setSelectedValues={setStudioImage}
          />
        </MultiRow>
        {/** translation agency + scriptwriter */}
        <MultiRow>
          <MultipleSelect
            placeholder="Translation agency"
            options={experts}
            selectedValues={translationAgency}
            setSelectedValues={setTranslationAgency}
          />
          <MultipleSelect
            placeholder="Script Writer"
            options={experts}
            selectedValues={scriptwriter}
            setSelectedValues={setScriptwriter}
          />
        </MultiRow>

        {/** keywords */}
        <Row>
          <Input
            placeholder="Keywords (séparés avec ,) ex: confiance,émotion,stress"
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
          />
        </Row>

        <Row>
          <FormControlLabel
            style={{ marginRight: 30 }}
            control={
              <Checkbox
                checked={srt}
                onChange={() => setSrt(!srt)}
                color="success"
              />
            }
            label="Srt ?"
          />
          <FormControlLabel
            style={{ marginRight: 30 }}
            control={
              <Checkbox
                checked={free}
                onChange={() => setFree(!free)}
                color="success"
              />
            }
            label="Free ?"
          />
          <FormControlLabel
            style={{ marginRight: 30 }}
            control={
              <Checkbox
                checked={dailyLimit}
                onChange={() => setDailyLimit(!dailyLimit)}
                color="success"
              />
            }
            label="Limite des épisodes ?"
          />
        </Row>
        <Row style={{ marginTop: 40 }}>
          <ButtonPrimary onClick={submitHandler}>
            {loading ? (
              <LoadingIcon color="inherit" />
            ) : (
              "Ajouter nouveau Trail"
            )}
          </ButtonPrimary>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default NewTrail;
