import styled from "styled-components";
import { H6 } from "../textStyles/style";
import axios from "axios";
import { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { languages } from "../../data/SelectOptions";
import { SelectInput } from "../Inputs";
import { Doughnut, Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

const Wrapper = styled.div`
  width: 100%;
  margin-top: 20px;

  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;
const RowReverse = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const SubscriptionsInfo = () => {
  const lngsOptions = [{ label: "All", value: "all" }, ...languages];
  const [year, setYear] = useState(new Date().getFullYear());
  const [data, setData] = useState(null);
  const [language, setLanguage] = useState("all");

  useEffect(() => {
    const getData = async () => {
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      axios
        .get(
          `${process.env.REACT_APP_SUBSCRIPTIONS_INFO}?year=${year}&language=${
            language === "all" ? "" : language
          }`,
          config
        )
        .then(async (res) => {
          setData(res.data);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getData();
  }, [year, language]);

  if (!data) {
    return null;
  }

  const colors = [
    "#FD7064",
    "#1B4965",
    "#fb8c00",
    "#A8C492",
    "#FFCE63",
    "#94CDDB",
    "#d50000",
    "#d500f9",
    "#ff5722",
    "#4527a0",
    "#263238",
  ];

  const colors3 = ["#FD7064", "#1B4965"];
  const labels3 = ["B2B connected users", "B2B available slots"];
  const values3 = [data.company, data.maxB2B];
  const labels = ["B2C users", "B2B users"];
  const values = [data.total - data.company, data.company];
  const labels2 = [
    "Freemium",
    "Lifetime Plan",
    "Monthly Plan",
    "Quarter Plan",
    "Annual Plan",
    "Gift",
  ];
  const values2 = [
    data.freemium,
    data.sub_lifetime + data.iap_sub_lifetime,
    data.sub_one_month + data.iap_sub_one_month,
    data.sub_three_months + data.iap_sub_three_months,
    data.sub_annual + data.iap_sub_annual,
    data.sub_one_month_gift +
      data.sub_three_months_gift +
      data.sub_annual_gift +
      data.sub_lifetime_gift,
  ];
  const values4 = [
    data.user_age[0] && data.user_age[0].count ? data.user_age[0].count : 0,
    data.user_age[1] && data.user_age[1].count ? data.user_age[1].count : 0,
    data.user_age[2] && data.user_age[2].count ? data.user_age[2].count : 0,
    data.user_age[3] && data.user_age[3].count ? data.user_age[3].count : 0,
    data.user_age[4] && data.user_age[4].count ? data.user_age[4].count : 0,
  ];

  const labels4 = ["NP", "46-55", "36-45", "26-35", "18-25"];
  const colors4 = ["#FD7064", "#A8C492", "#1b4965", "#FFCE63", "#d50000"];

  const values5 = [
    data.user_gender[0] && data.user_gender[0].count
      ? data.user_gender[0].count
      : 0,
    data.user_gender[1] && data.user_gender[1].count
      ? data.user_gender[1].count
      : 0,
    data.user_gender[2] && data.user_gender[2].count
      ? data.user_gender[2].count
      : 0,
  ];
  const labels5 = ["F", "M", "NP"];
  const colors5 = ["#FD7064", "#1b4965", "#A8C492"];

  const pourcentageHandler = (number) => {
    if (data.total === 0) {
      return "0.00%";
    } else {
      return ((number / data.total) * 100).toFixed(2) + "%";
    }
  };

  const B2bpourcentageHandler = (number) => {
    if (data.maxB2B === 0) {
      return "0.00%";
    } else {
      return ((number / data.maxB2B) * 100).toFixed(2) + "%";
    }
  };

  const B2bSubscriptionsPourcentage = (number) => {
    if (data.company === 0) {
      return "0.00%";
    } else {
      return ((number / data.company) * 100).toFixed(2) + "%";
    }
  };

  return (
    <Wrapper style={{ backgroundColor: "#f5f5f5", borderRadius: 20 }}>
      <div style={{ margin: 20 }}>
        <RowReverse>
          <div style={{ width: 150, marginRight: 15 }}>
            <SelectInput
              values={lngsOptions}
              placeholder="Language"
              small
              value={language}
              onChange={(e) => {
                setLanguage(e.target.value);
              }}
            />
          </div>
          <TextField
            id="year"
            label="Year"
            style={{ width: "150px" }}
            type="number"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />
        </RowReverse>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "stretch",
          }}
        >
          <div>
            <H6 style={{ fontSize: 22, textDecoration: "underline" }}>
              B2C Subscriptions Details
            </H6>
            <Table>
              <TableHead>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#484848", fontSize: 14 }}
                >
                  Freemium
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#484848", fontSize: 14 }}
                >
                  lifetime Plan
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#484848", fontSize: 14 }}
                >
                  Monthly Plan
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#484848", fontSize: 14 }}
                >
                  Quarter Plan
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#484848", fontSize: 14 }}
                >
                  Annual Plan
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#484848", fontSize: 14 }}
                >
                  lifetime Gift
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#484848", fontSize: 14 }}
                >
                  Monthly Gift
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#484848", fontSize: 14 }}
                >
                  Quarter Gift
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#484848", fontSize: 14 }}
                >
                  Annual Gift
                </TableCell>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      color: "#484848",
                      fontSize: 14,
                    }}
                  >
                    {data.freemium} ({pourcentageHandler(data.freemium)})
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      color: "#484848",
                      fontSize: 14,
                    }}
                  >
                    {`Stripe : ${data.sub_lifetime} (${pourcentageHandler(
                      data.sub_lifetime
                    )})`}
                    <br></br>
                    {`IAP : ${data.iap_sub_lifetime} (${pourcentageHandler(
                      data.iap_sub_lifetime
                    )})`}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      color: "#484848",
                      fontSize: 14,
                    }}
                  >
                    {`Stripe : ${data.sub_one_month} (${pourcentageHandler(
                      data.sub_one_month
                    )})`}
                    <br></br>
                    {`IAP : ${data.iap_sub_one_month} (${pourcentageHandler(
                      data.iap_sub_one_month
                    )})`}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      color: "#484848",
                      fontSize: 14,
                    }}
                  >
                    {`Stripe : ${data.sub_three_months} (${pourcentageHandler(
                      data.sub_three_months
                    )})`}
                    <br></br>
                    {`IAP : ${data.iap_sub_three_months} (${pourcentageHandler(
                      data.iap_sub_three_months
                    )})`}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      color: "#484848",
                      fontSize: 14,
                    }}
                  >
                    {`Stripe : ${data.sub_annual} (${pourcentageHandler(
                      data.sub_annual
                    )})`}
                    <br></br>
                    {`IAP : ${data.iap_sub_annual} (${pourcentageHandler(
                      data.iap_sub_annual
                    )})`}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      color: "#484848",
                      fontSize: 14,
                    }}
                  >
                    {`${data.sub_lifetime_gift} (${pourcentageHandler(
                      data.sub_lifetime_gift
                    )})`}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      color: "#484848",
                      fontSize: 14,
                    }}
                  >
                    {`${data.sub_one_month_gift} (${pourcentageHandler(
                      data.sub_one_month_gift
                    )})`}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      color: "#484848",
                      fontSize: 14,
                    }}
                  >
                    {`${data.sub_three_months_gift} (${pourcentageHandler(
                      data.sub_three_months_gift
                    )})`}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      color: "#484848",
                      fontSize: 14,
                    }}
                  >
                    {`${data.sub_annual_gift} (${pourcentageHandler(
                      data.sub_annual_gift
                    )})`}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div style={{ maxWidth: 260, marginLeft: 10 }}>
            <Pie
              plugins={[ChartDataLabels]}
              options={{
                plugins: {
                  legend: {
                    display: true,

                    position: "right",
                  },
                  datalabels: {
                    display: (context) =>
                      context.dataset.data[context.dataIndex] !== 0,
                    formatter: (value, ctx) => {
                      let sum = data.total;

                      let percentage = ((value / sum) * 100).toFixed(0) + "%";
                      return " " + percentage;
                    },
                    color: "white", // Label color
                    font: {
                      weight: "bold", // Label font weight
                      size: 10,
                    },
                  },
                },
              }}
              data={{
                labels: labels2,
                datasets: [
                  {
                    label: "Users (s)",
                    data: values2,
                    backgroundColor: colors,
                  },
                ],
              }}
            />
          </div>
        </div>
        <H6
          style={{
            fontSize: 22,
            textDecoration: "underline",
          }}
        >
          Customer Segmentation - B2C || B2B{" "}
        </H6>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            marginTop: 15,
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: 330 }}>
              <Table>
                <TableHead>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      color: "#484848",
                      fontSize: 14,
                    }}
                  >
                    Connected B2B
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      color: "#484848",
                      fontSize: 14,
                    }}
                  >
                    B2C
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      color: "#484848",
                      fontSize: 14,
                    }}
                  >
                    TOTAL
                  </TableCell>
                </TableHead>
                <TableBody>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      color: "#484848",
                      fontSize: 14,
                    }}
                  >
                    {data.company} ({pourcentageHandler(data.company)})
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      color: "#484848",
                      fontSize: 14,
                    }}
                  >
                    {data.total - data.company} (
                    {pourcentageHandler(data.total - data.company)})
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      color: "#484848",
                      fontSize: 14,
                    }}
                  >
                    {data.total} (100%)
                  </TableCell>
                </TableBody>
              </Table>
            </div>
            <div style={{ maxWidth: 180, marginLeft: 20 }}>
              <Pie
                plugins={[ChartDataLabels]}
                options={{
                  plugins: {
                    legend: {
                      display: true,

                      position: "bottom",
                    },
                    datalabels: {
                      display: (context) =>
                        context.dataset.data[context.dataIndex] !== 0,
                      formatter: (value, ctx) => {
                        let sum = data.total;

                        let percentage = ((value / sum) * 100).toFixed(0) + "%";
                        return percentage;
                      },
                      color: "white", // Label color
                      font: {
                        weight: "bold", // Label font weight
                      },
                    },
                  },
                }}
                data={{
                  labels,
                  datasets: [
                    {
                      label: "Users (s)",
                      data: values,
                      backgroundColor: colors,
                    },
                  ],
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <H6
                style={{
                  fontSize: 22,
                  textDecoration: "underline",
                  paddingLeft: 10,
                  paddingBottom: 10,
                }}
              >
                B2B Details
              </H6>
              <div>
                <Table>
                  <TableHead>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                        color: "#484848",
                        fontSize: 14,
                      }}
                    >
                      Connected B2B
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                        color: "#484848",
                        fontSize: 14,
                      }}
                    >
                      Available B2B Slots
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                        color: "#484848",
                        fontSize: 14,
                      }}
                    >
                      Max B2B Users
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                        color: "#484848",
                        fontSize: 14,
                      }}
                    >
                      {data.company} ({B2bpourcentageHandler(data.company)})
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                        color: "#484848",
                        fontSize: 14,
                      }}
                    >
                      {data.maxB2B - data.company} (
                      {B2bpourcentageHandler(data.maxB2B - data.company)})
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                        color: "#484848",
                        fontSize: 14,
                      }}
                    >
                      {data.maxB2B} (100%)
                    </TableCell>
                  </TableBody>
                </Table>
              </div>
            </div>
            <div style={{ maxWidth: 180, marginLeft: 40, marginTop: 20 }}>
              <Doughnut
                plugins={[ChartDataLabels]}
                options={{
                  plugins: {
                    legend: {
                      display: true,
                      position: "bottom",
                    },

                    datalabels: {
                      display: (context) =>
                        context.dataset.data[context.dataIndex] !== 0,
                      formatter: (value, ctx) => {
                        let sum = 0;
                        let dataArr = ctx.chart.data.datasets[0].data;
                        dataArr.map((data) => {
                          sum += data;
                        });
                        let percentage = ((value * 100) / sum).toFixed(0) + "%";
                        return percentage;
                      },
                      color: "white", // Label color
                      font: {
                        weight: "bold", // Label font weight
                      },
                    },
                  },
                }}
                data={{
                  labels: labels3,
                  datasets: [
                    {
                      label: "Users (s)",
                      data: values3,
                      backgroundColor: colors3,
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            marginTop: 20,
            marginBottom: 30,
          }}
        >
          <div
            style={{
              height: 200,
              width: "50%",
              marginTop: 20,
              marginRight: 40,
            }}
          >
            <H6 style={{ fontSize: 22, textDecoration: "underline" }}>
              B2B connected users
            </H6>

            <Table style={{ marginTop: 15 }}>
              <TableHead>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#484848", fontSize: 14 }}
                >
                  Prepaid Subscription
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#484848", fontSize: 14 }}
                >
                  Annual Plan (CY24)
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#484848", fontSize: 14 }}
                >
                  Annual Plan (CY35)
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#484848", fontSize: 14 }}
                >
                  Lifetime B2B Subscriptions
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#484848", fontSize: 14 }}
                >
                  Total Connected B2B Users
                </TableCell>
              </TableHead>
              <TableBody>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#484848", fontSize: 14 }}
                >
                  {data.b2b_prepaid} (
                  {B2bSubscriptionsPourcentage(data.b2b_prepaid)})
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#484848", fontSize: 14 }}
                >
                  {data.b2b_sub_annual_cy24} (
                  {B2bSubscriptionsPourcentage(data.b2b_sub_annual_cy24)})
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#484848", fontSize: 14 }}
                >
                  {data.b2b_sub_annual_cy35} (
                  {B2bSubscriptionsPourcentage(data.b2b_sub_annual_cy35)})
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#484848", fontSize: 14 }}
                >
                  {data.b2b_sub_lifetime} (
                  {B2bSubscriptionsPourcentage(data.b2b_sub_lifetime)})
                </TableCell>

                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#484848", fontSize: 14 }}
                >
                  {data.company} ({B2bSubscriptionsPourcentage(data.company)})
                </TableCell>
              </TableBody>
            </Table>
          </div>
          <div
            style={{
              marginTop: 20,
              marginLeft: 20,
              display: "flex",
            }}
          >
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "1px #484848 dashed",
                height: 180,
                marginRight: 10,
              }}
            >
              <H6
                style={{
                  fontSize: 22,
                  textDecoration: "underline",
                  textAlign: "center",
                }}
              >
                Age Details
              </H6>

              <Pie
                style={{ marginTop: 20 }}
                plugins={[ChartDataLabels]}
                options={{
                  plugins: {
                    legend: {
                      display: true,
                      position: "right",
                    },
                    datalabels: {
                      display: (context) =>
                        context.dataset.data[context.dataIndex] !== 0,
                      formatter: (value, ctx) => {
                        let sum = 0;
                        let dataArr = ctx.chart.data.datasets[0].data;
                        dataArr.map((data) => {
                          sum += data;
                        });
                        let percentage = ((value * 100) / sum).toFixed(0) + "%";
                        return percentage;
                      },
                      color: "white", // Label color
                      font: {
                        weight: "bold", // Label font weight
                        size: "10px",
                      },
                    },
                  },
                  maintainAspectRatio: false,
                  height: 200,
                }}
                data={{
                  labels: labels4,
                  datasets: [
                    {
                      label: "Users (s)",
                      data: values4,
                      backgroundColor: colors4,
                    },
                  ],
                }}
              />
            </div>

            <div
              style={{
                alignItems: "center",
                justifyContent: "center",

                height: 180,
              }}
            >
              <H6
                style={{
                  fontSize: 22,
                  textDecoration: "underline",
                  textAlign: "center",
                }}
              >
                Gender Details
              </H6>

              <Pie
                style={{ marginTop: 20 }}
                plugins={[ChartDataLabels]}
                options={{
                  plugins: {
                    legend: {
                      display: true,
                      position: "right",
                    },
                    datalabels: {
                      display: (context) =>
                        context.dataset.data[context.dataIndex] !== 0,
                      formatter: (value, ctx) => {
                        let sum = 0;
                        let dataArr = ctx.chart.data.datasets[0].data;
                        dataArr.map((data) => {
                          sum += data;
                        });
                        let percentage = ((value * 100) / sum).toFixed(0) + "%";
                        return percentage;
                      },
                      color: "white", // Label color
                      font: {
                        weight: "bold", // Label font weight
                        size: "10px",
                      },
                    },
                  },
                  maintainAspectRatio: false,
                  height: 200,
                }}
                data={{
                  labels: labels5,
                  datasets: [
                    {
                      label: "Users (s)",
                      data: values5,
                      backgroundColor: colors5,
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SubscriptionsInfo;
