import styled, { createGlobalStyle } from "styled-components";
import back from "./images/main/tiles.webp";

const GlobalStyle = createGlobalStyle`
    *{
    font-family: "Oxygen";
    font-weight: 400;
    scrollbar-width: thin;
   
    }
`;

export const AppWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const BodyFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  overflow: hidden;
`;

export const BodyOne = styled.div`
  width: 100%;
  position: relative;
  padding-top: ${({ ignore }) => (ignore ? "0px" : "clamp(54px, 8vw, 84px)")};
  box-sizing: border-box;
  top: 0;
  left: 0;
  background-color: #fbf6f3;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

export const BodyTwo = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(${back});
  mix-blend-mode: darken;
  z-index: -1;
  opacity: 0.15;
`;

export default GlobalStyle;
