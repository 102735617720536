import React, { useEffect, useState } from "react";
import { RowBetween } from "../app-users/styles";
import { Wrapper } from "./styles";
import { H6, LoadingIcon } from "../../components/textStyles/style";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../components/buttonStyles/style";
import { PaginationContainer, SearchContainer } from "../app-users/appUsers";
import { SelectInput } from "../../components/Inputs";
import moment from "moment";
import { LoadingBigIcon } from "../../components/textStyles/style";
import { ChipText } from "../../components/tables/styles";
import styled from "styled-components";
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";

const ContentWrapper = styled.div`
  width: 100%;
  padding-top: 30px;

  justify-content: center;
  display: flex;
  min-height: 200px;
`;

const Donations = ({ association_id }) => {
  const donationsStatus = [
    { label: "All", value: "all" },
    { label: "Paid", value: "true" },
    { label: "Unpaid", value: "false" },
  ];

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesNumber, setPagesNumber] = useState(null);
  const [searchTotal, setSearchTotal] = useState(null);
  const [paidFilter, setPaidFilter] = useState("all");

  const RenderDon = ({ don }) => {
    const user = don.user_data[0];
    const [updateLoader, setUpdateLoader] = useState(false);

    const [paid, setPaid] = useState(don.paid);
    const [paidAt, setPaidAt] = useState(don.paidAt ? don.paidAt : new Date());
    const [paidBy, setPaidBy] = useState(
      don.dashboard_user ? don.dashboard_user[0] : null
    );

    const pressHandler = async () => {
      const TOKEN = localStorage.getItem("TOKEN");
      const data = {
        access_token: TOKEN,
        id: don._id,
        paid: !paid,
        paidAt: paid ? null : new Date(),
      };
      if (!updateLoader) {
        setUpdateLoader(true);
        axios
          .put(process.env.REACT_APP_ASSOCIATION_DONATIONS, data)
          .then((res) => {
            setPaid(!paid);
            setPaidAt(new Date());
            console.log(res.data);
            setPaidBy(res.data.dashboard_user[0]);
            setUpdateLoader(false);
          })
          .catch((err) => {
            setUpdateLoader(false);
          });
      }
    };

    return (
      <TableRow key={don._id}>
        <TableCell style={{ color: "#1B4965", fontSize: 13 }} align="center">
          {moment(don.createdAt).format("DD/MM/YYYY HH:mm")}
        </TableCell>
        <TableCell
          style={{
            color: "#1B4965",
            fontSize: 14,
          }}
          align="center"
        >
          <Link
            style={{ color: "#1b4965" }}
            to={`/app-user/${user._id}`}
            target="_blank"
          >
            {user.firstname} {user.lastname}
            <br></br>
            {user.email}
          </Link>
        </TableCell>
        <TableCell
          style={{
            color: "#1B4965",
            fontSize: 14,
          }}
          align="center"
        >
          <ChipText success={paid} danger={!paid}>
            {paid ? "Paid" : "Unpaid"}
          </ChipText>
        </TableCell>

        <TableCell
          align="center"
          style={{
            color: "#1B4965",
            fontSize: 14,
          }}
        >
          {paid ? moment(paidAt).format("DD/MM/YYYY HH:mm") : " - "}
          {paidBy ? `\n${paidBy.firstname} ${paidBy.firstname}` : ""}
        </TableCell>
        <TableCell align="center">
          <ButtonPrimary
            onClick={pressHandler}
            style={{
              width: 200,
              backgroundColor: paid ? "#FD7064" : "#88C1A3",
            }}
          >
            {updateLoader ? (
              <LoadingIcon color="#fff" />
            ) : paid ? (
              "Mark as  unpaid"
            ) : (
              "Mark as paid"
            )}
          </ButtonPrimary>
        </TableCell>
      </TableRow>
    );
  };

  const getData = async () => {
    setLoader(true);
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const url = `${process.env.REACT_APP_ASSOCIATION_DONATIONS}?pageNumber=${currentPage}&association=${association_id}&paid=${paidFilter}`;

    const Response = await axios.get(url, config);
    const { dons, page, pages, count, paidSum, unpaidSum } = Response.data;

    setData(dons);
    console.log("dons", dons);
    setCurrentPage(page);
    setPagesNumber(pages);
    setSearchTotal(count);
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [currentPage, paidFilter]);

  return (
    <Wrapper style={{ marginTop: 20 }}>
      <RowBetween>
        <H6>
          Association Donations{" "}
          <H6 color="#807e7c">({searchTotal} result(s) found)</H6>
        </H6>

        <div style={{ width: 250 }}>
          <SelectInput
            values={donationsStatus}
            placeholder="Status"
            value={paidFilter}
            onChange={(e) => {
              setCurrentPage(1);
              setPaidFilter(e.target.value);
            }}
          />
        </div>
      </RowBetween>
      {/** data */}
      <ContentWrapper>
        {loader ? (
          <div style={{ marginTop: 50 }}>
            <LoadingBigIcon />
          </div>
        ) : data.length == 0 ? (
          <Typography fontSize={18} color="#807e7c">
            No donations found ...
          </Typography>
        ) : (
          <div>
            {pagesNumber && (
              <PaginationContainer>
                <Pagination
                  color="primary"
                  count={pagesNumber}
                  page={currentPage}
                  onChange={(e, val) => setCurrentPage(val)}
                />
              </PaginationContainer>
            )}
            {/** table */}

            <Table>
              <TableHead>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#1B4965", width: 150 }}
                >
                  Created at
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#1B4965", width: 400 }}
                >
                  User
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#1B4965", width: 150 }}
                >
                  Status
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#1B4965", width: 150 }}
                >
                  Paid At
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#1B4965", width: 200 }}
                ></TableCell>
              </TableHead>
              <TableBody>
                {data.map((don) => {
                  return <RenderDon don={don} />;
                })}
              </TableBody>
            </Table>
          </div>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default Donations;
