import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Button } from "@mui/material";
import axios from "axios";
import { H5 } from "../../components/textStyles/style";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend
);

const CompanyWorkshopsStats = ({ company }) => {
  const [select, setSelect] = useState("months");
  const [responseData, setResponseData] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    const getData = async () => {
      axios
        .get(
          `${process.env.REACT_APP_COMPANY_WORKSHOPS_STAT}?select=${select}&company_id=${company}`
        )
        .then((res) => {
          setResponseData(res.data.map((el) => el.count));

          setLabels(
            res.data.map((el) =>
              select === "days"
                ? `${el._id.day}/${el._id.month}/${el._id.year}`
                : select === "months"
                  ? `${el._id.month}/${el._id.year}`
                  : el._id.year
            )
          );
        });
    };
    getData();
  }, [select, company]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      datalabels: {
        display: (context) => context.dataset.data[context.dataIndex] !== 0,
        align: "right", // You can adjust label alignment (top, center, bottom)
        color: "rgb(253, 112, 100)", // Label color
        font: {
          weight: "bold", // Label font weight
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Workshops Views",
        data: responseData,
        borderColor: "rgb(253, 112, 100)",
        borderWidth: 3,
        backgroundColor: "rgba(253, 112, 100, 0.3)",
      },
    ],
  };

  return (
    <div>

      <div>
        <Line options={options} data={data} plugins={[ChartDataLabels]} />
      </div>
    </div>
  );
};

export default CompanyWorkshopsStats;
