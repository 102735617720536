import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { ButtonSecondary } from "../../components/buttonStyles/style";
import NewAssociationContactModal from "../../components/modals/NewAssociationContactModal";
import { H6, LoadingIcon } from "../../components/textStyles/style";
import { genderOptions } from "../../data/SelectOptions";
import { RowBetween } from "../app-users/styles";
import { Wrapper } from "./styles";
import { Delete, Edit, Email, Sms } from "@material-ui/icons";
import SendEmailModal from "../../components/modals/SendEmailModal";
import { useHistory } from "react-router-dom";
import axios from "axios";
import UpdateAssociationContantModal from "../../components/modals/UpdateAssociationContantModal";
import SendSmsModal from "../../components/modals/SendSmsModal";

const AssociationContacts = ({ contacts, association_id }) => {
  const history = useHistory();
  const [showContactModal, setShowContactModal] = useState(false);
  const [showSendMailModal, setShowSendMailModal] = useState(false);
  const [showSendSmsModal, setShowSendSmsModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [deleteModal, setDeleteModal] = useState(null);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [showUpdateContactModal, setShowUpdateContactModal] = useState(false);
  const deleteHandler = () => {
    if (!deleteLoader) {
      setDeleteLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      console.log(
        `${process.env.REACT_APP_ASSOCIATION_CONTACT}?id=${selectedContact._id}`
      );
      try {
        axios.delete(
          `${process.env.REACT_APP_ASSOCIATION_CONTACT}?id=${selectedContact._id}`,
          { data: { access_token: TOKEN } }
        );
        setDeleteLoader(false);
        setDeleteModal(false);
        history.go(0);
      } catch (error) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(error);
        setDeleteLoader(false);
        setDeleteModal(false);
      }
    }
  };

  return (
    <Wrapper>
      <RowBetween>
        <H6>
          Association Contact List -{" "}
          <H6 color="#807e7c">
            {contacts.length} {contacts.length > 1 ? "contacts" : "contact"}
          </H6>
        </H6>

        <ButtonSecondary onClick={() => setShowContactModal(true)}>
          New Contact
        </ButtonSecondary>
      </RowBetween>
      {contacts.length > 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableCell
                style={{ fontWeight: "bold", color: "#1B4965", fontSize: 14 }}
              >
                Civility
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", color: "#1B4965", fontSize: 14 }}
              >
                Lastname
              </TableCell>

              <TableCell
                style={{
                  fontWeight: "bold",
                  color: "#1B4965",
                  fontSize: 14,
                }}
              >
                Firstname
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  color: "#1B4965",
                  fontSize: 14,
                }}
              >
                Position
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  color: "#1B4965",
                  fontSize: 14,
                }}
              >
                Mail
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", color: "#1B4965", fontSize: 14 }}
              >
                Mobile Phone
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", color: "#1B4965", fontSize: 14 }}
              >
                Landline Phone
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", color: "#1B4965", fontSize: 14 }}
              >
                Created At
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", color: "#1B4965", fontSize: 14 }}
              >
                Actions
              </TableCell>
            </TableHead>
            <TableBody>
              {contacts.map((contact) => {
                const createdAt = moment(contact.createdAt).format("LLL"); // January 3, 2023 9:09 PM
                const gender = genderOptions.find(
                  (g) => g.value === contact.gender
                );
                return (
                  <TableRow key={contact._id}>
                    <TableCell style={{ fontSize: 14 }}>
                      {gender ? gender.label : "-"}
                    </TableCell>
                    <TableCell style={{ fontSize: 14 }}>
                      {contact.lastname}
                    </TableCell>
                    <TableCell style={{ fontSize: 14 }}>
                      {contact.firstname}
                    </TableCell>
                    <TableCell style={{ fontSize: 14 }}>
                      {contact.poste}
                    </TableCell>
                    <TableCell style={{ fontSize: 14 }}>
                      {contact.email}
                    </TableCell>

                    <TableCell style={{ fontSize: 14 }}>
                      {contact.mobile}
                    </TableCell>
                    <TableCell style={{ fontSize: 14 }}>
                      {contact.phone}
                    </TableCell>
                    <TableCell style={{ fontSize: 14 }}>{createdAt}</TableCell>
                    <TableCell style={{ fontSize: 14 }}>
                      <ButtonGroup
                        variant="outlined"
                        aria-label="outlined button group"
                      >
                        <Button
                          onClick={() => {
                            setSelectedContact(null);
                            setSelectedContact(contact);
                            setShowUpdateContactModal(true);
                          }}
                        >
                          <Edit />
                        </Button>
                        <Button
                          onClick={() => {
                            setSelectedContact(null);
                            setSelectedContact(contact);
                            setDeleteModal(true);
                          }}
                        >
                          <Delete />
                        </Button>
                        <Button
                          onClick={() => {
                            setSelectedContact(null);
                            setSelectedContact(contact);
                            setShowSendMailModal(true);
                          }}
                        >
                          <Email />
                        </Button>
                        <Button
                          onClick={() => {
                            setSelectedContact(null);
                            setSelectedContact(contact);
                            setShowSendSmsModal(true);
                          }}
                          disabled={contact.mobile < 4}
                        >
                          <Sms />
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {/** new association contact modal */}
      <NewAssociationContactModal
        isOpen={showContactModal}
        setIsOpen={setShowContactModal}
        association={association_id}
      />
      {/** update association contact modal */}
      {selectedContact && (
        <UpdateAssociationContantModal
          isOpen={showUpdateContactModal}
          setIsOpen={setShowUpdateContactModal}
          association={association_id}
          contact={selectedContact}
          setSelectedContact={setSelectedContact}
        />
      )}
      {/**  modal send mail */}
      {selectedContact && (
        <SendEmailModal
          email={selectedContact.email}
          isOpen={showSendMailModal}
          setIsOpen={setShowSendMailModal}
        />
      )}
      {/**  modal send sms */}
      {selectedContact && (
        <SendSmsModal
          phone={selectedContact.mobile}
          isOpen={showSendSmsModal}
          setIsOpen={setShowSendSmsModal}
        />
      )}
      {/** modal delete contact */}
      {/** dialog to confirm delete */}
      {selectedContact && (
        <Dialog
          open={deleteModal}
          onClose={() => {
            setSelectedContact(null);
            setDeleteModal(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            "Supprimer ce contact ?"
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Voulez vous supprimer le contact :{" "}
              {selectedContact.firstname + " " + selectedContact.lastname} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ padding: "10px" }}>
            <Button onClick={deleteHandler}>
              {deleteLoader ? <LoadingIcon /> : "Supprimer"}
            </Button>
            <Button
              autoFocus
              onClick={() => {
                setSelectedContact(null);
                setDeleteModal(false);
              }}
            >
              Annuler
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Wrapper>
  );
};

export default AssociationContacts;
