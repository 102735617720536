import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import axios from "axios";
import ChartDataLabels from "chartjs-plugin-datalabels";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const CompanyUsersStat = ({ company }) => {
  const [select, setSelect] = useState("months");
  const [responseData, setResponseData] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    const getData = async () => {
      axios
        .get(
          `${process.env.REACT_APP_COMPANY_USERS_STAT}?company_id=${company}&select=${select}`
        )
        .then((res) => {
          setResponseData(res.data.map((el) => el.count));

          setLabels(
            res.data.map((el) =>
              select === "days"
                ? `${el._id.day}/${el._id.month}/${el._id.year}`
                : select === "months"
                  ? `${el._id.month}/${el._id.year}`
                  : el._id.year
            )
          );

        });
    };
    getData();
  }, [select, company]);

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Users Inscription(s)",
        data: responseData,
        borderColor: "rgb(27, 73, 101)",
        borderWidth: 3,
        backgroundColor: "rgba(27, 73, 101, 0.3)",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      datalabels: {
        display: (context) => context.dataset.data[context.dataIndex] !== 0,
        align: "left", // You can adjust label alignment (top, center, bottom)
        color: "rgb(27, 73, 101)", // Label color
        font: {
          weight: "bold", // Label font weight
          size: 12
        },
      },
    },
  };

  return (
    <div>

      <div>
        <Line data={data} options={options} plugins={[ChartDataLabels]} />
      </div>

    </div>
  );
};

export default CompanyUsersStat;
