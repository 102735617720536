import {
  ItemBottomSection,
  ItemTopSection,
  Logo,
  SwiperBackground,
  SwiperItemContainer,
} from "./style";
import { H6, TextBold } from "../textStyles/style";
import WhiteLogo from "../../images/main/white-logo-s.svg";

const SwiperItem = ({ Type, item, active }) => {
  return (
    <SwiperItemContainer
      to={
        Type === "Trail"
          ? `/trail/${item.trail_code}`
          : `/workshop/${item.workshop_code}`
      }
      active={active}
    >
      <SwiperBackground src={item.poster_link} />

      <ItemTopSection>
        <Logo src={WhiteLogo} alt="white_logo" />
      </ItemTopSection>
      <ItemBottomSection>
        <TextBold color="white">{Type}</TextBold>
        <H6 color="white">{item.title}</H6>
      </ItemBottomSection>
    </SwiperItemContainer>
  );
};

export default SwiperItem;
