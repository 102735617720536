import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { funSubTypes, types } from "../../data/SelectOptions";
import { ButtonPrimaryLink } from "../buttonStyles/style";
import { H6 } from "../textStyles/style";
import { ChipText, Image } from "./styles";

const WorkshopsTable = ({ data }) => {
  const sortByFree = data.sort((a, b) => Number(b.free) - Number(a.free));
  const numOfFreeTrails = data.filter(
    (workshop) => workshop.free === true
  ).length;

  return (
    <div style={{ marginLeft: 30, marginRight: 30, width: "100%" }}>
      <H6>{numOfFreeTrails}</H6>
      <TableContainer>
        <Table>
          <TableHead>
            <TableCell
              align="center"
              style={{ fontWeight: "bold", color: "#1B4965" }}
            >
              Image
            </TableCell>
            <TableCell
              align="center"
              style={{ fontWeight: "bold", color: "#1B4965" }}
            >
              Atelier Code
            </TableCell>
            <TableCell
              align="center"
              style={{ fontWeight: "bold", color: "#1B4965" }}
            >
              Titre
            </TableCell>
            <TableCell
              align="center"
              style={{ fontWeight: "bold", color: "#1B4965" }}
            >
              Duration
            </TableCell>
            <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
              Catégorie
            </TableCell>
            <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
              Sous Catégorie
            </TableCell>
            <TableCell
              align="center"
              style={{ fontWeight: "bold", color: "#1B4965" }}
            >
              Free ?
            </TableCell>

            <TableCell
              style={{ fontWeight: "bold", color: "#1B4965" }}
            ></TableCell>
          </TableHead>
          <TableBody>
            {sortByFree.map((workshop) => {
              const type = types.find((type) => type.value === workshop.type);
              const subType = funSubTypes.find(
                (sub) => sub.value === workshop.subType
              );
              return (
                <TableRow key={workshop.workshop_code}>
                  {/** image */}
                  <TableCell>
                    <Image src={workshop.poster_link} />
                  </TableCell>
                  <TableCell align="center">{workshop.workshop_code}</TableCell>
                  <TableCell align="center">{workshop.title}</TableCell>
                  <TableCell align="center">
                    {workshop.duration ? workshop.duration + " min" : " - "}
                  </TableCell>
                  <TableCell align="center">
                    {type ? type.label : " - "}
                  </TableCell>{" "}
                  <TableCell align="center">
                    {subType ? subType.label : " - "}
                  </TableCell>
                  <TableCell align="center">
                    {workshop.free ? (
                      <ChipText success>Gratuit</ChipText>
                    ) : (
                      <ChipText>Payant</ChipText>
                    )}
                  </TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">
                    <ButtonPrimaryLink
                      to={`/workshop/${workshop.workshop_code}`}
                      width="40px"
                      height="40px"
                    >
                      &#8594;
                    </ButtonPrimaryLink>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default WorkshopsTable;
