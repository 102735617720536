import {
    Button,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
    H5,
    LoadingBigIcon,
    SmallText,
} from "../../components/textStyles/style";
import axios from "axios";
import {
    genderOptions,
    ageOptions,
} from "../../data/SelectOptions";
import { ButtonPrimaryLink } from "../../components/buttonStyles/style";
import { SearchInput, } from "../../components/Inputs";
import { useParams } from "react-router-dom";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
    width: 100vw;
    min-height: ${Height};
    display: flex;
    justify-content: center;
  `;
const ContentWrapper = styled.div`
    background-color: white;
    width: 90%;
    padding: 30px;
    border-radius: 20px;
    margin: 30px 0;
  `;

const LoadWrapper = styled.div`
    width: 100%;
    display: flex;
    margin-top: 50px;
    align-items: center;
    justify-content: center;
  `;

export const PaginationContainer = styled.div`
    width: 100%;
    display: flex;
  
    align-items: center;
    justify-content: center;
  `;

export const SearchContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
  `;

export const getSubscription = (info) => {
    switch (info) {
        case "freemium":
            return "Freemium";
        case "company":
            return "company";
        case "sub_one_month":
            return "Monthly Plan (Stripe)";
        case "sub_three_months":
            return "3 Months Plan (Stripe)";
        case "sub_annual":
            return "Annual Plan (Stripe)";
        case "sub_lifetime":
            return "Lifetime Plan (Stripe)";
        case "iap_sub_one_month":
            return "Monthly Plan (IAP)";
        case "iap_sub_three_months":
            return "3 Months Plan (IAP)";
        case "iap_sub_annual":
            return "Annual Plan (IAP)";
        case "iap_sub_lifetime":
            return "Lifetime Plan (IAP)";
        case "sub_one_month_gift":
            return "Monthly Plan (Gift)";
        case "sub_three_months_gift":
            return "3 Months Plan (Gift)";
        case "sub_annual_gift":
            return "Annual Plan (Gift)";
        case "sub_lifetime_gift":
            return "Lifetime Plan (Gift)";
        case "sub_annual_cy35":
            return "Annual Plan CY35";
        case "sub_annual_cy24":
            return "Annual Plan CY24";
        default:
            return "-";
    }
};

const CompanyUsers = () => {
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [companyData, setCompanyData] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [pagesNumber, setPagesNumber] = useState(null);
    const [searchTotal, setSearchTotal] = useState(null);
    const [keyword, setKeyword] = useState("");


    const { company } = useParams()

    const getData = async () => {
        setLoader(true);
        const TOKEN = localStorage.getItem("TOKEN");
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${TOKEN}`,
            },
        };
        const Response = await axios.get(
            `${process.env.REACT_APP_GET_COMPANY_USERS}?keyword=${keyword}&pageNumber=${currentPage}&company=${company}`,
            config
        );
        const { users, page, pages, count } = Response.data;

        setData(users);
        setCurrentPage(page);
        setPagesNumber(pages);
        setSearchTotal(count);
        setLoader(false);
    };

    useEffect(() => {
        getData();
    }, [currentPage, keyword]);


    const getCompany = async () => {
        const TOKEN = localStorage.getItem("TOKEN");
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${TOKEN}`,
            },
        };
        const response = await axios.get(
            `${process.env.REACT_APP_GET_COMPANY}?company_id=${company}`,
            config
        );

        setCompanyData(response.data);
    }

    useEffect(() => {
        getCompany()
    }, [])

    return (
        <Wrapper>
            <ContentWrapper>
                <H5>{companyData && `${companyData.name}`} - USERS</H5>

                <hr />
                <SearchContainer>

                    <SearchInput
                        onFocus={() => setCurrentPage(1)}
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                    />

                    <SmallText>{searchTotal} users found</SmallText>
                </SearchContainer>

                {data.length > 0 && pagesNumber && (
                    <PaginationContainer>
                        <Pagination
                            color="primary"
                            count={pagesNumber}
                            page={currentPage}
                            onChange={(e, val) => setCurrentPage(val)}
                        />
                    </PaginationContainer>
                )}

                {loader ? (
                    <LoadWrapper>
                        <LoadingBigIcon />
                    </LoadWrapper>
                ) : data.length > 0 ? (
                    <div style={{ margin: 30, marginTop: 10 }}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableCell
                                        align="center"
                                        style={{ fontWeight: "bold", color: "#1B4965" }}
                                    >
                                        Pseudo
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        style={{ fontWeight: "bold", color: "#1B4965" }}
                                    >
                                        Gender
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        style={{ fontWeight: "bold", color: "#1B4965" }}
                                    >
                                        Lastname
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        style={{ fontWeight: "bold", color: "#1B4965" }}
                                    >
                                        Firstname
                                    </TableCell>

                                    <TableCell
                                        align="center"
                                        style={{ fontWeight: "bold", color: "#1B4965" }}
                                    >
                                        Mail
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        style={{ fontWeight: "bold", color: "#1B4965" }}
                                    >
                                        Phone
                                    </TableCell>

                                    <TableCell
                                        align="center"
                                        style={{ fontWeight: "bold", color: "#1B4965" }}
                                    >
                                        Age range
                                    </TableCell>

                                    <TableCell
                                        align="center"
                                        style={{ fontWeight: "bold", color: "#1B4965" }}
                                    ></TableCell>
                                    <TableCell
                                        align="center"
                                        style={{ fontWeight: "bold", color: "#1B4965" }}
                                    ></TableCell>
                                </TableHead>
                                <TableBody>
                                    {data.map((user) => {
                                        const age = ageOptions.find(
                                            (age) => age.value === user.age
                                        );
                                        const gender = genderOptions.find(
                                            (gender) => gender.value === user.genre
                                        );
                                        if (user.firstname === "") {
                                            return null;
                                        }
                                        return (
                                            <TableRow key={user._id}>
                                                <TableCell align="center" scope="row">
                                                    {user.pseudo}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {gender ? gender.label : "-"}
                                                </TableCell>
                                                <TableCell align="center" scope="row">
                                                    {user.lastname}
                                                </TableCell>
                                                <TableCell align="center" scope="row">
                                                    {user.firstname}
                                                </TableCell>

                                                <TableCell align="center" scope="row">
                                                    {user.email}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {user.phoneCode + user.phoneNumber === "+"
                                                        ? "-"
                                                        : user.phoneCode + user.phoneNumber}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {age ? age.label : "-"}
                                                </TableCell>

                                                <TableCell align="center">
                                                    <Button
                                                        onClick={(e) =>
                                                            window.open(
                                                                `https://dashboard.stripe.com/customers/${user.customer}`,
                                                                "_blank"
                                                            )
                                                        }
                                                    >
                                                        Stripe
                                                    </Button>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <ButtonPrimaryLink
                                                        to={`/app-user/${user._id}`}
                                                        width="40px"
                                                        height="40px"
                                                    >
                                                        &#8594;
                                                    </ButtonPrimaryLink>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {pagesNumber && (
                            <PaginationContainer style={{ marginTop: 20 }}>
                                <Pagination
                                    color="primary"
                                    count={pagesNumber}
                                    page={currentPage}
                                    onChange={(e, val) => setCurrentPage(val)}
                                />
                            </PaginationContainer>
                        )}
                    </div>
                ) : (
                    <></>
                )}
            </ContentWrapper>
        </Wrapper>
    );
};

export default CompanyUsers;
