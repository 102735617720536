import styled from "styled-components";
import { H5 } from "../textStyles/style";
import axios from "axios";
import icon_trails from "../../images/main/icon_home/image21.png";
import icon_books from "../../images/main/icon_home/image3.png";
import icon_workshops from "../../images/main/icon_home/image27.png";
import icon_message from "../../images/main/icon_home/image4.png";
import icon_Episode from "../../images/main/icon_home/image19.png";
import { useState, useEffect } from "react";
import { Button, ButtonGroup, Typography } from "@mui/material";

const Wrapper = styled.div`
  align-items: left;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
`;
const RowBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ElementsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  margin-top: 20px;
`;

const ElementContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  padding-top: 10px;
  width: 19%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ContentStat = () => {
  const [english, setEnglish] = useState("all");
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const getData = async () => {
    setLoader(true);
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };

    // will need a proprer API call !

    const Response = await axios.get(
      `${process.env.REACT_APP_GET_CONTENT_INFO}?english=${english}`,
      config
    );
    const Result = Response.data;

    setData(Result);
    setLoader(false);
  };
  useEffect(() => {
    getData();
  }, [english]);

  return (
    <Wrapper>
      <RowBetween style={{ paddingLeft: 10, paddingRight: 10 }}>
        <H5 style={{ fontSize: 22, textDecoration: "underline" }}>
          Contents Informations
        </H5>
        <ButtonGroup>
          <Button
            size="small"
            onClick={() => setEnglish("all")}
            variant="contained"
            color={english != "all" ? "inherit" : "primary"}
          >
            all
          </Button>
          <Button
            size="small"
            onClick={() => setEnglish("fr")}
            variant="contained"
            color={english != "fr" ? "inherit" : "primary"}
          >
            fr
          </Button>
          <Button
            size="small"
            onClick={() => setEnglish("en")}
            variant="contained"
            color={english != "en" ? "inherit" : "primary"}
          >
            en
          </Button>
        </ButtonGroup>
      </RowBetween>
      <ElementsContainer>
        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Trails
          </Typography>
          <img height={50} src={icon_trails} alt="Trails Icon" />

          <Typography>{data.nbrTrails}</Typography>
        </ElementContainer>

        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Episode
          </Typography>
          <img height={50} src={icon_Episode} alt="Episode Icon" />

          <Typography>{data.nbrEpisode}</Typography>
        </ElementContainer>

        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Workshops
          </Typography>
          <img height={50} src={icon_workshops} alt="Workshops Icon" />

          <Typography>{data.nbrWorkshops}</Typography>
        </ElementContainer>

        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Monthly Messages
          </Typography>

          <img height={50} src={icon_message} alt="Message Icon" />
          <Typography>{data.nbrmessage}</Typography>
        </ElementContainer>

        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Books
          </Typography>
          <img
            height={50}
            src={icon_books}
            alt="Books Icon"
            style={{ marginRight: "10px" }}
          />

          <Typography>{data.nbrBook}</Typography>
        </ElementContainer>
      </ElementsContainer>
    </Wrapper>
  );
};

export default ContentStat;
