import React from "react";
import { useState } from "react";
import { SelectInput } from "../../components/Inputs";
import {
    languagesWithAll,
    monthsOptionsWithoutAll,
    yearsOptionsWithoutAll,
} from "../../data/SelectOptions";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useEffect } from "react";
import axios from "axios";
import { lislColors } from "../../styles/colors";

const black = "transparent";
const black2 = "transparent";


const ChurnRate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const [selectedYear, setSelectedYear] = useState(year);
    const [lng, setLng] = useState("all");
    const [totalOfYear, setTotalOfYear] = useState(0);



    const getTotal = (y, l) => {
        axios
            .get(
                `${process.env.REACT_APP_COMPANY_STAT}/users-lost-number?year=${y}&language=${l}`
            )
            .then((res) => {
                const response = res.data;
                setTotalOfYear(response.total)
            });
    }

    useEffect(() => {
        getTotal(selectedYear, lng)
    }, [selectedYear, lng])


    const RenderCell = ({ el, i, type }) => {
        const [data, setData] = useState({});
        useEffect(() => {
            axios
                .get(
                    `${process.env.REACT_APP_COMPANY_STAT}/churn-rate?year=${selectedYear}&month=${el.value}&language=${lng}&type=${type}`
                )
                .then((res) => {
                    const response = res.data;

                    setData(response);
                });
        }, [selectedYear, lng]);

        return (
            <TableCell
                align="center"
                style={{
                    fontWeight: "bold",
                    color: "#1B4965",
                    fontSize: type === "all" ? 14 : 12,
                    backgroundColor:
                        type === "all"
                            ? "transparent"
                            : type === "freemium"
                                ? black2
                                : type === "company"
                                    ? black
                                    : black2,
                }}
            >
                {data.count ? data.count : "0"}
            </TableCell>
        );
    };



    return (
        <div
            style={{
                marginTop: 20,
                backgroundColor: "#f5f5f5",
                padding: 20,
                borderRadius: 15,
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography style={{ fontSize: 20, color: "#484848" }}>
                    Churn Rate (Lost users)
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: 100, marginRight: 10 }}>
                        <SelectInput
                            values={languagesWithAll}
                            placeholder="Language *"
                            value={lng}
                            onChange={(e) => setLng(e.target.value)}
                        />
                    </div>
                    <div style={{ width: 100 }}>
                        <SelectInput
                            values={yearsOptionsWithoutAll}
                            placeholder="Year *"
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            {/** table */}
            <Table style={{ marginTop: 12, marginBottom: 12 }}>
                <TableHead>
                    <TableCell
                        align="center"
                        style={{ fontWeight: "bold", color: "#1B4965", fontSize: 12 }}
                    >
                        Month
                    </TableCell>
                    {monthsOptionsWithoutAll.map((el) => (
                        <TableCell
                            align="center"
                            key={el.value}
                            style={{ fontWeight: "bold", color: "#1B4965", fontSize: 12 }}
                        >
                            {el.label}
                        </TableCell>
                    ))}
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell
                            align="center"
                            style={{ fontWeight: "bold", color: "#1b4965", fontSize: 12, backgroundColor: black2 }}
                        >
                            Subscribed Users
                        </TableCell>
                        {monthsOptionsWithoutAll.map((el, i) => (
                            <RenderCell key={el.value} el={el} i={i} type="subscribed" />
                        ))}
                    </TableRow>
                    <TableRow>
                        <TableCell
                            align="center"
                            style={{ fontWeight: "bold", color: "#1b4965", backgroundColor: black }}
                        >
                            B2B Users
                        </TableCell>
                        {monthsOptionsWithoutAll.map((el, i) => (
                            <RenderCell key={el.value} el={el} i={i} type="company" />
                        ))}
                    </TableRow>
                    <TableRow>
                        <TableCell
                            align="center"
                            style={{ fontWeight: "bold", color: "#1b4965", fontSize: 12, backgroundColor: black2 }}
                        >
                            Freemium Users
                        </TableCell>
                        {monthsOptionsWithoutAll.map((el, i) => (
                            <RenderCell key={el.value} el={el} i={i} type="freemium" />
                        ))}
                    </TableRow>
                    <TableRow>
                        <TableCell
                            align="center"
                            style={{ fontWeight: "bold", color: "#1B4965", fontSize: 14 }}
                        >
                            Total
                        </TableCell>
                        {monthsOptionsWithoutAll.map((el, i) => (
                            <RenderCell key={el.value} el={el} i={i} type="all" />
                        ))}
                    </TableRow>
                </TableBody>
            </Table>
            <div style={{ display: 'flex', flexDirection: 'row-reverse', }}>
                <Typography bgcolor='rgb(123, 150, 101)' style={{ padding: 10, fontSize: 14 }} fontWeight="500" color={lislColors.white} >{totalOfYear} Lost users in {selectedYear}</Typography>
            </div>
        </div>
    );
};

export default ChurnRate;
