import styled from "styled-components";
import { H5 } from "../textStyles/style";
import axios from "axios";
import icon_msg_not_approved from "../../images/main/icon_home/image14.png";
import icon_compLimit from "../../images/main/icon_home/image10.png";
import icon_coach from "../../images/main/icon_home/image17.png";
import { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Button, ButtonGroup, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  align-items: left;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
`;
const RowBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ElementsContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  margin: 10px;
  margin-top: 20px;
  flex-wrap: wrap;
`;

const ElementContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  padding-top: 10px;
  width: 19%;
  display: flex;
  flex-direction: column;
  margin-right: 1%;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const WarningStat = () => {
  const [yearOptions, setYearOptions] = useState(false);
  const [language, setLanguage] = useState("all");
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const getData = async () => {
    setLoader(true);
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };

    // will need a proprer API call !

    const Response = await axios.get(
      `${process.env.REACT_APP_GET_WARNING_INFO}?language=${language}&year=${yearOptions ? year : ""
      }`,

      config
    );
    const Result = Response.data;
    console.log(`${process.env.REACT_APP_GET_WARNING_INFO}?language=${language}&year=${yearOptions ? year : ""}`)
    setData(Result);

    setLoader(false);
  };
  useEffect(() => {
    getData();
  }, [language, year]);

  return (
    <Wrapper >
      <RowBetween style={{ paddingLeft: 10, paddingRight: 10 }}>
        <H5 style={{ fontSize: 22, textDecoration: "underline" }}>Warnings</H5>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            size="small"
            onClick={() => setYearOptions(!yearOptions)}
            variant="contained"
            color={yearOptions != true ? "inherit" : "primary"}
            style={{ marginRight: 10 }}
          >
            <Typography style={{ textTransform: "capitalize" }} fontSize={12}>
              Year Option
            </Typography>
          </Button>

          <TextField
            size="sm"
            disabled={!yearOptions}
            id="year"
            style={{ width: "100px" }}
            type="number"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />
        </div>

        <ButtonGroup>
          <Button
            size="small"
            onClick={() => setLanguage("all")}
            variant="contained"
            color={language != "all" ? "inherit" : "primary"}
          >
            all
          </Button>
          <Button
            size="small"
            onClick={() => setLanguage("fr")}
            variant="contained"
            color={language != "fr" ? "inherit" : "primary"}
          >
            fr
          </Button>
          <Button
            size="small"
            onClick={() => setLanguage("en")}
            variant="contained"
            color={language != "en" ? "inherit" : "primary"}
          >
            en
          </Button>
        </ButtonGroup>
      </RowBetween>
      <ElementsContainer>
        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Post waiting for approval
          </Typography>
          <img height={50} src={icon_msg_not_approved} alt="Client Icon" />
          <Typography>{data.message}</Typography>
        </ElementContainer>
        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Corp limit reached
          </Typography>
          <img height={50} src={icon_compLimit} alt="Active Icon" />
          <Typography>
            {data && data.companies && data.companies.length > 0
              ? data.companies.length
              : "0"}
          </Typography>
        </ElementContainer>
        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Corp limit reached List
          </Typography>
          <div
            style={{
              height: 100,
              padding: "10px",
              width: "90%",
              overflowY: "scroll",
            }}
          >
            {data.companies &&
              data.companies.map((item) => (
                <div key={item._id}>
                  <Link to={`/company/${item._id}`} width="40px" height="40px">
                    {item.name}
                  </Link>
                </div>
              ))}
          </div>
        </ElementContainer>
        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Coaching on hold
          </Typography>
          <img height={50} src={icon_coach} alt="Message Icon" />
          <Typography>
            {data && data.coaching && data.coaching.length > 0
              ? data.coaching.length + ' Appointment(s)'
              : "0 Appointment"}
          </Typography>
        </ElementContainer>
        <ElementContainer>
          <Typography color="#1b4965" fontWeight={"600"} textAlign="center">
            Coaching on hold  List
          </Typography>

          <div
            style={{
              height: 100,
              padding: "10px",
              width: "90%",
              overflowY: "scroll",
            }}
          >
            {data.coaching &&
              data.coaching.map((item) => (
                <div key={item._id}>
                  <Link
                    to={`/appointment/${item._id}`}
                    width="40px"
                    height="40px"
                  >
                    {item.email}
                  </Link>
                </div>
              ))}
          </div>
        </ElementContainer>
        {/** Cancel By Expert appointment */}
        <ElementContainer style={{ marginTop: 15, paddingBottom: 10 }}>
          <Typography color="#1b4965" fontWeight={"600"} fontSize={13} textAlign="center">
            Appointments canceld by Experts
          </Typography>
          <img height={50} src={icon_msg_not_approved} alt="Client Icon" />
          <Typography>{data.canceledCoachingByExpert?.length} Appointment(s)</Typography>
        </ElementContainer>
        <ElementContainer style={{ marginTop: 15, paddingBottom: 10 }}>
          <Typography color="#1b4965" fontWeight={"600"} fontSize={13} textAlign="center">
            Appointments canceld by Experts
          </Typography>
          <div
            style={{
              height: 100,
              padding: "10px",
              width: "90%",
              overflowY: "scroll",
            }}
          >
            {data.canceledCoachingByExpert &&
              data.canceledCoachingByExpert.map((item) => (
                <div key={item._id}>
                  <Link to={`/appointment/${item._id}`} width="40px" height="40px">
                    {item.email}
                  </Link>
                </div>
              ))}
          </div>
        </ElementContainer>
        {/** Cancel By User and need to be refund */}
        <ElementContainer style={{ marginTop: 15, paddingBottom: 10 }} >
          <Typography color="#1b4965" fontSize={13} fontWeight={"600"} textAlign="center">
            Appointments To Refund
          </Typography>
          <img height={50} src={icon_msg_not_approved} alt="Client Icon" />
          <Typography>{data.canceledCoachingToRefund?.length} Appointment(s)</Typography>
        </ElementContainer>
        <ElementContainer style={{ marginTop: 15, paddingBottom: 10 }} >
          <Typography color="#1b4965" fontWeight={"600"} fontSize={13} textAlign="center">
            Appointment to Refund
          </Typography>
          <div
            style={{
              height: 100,
              padding: "10px",
              width: "90%",
              overflowY: "scroll",
            }}
          >
            {data.canceledCoachingToRefund &&
              data.canceledCoachingToRefund.map((item) => (
                <div key={item._id}>
                  <Link to={`/appointment/${item._id}`} width="40px" height="40px">
                    {item.email}
                  </Link>
                </div>
              ))}
          </div>
        </ElementContainer>

        {/** Cancel By User and need to be refund */}
        <ElementContainer style={{ marginTop: 15, paddingBottom: 10, backgroundColor: 'transparent' }} >

        </ElementContainer>
        {/** Cancel By User and need to be refund */}
        <ElementContainer style={{ marginTop: 15, paddingBottom: 10 }} >
          <Typography color="#1b4965" fontSize={13} fontWeight={"600"} textAlign="center">
            {`Company Contract that ENDS IN < 30 DAYS`}
          </Typography>
          <img height={50} src={icon_msg_not_approved} alt="Client Icon" />
          <Typography>{data.closeFinishedContracts?.length} contract(s)</Typography>
        </ElementContainer>
        <ElementContainer style={{ marginTop: 15, paddingBottom: 10 }} >
          <Typography color="#1b4965" fontWeight={"600"} style={{ marginRight: 10, marginLeft: 10 }} fontSize={13} textAlign="center">
            {`Companies with contracts that  ENDS IN < 30 DAYS`}
          </Typography>
          <div
            style={{
              height: 100,
              padding: "10px",
              width: "90%",
              overflowY: "scroll",
            }}
          >
            {data.closeFinishedContracts &&
              data.closeFinishedContracts.map((item) => (
                <div key={item._id}>
                  <Link to={`/company/${item._id}`} width="40px" height="40px">
                    {item.name}
                  </Link>
                </div>
              ))}
          </div>
        </ElementContainer>

      </ElementsContainer>
    </Wrapper>
  );
};

export default WarningStat;
