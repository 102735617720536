import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { H6 } from "../../../components/textStyles/style";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend
);

const GroupChatEntries = ({ chatEntriesByMonth }) => {
  const [responseData, setResponseData] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    setResponseData(chatEntriesByMonth.map((el) => el.count));

    setLabels(chatEntriesByMonth.map((el) => `${el._id.month}/${el._id.year}`));
  }, [chatEntriesByMonth]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      datalabels: {
        display: (context) => context.dataset.data[context.dataIndex] !== 0,
        align: "right", // You can adjust label alignment (top, center, bottom)
        color: "rgb(28, 73, 102)", // Label color
        font: {
          weight: "bold", // Label font weight
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        fill: true,

        label: "Live Chat entries",
        data: responseData,
        borderColor: "rgb(28, 73, 102)",
        borderWidth: 3,
        backgroundColor: "rgba(28, 73, 102, 0.3)",
      },
    ],
  };

  return (
    <div style={{ width: "100%" }}>
      <H6 style={{ fontSize: 13, textTransform: 'uppercase', fontWeight: "bold" }} color="#222">
        2.1 Chat entries
      </H6>

      <div style={{ width: "100%" }}>
        <Line
          style={{ width: "100%" }}
          plugins={[ChartDataLabels]}
          options={options}
          data={data}
        />
      </div>
    </div>
  );
};

export default GroupChatEntries;
