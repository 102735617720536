import React from "react";
import { useState } from "react";
import { SelectInput } from "../../components/Inputs";
import {
    languagesWithAll,
    monthsOptionsWithoutAll,
    yearsOptionsWithoutAll,
} from "../../data/SelectOptions";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useEffect } from "react";
import axios from "axios";

const SubscriptionChange = () => {
    const now = new Date();
    const year = now.getFullYear();
    const [selectedYear, setSelectedYear] = useState(year);
    const [lng, setLng] = useState('all')
    const [totals, setTotals] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [totalsUpgrades, setTotalsUpgrades] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [totalsDowngrades, setTotalsDowngrades] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    function calculateSumWithAcc(arr) {
        const sum = arr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        return sum;
    }

    const totalOfYear = calculateSumWithAcc(totals)
    const totalUpgradesOfYear = calculateSumWithAcc(totalsUpgrades)
    const totalDownGradeOfYear = calculateSumWithAcc(totalsDowngrades)


    const RenderCell = ({ el, type, i }) => {
        const [data, setData] = useState({})


        function calculateTotalCount(arr) {
            let total = 0;
            for (let i = 0; i < arr.length; i++) {
                total += arr[i].count;
            }
            return total;
        }

        useEffect(() => {
            axios.get(`${process.env.REACT_APP_COMPANY_STAT}/subscription-change?year=${selectedYear}&month=${el.value}&language=${lng}`).then(res => {
                const response = res.data
                const tot = calculateTotalCount(response)

                const arr = response.filter(item => item._id === type)
                if (arr[0]) {
                    setData(arr[0])
                    let tts = totals
                    tts[i] = tot
                    setTotals(tts)

                    if (type === "upgrade") {
                        let TotUpgrade = totalsUpgrades
                        TotUpgrade[i] = arr[0].count
                        setTotalsUpgrades(TotUpgrade)
                    } else {
                        let TotDowngrade = totalsDowngrades
                        TotDowngrade[i] = arr[0].count
                        setTotalsDowngrades(TotDowngrade)
                    }

                }

            })
        }, [year])

        return (
            <TableCell
                align="center"
                style={{ fontWeight: "bold", color: "#484848", fontSize: 12 }}
            >
                {data.count ? data.count : "0"}
            </TableCell>
        );
    };

    return (
        <div
            style={{
                marginTop: 20,
                backgroundColor: "#f5f5f5",
                padding: 20,
                borderRadius: 15,
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography style={{ fontSize: 20, color: '#484848' }}>Plans Upgrade / Downgrade</Typography>
                <div style={{ display: 'flex', alignItems: "center" }}>
                    <div style={{ width: 100, marginRight: 10, }}>
                        <SelectInput
                            values={languagesWithAll}
                            placeholder="Language *"
                            value={lng}
                            onChange={(e) => setLng(e.target.value)}
                        />
                    </div>
                    <div style={{ width: 100, }}>
                        <SelectInput
                            values={yearsOptionsWithoutAll}
                            placeholder="Year *"
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(e.target.value)}
                        />
                    </div>
                </div>

            </div>
            {/** table */}
            <Table style={{ marginTop: 12, marginBottom: 12 }}>
                <TableHead>
                    <TableCell
                        align="center"
                        style={{ fontWeight: "bold", color: "#1B4965", fontSize: 12 }}
                    >
                        Month
                    </TableCell>
                    {[...monthsOptionsWithoutAll, { value: '', label: 'Total' }].map((el) => (
                        <TableCell
                            align="center"
                            key={el.value}
                            style={{ fontWeight: "bold", color: el.label === "Total" ? '#1a612d' : "#1B4964", fontSize: el.label === "Total" ? 14 : 12 }}
                        >
                            {el.label}
                        </TableCell>
                    ))}
                </TableHead>
                <TableBody>
                    {/** upgrade */}

                    <TableRow>
                        <TableCell
                            align="center"
                            style={{ fontWeight: "bold", color: "#1B4965", fontSize: 12 }}
                        >
                            UPGRADES
                        </TableCell>
                        {monthsOptionsWithoutAll.map((el, i) => (
                            <RenderCell el={el} type="upgrade" i={i} />
                        ))}
                        <TableCell
                            align="center"
                            style={{ fontWeight: "bold", color: "#1a612d", fontSize: 14 }}
                        >
                            {totalUpgradesOfYear}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            align="center"
                            style={{ fontWeight: "bold", color: "#1B4965", fontSize: 12 }}
                        >
                            DOWNGRADES
                        </TableCell>
                        {monthsOptionsWithoutAll.map((el, i) => (
                            <RenderCell el={el} type="downgrade" i={i} />
                        ))}
                        <TableCell
                            align="center"
                            style={{ fontWeight: "bold", color: "#1a612d", fontSize: 14 }}
                        >
                            {totalDownGradeOfYear}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            align="center"
                            style={{ fontWeight: "bold", color: "#1a612d", fontSize: 14 }}
                        >
                            Total
                        </TableCell>
                        {totals.map((num) => (
                            <TableCell
                                align="center"
                                style={{ fontWeight: "bold", color: "#1a612d", fontSize: 14 }}
                            >
                                {num}
                            </TableCell>

                        ))}
                        <TableCell
                            align="center"
                            style={{ fontWeight: "bold", color: "#1a612d", fontSize: 14 }}
                        >
                            {totalOfYear}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

        </div>
    );
};

export default SubscriptionChange;
