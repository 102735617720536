import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import { Input } from "../../components/Inputs";
import {
  H5,
  H6,
  LoadingIcon,
  TextBold,
} from "../../components/textStyles/style";
import "../../styles/custom-phone-input.css";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { typeNotificationOptions } from "../../data/SelectOptions";
let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const NewNotification = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [textFr, setTextFr] = useState("");
  const [textEn, setTextEn] = useState("");
  const [titleFr, setTitleFr] = useState("");
  const [titleEn, setTitleEn] = useState("");
  const [type, setType] = useState("one_use");

  const submitHandler = async () => {
    if (!loading) {
      setLoading(true);
      setErrorMessage("");
      const TOKEN = localStorage.getItem("TOKEN");
      try {
        const data = {
          access_token: TOKEN,
          text_fr: textFr,
          text_en: textEn,
          title_fr: titleFr,
          title_en: titleEn,
          type,
        };

        await axios.post(process.env.REACT_APP_NOTIFICATION, data);
        setLoading(false);
        history.goBack();
      } catch (error) {
        setLoading(false);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(error);
        setErrorMessage("Problem connexion with server");
      }
    }
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <H5>New Notification</H5>
        <Row>
          <TextBold color="#c22222">
            {errorMessage ? errorMessage : " "}
          </TextBold>
        </Row>
        <Row>
          <Input
            placeholder="Notification Title - FR"
            value={titleFr}
            onChange={(e) => setTitleFr(e.target.value)}
          />
          <Input
            placeholder="Notification Title - EN"
            value={titleEn}
            onChange={(e) => setTitleEn(e.target.value)}
          />
        </Row>
        <Row>
          <Input
            multiline
            placeholder="Notification Content - FR"
            value={textFr}
            onChange={(e) => setTextFr(e.target.value)}
          />
          <Input
            multiline
            placeholder="Notification Content - EN"
            value={textEn}
            onChange={(e) => setTextEn(e.target.value)}
          />
        </Row>
        <div style={{ marginTop: 20, marginLeft: 10 }}>
          <FormControl>
            <H6 id="type-buttons-group-label">Notification Delivery Options</H6>
            <RadioGroup
              aria-labelledby="type-buttons-group-label"
              value={type}
              name="type-buttons-group"
              onChange={handleTypeChange}
            >
              {typeNotificationOptions.map((element) => (
                <FormControlLabel
                  color="#1b4965"
                  key={element.value}
                  value={element.value}
                  control={<Radio />}
                  label={element.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>

        <Row style={{ marginTop: 40 }}>
          <ButtonPrimary onClick={submitHandler}>
            {loading ? <LoadingIcon color="inherit" /> : "Add new notification"}
          </ButtonPrimary>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default NewNotification;
