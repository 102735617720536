import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ButtonPrimary, ButtonSecondary } from "../buttonStyles/style";
import { H6, LoadingIcon, SmallBoldText } from "../textStyles/style";
import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import PhoneInput from "react-phone-number-input";
import { genderOptions } from "../../data/SelectOptions";
import { Input, SelectInput } from "../Inputs";
import { useHistory } from "react-router-dom";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;
const PhoneInputWrapper = styled.div`
  border: none;
  overflow: hidden;
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export default function NewAssociationContactModal({
  isOpen,
  setIsOpen,
  association,
}) {
  const history = useHistory();
  const [gender, setGender] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [mobile, setMobile] = useState("");
  const [phone, setPhone] = useState("");
  const [poste, setPoste] = useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [email, setEmail] = useState("");
  const [submitLoader, setSubmitLoader] = React.useState(false);
  const handleClose = () => {
    if (!submitLoader) {
      setIsOpen(false);
      setErrorMessage("");
      setGender("");
      setFirstname("");
      setLastname("");
      setMobile("");
      setPhone("");
      setEmail("");
      setPoste("");
    }
  };

  const submitHandler = () => {
    if (!submitLoader) {
      setErrorMessage("");
      setSubmitLoader(true);
      const re = /\S+@\S+\.\S+/;
      const isValid = re.test(email);

      if (gender === "") {
        setErrorMessage("Remplir le champs de civilité");
        setSubmitLoader(false);
      } else if (firstname.length < 2) {
        setErrorMessage("Prénom invalide");
        setSubmitLoader(false);
      } else if (lastname.length < 2) {
        setErrorMessage("nom invalide");
        setSubmitLoader(false);
      } else if (!isValid) {
        setErrorMessage("adresse mail invalide");
        setSubmitLoader(false);
      } else {
        console.log(association);
        const TOKEN = localStorage.getItem("TOKEN");
        axios
          .post(process.env.REACT_APP_ASSOCIATION_CONTACT, {
            access_token: TOKEN,
            gender,
            firstname,
            lastname,
            email,
            association_id: association,
            phone,
            mobile,
            poste,
          })
          .then(() => {
            history.go(0);
            setSubmitLoader(false);
          })
          .catch((err) => {
            console.log(err);
            setSubmitLoader(false);
          });
      }
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
      <TitleContainer>
        <H6>New Contact</H6>
      </TitleContainer>

      <DialogContent>
        {/** form */}
        {/** civilité + poste */}
        <Row>
          <SelectInput
            values={genderOptions}
            placeholder="Civilité"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          />
          <Input
            placeholder="Poste (fonction)"
            value={poste}
            onChange={(e) => setPoste(e.target.value)}
          />
        </Row>
        {/** firstname - lastname */}
        <Row>
          <Input
            placeholder="Prénom"
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
          />
          <Input
            placeholder="Nom"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />
        </Row>
        <Row>
          <Input
            placeholder="Adresse mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Row>
        {/** mobile + fix phones */}
        <Row style={{ marginBottom: 20 }}>
          <PhoneInputWrapper>
            <PhoneInput
              placeholder="Numéro mobile"
              value={mobile}
              onChange={setMobile}
              defaultCountry="FR"
              international={false}
              rules={{ required: true }}
            />
          </PhoneInputWrapper>
          <PhoneInputWrapper>
            <PhoneInput
              placeholder="Numéro Fixe"
              value={phone}
              onChange={setPhone}
              defaultCountry="FR"
              international={false}
              rules={{ required: true }}
            />
          </PhoneInputWrapper>
        </Row>
        {/** error message */}
        <SmallBoldText
          style={{ textAlign: "center", fontSize: 12, color: "#c22222" }}
        >
          {errorMessage ? errorMessage : " - "}
        </SmallBoldText>
      </DialogContent>

      <DialogActions style={{ marginTop: -10, marginBottom: 15 }}>
        <ButtonPrimary onClick={submitHandler}>
          {submitLoader ? <LoadingIcon color="#ffffff" /> : "Enregistrer"}
        </ButtonPrimary>

        <ButtonSecondary onClick={handleClose}>Close</ButtonSecondary>
      </DialogActions>
    </Dialog>
  );
}
