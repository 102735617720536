import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import { Input, SelectInput } from "../../components/Inputs";
import LazyLoading from "../../components/lazyLoading";
import {
    H5,
    H6,
    H7,
    LoadingIcon,
    TextBold,
} from "../../components/textStyles/style";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SuccessImage from "../../images/main/green_circle_tick.svg";
import { languages } from "../../data/SelectOptions";
import { Typography } from "@mui/material";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const UpdateBilan = () => {
    const MySwal = withReactContent(Swal);
    const history = useHistory();
    const { section } = useParams();


    const [loader, setLoader] = useState(true)
    const [nameFr, setNameFr] = useState('')
    const [nameEn, setNameEn] = useState('')
    const [textFr, setTextFr] = useState('')
    const [textEn, setTextEn] = useState('')
    const [text1, setText1] = useState('')
    const [text2, setText2] = useState('')
    const [text3, setText3] = useState('')
    const [text4, setText4] = useState('')
    const [text5, setText5] = useState('')
    const [text6, setText6] = useState('')
    const [questions, setQuestions] = useState([])
    const [lng, setLng] = useState('fr')
    const [submitLoader, setSubmitLoader] = useState(false)


    // UPDATE SUBMIT
    const submitHandler = async () => {
        setSubmitLoader(true);

        try {
            const data = {
                section,
                name_fr: nameFr,
                name_en: nameEn,
                text_fr: textFr,
                text_en: textEn,
                level1: { text_fr: text1, text_en: text2 },
                level2: { text_fr: text3, text_en: text4 },
                level3: { text_fr: text5, text_en: text6 },
            };

            await axios.put(`${process.env.REACT_APP_QESTIONNAIRES}/update-bilan-analysis`, data);
            setSubmitLoader(false);
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            MySwal.fire({
                title: <H5>Modifications Saved</H5>,
                html: <></>,
                imageUrl: SuccessImage,
                imageWidth: 200,
                imageHeight: 150,
                imageAlt: "Custom image",
                timer: 1500,
                showConfirmButton: false,

                timerProgressBar: true,
                padding: "2vw",
                showCloseButton: true,
                showClass: {
                    popup: "animate__animated animate__fadeInDown animate__fast",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp animate__faster",
                },
            }).then(() => {
                history.goBack();
            });
        } catch (error) {
            setSubmitLoader(false);
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            alert('Probléme de connexion.')
        }
    };

    const getData = async () => {
        setLoader(true);

        const Response = await axios.get(`${process.env.REACT_APP_QESTIONNAIRES}/get-bilan-analysis?section=${section}`);
        const data = Response.data
        setNameFr(data.name_fr)
        setNameEn(data.name_en)
        setTextFr(data.text_fr ? data.text_fr : "")
        setTextEn(data.text_en ? data.text_en : "")
        setText1(data.level1.text_fr)
        setText2(data.level1.text_en)
        setText3(data.level2.text_fr)
        setText4(data.level2.text_en)
        setText5(data.level3.text_fr)
        setText6(data.level3.text_en)
        axios.get(`${process.env.REACT_APP_QESTIONNAIRES}/questions-by-section?section=${data.section}`).then(questionsRes => {
            setQuestions(questionsRes.data)
        })
        setLoader(false);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <Wrapper>
            {loader ? (
                <LazyLoading height={Height} />
            ) : (
                <ContentWrapper>
                    {/*<div style={{ borderBottom: '1px dashed #484848', paddingBottom: 20, marginBottom: 15 }}>
                        <H6>Questions Overview</H6>
                        <div>
                            {questions.map((q, i) => {
                                const answerStrings = q.answers.map((answer) => `${answer.label} = ${answer.value}`);
                                return (
                                    <div key={q.label} style={{ borderBottom: '1px dashed #484848', paddingTop: 10, paddingBottom: 10 }}>
                                        <Typography style={{ fontSize: 14, color: '#555' }}>
                                            {`${i + 1}. ${q.label}`}
                                        </Typography>
                                        <Typography style={{ fontSize: 14, color: '#000' }}>
                                            {`Answers :  ${answerStrings.join(', ')}`}
                                        </Typography>
                                    </div>
                                )
                            })}
                        </div>

                        </div>*/}
                    <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 15 }}>
                        <div style={{ width: 260 }}>
                            <SelectInput
                                values={languages}
                                placeholder="Language"
                                value={lng}
                                onChange={(e) => setLng(e.target.value)}
                            />
                        </div>
                    </div>
                    <div style={{ marginBottom: 15 }}>
                        <H7>Section</H7>
                    </div>
                    {lng === "fr" ? <Input

                        placeholder="Section name - FR"
                        value={nameFr}
                        onChange={(e) => setNameFr(e.target.value)}
                    /> :
                        <Input

                            placeholder="Section name - En"
                            value={nameEn}
                            onChange={(e) => setNameEn(e.target.value)}
                        />
                    }
                    <div style={{ marginTop: 15 }}>
                        <H7>Analysis (always shown to user)</H7>
                    </div>
                    <Row>
                        {lng === "fr" ? <Input
                            multiline={true}
                            rows={6}
                            placeholder="Analysis - FR"
                            value={textFr}
                            onChange={(e) => setTextFr(e.target.value)}
                        /> : <Input
                            multiline={true}
                            rows={6}
                            placeholder="Analysis - EN"
                            value={textEn}
                            onChange={(e) => setTextEn(e.target.value)}
                        />}

                    </Row>
                    <div style={{ marginTop: 15 }}>
                        <H7>Conditional Analysis</H7>
                    </div>
                    <Row>
                        {lng === "fr" ? <Input
                            multiline={true}
                            rows={6}
                            placeholder="Score 0 → 33 - FR"
                            value={text1}
                            onChange={(e) => setText1(e.target.value)}
                        /> : <Input
                            multiline={true}
                            rows={6}
                            placeholder="Score 0 → 33 - EN"
                            value={text2}
                            onChange={(e) => setText2(e.target.value)}
                        />}

                    </Row>
                    <Row>
                        {lng === "fr" ? <Input
                            multiline={true}
                            rows={6}
                            placeholder="Score 34 → 66 - FR"
                            value={text3}
                            onChange={(e) => setText3(e.target.value)}
                        /> :
                            <Input
                                multiline={true}
                                rows={6}
                                placeholder="Score 34 → 66 - EN"
                                value={text4}
                                onChange={(e) => setText4(e.target.value)}
                            />}
                    </Row>
                    <Row>
                        {lng === "fr" ? <Input
                            multiline={true}
                            rows={6}
                            placeholder="Score 67 → 100 - FR"
                            value={text5}
                            onChange={(e) => setText5(e.target.value)}
                        /> :
                            <Input
                                multiline={true}
                                rows={6}
                                placeholder="Score 67 → 100 - EN"
                                value={text6}
                                onChange={(e) => setText6(e.target.value)}
                            />}
                    </Row>

                    <Row style={{ marginTop: 40 }}>
                        <ButtonPrimary onClick={submitHandler}>
                            {submitLoader ? <LoadingIcon color="inherit" /> : "Enregister"}
                        </ButtonPrimary>
                    </Row>
                </ContentWrapper>
            )
            }
        </Wrapper >
    );
};

export default UpdateBilan;
