import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { H5, LoadingBigIcon } from "../../../components/textStyles/style";
import axios from "axios";
import {
    ButtonPrimaryLink,
    PrimaryLink,
} from "../../../components/buttonStyles/style";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
    width: 100vw;
    min-height: ${Height};
    display: flex;
    justify-content: center;
  `;
const ContentWrapper = styled.div`
    background-color: white;
    width: 90%;
    padding: 30px;
    border-radius: 20px;
    margin: 30px 0;
  `;

const LoadWrapper = styled.div`
    width: 100%;
    display: flex;
    margin-top: 50px;
    align-items: center;
    justify-content: center;
  `;

const Dominos = () => {
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);


    const getData = async () => {
        setLoader(true);
        const TOKEN = localStorage.getItem("TOKEN");
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${TOKEN}`,
            },
        };

        const Response = await axios.get(`${process.env.REACT_APP_GAMES}/dominos-levels`, config);

        setData(Response.data);

        setLoader(false);
    };

    useEffect(() => {
        getData();
    }, []);

    const renderDomino = (dom) => {
        return `${dom.up} | ${dom.down}`
    }

    return (
        <Wrapper>
            <ContentWrapper>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div style={{ width: 260 }}>
                        <ButtonPrimaryLink to="/new-domino">
                            Add new Level
                        </ButtonPrimaryLink>
                    </div>
                </div>
                <H5>Dominos Levels</H5>
                {loader ? (
                    <LoadWrapper>
                        <LoadingBigIcon />
                    </LoadWrapper>
                ) : (
                    <div style={{ margin: 30 }}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableCell style={{ fontWeight: "bold", color: "#1B4965", fontSize: 12 }} align="center">
                                        Level
                                    </TableCell>
                                    <TableCell style={{ fontWeight: "bold", color: "#1B4965", fontSize: 12 }} align="center">
                                        Problem Text
                                    </TableCell>
                                    <TableCell style={{ fontWeight: "bold", color: "#1B4965", fontSize: 12 }} align="center">
                                        Problem Pieces
                                    </TableCell>
                                    <TableCell style={{ fontWeight: "bold", color: "#1B4965", fontSize: 12 }} align="center">
                                        Choices Text
                                    </TableCell>
                                    <TableCell style={{ fontWeight: "bold", color: "#1B4965", fontSize: 12 }} align="center">
                                        Choices Pieces
                                    </TableCell>
                                    <TableCell style={{ fontWeight: "bold", color: "#1B4965", fontSize: 12 }} align="center">
                                        Correct Piece
                                    </TableCell>

                                    <TableCell
                                        style={{ fontWeight: "bold", color: "#1B4965", fontSize: 12 }} align="center"
                                    ></TableCell>
                                </TableHead>
                                <TableBody>
                                    {data.map((el, i) => {
                                        return (
                                            <TableRow key={el._id}>
                                                <TableCell component="th" align="center" scope="row">
                                                    {i + 1}
                                                </TableCell>

                                                <TableCell align="center">
                                                    <Typography fontSize={12} color='#484848'>{el.text1_fr}</Typography>
                                                    <Typography fontSize={12} color='#484848'>{el.text1_en}</Typography>
                                                </TableCell>

                                                <TableCell align="center">
                                                    {el.gamePieces.map(d => <Typography key={renderDomino(d)} fontSize={12} color='#484848'>{renderDomino(d)}</Typography>)}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Typography fontSize={12} color='#484848'>{el.text2_fr}</Typography>
                                                    <Typography fontSize={12} color='#484848'>{el.text2_en}</Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {el.choisePieces.map(d => <Typography key={renderDomino(d)} fontSize={12} color='#484848'>{renderDomino(d)}</Typography>)}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Typography fontSize={14} color='#1b4965'>{renderDomino(el.correctPiece)}</Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <ButtonPrimaryLink
                                                        to={`/domino/${el._id}`}
                                                        width="40px"
                                                        height="40px"
                                                    >
                                                        &#8594;
                                                    </ButtonPrimaryLink>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}
            </ContentWrapper>
        </Wrapper>
    );
};

export default Dominos;
