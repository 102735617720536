import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ButtonPrimary, ButtonSecondary } from "../buttonStyles/style";
import { H6, H7, LoadingIcon, SmallBoldText } from "../textStyles/style";
import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import { Input } from "../Inputs";
import moment from "moment";


const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const TitleContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;

`;

export default function UpdateCompagne({ isOpen, setIsOpen, getCompanyData, compagneToUpdate, setCompagneTopUpdate }) {




    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [name, setName] = useState('')
    const [errorMessage, setErrorMessage] = React.useState("");
    const [submitLoader, setSubmitLoader] = React.useState(false);

    const handleClose = () => {
        if (!submitLoader) {
            setIsOpen(false);
            setErrorMessage("");
            setSubmitLoader(false)
            setCompagneTopUpdate(null)
        }
    };



    React.useEffect(() => {
        if (compagneToUpdate) {
            const start = compagneToUpdate.startDate
                ? moment(compagneToUpdate.startDate).format("yyyy-MM-DD")
                : "";
            const end = compagneToUpdate.endDate
                ? moment(compagneToUpdate.endDate).format("yyyy-MM-DD")
                : "";
            setStartDate(start)
            setEndDate(end)
            setName(compagneToUpdate.name)
        }
    }, [compagneToUpdate])


    const submitHandler = () => {

        if (!submitLoader) {
            setSubmitLoader(true)
            setErrorMessage('')
            axios
                .post(
                    `${process.env.REACT_APP_QESTIONNAIRES}/update-compagne?compagne=${compagneToUpdate._id}`,
                    {
                        startDate,
                        endDate,
                        name
                    }
                )
                .then((res) => {
                    handleClose();
                    getCompanyData();
                });
        }
    };



    return (
        <Dialog fullWidth maxWidth="lg" open={isOpen} onClose={handleClose}>
            <TitleContainer>
                <H6>Update Campaign</H6>
            </TitleContainer>

            <DialogContent>

                <SmallBoldText
                    style={{ textAlign: "center", fontSize: 12, color: "#c22222", }}
                >
                    {errorMessage ? errorMessage : " - "}
                </SmallBoldText>
                <Row>
                    <Input
                        placeholder="Name *"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Row>
                <Row>
                    <div style={{ width: "100%" }}>
                        <H7 style={{ marginBottom: 8 }}>Start Date *</H7>
                        <Input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </div>
                    <div style={{ width: "100%" }}>
                        <H7 style={{ marginBottom: 8 }}>End Date *</H7>
                        <Input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </div>
                </Row>
            </DialogContent>

            <DialogActions style={{ marginTop: -10, marginBottom: 15 }}>
                <ButtonPrimary onClick={submitHandler}>
                    {submitLoader ? <LoadingIcon color="#ffffff" /> : "Save"}
                </ButtonPrimary>

                <ButtonSecondary onClick={handleClose}>Close</ButtonSecondary>
            </DialogActions>
        </Dialog>
    );
}
