import {
  Button,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  H5,
  LoadingBigIcon,
  SmallText,
} from "../../components/textStyles/style";
import axios from "axios";
import { ButtonPrimaryLink } from "../../components/buttonStyles/style";
import { ChipText } from "../../components/tables/styles";
import { SearchInput, SelectInput } from "../../components/Inputs";
import moment from "moment-timezone";
import {
  appointmentStatus,
  refundOptions,
  types,
} from "../../data/SelectOptions";
import { Link } from "react-router-dom";
import ReactDatePicker from "react-datepicker";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;

const LoadWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
`;

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
`;

export const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;

  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
`;

const Appointments = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesNumber, setPagesNumber] = useState(null);
  const [searchTotal, setSearchTotal] = useState(null);
  const [status, setStatus] = useState(-1);
  const [experts, setExperts] = useState([{ label: "Tous", value: "all" }]);
  const [selectedExpert, setSelectedExpert] = useState("all");
  const [selectRefund, setSelectRefund] = useState("all");
  const [keyword, setKeyword] = useState("");
  const [startDate, setStartDate] = useState(new Date("01/01/2023"));
  const [endDate, setEndDate] = useState(new Date());
  const [filterDate, setFilterDate] = useState("createdAt");
  const getData = async () => {
    setLoader(true);
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const url = `${process.env.REACT_APP_COACHING_APPOINTMENTS
      }?pageNumber=${currentPage}&status=${status > -1 ? status : ""
      }&monthStart=${startDate.getMonth() + 1
      }&yearStart=${startDate.getFullYear()}&monthEnd=${endDate.getMonth() + 1
      }&yearEnd=${endDate.getFullYear()}&keyword=${keyword}&filterDate=${filterDate}&expert_id=${selectedExpert === "all" ? "" : selectedExpert
      }&refund=${status === 5 ? selectRefund : ""}`;

    const Response = await axios.get(url, config);
    const { appointments, page, pages, count } = Response.data;
    console.log(url);
    // get experts with value 1 (coaching (see selectOptions.js in data folder)) list
    const ex = await axios.get(
      `${process.env.REACT_APP_GET_EXPERTS_DROPDOWN}?category=1`,
      config
    );

    setExperts([{ label: "Tous", value: "all" }, ...ex.data]);

    setData(appointments);
    setCurrentPage(page);
    setPagesNumber(pages);
    setSearchTotal(count);
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [
    currentPage,
    status,
    keyword,
    selectedExpert,
    startDate,
    endDate,
    filterDate,
    selectRefund,
  ]);

  return (
    <Wrapper>
      <ContentWrapper>
        <H5>Coaching Appointments - LISL UP</H5>

        <hr />

        <SelectContainer>
          {/** filter by expert */}
          <div style={{ width: 250 }}>
            <SelectInput
              values={experts}
              placeholder="Expert"
              value={selectedExpert}
              onChange={(e) => setSelectedExpert(e.target.value)}
            />
          </div>
          {/** search */}
          <SearchInput
            style={{ width: 250 }}
            onFocus={() => setCurrentPage(1)}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
          {/** status */}
          <div style={{ width: 250 }}>
            <SelectInput
              values={appointmentStatus}
              placeholder="Appointments status"
              value={status}
              onChange={(e) => {
                setCurrentPage(1);
                setStatus(e.target.value);
              }}
            />
          </div>
          {/** refunded options */}
          {status === 5 && (
            <div style={{ width: 250 }}>
              <SelectInput
                values={refundOptions}
                placeholder="Refund"
                value={selectRefund}
                onChange={(e) => {
                  setCurrentPage(1);
                  setSelectRefund(e.target.value);
                }}
              />
            </div>
          )}

          <SmallText>{searchTotal} Appointment(s) founded</SmallText>
        </SelectContainer>
        {/** filter by date */}
        <div style={{ marginTop: 20 }}>
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 10 }}
          >
            <Typography
              style={{
                color: "#1b4965",
                fontFamily: "Oxygen",
                fontWeight: "700",
                fontSize: 14,
                marginRight: 10,
              }}
            >
              Filter By
            </Typography>
            <Button
              style={{ marginRight: 5, height: 40 }}
              onClick={() => setFilterDate("createdAt")}
              variant="contained"
              color={filterDate !== "createdAt" ? "inherit" : "primary"}
            >
              <Typography fontSize={12}>Creation Date</Typography>
            </Button>
            <Button
              style={{ marginRight: 5, height: 40 }}
              onClick={() => setFilterDate("time")}
              variant="contained"
              color={filterDate !== "time" ? "inherit" : "primary"}
            >
              <Typography fontSize={12}>Appointment Date</Typography>
            </Button>
          </div>
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{
                  color: "#1b4965",
                  fontFamily: "Oxygen",
                  fontWeight: "700",
                  marginBottom: 5,
                  marginRight: 5,
                  fontSize: 14,
                }}
              >
                Start -
              </Typography>
              <div style={{ marginRight: 10 }}>
                <ReactDatePicker
                  selected={startDate}
                  wrapperClassName="datePicker"
                  onChange={(date) => setStartDate(date)}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  showFullMonthYearPicker
                  showTwoColumnMonthYearPicker
                />
              </div>
              <Typography
                style={{
                  color: "#1b4965",
                  fontFamily: "Oxygen",
                  fontWeight: "700",
                  marginBottom: 5,
                  marginRight: 5,
                  fontSize: 14,
                }}
              >
                End -
              </Typography>
              <div style={{ marginRight: 10 }}>
                <ReactDatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  showFullMonthYearPicker
                  showTwoColumnMonthYearPicker
                />
              </div>
            </div>
          </div>
        </div>

        {data.length > 0 && pagesNumber && (
          <PaginationContainer>
            <Pagination
              color="primary"
              count={pagesNumber}
              page={currentPage}
              onChange={(e, val) => setCurrentPage(val)}
            />
          </PaginationContainer>
        )}

        {loader ? (
          <LoadWrapper>
            <LoadingBigIcon />
          </LoadWrapper>
        ) : data.length > 0 ? (
          <div style={{ marginTop: 10 }}>
            <Table>
              <TableHead>
                <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                  Creation Date
                </TableCell>
                <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                  Topic
                </TableCell>
                <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                  Prénom
                </TableCell>

                <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                  Nom
                </TableCell>
                <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                  Email
                </TableCell>
                <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                  Ressource
                </TableCell>
                <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                  Expert
                </TableCell>
                <TableCell style={{ fontWeight: "bold", color: "#1B4965" }}>
                  Date RDV
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#1B4965" }}
                >
                  Status
                </TableCell>
                <TableCell></TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", color: "#1B4965" }}
                >
                  Refunded
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableHead>
              <TableBody>
                {data.map((rdv) => {
                  const createdAt = moment
                    .tz(rdv.createdAt, "Europe/Paris")
                    .format("DD/MM/YYYY HH:mm"); // January 3, 2023 9:09 PM
                  const status = appointmentStatus.find(
                    (s) => s.value === rdv.status
                  );
                  const category =
                    rdv.category === "all"
                      ? { label: "Tous", value: "-" }
                      : rdv.category
                        ? types.find((cat) => cat.value === rdv.category)
                        : { label: " - ", value: "-" };
                  return (
                    <TableRow key={rdv._id}>
                      <TableCell
                        style={{
                          fontSize: 13,
                          width: 100,
                        }}
                        scope="row"
                      >
                        {createdAt}
                      </TableCell>
                      <TableCell>{rdv.topic}</TableCell>
                      <TableCell>{rdv.firstname}</TableCell>
                      <TableCell>{rdv.lastname}</TableCell>
                      <TableCell>{rdv.email}</TableCell>
                      <TableCell style={{ width: 100 }}>
                        {rdv.trail_code ? (
                          <Link
                            style={{ color: "#1b4965" }}
                            to={`/trail/${rdv.trail_code}`}
                            target="_blank"
                          >
                            Trail : {rdv.trail_title}
                          </Link>
                        ) : rdv.category ? (
                          `Ateliers : ${category.label}`
                        ) : (
                          " - "
                        )}
                      </TableCell>

                      {/** expert */}
                      <TableCell>
                        {rdv.expert ? (
                          <Link
                            style={{ color: "#1b4965" }}
                            to={`/expert/${rdv.expert_data[0]._id}`}
                            target="_blank"
                          >
                            {`${rdv.expert_data[0].lastname} ${rdv.expert_data[0].firstname}`}
                          </Link>
                        ) : (
                          " - "
                        )}
                      </TableCell>
                      <TableCell
                        style={{ color: "#1b4965", fontWeight: "bold" }}
                      >
                        {rdv.time
                          ? moment
                            .tz(rdv.time, "Europe/Paris")
                            .format("DD-MM-YYYY HH:mm")
                          : " - "}
                      </TableCell>
                      <TableCell align="center">
                        <ChipText
                          dark={rdv.status === 0}
                          warning={rdv.status === 1}
                          success={rdv.status === 3}
                          danger={
                            rdv.status === 4 ||
                            rdv.status === 5 ||
                            rdv.status === 6
                          }
                          style={{ width: 155 }}
                        >
                          {status.label}
                        </ChipText>
                      </TableCell>
                      <TableCell>
                        {rdv.isGift === "false" || rdv.isGift === false ? (
                          <></>
                        ) : (
                          <ChipText style={{ width: 30 }} warning>
                            G
                          </ChipText>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {rdv.status == 5 && (
                          <ChipText

                            style={{ width: 200, backgroundColor: '#88C1A3', color: '#1b4965' }}
                          >
                            {rdv.companyPayment
                              ? `Company (${rdv.remboursable ? "Refunded" : "Not refunded"
                              })`
                              : rdv.remboursable
                                ? `${rdv.remboursed ? "Refunded" : "To Refund"}`
                                : "Not Refundable"}
                          </ChipText>
                        )}
                      </TableCell>

                      <TableCell align="right">
                        <ButtonPrimaryLink
                          to={`/appointment/${rdv._id}`}
                          width="40px"
                          height="40px"
                        >
                          &#8594;
                        </ButtonPrimaryLink>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            {pagesNumber && (
              <PaginationContainer style={{ marginTop: 20 }}>
                <Pagination
                  color="primary"
                  count={pagesNumber}
                  page={currentPage}
                  onChange={(e, val) => setCurrentPage(val)}
                />
              </PaginationContainer>
            )}
          </div>
        ) : (
          <></>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default Appointments;
