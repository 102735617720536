import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 20px;
`;
export const UserImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 40px;
`;

export const AvatarContainer = styled.div`
  width: 74px;
  height: 74px;
  border-radius: 40px;
  border: 3px #a89d9c solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;