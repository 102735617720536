import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import { Input, SelectInput } from "../../components/Inputs";
import {
  H5,
  LoadingIcon,
  TextBold,
  LoadingBigIcon,
} from "../../components/textStyles/style";
import "../../styles/custom-phone-input.css";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import { monthsOptions } from "../../data/SelectOptions";
let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;
const LoadWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
`;

const UpdateMessage = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [textFr, setTextFr] = useState("");
  const [textEn, setTextEn] = useState("");
  const [picture, setPicture] = useState("");
  const [loader, setLoader] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const { message_id } = useParams();
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const TOKEN = localStorage.getItem("TOKEN");
  const submitHandler = async () => {
    if (!loading) {
      setLoading(true);
      setErrorMessage("");
      const TOKEN = localStorage.getItem("TOKEN");
      try {
        const data = {
          access_token: TOKEN,
          text_fr: textFr,
          text_en: textEn,
          picture,
          month,
          year,
        };

        await axios.put(
          `${process.env.REACT_APP_MESSAGE}?message_id=${message_id}`,
          data
        );
        setLoading(false);
        history.goBack();
      } catch (error) {
        setLoading(false);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(error);
        setErrorMessage("Probléme de Connexion");
      }
    }
  };

  const deleteHandler = () => {
    if (!deleteLoader) {
      setDeleteLoader(true);
      try {
        axios.delete(
          `${process.env.REACT_APP_MESSAGE}?message_id=${message_id}`,
          { data: { access_token: TOKEN } }
        );
        setDeleteLoader(false);
        setDeleteModal(false);
        history.goBack();
      } catch (error) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(error);
        setErrorMessage("Probléme de Connexion");
        setDeleteLoader(false);
        setDeleteModal(false);
      }
    }
  };

  useEffect(() => {
    const getMessageData = async () => {
      setLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MESSAGE}?message_id=${message_id}`,
        config
      );
      const message = response.data;
      // SET INPUTS TO EXPERT VALUES
      setTextFr(message.text_fr);
      setTextEn(message.text_en);
      setPicture(message.picture);
      setMonth(message.month ? message.month : "");
      setYear(message.year ? message.year : "");
      setLoader(false);
    };

    getMessageData();
  }, [message_id, TOKEN]);

  return (
    <Wrapper>
      <ContentWrapper>
        <H5>Update Message</H5>
        {loader ? (
          <LoadWrapper>
            <LoadingBigIcon />
          </LoadWrapper>
        ) : (
          <>
            <Row>
              <TextBold color="#c22222">
                {errorMessage ? errorMessage : " "}
              </TextBold>
            </Row>

            <Row>
              <Input
                multiline
                placeholder="Message - FR"
                value={textFr}
                onChange={(e) => setTextFr(e.target.value)}
              />
              <Input
                multiline
                placeholder="Message - EN"
                value={textEn}
                onChange={(e) => setTextEn(e.target.value)}
              />
            </Row>
            <Row>
              <SelectInput
                values={monthsOptions}
                placeholder="Message display month *"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
              />
              <Input
                placeholder="Year"
                value={year}
                onChange={(e) => setYear(e.target.value)}
              />
            </Row>
            <Row>
              <Input
                placeholder="Image Link"
                value={picture}
                onChange={(e) => setPicture(e.target.value)}
              />
            </Row>

            <Row style={{ marginTop: 40 }}>
              <ButtonPrimary onClick={submitHandler}>
                {loading ? <LoadingIcon color="inherit" /> : "Update message"}
              </ButtonPrimary>
            </Row>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={() => setDeleteModal(true)}>
                <DeleteIcon />
              </Button>
            </div>
          </>
        )}
      </ContentWrapper>
      {/** dialog to confirm delete */}
      <Dialog
        open={deleteModal}
        onClose={setDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete this message?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to permanently delete this message?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "10px" }}>
          <Button onClick={deleteHandler}>
            {deleteLoader ? <LoadingIcon /> : "Delete"}
          </Button>
          <Button autoFocus onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Wrapper>
  );
};

export default UpdateMessage;
