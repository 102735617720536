import React from "react";
import axios from "axios";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Input, InputTextarea } from "../../components/Inputs";
import { H5, H6, H7, LoadingIcon } from "../../components/textStyles/style";
import {
  ButtonPrimary,
  ButtonPrimaryLink,
} from "../../components/buttonStyles/style";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SuccessImage from "../../images/main/green_circle_tick.svg";
import moment from "moment";
import { contactObject, contactSubObject } from "../../data/SelectOptions";
let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
`;
const ContentWrapper = styled.div`
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin-left: 6vw;
`;
const ContentWrapper2 = styled.div`
  width: 100%;
  padding: 10px;
  margin: 20px;
  margin-left: auto;
  margin-right: 0;
`;

const ButtonWrapper = styled.div`
  display: inline-flex;
  position: relative;
  width: 200px;
`;
const EmailWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 10px 0;
`;
const EmailContent = styled.div`
  position: relative;
  background-color: #ffffff;
  width: 70vw;
  height: auto;
  padding: 30px;
  border-radius: 20px;
  margin: 30px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;
const ContactItem = () => {
  const MySwal = withReactContent(Swal);

  const path = window.location.pathname.split("/");
  const id = path[2];
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [message, setMessage] = useState("");
  const [reponse, setReponse] = useState("");
  const [object, setObject] = useState("");
  const [submitReady, setsubmitReady] = React.useState(false);
  const [submitLoader, setSubmitLoader] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showName, setShowName] = useState("");
  const [faqFeeding, setFaqFeeding] = useState([]);

  const getData = async (id) => {
    setLoader(true);
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const Response = await axios.get(
      `${process.env.REACT_APP_CONTACT_MESSAGE}?id=${id}`,
      config
    );

    const ContactsResponse = Response.data;
    const obj = contactObject.find((el) => el.value === Response.data.object);
    const subObject = contactSubObject.find(
      (el) => el.value === Response.data.subObject
    ) || { label: Response.data.subObject };
    setData({
      ...ContactsResponse,
      obj: obj.label,
      subObject: subObject.label,
    });
    setMessage(ContactsResponse.message);
    setObject(ContactsResponse.object);
    setReponse(ContactsResponse.response);
    setLoader(false);
  };

  const submitHandler = () => {
    if (!submitLoader) {
      setErrorMessage("");
      setSubmitLoader(true);
      if (reponse.length < 4) {
        setErrorMessage("message trés court");
        setSubmitLoader(false);
      } else if (object.length < 4) {
        setErrorMessage("objet du mail trés court");
        setSubmitLoader(false);
      } else {
        const t = reponse.split("\n");
        const email = data.email;
        const TOKEN = localStorage.getItem("TOKEN");
        axios
          .post(process.env.REACT_APP_SEND_EMAIL, {
            email,
            object,
            text: t,
            access_token: TOKEN,
          })
          .then((res) => {
            console.log(res.data);
            submitResponseEmail();
            setsubmitReady(true);
            setShowSuccess(true);
            setTimeout(() => {
              setShowName(email);
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              setLoading(false);
              MySwal.fire({
                title: <H5>Email Send</H5>,
                html: <></>,
                imageUrl: SuccessImage,
                imageWidth: 180,
                imageHeight: 130,
                imageAlt: "Custom image",
                timer: 1500,
                showConfirmButton: false,

                timerProgressBar: true,
                padding: "2vw",
                showCloseButton: true,
                showClass: {
                  popup: "animate__animated animate__fadeInDown animate__fast",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp animate__faster",
                },
              });
            }, 1000);
            setSubmitLoader(false);
          })
          .catch((err) => {
            setSubmitLoader(false);
            console.log(err);
          });
      }
      if (id) {
        //submitResponse();
      }
    }
  };

  useEffect(() => {
    if (submitReady) {
      console.log("coucou");
      submitResponse();
    }
  }, [submitReady]);

  useEffect(() => {
    getData(id);
  }, [id]);

  const submitResponseEmail = async () => {
    setLoading(true);
    setErrorMessage("");
    const TOKEN = localStorage.getItem("TOKEN");
    try {
      console.log("sending to data");
      const resp = { access_token: TOKEN, id: id, response: reponse };
      console.log(resp);
      await axios.post(`${process.env.REACT_APP_CONTACT_RESPONDED}`, resp);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      setErrorMessage("Probléme de Connexion");
    }
  };
  const submitResponse = async () => {
    setShowEmailModal(true);
    console.log("test 51451116456");
    setLoading(true);
    setErrorMessage("");
    const TOKEN = localStorage.getItem("TOKEN");
    try {
      const resp = { id: id, response: reponse };
      console.log(resp);
      await axios.post(`${process.env.REACT_APP_CONTACT_FAQ}`, resp);
      setLoading(false);
      history.goBack();
    } catch (error) {
      setLoading(false);
      console.log(error);
      setErrorMessage("Probléme de Connexion");
    }
  };

  return (
    <div>
      <Wrapper>
        <ContentWrapper>
          <H5>Answer FAQ</H5>
          <EmailWrapper>
            <H6>
              {data.firstname} {data.lastname}
            </H6>
            <div style={{ height: 20 }}></div>
            <H7>Language: {data.language}</H7>
            <H7>Email: {data.email}</H7>
            <H7>Topic: {data.obj}</H7>
            <H7>Details {data.subObject}</H7>
            <H7>
              message sent : {moment(data.createdAt).format("DD-MM-YYYY HH:mm")}
            </H7>
            <EmailContent>
              <Input
                placeholder="Topic"
                value={object}
                onChange={(e) => setObject(e.target.value)}
              />

              <div style={{ marginTop: 20, marginBottom: 15 }}>
                <InputTextarea
                  placeholder="Request"
                  value={message}
                  multiline={true}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>

              <Input
                placeholder="Answer"
                value={reponse}
                multiline={true}
                onChange={(e) => setReponse(e.target.value)}
              />
              <ContentWrapper2>
                <ButtonWrapper>
                  <ButtonPrimaryLink
                    disabled={data.faq_feeding || !reponse ? true : false}
                    onClick={
                      data.faq_feeding || !reponse ? (e) => e.preventDefault() : undefined
                    } // prevent default behavior if disabled
                    to={{
                      pathname: "/add-faq",
                      search:
                        "?question=" +
                        message +
                        "&reponse=" +
                        reponse +
                        "&id=" +
                        id +
                        "&cb=" +
                        Math.random(),
                    }}
                  >
                    {data.faq_feeding ? "Already added" : "Add to FaQ"}
                  </ButtonPrimaryLink>
                </ButtonWrapper>
                <ButtonWrapper>
                  {!showSuccess && (
                    <ButtonPrimary onClick={submitHandler}>
                      {submitLoader ? (
                        <LoadingIcon color="#fff" />
                      ) : (
                        "Send mail"
                      )}
                    </ButtonPrimary>
                  )}
                </ButtonWrapper>
              </ContentWrapper2>
            </EmailContent>
          </EmailWrapper>
        </ContentWrapper>
      </Wrapper>
    </div>
  );
};

export default ContactItem;
