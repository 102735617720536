import React from "react";
import { Typography } from "@mui/material";
import { H5 } from "../../../components/textStyles/style";
import { RowBetween } from "../CompanyStat";

const ExampleTrails = () => {

    const responseData = [
        {
            "_id": "T004",
            "count": 228,
            "trailData": [
                {
                    "_id": "635a4b4d4b37d648d68e49c1",
                    "title": "Objectif: no stress"
                }
            ]
        },
        {
            "_id": "T005",
            "count": 136,
            "trailData": [
                {
                    "_id": "635a4b4d4b37d648d68e49bf",
                    "title": "Trouvez l'équilibre émotionnel"
                },
                {
                    "_id": "63a9baf929a9cdb8000e6a7d",
                    "title": "Find emotional balance"
                }
            ]
        },
        {
            "_id": "T002",
            "count": 111,
            "trailData": [
                {
                    "_id": "635a4b4d4b37d648d68e49be",
                    "title": "Le chaudron des croyances"
                },
                {
                    "_id": "63a9b26f29a9cdb8000e6a74",
                    "title": "Cauldron of Beliefs"
                }
            ]
        },
        {
            "_id": "T001",
            "count": 74,
            "trailData": [
                {
                    "_id": "635a4b4d4b37d648d68e49c0",
                    "title": "La confiance en soi : Recette de l'abondance."
                },
                {
                    "_id": "63a9c7c329a9cdb8000e6a99",
                    "title": "Self-confidence: Recipe for abundance."
                }
            ]
        }
    ]

    return (
        <div>
            <H5 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
                6. Top Watched Trails
            </H5>
            {
                responseData.map((item) => {
                    const trail = item.trailData ? item.trailData[0] : null;
                    return (
                        <RowBetween key={item._id}>
                            <Typography fontSize={13} color="#444">
                                {trail ? trail.title : "-"}
                            </Typography>
                            <Typography fontSize={13} color="#484848">
                                {item.count} view(s)
                            </Typography>
                        </RowBetween>
                    );
                })
            }
        </div >
    );
};

export default ExampleTrails;
