import React from 'react'
import styled from 'styled-components';
import moment from 'moment';
import html2pdf from "html2pdf.js";
import { Typography } from '@mui/material';
import Annexe from '../Annexe';
import { lislColors } from '../../../styles/colors';
import { ButtonPrimary } from '../../../components/buttonStyles/style';
import { Print } from '@material-ui/icons';
import { Logo } from '../../../components/header/style';
import logo from "../../../images/main/green-logo.svg";
import { H5, H6 } from '../../../components/textStyles/style';
import ExampleUsersStat from './ExampleUsersStat';
import ExampleCoachingstat from './ExampleCoachingstat';
import ExampleRank from './ExampleRank';
import ExampleTrails from './ExampleTrails';
import ExampleWorkshop from './ExampleWorkshop';
import ExampleWorkshopStats from './ExampleWorkshopStats';
import ExamplePurchases from './ExamplePurchases';
import ExamplePurchasesStat from './ExamplePurchasesStat';
import ExampleChartEntries from './ExampleChartEntries';
import ExampleGender from './ExampleGender';
import ExampleAge from './ExampleAge';
import ExampleLanguage from './ExampleLanguage';
import ExampleReporting from './ExampleReporting';

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 753px;
  padding: 20px;
  border-radius: 20px;
`;
const RowBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  border-bottom: 1px #484848 dashed;
  padding-bottom: 2px;
`;
const Report = () => {





    const now = new Date();
    const formattedDate = moment(now).format("DD-MM-YYYY");

    const printHandler = () => {
        const element = document.getElementById("to-print");
        const opt = {
            filename: `Example Report-${formattedDate}.pdf`,
            margin: 5,

            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };

        html2pdf().set(opt).from(element).save();
    };

    return (
        <Wrapper>
            <div
                style={{
                    position: "absolute",
                    right: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                }}
            >
                <ButtonPrimary onClick={printHandler}>
                    <Print />
                </ButtonPrimary>
            </div>
            <ContentWrapper>
                <div>
                    {/** general info */}
                    <div id="to-print">
                        <div style={{ height: "287mm", marginLeft: 15, marginRight: 15, position: 'relative' }}>
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: 10,
                                    right: 0,
                                    borderBottom: "1px #484848 dashed",
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div>
                                    <Typography color="#444" textAlign="right" fontSize={10}>
                                        1/4</Typography>
                                </div>
                                <div>
                                    <Typography color="#444" textAlign="right" fontSize={10}>
                                        CONFIDENTIAL
                                    </Typography>
                                    <Typography color="#484848" fontSize={10}>
                                        This document contains confidential and proprietary
                                        information. Unauthorized distribution is prohibited.
                                    </Typography>
                                </div>
                            </div>
                            <Logo
                                style={{
                                    width: 400,
                                    height: 160,
                                    alignSelf: "center",
                                    position: "absolute",
                                    top: "120mm",
                                    opacity: 0.25,
                                    left: "45mm",
                                }}
                                src={logo}
                                alt="image"
                            />
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    <Logo
                                        style={{
                                            width: 200,
                                            height: 80,
                                            marginLeft: -20,
                                            marginBottom: 5,
                                        }}
                                        src={logo}
                                        alt="image"
                                    />
                                    <div style={{ marginLeft: 10 }}>
                                        <Typography color={lislColors.black} fontSize={12}>
                                            MASSA TRAILS SAS
                                        </Typography>
                                        <Typography color="#444" fontSize={10}>
                                            47 rue des Batignolles – 75017 PARIS
                                        </Typography>
                                    </div>
                                </div>
                                <Typography color="#484848" fontSize={13}>
                                    {formattedDate}
                                </Typography>
                            </div>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <H5 style={{ textAlign: "center", fontSize: 22 }}>
                                    Report - COMPANY LMT
                                </H5>
                            </div>

                            <div style={{ marginTop: 30 }}>
                                <H6 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
                                    1. General Informations
                                </H6>
                            </div>
                            {/** general infos */}
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "stretch",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div
                                    style={{
                                        width: "48%",
                                        borderRight: "0.25px #484848 solid",
                                        paddingRight: "2%",
                                    }}
                                >
                                    <RowBetween>
                                        <Typography fontSize={13} style={{ color: "#363636" }}>
                                            Num order
                                        </Typography>
                                        <Typography fontSize={13} color="#484848">
                                            INV0001
                                        </Typography>
                                    </RowBetween>
                                    <RowBetween>
                                        <Typography fontSize={13} style={{ color: "#363636" }}>
                                            Typology
                                        </Typography>
                                        <Typography fontSize={13} color="#484848">
                                            Prospect
                                        </Typography>
                                    </RowBetween>
                                    <RowBetween>
                                        <Typography fontSize={13} style={{ color: "#363636" }}>
                                            Sector
                                        </Typography>
                                        <Typography fontSize={13} color="#484848">
                                            Services
                                        </Typography>
                                    </RowBetween>
                                    <RowBetween>
                                        <Typography fontSize={13} style={{ color: "#363636" }}>
                                            Group
                                        </Typography>
                                        <Typography fontSize={13} color="#484848">
                                            -
                                        </Typography>
                                    </RowBetween>

                                </div>

                                <div style={{ width: "48%" }}>
                                    <RowBetween>
                                        <Typography fontSize={13} style={{ color: "#363636" }}>
                                            Phone number
                                        </Typography>
                                        <Typography fontSize={13} color="#484848">
                                            +33123456789
                                        </Typography>
                                    </RowBetween>
                                    <RowBetween>
                                        <Typography fontSize={13} style={{ color: "#363636" }}>
                                            Total Employees
                                        </Typography>
                                        <Typography fontSize={13} color="#484848">
                                            1500 Employees
                                        </Typography>
                                    </RowBetween>
                                    <RowBetween>
                                        <Typography fontSize={13} style={{ color: "#363636" }}>
                                            Payment Method
                                        </Typography>
                                        <Typography fontSize={13} color="#484848">
                                            Prepaid
                                        </Typography>
                                    </RowBetween>

                                    <RowBetween>
                                        <Typography fontSize={13} style={{ color: "#363636" }}>
                                            Creation Date
                                        </Typography>
                                        <Typography fontSize={13} color="#484848">
                                            March 1, 2023
                                        </Typography>
                                    </RowBetween>
                                </div>
                            </div>


                            <div>
                                {/** contract info */}

                                <div
                                    style={{
                                        marginTop: 20,
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "stretch",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "48%",
                                            borderRight: "0.25px #484848 solid",
                                            paddingRight: "2%",
                                        }}
                                    >
                                        <H6 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
                                            2. Current Contract Informations
                                        </H6>

                                        <RowBetween>
                                            <Typography fontSize={13}>Period</Typography>
                                            <Typography fontSize={13} color="#484848">
                                                March 1, 2023 - March 1, 2024
                                            </Typography>
                                        </RowBetween>
                                        <RowBetween>
                                            <Typography fontSize={13}>Trails Access</Typography>
                                            <Typography fontSize={13} color="#484848">
                                                4 Trails
                                            </Typography>
                                        </RowBetween>
                                        <RowBetween>
                                            <Typography fontSize={13}>Users access</Typography>
                                            <Typography fontSize={13} color="#484848">
                                                400 Users
                                            </Typography>
                                        </RowBetween>
                                        <RowBetween>
                                            <Typography fontSize={13}>
                                                Flash Coaching Appoitments
                                            </Typography>
                                            <Typography fontSize={13} color="#484848">
                                                30 Appointments
                                            </Typography>
                                        </RowBetween>
                                        <RowBetween>
                                            <Typography fontSize={13}>E-books</Typography>
                                            <Typography fontSize={13} color="#484848">
                                                200 ebooks
                                            </Typography>
                                        </RowBetween>
                                        <RowBetween>
                                            <Typography fontSize={13}>Created</Typography>
                                            <Typography fontSize={13} color="#484848">
                                                March 01, 2023
                                            </Typography>
                                        </RowBetween>
                                    </div>
                                    <div style={{ width: "48%" }}>

                                        <div>
                                            <H6 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
                                                Amendments(s)
                                            </H6>
                                            <RowBetween>
                                                <Typography fontSize={13}>
                                                    Trails Access
                                                </Typography>
                                                <Typography fontSize={13} color="#484848">
                                                    1 Trails
                                                </Typography>
                                            </RowBetween>
                                            <RowBetween>
                                                <Typography fontSize={13}>
                                                    Users access
                                                </Typography>
                                                <Typography fontSize={13} color="#484848">
                                                    0 Users
                                                </Typography>
                                            </RowBetween>
                                            <RowBetween>
                                                <Typography fontSize={13}>
                                                    Flash Coaching Appoitments
                                                </Typography>
                                                <Typography fontSize={13} color="#484848">
                                                    10 Appointments
                                                </Typography>
                                            </RowBetween>
                                            <RowBetween>
                                                <Typography fontSize={13}>E-books</Typography>
                                                <Typography fontSize={13} color="#484848">
                                                    0 ebooks
                                                </Typography>
                                            </RowBetween>
                                            <RowBetween>
                                                <Typography fontSize={13}>Created</Typography>
                                                <Typography fontSize={13} color="#484848">
                                                    August 21, 2023
                                                </Typography>
                                            </RowBetween>
                                        </div>

                                    </div>
                                </div>

                            </div>

                            {/** user access */}
                            <div
                                style={{
                                    marginTop: 20,
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "stretch",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div
                                    style={{
                                        width: "48%",
                                        borderRight: "0.25px #484848 solid",
                                        paddingRight: "2%",
                                    }}
                                >
                                    <H6 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
                                        3. Users Slots
                                    </H6>

                                    <div
                                        style={{
                                            width: 300,
                                            height: 8,
                                            borderRadius: 20,
                                            backgroundColor: "#d3d3d3",
                                            marginTop: 10,
                                        }}
                                    >
                                        <div
                                            style={{
                                                width:
                                                    300 * (280 / 400),

                                                height: 8,
                                                borderRadius: 20,
                                                backgroundColor: "#1b4965",
                                                position: "absolute",
                                            }}
                                        ></div>
                                    </div>
                                    <RowBetween>
                                        <Typography style={{ color: "#363636" }} fontSize={13}>
                                            Connected Users
                                        </Typography>
                                        <Typography color="#484848" fontSize={13}>
                                            {280} users ( ≈
                                            {(
                                                (280 / 400) *
                                                100
                                            ).toFixed(0)}
                                            % )
                                        </Typography>
                                    </RowBetween>
                                    <RowBetween>
                                        <Typography style={{ color: "#363636" }} fontSize={13}>
                                            Available Slots
                                        </Typography>
                                        <Typography color="#484848" fontSize={13}>
                                            {400 - 280} users
                                        </Typography>
                                    </RowBetween>
                                    <RowBetween>
                                        <Typography fontSize={13} style={{ color: "#363636" }}>
                                            Total Slots
                                        </Typography>
                                        <Typography color="#484848" fontSize={13}>
                                            {400} users
                                        </Typography>
                                    </RowBetween>
                                </div>
                                <div style={{ width: "48%" }}>
                                    <ExampleUsersStat />
                                </div>
                            </div>
                            {/** flash coaching */}
                            <div
                                style={{
                                    marginTop: 20,
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "stretch",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div
                                    style={{
                                        width: "48%",
                                        borderRight: "0.25px #484848 solid",
                                        paddingRight: "2%",
                                    }}
                                >
                                    <div>
                                        <H6 style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: "bold" }} color="#000">
                                            4. Flash Coaching (Via Contract)
                                        </H6>

                                        <div
                                            style={{
                                                width: 300,
                                                height: 8,
                                                borderRadius: 20,
                                                backgroundColor: "#d3d3d3",
                                                marginTop: 10,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width:
                                                        300 * (280 / 400),

                                                    height: 8,
                                                    borderRadius: 20,
                                                    backgroundColor: "#1b4965",
                                                    position: "absolute",
                                                }}
                                            ></div>
                                        </div>
                                        <RowBetween>
                                            <Typography style={{ color: "#363636" }} fontSize={13}>
                                                Used Flash Coaching Appointments
                                            </Typography>
                                            <Typography fontSize={13} color="#484848">
                                                {16} (
                                                {16 === 0
                                                    ? "0"
                                                    : (
                                                        (16 / 40) *
                                                        100
                                                    ).toFixed(0)}
                                                %)
                                            </Typography>
                                        </RowBetween>
                                        <RowBetween>
                                            <Typography style={{ color: "#363636" }} fontSize={13}>
                                                Available Flash Coaching Appointments
                                            </Typography>
                                            <Typography fontSize={13} color="#484848">
                                                {40 - 16}{" "}
                                            </Typography>
                                        </RowBetween>
                                        <RowBetween>
                                            <Typography style={{ color: "#363636" }} fontSize={13}>
                                                Total Flash Coaching Appointments
                                            </Typography>
                                            <Typography fontSize={13} color="#484848">
                                                {40}
                                            </Typography>
                                        </RowBetween>
                                    </div>
                                </div>
                                <div style={{ width: "48%" }}>
                                    <ExampleCoachingstat />
                                </div>
                            </div>
                        </div>
                        {/** second page */}
                        <div style={{ height: "286mm", marginLeft: 15, marginRight: 15, position: 'relative' }}>
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: 25,
                                    right: 0,
                                    borderBottom: "1px #484848 dashed",
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div>
                                    <Typography color="#444" textAlign="right" fontSize={10}>
                                        2/4</Typography>
                                </div>
                                <div>
                                    <Typography color="#444" textAlign="right" fontSize={10}>
                                        CONFIDENTIAL
                                    </Typography>
                                    <Typography color="#484848" fontSize={10}>
                                        This document contains confidential and proprietary
                                        information. Unauthorized distribution is prohibited.
                                    </Typography>
                                </div>
                            </div>
                            <Logo
                                style={{
                                    width: 400,
                                    height: 160,
                                    alignSelf: "center",
                                    position: "absolute",
                                    top: "120mm",
                                    opacity: 0.25,
                                    left: "45mm",
                                }}
                                src={logo}
                                alt="image"
                            />
                            <div>
                                <div
                                    style={{
                                        marginTop: 20,
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "stretch",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "48%",
                                            borderRight: "0.25px #484848 solid",
                                            paddingRight: "2%",
                                        }}
                                    >
                                        <ExampleRank />
                                    </div>
                                    <div style={{ width: "48%" }}>
                                        <ExampleTrails />
                                    </div>
                                </div>
                            </div>

                            <div
                                style={{
                                    marginTop: 40,
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "stretch",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div
                                    style={{
                                        width: "48%",
                                        borderRight: "0.25px #484848 solid",
                                        paddingRight: "2%",
                                    }}
                                >
                                    <ExampleWorkshop />
                                </div>
                                <div style={{ width: "48%", marginTop: 10 }}>
                                    <ExampleWorkshopStats />
                                </div>
                            </div>

                            <div
                                style={{
                                    marginTop: 40,
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "stretch",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div
                                    style={{
                                        width: "48%",
                                        borderRight: "0.25px #484848 solid",
                                        paddingRight: "2%",
                                    }}
                                >
                                    <ExamplePurchases />
                                </div>
                                <div style={{ width: "48%", marginTop: 10 }}>
                                    <ExamplePurchasesStat />
                                </div>
                            </div>

                            <div
                                style={{
                                    marginTop: 40,
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "flex-start",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div
                                    style={{
                                        width: "48%",
                                        borderRight: "0.25px #484848 solid",
                                        paddingRight: "2%",
                                    }}
                                >
                                    <div>
                                        <ExampleChartEntries />
                                    </div>
                                </div>
                                <div style={{ width: "48%" }}>
                                    <ExampleGender />
                                </div>
                            </div>
                        </div>
                        {/** page 3 */}
                        <div style={{ height: "286mm", marginLeft: 15, marginRight: 15, position: 'relative' }}>
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: 40,
                                    right: 0,
                                    borderBottom: "1px #484848 dashed",
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div>
                                    <Typography color="#444" textAlign="right" fontSize={10}>
                                        3/4</Typography>
                                </div>
                                <div>
                                    <Typography color="#444" textAlign="right" fontSize={10}>
                                        CONFIDENTIAL
                                    </Typography>
                                    <Typography color="#484848" fontSize={10}>
                                        This document contains confidential and proprietary
                                        information. Unauthorized distribution is prohibited.
                                    </Typography>
                                </div>
                            </div>
                            <Logo
                                style={{
                                    width: 400,
                                    height: 160,
                                    alignSelf: "center",
                                    position: "absolute",
                                    top: "120mm",
                                    opacity: 0.25,
                                    left: "45mm",
                                }}
                                src={logo}
                                alt="image"
                            />
                            <div
                                style={{
                                    marginTop: 20,
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "stretch",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div
                                    style={{
                                        width: "48%",
                                        borderRight: "0.25px #484848 solid",
                                        paddingRight: "2%",
                                    }}
                                >
                                    <div>
                                        <ExampleAge />
                                    </div>
                                </div>
                                <div style={{ width: "48%" }}>
                                    <ExampleLanguage />
                                </div>
                            </div>

                            {
                                /** reporting */
                                <div style={{ width: "100%", marginTop: 50 }}>
                                    <ExampleReporting />
                                </div>}
                        </div>
                        { /** annexe */}
                        <div style={{ height: "267mm", marginLeft: 15, marginRight: 15, position: 'relative' }}>
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: 20,
                                    right: 0,
                                    borderBottom: "1px #484848 dashed",
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div>
                                    <Typography color="#444" textAlign="right" fontSize={10}>
                                        4/4</Typography>
                                </div>
                                <div>
                                    <Typography color="#444" textAlign="right" fontSize={10}>
                                        CONFIDENTIAL
                                    </Typography>
                                    <Typography color="#484848" fontSize={10}>
                                        This document contains confidential and proprietary
                                        information. Unauthorized distribution is prohibited.
                                    </Typography>
                                </div>
                            </div>
                            <Logo
                                style={{
                                    width: 400,
                                    height: 160,
                                    alignSelf: "center",
                                    position: "absolute",
                                    top: "120mm",
                                    opacity: 0.25,
                                    left: "45mm",
                                }}
                                src={logo}
                                alt="image"
                            />
                            <Annexe />
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </Wrapper>
    )
}

export default Report