import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../components/buttonStyles/style";
import { Input, SelectInput } from "../../components/Inputs";
import {
  H5,
  LoadingBigIcon,
  LoadingIcon,
  TextBold,
} from "../../components/textStyles/style";
import {
  FAQCategories,
  FAQFacturationSubCategories,
  FAQTechniqueSubCategories,
  FAQWorkshopsSubCategories,
} from "../../data/SelectOptions";
import { useParams } from "react-router-dom";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const RowBetween = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 20px;
`;

const FAQ = () => {
  const { faq_id } = useParams();
  const [loader, setLoader] = useState(true);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [trails, setTrails] = useState([]);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const history = useHistory();
  const submitHandler = () => {
    if (!loading) {
      setLoading(true);
      if (
        question.length > 2 &&
        category.length > 0 &&
        answer.length > 2 &&
        (category == "autres" || subCategory.length > 0)
      ) {
        const TOKEN = localStorage.getItem("TOKEN");
        axios
          .put(`${process.env.REACT_APP_FAQ}?faq_id=${faq_id}`, {
            access_token: TOKEN,
            question,
            answer,
            category,
            subCategory,
          })
          .then(() => {
            setTimeout(() => {
              setLoading(false);
              history.goBack();
            }, 800);
          })
          .catch((err) => {
            setErrorMessage("Erreur connexion avec le serveur");
            setLoading(true);
          });
      } else {
        setErrorMessage("Invalid data");
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const getData = async () => {
      setSubCategory("");
      setLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };

      // GET FAQ DATA
      const ResponseFAQ = await axios.get(
        `${process.env.REACT_APP_FAQ}?faq_id=${faq_id}`,
        config
      );
      const f = ResponseFAQ.data;
      setCategory(f.category);
      setSubCategory(f.subCategory);
      setAnswer(f.answer);
      setQuestion(f.question);

      // GET TRAILS DATA
      const Response = await axios.get(
        `${process.env.REACT_APP_GET_TRAILS}?language=${f.language}`,
        config
      );
      const t = [];
      await Response.data.map((trail) => {
        t.push({ label: trail.title, value: trail.title });
      });
      setTrails([{ value: "all_trails", label: "Tous les Trails" }, , ...t]);
      setLoader(false);
    };
    getData();
  }, []);

  const deleteHandler = () => {
    if (!deleteLoader) {
      setDeleteLoader(true);

      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };

      // GET FAQ DATA
      axios
        .delete(`${process.env.REACT_APP_FAQ}?faq_id=${faq_id}`, config)
        .then(() => {
          setTimeout(() => {
            setDeleteLoader(false);
            history.goBack();
          }, 500);
        })
        .catch((err) => {
          setDeleteLoader(false);
          console.log(err);
        });
    }
  };

  if (loader) {
    return (
      <Wrapper style={{ alignItems: "center" }}>
        <LoadingBigIcon />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <ContentWrapper>
        <RowBetween>
          <H5>Update FAQ</H5>
          <div style={{ width: 150 }}>
            <ButtonSecondary width={150} onClick={deleteHandler}>
              {deleteLoader ? <LoadingIcon color="inherit" /> : "Supprimer"}
            </ButtonSecondary>
          </div>
        </RowBetween>
        <Row>
          <TextBold color="#c22222">
            {errorMessage ? errorMessage : " "}
          </TextBold>
        </Row>

        {/** language & catégorie */}
        <Row>
          <SelectInput
            values={FAQCategories}
            placeholder="Catégorie *"
            value={category}
            onChange={(e) => {
              setCategory(e.target.value);
              setSubCategory("");
            }}
          />
        </Row>
        {/** sub category */}
        <Row>
          {category == "trail" ? (
            <SelectInput
              values={trails}
              placeholder="Sous-catégories *"
              value={subCategory}
              onChange={(e) => {
                setSubCategory(e.target.value);
              }}
            />
          ) : category == "facturation" ? (
            <SelectInput
              values={FAQFacturationSubCategories}
              placeholder="Sous-catégories *"
              value={subCategory}
              onChange={(e) => {
                setSubCategory(e.target.value);
              }}
            />
          ) : category == "technique" ? (
            <SelectInput
              values={FAQTechniqueSubCategories}
              placeholder="Sous-catégories *"
              value={subCategory}
              onChange={(e) => {
                setSubCategory(e.target.value);
              }}
            />
          ) : category == "workshop" ? (
            <SelectInput
              values={FAQWorkshopsSubCategories}
              placeholder="Sous-catégories *"
              value={subCategory}
              onChange={(e) => {
                setSubCategory(e.target.value);
              }}
            />
          ) : (
            <></>
          )}
        </Row>
        {/** Question + Response */}
        <Row>
          <Input
            placeholder="Question *"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
        </Row>
        <Row>
          <Input
            multiline
            placeholder="Réponses *"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          />
        </Row>
        <Row style={{ marginTop: 40 }}>
          <ButtonPrimary onClick={submitHandler}>
            {loading ? <LoadingIcon color="inherit" /> : "Enregistrer"}
          </ButtonPrimary>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default FAQ;
