import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import axios from "axios";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../components/buttonStyles/style";
import { Input, SelectInput } from "../../components/Inputs";
import {
  H5,
  LoadingIcon,
  TextBold,
  LoadingBigIcon,
  H6,
  SmallBoldText,
} from "../../components/textStyles/style";
import "../../styles/custom-phone-input.css";
import { languages } from "../../data/SelectOptions";
import { useTranslation } from "react-i18next";
import Questions from "./Questions";
import { CheckCircle } from "@mui/icons-material";
import { lislColors } from "../../styles/colors";
import AddQuestionnaireMessage from "../../components/modals/AddQuestionnaireMessage";
import { Edit } from "@material-ui/icons";
import UpdateQuestionnaireMessage from "../../components/modals/UpdateQuestionnaireMessage";
let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;
const LoadWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
`;

const UpdateQuestionnaire = () => {
  const history = useHistory();
  const { i18n } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [language, setLanguage] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loader, setLoader] = useState("");
  const [currentLanguage, setCurrentLanguage] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteMessageModal, setDeleteMessageModal] = useState(false);
  const [updateMessageModal, setUpdateMessageModal] = useState("");

  const [addMessageModal, setAddMessageModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [questions, setQuestions] = useState([]);
  const { questionnaire_id } = useParams();
  const [trail, setTrail] = useState("");
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [trails, setTrails] = useState([]);
  const [messages, setMessages] = useState([]);
  const TOKEN = localStorage.getItem("TOKEN");

  const [welcome, setWelcome] = useState(null);
  const [published, setPublished] = useState(null);

  const submitHandler = async () => {
    if (!loading) {
      setLoading(true);
      setErrorMessage("");
      const TOKEN = localStorage.getItem("TOKEN");
      try {
        const data = {
          access_token: TOKEN,
          name,
          trail,
          description,
          language,
        };

        await axios.put(
          `${process.env.REACT_APP_QESTIONNAIRES}/update?id=${questionnaire_id}`,
          data
        );
        setLoading(false);
        history.goBack();
      } catch (error) {
        setLoading(false);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(error);
        setErrorMessage("Probléme de Connexion");
      }
    }
  };

  const togglePublish = async () => {
    const TOKEN = localStorage.getItem("TOKEN");
    await axios
      .put(
        `${process.env.REACT_APP_QESTIONNAIRES}/update?id=${questionnaire_id}`,
        { published: !published, access_token: TOKEN }
      )
      .then(() => {
        setPublished(!published);
      });
  };

  const deleteHandler = async () => {
    const TOKEN = localStorage.getItem("TOKEN");
    if (!deleteLoader) {
      setDeleteLoader(true);
      try {
        axios.delete(
          `${process.env.REACT_APP_QESTIONNAIRES}/delete?id=${questionnaire_id}`,
          { data: { access_token: TOKEN } }
        );
        setDeleteLoader(false);
        setDeleteModal(false);
        history.goBack();
      } catch (error) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(error);
        setErrorMessage("Probléme de Connexion");
        setDeleteLoader(false);
        setDeleteModal(false);
      }
    }
  };

  const markAsWelcome = async () => {
    const TOKEN = localStorage.getItem("TOKEN");
    try {
      const data = {
        access_token: TOKEN,
        language: currentLanguage,
      };

      await axios.post(
        `${process.env.REACT_APP_QESTIONNAIRES}/mark-as-welcome?id=${questionnaire_id}`,
        data
      );

      history.goBack();
    } catch (error) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      console.log(error);
    }
  };

  const deleteMessageHandler = () => {
    if (!deleteLoader) {
      setDeleteLoader(true);
      try {
        axios.delete(
          `${process.env.REACT_APP_QESTIONNAIRES}/delete-message?message_id=${selectedMessage._id}`
        );
        setDeleteLoader(false);
        setDeleteMessageModal(false);
        history.go(0);
      } catch (error) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(error);

        setDeleteLoader(false);
        setDeleteMessageModal(false);
      }
    }
  };

  useEffect(() => {
    const getQuestionnaireData = async () => {
      setLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_QESTIONNAIRES}/get/?id=${questionnaire_id}`,
        config
      );
      setName(response.data.name);
      setPublished(response.data.published);
      setDescription(response.data.description);
      setLanguage(response.data.language);
      setCurrentLanguage(response.data.language);
      setMessages(response.data.messages);
      setTrail(response.data.trail ? response.data.trail : "");
      setQuestions(response.data.questions);
      setWelcome(response.data.welcome);
      const trailsResponse = await axios.get(
        `${process.env.REACT_APP_GET_TRAILS}?language=${response.data.language}`,
        config
      );

      const array = [{ label: "Welcome", value: "welcome" }];
      await trailsResponse.data.map((i) =>
        array.push({ label: i.title, value: i.trail_code })
      );
      setTrails(array);

      setLoader(false);
    };

    getQuestionnaireData();
  }, [questionnaire_id, TOKEN]);

  const changeLanguageHandler = (e) => {
    i18n.changeLanguage(e.target.value);
    setLanguage(e.target.value);
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <H5>Questionnaire</H5>
          <div style={{ display: "flex", alignItems: "center" }}>
            {welcome != null && (
              <div style={{ width: !welcome ? 280 : 200, marginRight: 10 }}>
                {welcome ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px #a8c492 solid",
                      padding: "15px 10px",
                      borderRadius: 200,
                    }}
                  >
                    <CheckCircle
                      style={{
                        color: "#A8C492",
                        fontSize: 18,
                        marginRight: 3,
                      }}
                    />
                    <SmallBoldText color="#A8C492">
                      Welcome Questionnaire
                    </SmallBoldText>
                  </div>
                ) : (
                  <ButtonSecondary
                    width={290}
                    style={{ alignItems: "center" }}
                    onClick={markAsWelcome}
                  >
                    Mark as Welcome Questionnaire
                  </ButtonSecondary>
                )}
              </div>
            )}

            {published != null && (
              <div style={{ width: 240 }}>
                <ButtonPrimary
                  width={240}
                  onClick={togglePublish}
                  style={{ backgroundColor: published ? "#c22222" : "#A8C492" }}
                >
                  {published
                    ? "Unpublish Questionnaire"
                    : "Publish Questionnaire"}
                </ButtonPrimary>
              </div>
            )}
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <H6 color="#484848">General Informations</H6>
        </div>
        {loader ? (
          <LoadWrapper>
            <LoadingBigIcon />
          </LoadWrapper>
        ) : (
          <>
            <Row>
              <TextBold color="#c22222">
                {errorMessage ? errorMessage : " "}
              </TextBold>
            </Row>
            <Row>
              <SelectInput
                values={languages}
                placeholder="Language"
                value={language}
                onChange={changeLanguageHandler}
              />
              <Input
                placeholder="Questionnaire Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Row>
            <Row style={{ alignItems: "flex-start" }}>
              <SelectInput
                values={trails}
                placeholder="Related Trail *"
                value={trail}
                onChange={(e) => setTrail(e.target.value)}
              />
              <Input
                multiline
                placeholder="Questionnaire Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Row>

            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <ButtonPrimary onClick={submitHandler}>
                {loading ? (
                  <LoadingIcon color="inherit" />
                ) : (
                  "Update General Informations"
                )}
              </ButtonPrimary>
            </div>
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setDeleteModal(true)}
              >
                <DeleteIcon />
              </Button>
            </div>
          </>
        )}
        <Divider style={{ marginTop: 20 }} />
        {messages ? (
          <div style={{ marginTop: 20 }}>
            <div
              style={{
                marginBottom: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <H6 color="#484848">Messages & Display Conditions</H6>
              <ButtonPrimary onClick={() => setAddMessageModal(true)}>
                Add New Condition
              </ButtonPrimary>
            </div>
            {messages.map((msg, i) => {
              return (
                <div
                  key={msg._id}
                  style={{
                    backgroundColor: lislColors.beige,
                    paddingTop: 15,
                    paddingBottom: 15,
                    paddingLeft: 5,
                    paddingRight: 5,
                    marginBottom: 10,
                  }}
                >
                  <Typography
                    style={{ fontSize: 16, color: "#1b4965", marginLeft: 10 }}
                  >
                    Condition nº {i + 1} : {msg.condition}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 16,
                      color: "#444",
                      marginLeft: 10,
                      marginTop: 10,
                    }}
                  >
                    Message : {msg.message}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      marginTop: 10,
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        setSelectedMessage(msg);
                        setDeleteMessageModal(true);
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                    <Button
                      style={{ marginRight: 10 }}
                      variant="outlined"
                      color="info"
                      onClick={() => {
                        setSelectedMessage(msg);
                        setUpdateMessageModal(true);
                      }}
                    >
                      <Edit />
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              marginBottom: 20,
            }}
          >
            <ButtonPrimary onClick={() => setAddMessageModal(true)}>
              Add New Condition
            </ButtonPrimary>
          </div>
        )}
        {messages && <Divider style={{ marginTop: 20 }} />}
        <div style={{ marginTop: 20, display: "flex", alignItems: "center" }}>
          <H6 color="#484848">Questionnaire Content</H6>
          {"  "}
          <Typography
            style={{ fontSize: 14, color: "#484848", marginLeft: 10 }}
          >
            {questions.length} Question(s)
          </Typography>
        </div>
        <div style={{ marginTop: 10 }}>
          <Questions id={questionnaire_id} data={questions} />
        </div>
      </ContentWrapper>
      {/** dialog to confirm delete  questionnaire*/}
      <Dialog
        open={deleteModal}
        onClose={setDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete this questionnaire?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to permanently delete this questionnaire?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "10px" }}>
          <Button onClick={deleteHandler}>
            {deleteLoader ? <LoadingIcon /> : "Delete"}
          </Button>
          <Button autoFocus onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/** dialog to confirm delete message */}
      <Dialog
        open={deleteMessageModal}
        onClose={setDeleteMessageModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete this message?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to permanently delete this condition?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "10px" }}>
          <Button onClick={deleteMessageHandler}>
            {deleteLoader ? <LoadingIcon /> : "Delete"}
          </Button>
          <Button autoFocus onClick={() => setDeleteMessageModal(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/** dialog to add message */}
      <AddQuestionnaireMessage
        questionnaire_id={questionnaire_id}
        isOpen={addMessageModal}
        setIsOpen={setAddMessageModal}
      />
      {/** dialog to add message */}
      {selectedMessage && (
        <UpdateQuestionnaireMessage
          msg={selectedMessage}
          isOpen={updateMessageModal}
          setIsOpen={setUpdateMessageModal}
        />
      )}
    </Wrapper>
  );
};

export default UpdateQuestionnaire;
