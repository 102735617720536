import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ButtonPrimary, ButtonSecondary } from "../buttonStyles/style";
import { H6, LoadingIcon, SmallBoldText } from "../textStyles/style";
import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import { countries } from "../../data/SelectOptions";
import { Input, SelectInput } from "../Inputs";
import { useHistory } from "react-router-dom";
import MultipleSelect from "../MulitpleSelect";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export default function AddGroupModal({ isOpen, setIsOpen, trails }) {
  const history = useHistory();
  const [name, setName] = useState("");

  const [country, setCountry] = useState("FR");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [reporting, setReporting] = useState([])
  const [submitLoader, setSubmitLoader] = React.useState(false);

  const handleClose = () => {
    if (!submitLoader) {
      setIsOpen(false);
      setErrorMessage("");
      setName("");
      setCountry("FR");
    }
  };

  const submitHandler = () => {
    if (name === "") {
      setErrorMessage("Group name is required");
      setSubmitLoader(false);
    } else if (country === "") {
      setErrorMessage("Country is required");
      setSubmitLoader(false);
    } else {
      if (!submitLoader) {
        setErrorMessage("");
        setSubmitLoader(true);
        const TOKEN = localStorage.getItem("TOKEN");
        axios
          .post(process.env.REACT_APP_GROUPS, {
            access_token: TOKEN,
            name,
            country,
            reporting
          })
          .then(() => {
            setIsOpen(false);
            setCountry("FR");
            setName("");
            setErrorMessage("");
            setSubmitLoader(false);
          })
          .catch((err) => {
            console.log(err);
            setSubmitLoader(false);
          });
      }
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
      <TitleContainer>
        <H6>Add New Group</H6>
      </TitleContainer>

      <DialogContent>
        {/** form */}
        {/** civilité + poste */}
        <Row>
          <SelectInput
            values={countries}
            placeholder="country *"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
          <Input
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Row>
        <Row>

          <MultipleSelect
            transparent
            placeholder="Choose From Trails list. (to show in company report)"
            options={trails}
            selectedValues={reporting}
            setSelectedValues={setReporting}
          />
        </Row>
        {/** error message */}
        <SmallBoldText
          style={{ textAlign: "center", fontSize: 12, color: "#c22222" }}
        >
          {errorMessage ? errorMessage : " - "}
        </SmallBoldText>
      </DialogContent>

      <DialogActions style={{ marginTop: -10, marginBottom: 15 }}>
        <ButtonPrimary onClick={submitHandler}>
          {submitLoader ? <LoadingIcon color="#ffffff" /> : "Save"}
        </ButtonPrimary>

        <ButtonSecondary onClick={handleClose}>Close</ButtonSecondary>
      </DialogActions>
    </Dialog>
  );
}
