import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ButtonPrimary, ButtonSecondary } from "../buttonStyles/style";
import { H5, H6, LoadingIcon, SmallBoldText } from "../textStyles/style";
import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import { Input } from "../Inputs";
import SuccessImage from "../../images/main/green_circle_tick.svg";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const Row = styled.div`
  margin-top: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

const Container = styled.div`
  padding: 20px;
`;

export default function ChangeUserPassword({ isOpen, setIsOpen }) {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [submitLoader, setSubmitLoader] = React.useState(false);
  const MySwal = withReactContent(Swal);
  const handleClose = () => {
    if (!submitLoader) {
      setIsOpen(false);
      setErrorMessage("");
      setPassword("");
      setNewPassword("");
      setConfirmPassword("");
    }
  };

  const submitHandler = () => {
    if (!submitLoader) {
      setErrorMessage("");
      setSubmitLoader(true);
      if (newPassword.length < 8) {
        setSubmitLoader(false);
        setErrorMessage("New Password must have at least 8 chars");
      } else if (newPassword != confirmPassword) {
        setErrorMessage("Password does not match");
        setSubmitLoader(false);
      } else {
        const TOKEN = localStorage.getItem("TOKEN");
        axios
          .post(process.env.REACT_APP_DASHBOARD_PROFILE_USER, {
            access_token: TOKEN,
            password,
            newPassword,
          })
          .then(() => {
            setSubmitLoader(false);
            MySwal.fire({
              title: <H5>Modifications saved</H5>,
              html: <></>,
              imageUrl: SuccessImage,
              imageWidth: 200,
              imageHeight: 150,
              imageAlt: "Custom image",
              timer: 1500,
              showConfirmButton: false,

              timerProgressBar: true,
              padding: "2vw",
              showCloseButton: true,
              showClass: {
                popup: "animate__animated animate__fadeInDown animate__fast",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp animate__faster",
              },
            });
            handleClose();
          })
          .catch((err) => {
            if (err.response.status === 400) {
              setSubmitLoader(false);
              setErrorMessage("Invalid Password");
            } else if (err.response.status === 403) {
              setSubmitLoader(false);
              setErrorMessage("Wrong Password");
            } else {
              setSubmitLoader(false);
              setErrorMessage("Connexion Problem");
            }
          });
      }
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
      <TitleContainer>
        <H6>Change my password</H6>
      </TitleContainer>

      {/** form */}
      {/** civilité + poste */}
      <Container>
        <Row>
          <Input
            placeholder="Current password *"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Row>
        {/** firstname - lastname */}
        <Row>
          <Input
            placeholder="New password *"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </Row>
        <Row>
          <Input
            placeholder="Confirm new password *"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Row>
        <Row style={{ justifyContent: "flex-start" }}>
          <SmallBoldText
            style={{
              textAlign: "center",
              fontSize: 14,
              marginLeft: 5,
              color: "#c22222",
            }}
          >
            {errorMessage ? errorMessage : " - "}
          </SmallBoldText>
        </Row>
      </Container>

      <DialogActions style={{ marginTop: -10, marginBottom: 15 }}>
        <ButtonPrimary onClick={submitHandler}>
          {submitLoader ? <LoadingIcon color="#ffffff" /> : "Change password"}
        </ButtonPrimary>

        <ButtonSecondary onClick={handleClose}>Close</ButtonSecondary>
      </DialogActions>
    </Dialog>
  );
}
