import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import styled from "styled-components";
import {
  H5,
  LoadingBigIcon,
  SmallText,
} from "../../components/textStyles/style";
import axios from "axios";
import {
  sectorsOptions,
  companyTypologieOptions,
  companiesPaymentOptions,
} from "../../data/SelectOptions";
import {
  ButtonPrimary,
  ButtonPrimaryLink,
  ButtonSecondary,
} from "../../components/buttonStyles/style";
import { ChipText } from "../../components/tables/styles";
import { SearchInput, SelectInput } from "../../components/Inputs";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { ArrowCircleDown, Person } from "@mui/icons-material";
import AddGroupModal from "../../components/modals/AddGroupModal";
import UpdateGroupModal from "../../components/modals/UpdateGroupModal";
import GroupsModal from "../../components/modals/GroupsModal";
import { lislColors } from "../../styles/colors";


let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;

const LoadWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
`;

const SearchContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const Companies = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [sector, setSector] = useState("");
  const [direction, setDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("createdAt");
  const [showAddGroup, setShowAddGroup] = useState(false);
  const [showGroups, setShowGroups] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [showUpdateGroup, setShowUpdateGroup] = useState(false)
  const [trails, setTrails] = useState([])
  const sectors = [
    {
      label: "All",
      value: "",
    },
    ...sectorsOptions,
  ];

  const sortOptions = [
    { label: "Creation Date", value: "createdAt" },
    { label: "Max users", value: "max_users" },
    { label: "Current users", value: "current_users" },
    { label: "Trails number", value: "number_trails" },
    { label: "Max Flash Coaching", value: "max_coaching" },
    { label: "Used Flash Coaching", value: "used_coaching" },
  ];


  const getTrailsAndAddGroup = async () => {
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };

    // get trails dropdown
    const trailsDropdown = await axios.get(
      `${process.env.REACT_APP_GET_TRAILS_DROPDOWN}`,
      config
    );
    setTrails(trailsDropdown.data)

    setShowAddGroup(true);
  };

  const getGroups = async () => {
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const Response = await axios.get(`${process.env.REACT_APP_GROUPS}`, config);
    // get trails dropdown
    const trailsDropdown = await axios.get(
      `${process.env.REACT_APP_GET_TRAILS_DROPDOWN}`,
      config
    );
    setTrails(trailsDropdown.data)

    setGroups(Response.data);
    setShowGroups(true);
  };

  const getData = async () => {
    setLoader(true);
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const Response = await axios.get(
      `${process.env.REACT_APP_GET_COMPANIES}?keyword=${keyword}&sector=${sector}&direction=${direction}&sortBy=${sortBy}`,
      config
    );

    setData(Response.data);

    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [keyword, sector, direction, sortBy]);

  return (
    <Wrapper>
      <ContentWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginBottom: 10,
          }}
        >
          <ButtonSecondary style={{ marginLeft: 10 }} onClick={getGroups}>
            All Groups
          </ButtonSecondary>
          <ButtonPrimary onClick={() => getTrailsAndAddGroup()}>
            Add new Group
          </ButtonPrimary>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <div style={{ width: 260 }}>
            <ButtonPrimaryLink to="/new-company">
              Add new Company
            </ButtonPrimaryLink>
          </div>
          <SearchContainer>
            <IconButton
              onClick={() =>
                direction === "asc" ? setDirection("desc") : setDirection("asc")
              }
            >
              {direction === "asc" ? (
                <ArrowCircleUpIcon />
              ) : (
                <ArrowCircleDown />
              )}
            </IconButton>
            <div style={{ width: 200, marginRight: 20 }}>
              <SelectInput
                values={sortOptions}
                placeholder="Sort By"
                value={sortBy}
                onChange={(e) => {
                  setSortBy(e.target.value);
                }}
              />
            </div>

            <div style={{ width: 200, marginRight: 20 }}>
              <SelectInput
                values={sectors}
                placeholder="Sector"
                value={sector}
                onChange={(e) => {
                  setSector(e.target.value);
                }}
              />
            </div>

            <SearchInput
              label="Company ..."
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />

            <SmallText>{data.length} companies found</SmallText>
          </SearchContainer>
        </div>

        <H5>Companies List</H5>
        {loader ? (
          <LoadWrapper>
            <LoadingBigIcon />
          </LoadWrapper>
        ) : (
          <div style={{ margin: 30 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Group
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Payment
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Typology
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Sector
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Connected Users
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Max Users
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Trails access
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Used Coaching
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Purchased Ebooks
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  ></TableCell>
                </TableHead>
                <TableBody>
                  {data.map((company) => {
                    const group =
                      company.group_data.length > 0
                        ? company.group_data[0]
                        : null;
                    const typologie = companyTypologieOptions.find(
                      (typ) => typ.value === company.typologie
                    );
                    const sector = sectorsOptions.find(
                      (sec) => sec.value === company.sector
                    );
                    const payment = companiesPaymentOptions.find(
                      (el) => el.value === company.payment
                    );
                    return (
                      <TableRow key={company._id}>
                        <TableCell align="center" scope="row">
                          {group ? `${group.name} (${group.country})` : "-"}
                        </TableCell>
                        <TableCell align="center" scope="row">
                          {company.name ? company.name : "-"}
                          {company.nego ? <Typography style={{ backgroundColor: lislColors.red1, padding: 5, fontSize: 10, color: '#fff', fontWeight: 'bold', marginTop: 5, borderRadius: 5 }}>Under Negotiation</Typography> : null}
                        </TableCell>
                        <TableCell align="center" scope="row">
                          {payment ? payment.label : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {typologie ? typologie.label : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {sector ? sector.label : "-"}
                        </TableCell>

                        <TableCell align="center">
                          {`${company.current_users} user(s)`}
                        </TableCell>
                        <TableCell align="center">
                          {`${company.max_users} user(s)`}
                        </TableCell>
                        <TableCell align="center">
                          {company.number_trails} trail(s)
                        </TableCell>
                        <TableCell align="center">
                          {`${company.used_coaching} / ${company.max_coaching}`}
                        </TableCell>

                        <TableCell align="center">
                          {`${company.purchased_ebooks} / ${company.ebooks} `}
                        </TableCell>
                        <TableCell align="center">
                          {company.status === "active" ? (
                            <ChipText success>Active</ChipText>
                          ) : (
                            <ChipText danger>Inactive</ChipText>
                          )}
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell style={{ display: 'flex', alignItems: 'center' }} align="center">
                          <ButtonPrimaryLink
                            to={`/company-users/${company._id}`}
                            width="40px"
                            height="40px"
                          >
                            <Person />
                          </ButtonPrimaryLink>
                          <div style={{ marginLeft: 5 }}>
                            <ButtonPrimaryLink
                              to={`/company/${company._id}`}
                              width="40px"
                              height="40px"
                            >
                              &#8594;
                            </ButtonPrimaryLink>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </ContentWrapper>
      {/** Add group modal */}
      <AddGroupModal trails={trails} isOpen={showAddGroup} setIsOpen={setShowAddGroup} />
      {/** update Group modal */}
      {selectedGroup && <UpdateGroupModal trails={trails} getGroups={getGroups} selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} isOpen={showUpdateGroup} setIsOpen={setShowUpdateGroup} />}
      {/**  group modal */}
      <GroupsModal
        setSelectedGroup={setSelectedGroup}
        setShowUpdateGroup={setShowUpdateGroup}
        isOpen={showGroups}
        setIsOpen={setShowGroups}
        groups={groups}
      />
    </Wrapper>
  );
};

export default Companies;
