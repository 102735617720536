import {
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  H5,
  LoadingBigIcon,
  SmallText,
} from "../../components/textStyles/style";
import axios from "axios";

import { ChipText } from "../../components/tables/styles";

import { PaginationContainer, SearchContainer } from "../app-users/appUsers";
import { SearchInput, SelectInput } from "../../components/Inputs";
import moment from "moment";
import { Payment } from "@material-ui/icons";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;

const LoadWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
`;
const Gifts = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesNumber, setPagesNumber] = useState(null);
  const [searchTotal, setSearchTotal] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [used, setUsed] = useState("fr");

  const usedStates = [
    { label: "All", value: "all" },
    { label: "active", value: "true" },
    { label: "Not active", value: "false" },
  ];

  const getData = async () => {
    setLoader(true);
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const url = `${process.env.REACT_APP_GIFTS}?used=${used}&pageNumber=${currentPage}&keyword=${keyword}`;

    const Response = await axios.get(url, config);
    const { gifts, page, pages, count } = Response.data;

    setData(gifts);

    setCurrentPage(page);
    setPagesNumber(pages);
    setSearchTotal(count);
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [currentPage, keyword, used]);

  return (
    <Wrapper>
      <ContentWrapper>
        <H5>Subscriptions Gifts</H5>
        <SearchContainer style={{ marginBottom: 15 }}>
          {/** used */}
          <div style={{ width: 250 }}>
            <SelectInput
              values={usedStates}
              placeholder="Status"
              value={used}
              onChange={(e) => {
                setCurrentPage(1);
                setUsed(e.target.value);
              }}
            />
          </div>

          {/** keyword */}
          <div style={{ width: 400 }}>
            <SearchInput
              style={{ width: 400 }}
              onFocus={() => setCurrentPage(1)}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </div>

          <SmallText>{searchTotal} result(s) found</SmallText>
        </SearchContainer>
        {loader ? (
          <LoadWrapper>
            <LoadingBigIcon />
          </LoadWrapper>
        ) : (
          <div style={{ margin: 30 }}>
            {data.length > 0 && pagesNumber && (
              <PaginationContainer>
                <Pagination
                  color="primary"
                  count={pagesNumber}
                  page={currentPage}
                  onChange={(e, val) => setCurrentPage(val)}
                />
              </PaginationContainer>
            )}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Buy at
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    User
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Recipient
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965", width: 110 }}
                  >
                    Plan
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965", width: 110 }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", color: "#1B4965" }}
                  >
                    Payment
                  </TableCell>
                </TableHead>
                <TableBody>
                  {data.map((gift) => {
                    return (
                      <TableRow key={gift._id}>
                        <TableCell
                          style={{ color: "#1B4965", fontSize: 13 }}
                          align="center"
                        >
                          {moment(gift.createdAt).format("LLL")}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "#1B4965",
                            fontSize: 14,
                          }}
                          align="center"
                        >
                          {gift.user}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "#1B4965",
                            fontSize: 14,
                          }}
                          align="center"
                        >
                          {gift.email}
                        </TableCell>

                        <TableCell align="center">
                          {gift.plan === "sub_lifetime" ? (
                            <ChipText style={{ width: 110 }}>
                              Lifetime Plan
                            </ChipText>
                          ) : gift.plan === "sub_annual" ? (
                            <ChipText style={{ width: 110 }}>
                              Annual Plan
                            </ChipText>
                          ) : gift.plan === "sub_three_months" ? (
                            <ChipText style={{ width: 110 }}>
                              3 Months Plan
                            </ChipText>
                          ) : (
                            <ChipText style={{ width: 110 }}>
                              1 Month Plan
                            </ChipText>
                          )}
                        </TableCell>
                        <TableCell
                          style={{ color: "#1B4965", fontSize: 13 }}
                          align="center"
                        >
                          <Typography fontSize={13}>
                            {gift.used ? (
                              moment(gift.usedAt).format("LLL")
                            ) : (
                              <ChipText danger style={{ width: 110 }}>
                                Not active yet
                              </ChipText>
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            onClick={() => {
                              window.open(
                                `https://dashboard.stripe.com/payments/${gift.payment}`,
                                "_blank"
                              );
                            }}
                            color="primary"
                            aria-label="see payment"
                            component="label"
                          >
                            <Payment />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default Gifts;
