import { Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { LoadingIcon } from "../../components/textStyles/style";

const CommentStatus = ({ post_id }) => {
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState("");
  useEffect(() => {
    setLoader(true);
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    axios
      .get(
        `${process.env.REACT_APP_COMMUNITY_COMMENTS_STATUS}?post_id=${post_id}`,
        config
      )
      .then((res) => {
        setData(res.data);
        setLoader(false);
      });
  }, []);
  if (loader) {
    return <LoadingIcon />;
  }
  return (
    <div>
      <Typography
        style={{ color: "#000" }}
        fontSize={13}
        fontWeight="600"
      >{`${data.numberOfReportedComments} commentaires signalés`}</Typography>
    </div>
  );
};

export default CommentStatus;
